import React, { useEffect, useState } from 'react';
import { Button, Input, Row } from '@a_team/ui-components';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  headerItem: {
    width: '223px',
  },
});

export const ReorderableMultipleInputFieldSet: React.FC<{
  values: any[];
  onSave: (values: any[]) => void;
  renderChild?: (
    index: number,
    value: any,
    onChange: (index: number, key: string, value: string | number | boolean) => void
  ) => JSX.Element;
  getEmptyItem?: () => any;
  headers?: string[];
}> = ({ values, onSave, renderChild, getEmptyItem, headers }) => {
  const styles = useStyles();
  const [items, setItems] = useState<any[]>([{ value: '' }]);

  const onValueChange = (index: number, key: string, value: string | number | boolean) => {
    const item = items[index];
    const isBoolean = value === false || value === true;
    const isEmpty = item?.length === 0;
    const numericValue = Number(value);
    item[key] = isNaN(numericValue) || isBoolean || isEmpty ? value : numericValue;

    setItems((current) => [...current.splice(0, index), item, ...current.splice(1, current.length - 1)]);
  };

  const onNewClick = () => {
    setItems((current) => [...current, getEmptyItem ? getEmptyItem() : { value: '' }]);
  };

  const onSaveClick = () => {
    onSave(items);
  };

  const onRemoveClick = (index: number) => {
    setItems((current) => [...current.splice(0, index), ...current.splice(1, current.length - 1)]);
  };

  const onHigherOrderClick = (index: number) => {
    const item = items[index];
    setItems((current) => [
      ...current.splice(0, index - 1),
      item,
      current[0],
      ...(2 < current.length ? current.splice(2, current.length - 2) : []),
    ]);
  };

  const onLowerOrderClick = (index: number) => {
    const item = items[index];
    setItems((current) => [
      ...current.splice(0, index),
      current[1],
      item,
      ...(current.length > 2 ? current.splice(2, current.length - 2) : []),
    ]);
  };

  useEffect(() => {
    if (values?.length) {
      setItems(values);
    }
  }, [values]);

  return (
    <>
      <Row direction="column" align="stretch" justify="between" noGutters>
        {headers && (
          <Row noGutters align="stretch" style={{ gap: 10 }}>
            {headers.map((header) => (
              <span className={styles.headerItem}>{header}</span>
            ))}
          </Row>
        )}
        {items.map((item, index) => (
          <Row align="stretch" noGutters style={{ gap: 10 }}>
            {renderChild ? (
              renderChild(index, item, onValueChange)
            ) : (
              <Input
                key={`input_${index}`}
                name={`item_${index}`}
                onChange={(e) => onValueChange(index, 'value', e.target.value)}
                value={item.value}
              />
            )}
            <Button
              key={`btn_up_${index}`}
              size="sm"
              variant="ghost"
              iconProps={{ name: 'arrowUp' }}
              onClick={() => onHigherOrderClick(index)}
              disabled={index === 0}
              role="button"
            >
              Up
            </Button>
            <Button
              key={`btn_down_${index}`}
              size="sm"
              variant="ghost"
              iconProps={{ name: 'arrowDown' }}
              onClick={() => onLowerOrderClick(index)}
              disabled={index === items.length - 1}
              role="button"
            >
              Down
            </Button>
            <Button
              key={`btn_remove_${index}`}
              size="sm"
              variant="ghost"
              iconProps={{ name: 'remove' }}
              onClick={() => onRemoveClick(index)}
              disabled={items.length <= 1}
              role="button"
            >
              Remove
            </Button>
          </Row>
        ))}
      </Row>
      <Row style={{ gap: 10 }} noGutters>
        <Button size="sm" variant="ghost" iconProps={{ name: 'arrowOpen' }} onClick={onNewClick}>
          New
        </Button>
        <Button size="sm" variant="ghost" iconProps={{ name: 'send' }} onClick={onSaveClick}>
          Save
        </Button>
      </Row>
    </>
  );
};
