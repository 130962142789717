import React from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: React.ReactNode[];
  separator?: React.ReactNode;
}

const useStyles = createUseStyles({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    margin: '16px 0',
  },
});

const RowContainer = ({ children, separator }: Props) => {
  const styles = useStyles();

  return (
    <div className={styles.rowContainer}>
      {children.map((child, index) =>
        separator && index !== 0 ? (
          <>
            {separator}
            {child}
          </>
        ) : (
          child
        )
      )}
    </div>
  );
};

export default RowContainer;
