import { AsyncThunk, configureStore } from '@reduxjs/toolkit';
import { auth } from 'features/auth/reducers/auth-reducers';
import { coldIdeas } from 'features/cold-ideas/reducers/cold-ideas-reducer';
import { companyPagesGenerationReducer } from 'features/company-pages-generation/redux/slice';
import { companyPages } from 'features/company-pages/reducers/company-pages-reducer';
import { profile as dqsProfileReducers } from 'features/dqs/reducers/profile-reducers';
import { dqsSettings as dqsSettingsReducers } from 'features/dqs/reducers/settings-reducers';
import { testbed as dqsTestbedReducers } from 'features/dqs/reducers/testbed-reducers';
import { enrichCompanies } from 'features/enrich-companies/reducers/enrich-companies-reducer';
import { facebookFriends } from 'features/facebook-friends/reducers/facebook-friends-reducer';
import { introSearchReducer } from 'features/intro-search/redux/slice';
import { linkedinConnections } from 'features/linkedin-connections/reducers/linkedin-connections-reducer';
import { linkedInJobPostings } from 'features/linkedin-job-postings/reducers/linkedin-job-postings-reducer';
import { lqs } from 'features/lqs/reducers/lqs-reducers';
import { scrubbing } from 'features/scrubbing/reducers/scrubbing-reducer';
import { scsReducer } from 'features/scs-request/redux/slice';
import { scsScoringProfile } from 'features/scs/reducers/scoring-profile-reducers';
import { scoringRequest as scsScoringRequest } from 'features/scs/reducers/scoring-request-reducers';
import { sgeOutbound } from 'features/sge-outbound/reducers/sge-outbound-reducer';
import { sge } from 'features/sge/reducers/sge-reducers';
import { supplyRequests } from 'features/supply-requests/reducers/supply-requests-reducers';
import { targetedLeads } from 'features/targeted-leads/reducers/targeted-leads-reducers';
import { teamRequestPagesReducer } from 'features/team-request-pages/redux/slice';
import waitlistedRoles from 'features/waitlisted-roles/reducers/waitlisted-roles-reducer';
import { warmLeadsUtils } from 'features/warm-leads-utils/reducers/warm-leads-utils-reducer';
import { warmLeads } from 'features/warm-leads/reducers/warm-leads-reducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: auth.reducer,
    lqs: lqs.reducer,
    sge: sge.reducer,
    scrubbing: scrubbing.reducer,
    facebookFriends: facebookFriends.reducer,
    linkedinConnections: linkedinConnections.reducer,
    warmLeads: warmLeads.reducer,
    warmLeadsUtils: warmLeadsUtils.reducer,
    sgeOutbound: sgeOutbound.reducer,
    supplyRequests: supplyRequests.reducer,
    dqsTestbedReducers: dqsTestbedReducers.reducer,
    dqsSettingsReducers: dqsSettingsReducers.reducer,
    dqsProfileReducers: dqsProfileReducers.reducer,
    scsScoringProfile: scsScoringProfile.reducer,
    scsScoringRequest: scsScoringRequest.reducer,
    companyPages: companyPages.reducer,
    linkedInJobPostings: linkedInJobPostings.reducer,
    targetedLeads: targetedLeads.reducer,
    coldIdeas: coldIdeas.reducer,
    enrichCompanies: enrichCompanies.reducer,
    waitlistedRoles: waitlistedRoles,
    companyPagesGeneration: companyPagesGenerationReducer,
    teamRequestPages: teamRequestPagesReducer,
    scs: scsReducer,
    introSearch: introSearchReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<Payload> = AsyncThunk<void, Payload, {}>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
