import { AxiosPromise } from 'axios';
import axios from '../config/axios-config';
import { getFormData } from './helpers';

const uploadLinkedinConnections = (
  primaryNodeLinkedinUrl: string,
  secondaryLinkedinUrlsFile: File | undefined
): AxiosPromise<{ message: string }> => {
  return axios.supplyInstance({
    method: 'POST',
    url: `v1/connections/linkedin`,
    data: getFormData({
      primaryNodeLinkedinUrl,
      csv: secondaryLinkedinUrlsFile,
    }),
  });
};

export default {
  uploadLinkedinConnections,
};
