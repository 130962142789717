import { Tab, Tabs } from '@a_team/ui-components';
import { FC } from 'react';
import { LinkedinJobPostingsCsv } from './linkedin-job-postings-csv';
import { LinkedInJobPostingsSingleCompany } from './linkedin-job-postings-single-company';

export const LinkedInJobPostings: FC = () => {
  return (
    <>
      <Tabs>
        <Tab id="first" label={'Single company'}>
          <LinkedInJobPostingsSingleCompany />
        </Tab>
        <Tab id="second" label={'Multiple companies via CSV'}>
          <LinkedinJobPostingsCsv />
        </Tab>
      </Tabs>
    </>
  );
};
