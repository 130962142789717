import { Button, Checkbox, Input, Select, SelectOption, Typography } from '@a_team/ui-components';
import PageHeader from 'core/components/page-header';
import RowContainer from 'core/components/row-container';
import { LqsSettingsLocation } from 'core/routing/locations';
import { RootState } from 'core/store';
import {
  handleFetchProfileTypesRequest,
  handleRunQueryRequest,
  handleUploadLinkedinUrlsFileRequest,
  handleUploadNamesCsvRequest,
} from 'features/lqs/actions/lqs-actions';
import { ChangeEvent, FC, FormEvent, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = createUseStyles({
  container: {
    maxWidth: '800px',
  },
});

export const LqsScore: FC = () => {
  const { container } = useStyles();
  const dispatch = useDispatch();
  const profileOptions = useSelector((state: RootState) => state.lqs.profileSettings.profileOptions);

  const { requestId, queryResults, runQueryInProgress, uploadNameFileInProgress, uploadLinkedIdUrlsFileInProgress } =
    useSelector((state: RootState) => state.lqs);

  const [query, setQuery] = useState('');
  const [skills, setSkills] = useState('');
  const [positions, setPositions] = useState('');
  const [github, setGithub] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [includeEmail, setIncludeEmail] = useState(false);
  const [includeClosestConnections, setIncludeClosestConnections] = useState(false);
  const [includeEmployeeCount, setIncludeEmployeeCount] = useState(false);
  const [applyExclusionLogic, setApplyExclusionLogic] = useState(true);
  const [selectedProfile, setSelectedProfile] = useState(profileOptions[0]);

  useEffect(() => {
    dispatch(handleFetchProfileTypesRequest());
  }, []);

  useEffect(() => {
    if (profileOptions && profileOptions.length > 0) {
      setSelectedProfile(profileOptions[0]);
    }
  }, [profileOptions]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      handleRunQueryRequest(query, selectedProfile.value, {
        skills,
        positions,
        github,
        utmCampaign,
      })
    );
  };

  const handleUploadNamesCSV = (e: ChangeEvent) => {
    const files = (e.target as HTMLInputElement)?.files;
    if (files !== null && files.length > 0) {
      dispatch(
        handleUploadNamesCsvRequest(selectedProfile.value, files[0], {
          skills,
          positions,
          github,
          utmCampaign,
          includeEmail,
          includeClosestConnections,
          includeEmployeeCount,
          applyExclusionLogic,
        })
      );
    }
  };

  const handleUploadLinkedInUrlsCSV = (e: ChangeEvent) => {
    const files = (e.target as HTMLInputElement)?.files;
    if (files !== null && files.length > 0) {
      dispatch(
        handleUploadLinkedinUrlsFileRequest(selectedProfile.value, files[0], {
          skills,
          positions,
          github,
          utmCampaign,
          includeEmail,
          includeClosestConnections,
          includeEmployeeCount,
          applyExclusionLogic,
        })
      );
    }
  };

  const capitalize = (key: string) => {
    return key.charAt(0).toUpperCase() + key.slice(1);
  };

  const hasQueryResults = useMemo(
    () => queryResults && queryResults.success && queryResults.rows?.length,
    [queryResults]
  );

  return (
    <div>
      <PageHeader
        title="LQS"
        description="LQS is a system that allows you to calculate a “Quality Score” for a LinkedIn profile. This score is calculated based on several variables such as years of experience, company/roles the builder worked at and more."
      />
      <form onSubmit={onSubmit}>
        <div className={container}>
          <Typography variant="h3">Upload a CSV file or enter LinkedIn url/name</Typography>
          <RowContainer separator="or">
            <Input type="file" accept=".csv" label="Upload LinkedIn URLs file" onChange={handleUploadLinkedInUrlsCSV} />
            <Input type="file" accept=".csv" label="Upload Names file" onChange={handleUploadNamesCSV} />
            <Input
              type="text"
              label="Query"
              placeholder="Enter LinkedIn URL or name"
              onChange={(e) => setQuery(e.target.value)}
              size="stretch"
            />
          </RowContainer>

          <Typography variant="textMedium" component="p" style={{ marginBottom: '12px' }}>
            Type of profile to use to calculate the score. Edit profiles in{' '}
            <Link to={LqsSettingsLocation}>Settings</Link>
          </Typography>
          <Select
            placeholder="Select the profile..."
            value={selectedProfile}
            onChange={(value) => setSelectedProfile(value as SelectOption)}
            options={profileOptions}
          />
          <Typography variant="textMedium" component="p" style={{ margin: '12px 0' }}>
            For convenience purposes, Hardcode values for CSV output. This will make it easier to upload the CSV to a
            outbound platform with values of your choice.
          </Typography>
          <RowContainer>
            <Input
              type="text"
              placeholder="Positions"
              value={positions}
              onChange={(e) => setPositions(e.target.value)}
            />
            <Input type="text" placeholder="Skills" value={skills} onChange={(e) => setSkills(e.target.value)} />
            <Input type="text" placeholder="Github" value={github} onChange={(e) => setGithub(e.target.value)} />
            <Input
              type="text"
              placeholder="Utm Campaign"
              value={utmCampaign}
              onChange={(e) => setUtmCampaign(e.target.value)}
            />
          </RowContainer>
          <RowContainer>
            <Checkbox
              label="Include emails"
              onChange={(e) => setIncludeEmail(e.target.checked)}
              checked={includeEmail}
            ></Checkbox>
            <Checkbox
              label="Include connections"
              onChange={(e) => setIncludeClosestConnections(e.target.checked)}
              checked={includeClosestConnections}
            ></Checkbox>
            <Checkbox
              label="Include employee count"
              onChange={(e) => setIncludeEmployeeCount(e.target.checked)}
              checked={includeEmployeeCount}
            ></Checkbox>
            <Checkbox
              label="Apply exclusion logic"
              onChange={(e) => setApplyExclusionLogic(e.target.checked)}
              checked={applyExclusionLogic}
            ></Checkbox>
          </RowContainer>
        </div>
        <Button
          margin="sm"
          loading={runQueryInProgress || uploadNameFileInProgress || uploadLinkedIdUrlsFileInProgress}
        >
          SUBMIT
        </Button>
      </form>
      {requestId && (
        <div>
          {(hasQueryResults && (
            <>
              {Object.keys(queryResults.rows[0]).map((key, kIdx) => (
                <p key={kIdx}>
                  <Typography variant="textMedium" weight="extra-bold">
                    {capitalize(key)}:&nbsp;
                  </Typography>
                  <Typography variant="textMedium">{queryResults.rows[0][key]}</Typography>
                </p>
              ))}
            </>
          )) || (
            <>
              <p>
                Your request{' '}
                <small>
                  <code>{requestId}</code>
                </small>{' '}
                is created now. You&apos;ll get a new email soon.
                <br />
                If not, please contact the support team with your request id.
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
