import { Typography } from '@a_team/ui-components';
import { RootState } from 'core/store';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { CompanyPagesConfigForm } from './company-pages-config-form';

export const CompanyPages: FC = () => {
  const { loading } = useSelector((state: RootState) => state.companyPages);
  return (
    <>
      <Typography variant="h1">Company Pages Config</Typography>
      <CompanyPagesConfigForm loading={loading} />
    </>
  );
};
