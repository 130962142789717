export const emptyStringPropsToNull = (input: any): any => {
  Object.getOwnPropertyNames(input).forEach((key) => {
    input[key] = input[key] === '' ? null : input[key];
  });

  return input;
};

export const getHoursFromCron = (input: string): number => {
  try {
    const hoursSegment = input.split(' ')[1];
    return Number(hoursSegment.replace('*/', ''));
  } catch (err) {
    return -1;
  }
};

export const getCronFromHours = (input: number): string => {
  return `0 */${input} * * *`;
};
