import * as actionsTypes from 'features/warm-leads/actions/warm-leads-action-types';
import { CreateWarmLeadsRequestModel, GetParamsSuccessModel } from 'features/warm-leads/warm-leads-request-model';

export const handleCreateWarmLeadsRequest = (
  payload: CreateWarmLeadsRequestModel
): actionsTypes.CreateWarmLeadsRequestAction => ({
  type: actionsTypes.CREATE_WARM_LEADS_REQUEST,
  payload,
});

export const handleCreateWarmLeadsSuccess = (requestId: string): actionsTypes.CreateWarmLeadsSuccessAction => ({
  type: actionsTypes.CREATE_WARM_LEADS_SUCCESS,
  requestId,
});

export const handleCreateWarmLeadsFail = (errorMessage: string): actionsTypes.CreateWarmLeadsFailAction => ({
  type: actionsTypes.CREATE_WARM_LEADS_FAIL,
  errorMessage,
});

export const handleGetParamsRequest = (): actionsTypes.GetParamsRequestAction => ({
  type: actionsTypes.GET_WARM_LEADS_PARAMS_REQUEST,
});

export const handleGetParamsSuccess = (payload: GetParamsSuccessModel): actionsTypes.GetParamsSuccessAction => ({
  type: actionsTypes.GET_WARM_LEADS_PARAMS_SUCCESS,
  payload,
});

export const handleGetParamsFail = (errorMessage: string): actionsTypes.GetParamsFailAction => ({
  type: actionsTypes.GET_WARM_LEADS_PARAMS_FAIL,
  errorMessage,
});
