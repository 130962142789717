import { FormItemConfig } from 'core/components/field-type';
import { StandaloneField } from 'core/components/standalone-field';

type Props = {
  fieldConfig: FormItemConfig[];
  formik: any;
  tagContainer: string;
};

const FieldGenerator = ({ fieldConfig, formik, tagContainer }: Props) => {
  return (
    <>
      {fieldConfig.map((formField) => {
        return (
          <div key={`field-${formField.name || ''}`}>
            <StandaloneField
              formField={formField}
              formik={formik}
              tagContainer={tagContainer}
              options={formField.options}
              showErrors={true}
              size={formField.size}
              disabled={formField.disabled}
            />
          </div>
        );
      })}
    </>
  );
};

export default FieldGenerator;
