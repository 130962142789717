import { SelectOption } from '@a_team/ui-components';
import { BaseScoringProfile } from 'core/models/Profiles';
import { ScoreRequestMetadata } from 'core/models/SupplyScore';
import { Settings } from '../models/settings';

export const FETCH_PROFILE_TYPES_REQUEST = 'lqs/FETCH_PROFILE_TYPES_REQUEST';
export interface FetchProfileTypesRequestAction {
  type: typeof FETCH_PROFILE_TYPES_REQUEST;
}

export const FETCH_PROFILE_TYPES_SUCCESS = 'lqs/FETCH_PROFILE_TYPES_SUCCESS';
export interface FetchProfileTypesSuccessAction {
  type: typeof FETCH_PROFILE_TYPES_SUCCESS;
  profileOptions: SelectOption[];
}

export const FETCH_PROFILE_TYPES_FAIL = 'lqs/FETCH_PROFILE_TYPES_FAIL';
export interface FetchProfileTypesFailAction {
  type: typeof FETCH_PROFILE_TYPES_FAIL;
}

export const SET_SELECTED_PROFILE = 'lqs/SET_SELECTED_PROFILE';
export interface SetSelectedProfileAction {
  type: typeof SET_SELECTED_PROFILE;
  selectedProfile: SelectOption;
}

export const SET_PROFILE_SETTINGS_ITEM = 'lqs/SET_PROFILE_SETTINGS_ITEM';
export interface SetProfileSettingsItemAction {
  type: typeof SET_PROFILE_SETTINGS_ITEM;
  value: string | number;
  modelPath: string[];
}

export const SET_CURRENT_PROFILE_NAME = 'lqs/SET_CURRENT_PROFILE_NAME';
export interface SetCurrentProfileName {
  type: typeof SET_CURRENT_PROFILE_NAME;
  value: string;
}

export const FETCH_PROFILE_SUCCESS = 'lqs/FETCH_PROFILE_SUCCESS';
export interface FetchProfileSuccessAction {
  type: typeof FETCH_PROFILE_SUCCESS;
  currentProfile: BaseScoringProfile;
}

export const FETCH_PROFILE_FAIL = 'lqs/FETCH_PROFILE_FAIL';
export interface FetchProfileFailAction {
  type: typeof FETCH_PROFILE_FAIL;
}

export const ADD_NEW_PROFILE = 'lqs/ADD_NEW_PROFILE';
export interface AddNewProfileAction {
  type: typeof ADD_NEW_PROFILE;
}

export const SAVE_PROFILE_SETTINGS_REQUEST = 'lqs/SAVE_PROFILE_SETTINGS_REQUEST';
export interface SaveProfileSettingsRequestAction {
  type: typeof SAVE_PROFILE_SETTINGS_REQUEST;
}

export const SAVE_PROFILE_SETTINGS_SUCCESS = 'lqs/SAVE_PROFILE_SETTINGS_SUCCESS';
export interface SaveProfileSettingsSuccessAction {
  type: typeof SAVE_PROFILE_SETTINGS_SUCCESS;
}

export const SAVE_PROFILE_SETTINGS_FAIL = 'lqs/SAVE_PROFILE_SETTINGS_FAIL';
export interface SaveProfileSettingsFailAction {
  type: typeof SAVE_PROFILE_SETTINGS_FAIL;
}

export const UPLOAD_NAME_CSV_REQUEST = 'lqs/UPLOAD_NAME_CSV_REQUEST';
export interface UploadNamesCsvRequestAction {
  type: typeof UPLOAD_NAME_CSV_REQUEST;
  selectedProfile: string;
  file: File;
  metadata: ScoreRequestMetadata;
}

export const UPLOAD_NAME_CSV_SUCCESS = 'lqs/UPLOAD_NAME_CSV_SUCCESS';
export interface UploadNamesCsvSuccessAction {
  type: typeof UPLOAD_NAME_CSV_SUCCESS;
  requestId: string;
}

export const UPLOAD_NAME_CSV_FAIL = 'lqs/UPLOAD_NAME_CSV_FAIL';
export interface UploadNamesCsvFailAction {
  type: typeof UPLOAD_NAME_CSV_FAIL;
}

export const UPLOAD_LINKEDIN_URLS_FILE_REQUEST = 'lqs/UPLOAD_LINKEDIN_URLS_FILE_REQUEST';
export interface UploadLinkedinUrlsFileRequestAction {
  type: typeof UPLOAD_LINKEDIN_URLS_FILE_REQUEST;
  selectedProfile: string;
  file: File;
  metadata: ScoreRequestMetadata;
}

export const UPLOAD_LINKEDIN_URLS_FILE_SUCCESS = 'lqs/UPLOAD_LINKEDIN_URLS_FILE_SUCCESS';
export interface UploadLinkedinUrlsFileSuccessAction {
  type: typeof UPLOAD_LINKEDIN_URLS_FILE_SUCCESS;
  requestId: string;
}

export const UPLOAD_LINKEDIN_URLS_FILE_FAIL = 'lqs/UPLOAD_LINKEDIN_URLS_FILE_FAIL';
export interface UploadLinkedinUrlsFileFailAction {
  type: typeof UPLOAD_LINKEDIN_URLS_FILE_FAIL;
}

export const RUN_QUERY_REQUEST = 'lqs/RUN_QUERY_REQUEST';
export interface RunQueryRequestAction {
  type: typeof RUN_QUERY_REQUEST;
  query: string;
  selectedProfile: string;
  metadata: ScoreRequestMetadata;
}

export const RUN_QUERY_SUCCESS = 'lqs/RUN_QUERY_SUCCESS';
export interface RunQuerySuccessAction {
  type: typeof RUN_QUERY_SUCCESS;
  requestId: string;
}

export const RUN_QUERY_FAIL = 'lqs/RUN_QUERY_FAIL';
export interface RunQueryFailAction {
  type: typeof RUN_QUERY_FAIL;
}

export const GET_QUERY_RESULTS_REQUEST = 'lqs/GET_QUERY_RESULTS_REQUEST';
export interface GetQueryResultsRequestAction {
  type: typeof GET_QUERY_RESULTS_REQUEST;
  requestId: string;
}

export const GET_QUERY_RESULTS_SUCCESS = 'lqs/GET_QUERY_RESULTS_SUCCESS';
export interface GetQueryResultsSuccessAction {
  type: typeof GET_QUERY_RESULTS_SUCCESS;
  result: any;
}

export const GET_QUERY_RESULTS_FAIL = 'lqs/GET_QUERY_RESULTS_FAIL';
export interface GetQueryResultsFailAction {
  type: typeof GET_QUERY_RESULTS_FAIL;
}

export const GET_SETTINGS_REQUEST = 'lqs/GET_SETTINGS_REQUEST';
export interface GetSettingsRequestAction {
  type: typeof GET_SETTINGS_REQUEST;
}

export const GET_SETTINGS_SUCCESS = 'lqs/GET_SETTINGS_SUCCESS';
export interface GetSettingsSuccessAction {
  type: typeof GET_SETTINGS_SUCCESS;
  result: Settings;
}

export const GET_SETTINGS_FAIL = 'lqs/GET_SETTINGS_FAIL';
export interface GetSettingsFailAction {
  type: typeof GET_SETTINGS_FAIL;
}

export const SAVE_SETTINGS_REQUEST = 'lqs/SAVE_SETTINGS_REQUEST';
export interface SaveSettingsRequestAction {
  type: typeof SAVE_SETTINGS_REQUEST;
}

export const SAVE_SETTINGS_SUCCESS = 'lqs/SAVE_SETTINGS_SUCCESS';
export interface SaveSettingsSuccessAction {
  type: typeof SAVE_SETTINGS_SUCCESS;
}

export const SAVE_SETTINGS_FAIL = 'lqs/SAVE_SETTINGS_FAIL';
export interface SaveSettingsFailAction {
  type: typeof SAVE_SETTINGS_FAIL;
}

export const SIGNAL_HIRE_EMAIL_CALL_LQS_THRESHOLD_CHANGE = 'lqs/SIGNAL_HIRE_EMAIL_CALL_LQS_THRESHOLD_CHANGE';
export interface SignalHireEmailCallLqsThresholdChangeAction {
  type: typeof SIGNAL_HIRE_EMAIL_CALL_LQS_THRESHOLD_CHANGE;
  value: number;
}

export const EXCLUDED_COMPANIES_CHANGE = 'lqs/EXCLUDED_COMPANIES_CHANGE';
export interface ExcludedCompaniesChangeAction {
  type: typeof EXCLUDED_COMPANIES_CHANGE;
  value: string;
}

export const EXCLUDED_SCHOOLS_CHANGE = 'lqs/EXCLUDED_SCHOOLS_CHANGE';
export interface ExcludedSchoolsChangeAction {
  type: typeof EXCLUDED_SCHOOLS_CHANGE;
  value: string;
}

export type LqsAction =
  | FetchProfileTypesRequestAction
  | FetchProfileTypesSuccessAction
  | FetchProfileTypesFailAction
  | SetSelectedProfileAction
  | FetchProfileSuccessAction
  | FetchProfileFailAction
  | SetProfileSettingsItemAction
  | SetCurrentProfileName
  | AddNewProfileAction
  | SaveProfileSettingsRequestAction
  | SaveProfileSettingsFailAction
  | SaveProfileSettingsSuccessAction
  | UploadNamesCsvRequestAction
  | UploadNamesCsvSuccessAction
  | UploadNamesCsvFailAction
  | UploadLinkedinUrlsFileRequestAction
  | UploadLinkedinUrlsFileSuccessAction
  | UploadLinkedinUrlsFileFailAction
  | RunQueryRequestAction
  | RunQueryFailAction
  | RunQuerySuccessAction
  | GetQueryResultsRequestAction
  | GetQueryResultsSuccessAction
  | GetQueryResultsFailAction
  | GetSettingsRequestAction
  | GetSettingsSuccessAction
  | GetSettingsFailAction
  | SaveSettingsRequestAction
  | SaveSettingsFailAction
  | SaveSettingsSuccessAction
  | SignalHireEmailCallLqsThresholdChangeAction
  | ExcludedCompaniesChangeAction
  | ExcludedSchoolsChangeAction;
