import { RootState } from 'core/store';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

type Props = {
  component: any;
  key: number;
  routes: any[];
};

const PrivateRoute: FC<Props> = ({ component: Component, routes, ...rest }) => {
  const userLoggedIn = useSelector<RootState, boolean>((state) => state.auth.userLoggedIn);

  return userLoggedIn ? (
    <Route {...rest} render={(props) => <Component {...props} routes={routes} />} />
  ) : (
    <Redirect to="/auth/signin" />
  );
};
export default PrivateRoute;
