import * as actionsTypes from 'features/facebook-friends/actions/facebook-friends-action-types';
import {
  FacebookFriendsRequestModel,
  MultipleConnectionsRequestModel,
} from 'features/facebook-friends/facebook-friends-request-model';

export const handleFacebookFriendsRequest = (
  payload: FacebookFriendsRequestModel
): actionsTypes.FacebookFriendsRequestAction => ({
  type: actionsTypes.MUTUAL_CONNECTIONS_REQUEST,
  payload,
});

export const handleFacebookFriendsSuccess = (payload: string): actionsTypes.FacebookFriendsSuccessAction => ({
  type: actionsTypes.MUTUAL_CONNECTIONS_SUCCESS,
  payload,
});

export const handleFacebookFriendsFail = (payload: string): actionsTypes.FacebookFriendsFailAction => ({
  type: actionsTypes.MUTUAL_CONNECTIONS_FAIL,
  payload,
});

export const handleMultipleConnectionsRequest = (
  payload: MultipleConnectionsRequestModel
): actionsTypes.MultipleConnectionsRequestAction => ({
  type: actionsTypes.MULTIPLE_CONNECTIONS_REQUEST,
  payload,
});

export const handleMultipleConnectionsSuccess = (payload: string): actionsTypes.MultipleConnectionsSuccessAction => ({
  type: actionsTypes.MULTIPLE_CONNECTIONS_SUCCESS,
  payload,
});

export const handleMultipleConnectionsFail = (payload: string): actionsTypes.MultipleConnectionsFailAction => ({
  type: actionsTypes.MULTIPLE_CONNECTIONS_FAIL,
  payload,
});

export const handleGetExclusionListRequest = (): actionsTypes.ExclusionListRequestAction => ({
  type: actionsTypes.EXCLUSION_LIST_REQUEST,
});

export const handleGetExclusionListSuccess = (payload: string[]): actionsTypes.ExclusionListSuccessAction => ({
  type: actionsTypes.EXCLUSION_LIST_SUCCESS,
  payload,
});

export const handleGetExclusionListFail = (payload: string): actionsTypes.ExclusionListFailAction => ({
  type: actionsTypes.EXCLUSION_LIST_FAIL,
  payload,
});

export const handleUpdateExclusionList = (payload: string[]): actionsTypes.ExclusionListUpdateAction => ({
  type: actionsTypes.EXCLUSION_LIST_UPDATE,
  payload,
});
