export const SIGNIN_SUCCESS = 'auth/SIGNIN_SUCCESS';
export interface SigninSuccessAction {
  type: typeof SIGNIN_SUCCESS;
  token: string;
  expiresOn: Date;
}

export const SIGNIN_FAIL = 'auth/SIGNIN_FAIL';
export interface SigninFailAction {
  type: typeof SIGNIN_FAIL;
  errorCode: string;
  details: string;
}

export const SIGNIN_RESET = 'auth/SIGNIN_RESET';
export interface SigninResetAction {
  type: typeof SIGNIN_RESET;
}

export const SIGNIN_SET_ERROR = 'auth/SIGNIN_SET_ERROR';
export interface SigninSetErrorAction {
  type: typeof SIGNIN_SET_ERROR;
  message: string;
}

export type AuthAction = SigninSuccessAction | SigninFailAction | SigninResetAction | SigninSetErrorAction;
