import { Buffer } from 'buffer';
import { ADMIN_USERS_LIST } from 'config/constants';

/**
 * Helper function to fetch if current user is admin
 *
 * @returns {boolean} - Whether the current user is admin
 */
export const checkIfUserIsAdmin = (): boolean => {
  try {
    const { token } = JSON.parse(localStorage.token);

    const tokenData = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());

    return ADMIN_USERS_LIST.includes(tokenData.email);
  } catch {
    return false;
  }
};
