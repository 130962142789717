import { createAsyncThunk } from '@reduxjs/toolkit';
import supplySearchApi from 'api/supply-search-api';
import { AppThunk } from 'core/store';
import { SearchSettings } from 'features/intro-search/intro-search-request-model';
import { introSearchActions } from './slice';

export const introSearchAction: AppThunk<{
  companyUrl: string;
  pageNumber: number;
  pageSize: number;
}> = createAsyncThunk('introSearch', async (data, { dispatch }) => {
  try {
    dispatch(introSearchActions.startIntroSearch());

    const res = await supplySearchApi.introSearch(data);

    dispatch(introSearchActions.introSearchSuccess(res.data));
  } catch (err: any) {
    dispatch(introSearchActions.introSearchFail(err?.response?.data?.message || err.message));
  }
});

export const getIntroSearchSettingsAction: AppThunk<void> = createAsyncThunk(
  'getIntroSearchSettings',
  async (_, { dispatch }) => {
    try {
      dispatch(introSearchActions.getIntroSearchSettings());

      const res = await supplySearchApi.getIntroSearchSettings();

      dispatch(introSearchActions.getIntroSearchSettingsSuccess(res.data));
    } catch (err: any) {
      dispatch(introSearchActions.introSearchFail(err?.response?.data?.message || err.message));
    }
  }
);

export const saveIntroSearchSettingsAction: AppThunk<SearchSettings> = createAsyncThunk(
  'saveIntroSearchSettings',
  async (data, { dispatch }) => {
    try {
      await supplySearchApi.saveIntroSearchSettings(data);
    } catch (err: any) {
      dispatch(introSearchActions.saveIntroSearchSettingsFail(err?.response?.data?.message || err.message));
    }
  }
);

export const getIntroSearchMonitoringUsersAction: AppThunk<void> = createAsyncThunk(
  'getIntroSearchMonitoringUsersAction',
  async (_, { dispatch }) => {
    try {
      dispatch(introSearchActions.setMonitoringLoading(true));
      dispatch(introSearchActions.startIntroSearch());

      const res = await supplySearchApi.getIntroSearchMonitoringUsers();

      dispatch(introSearchActions.setMonitoringUsers(res.data));
    } catch (err: any) {
      dispatch(introSearchActions.introSearchFail(err?.response?.data?.message || err.message));
    } finally {
      dispatch(introSearchActions.setMonitoringLoading(false));
    }
  }
);
