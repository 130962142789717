import demandLeadsApi from 'api/demand-leads-api';
import { AxiosResponse } from 'axios';
import * as actionsTypes from 'features/linkedin-job-postings/actions/linkedin-job-postings-action-types';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { linkedInJobPostings } from '../reducers/linkedin-job-postings-reducer';

function* createLinkedInJobsRequest({ payload }: actionsTypes.CreateLinkedInJobsRequestAction) {
  try {
    const res: AxiosResponse = yield call(demandLeadsApi.createLinkedInJobsRequest, payload);
    yield put(linkedInJobPostings.actions.createLinkedInJobsRequestSuccess(res.data?.requestId));
    toast.success(`Created request with id ${res.data?.requestId}`);
  } catch (err: any) {
    toast.error(`Error creating request: ${err.message}`);
    yield put(linkedInJobPostings.actions.createLinkedInJobsRequestFail(err?.response?.data?.message || err.message));
  }
}

function* createLinkedInJobsCsvRequest({ payload }: actionsTypes.CreateLinkedInJobsCsvRequestAction) {
  try {
    const res: AxiosResponse = yield call(demandLeadsApi.createLinkedInJobsCsvRequest, payload);
    yield put(linkedInJobPostings.actions.createLinkedInJobsCsvRequestSuccess(res.data));
    toast.success(`Created request with id ${res.data?.requestId}`);
  } catch (err: any) {
    toast.error(`Error creating request: ${err.message}`);
    yield put(
      linkedInJobPostings.actions.createLinkedInJobsCsvRequestFail(err?.response?.data?.message || err.message)
    );
  }
}

export default function* saga() {
  yield takeLatest(actionsTypes.CREATE_LINKEDIN_JOBS_REQUEST, createLinkedInJobsRequest);
  yield takeLatest(actionsTypes.CREATE_LINKEDIN_JOBS_CSV_REQUEST, createLinkedInJobsCsvRequest);
}
