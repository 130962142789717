import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navigation from './navigation';

interface LayoutProps {
  children: ReactNode;
  ctaBlock?: ReactNode;
  headerMainBlock?: ReactNode;
}
export const DEFAULT_TOAST_DURATION = 5000;
const useStyles = createUseStyles({
  layout: {
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    height: '100vh',
    overflow: 'auto',
  },
  workspace: {
    padding: '20px 80px',
  },
  toastContainer: {
    width: 'auto',
    '& .Toastify__toast': {
      minHeight: 32,
      fontSize: 14,
      lineHeight: '20px',
      padding: '4px 8px 4px 4px',
      borderRadius: 4,
      width: 'auto',
      background: '#35373a',
      fontFamily: 'inherit',
    },
    '& .Toastify__toast .Toastify__toast-body div': {
      width: 'auto',
    },
    '& .Toastify__toast .Toastify__toast-icon': {
      width: 16,
      height: 16,
    },
  },
});

const Layout: FC<LayoutProps> = ({ children }) => {
  const styles = useStyles();
  return (
    <>
      <div className={styles.layout}>
        <Navigation />
        <main className={styles.main}>
          <div className={styles.workspace}>{children}</div>
        </main>

        <ToastContainer
          position="bottom-right"
          hideProgressBar
          pauseOnFocusLoss
          pauseOnHover
          autoClose={DEFAULT_TOAST_DURATION}
          transition={Zoom}
          className={styles.toastContainer}
        />
      </div>
    </>
  );
};

export default Layout;
