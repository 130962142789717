import axios from 'config/axios-config';

export type DqsInput = {
  companyDomain: string;
  totalMoneyRaisedAmount: number;
  numberOfEmployees: number;
  companyCountryISO2?: string;
};

export enum DqsDecision {
  WAITLIST = 'waitlist',
  PASS = 'pass',
  PASS_WITH_MEETING = 'pass-with-meeting',
  PASS_WITH_DELAY = 'pass-with-delay',
}

export interface DqsResult {
  companyDomain: string;
  reason: string;
  decision: DqsDecision;
  hubspotCalendarUrl: string;
  matchedProfileId?: number;
  matchedProfileName?: number;
}

export interface DqsSettings {
  id: string;
  excludedDomainsCSV: string | undefined;
  excludedDomainDecision: string;
  excludedDomainHubspotCalendarUrl: string;
  unmatchedDecision: string;
  unmatchedHubspotCalendarUrl: string;
}

export interface DqsProfileBase {
  name: string;
  sortOrder: number;
  minRaise: number;
  maxRaise: number;
  minEmployees: number;
  maxEmployees: number;
  withCountriesCSV?: string;
  nameKeywordsCSV?: string;
  industryKeywordsCSV?: string;
  hubspotCalendarUrl: string;
  decision: DqsDecision;
  passDelayHours: number;
}

export interface DqsProfile extends DqsProfileBase {
  id: number;
}

/**
 * Get the DQS for a particular domain name.
 * @param {DqsInput} payload
 * @returns The calculated score as a {@link DqsResult}
 */
const getScore = (payload: DqsInput) => axios.demandInstance.get<DqsResult>('v1/dqs', { params: payload });

/**
 * Get the settings used when calculating the DQS
 * @returns The current settings {@link DqsSettings}
 */
const getSettings = () => axios.demandInstance.get<DqsSettings>('v1/dqs/score-settings');

/**
 * Persist DQS settings to the database.
 * @param {DqsSettings} payload
 * @returns The updated settings {@link DqsSettings}
 */
const saveSettings = (payload: DqsSettings) =>
  axios.demandInstance.put<DqsSettings>(`v1/dqs/score-settings/${payload.id}`, payload);

/**
 * Loads all DQS profiles in order of their sortOrder values
 * @returns Array of {@link DqsProfile} entries
 */
const getProfiles = () => axios.demandInstance.get<DqsProfile[]>('v1/dqs/score-profiles');

/**
 * Creates a new DQS profile
 * @param {DqsProfileBase} payload The data for the new profile
 * @returns The {@link DqsProfile} entry, including its id
 */
const createProfile = (payload: DqsProfileBase) =>
  axios.demandInstance.post<DqsProfile>('v1/dqs/score-profiles', payload);

/**
 * Saves a DQS profile
 * @param {DqsProfile} payload The data to save (requires an id value)
 * @returns The full {@link DqsProfile} instance
 */
const saveProfile = (payload: DqsProfile) =>
  axios.demandInstance.put<DqsProfile>(`v1/dqs/score-profiles/${payload.id}`, payload);

/**
 * Moves a DQS profile up in the sort order.
 * This means it will appear higher up in the list
 * @param {number} id The id of the profile to move
 * @returns A list of {@link DqsProfile[]} entries, sorted by their sortOrder values.
 */
const moveProfileUp = (id: number) => axios.demandInstance.post<DqsProfile[]>('v1/dqs/score-profiles/move-up', { id });

/**
 * Moves a profile down in the sort order.
 * This means it will show lower down in the list
 * @param {number} id The id of the profile to move
 * @returns A list of {@link DqsProfile[]} entries, sorted by their sortOrder values.
 */
const moveProfileDown = (id: number) =>
  axios.demandInstance.post<DqsProfile[]>('v1/dqs/score-profiles/move-down', { id });

/**
 * Deletes a DQS profile from the database.
 * @param {number} id The id of the profile to delete
 * @returns {boolean} true
 */
const deleteProfile = (id: number) => axios.demandInstance.delete<boolean>(`v1/dqs/score-profiles/${id}`);

export default {
  getScore,
  getSettings,
  saveSettings,
  getProfiles,
  createProfile,
  saveProfile,
  moveProfileUp,
  moveProfileDown,
  deleteProfile,
};
