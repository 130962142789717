import {
  FacebookFriendsRequestModel,
  MultipleConnectionsRequestModel,
} from 'features/facebook-friends/facebook-friends-request-model';

export const MUTUAL_CONNECTIONS_REQUEST = 'connections/MUTUAL_CONNECTIONS_REQUEST';
export interface FacebookFriendsRequestAction {
  type: typeof MUTUAL_CONNECTIONS_REQUEST;
  payload: FacebookFriendsRequestModel;
}

export const MUTUAL_CONNECTIONS_SUCCESS = 'connections/MUTUAL_CONNECTIONS_SUCCESS';
export interface FacebookFriendsSuccessAction {
  type: typeof MUTUAL_CONNECTIONS_SUCCESS;
  payload: string;
}

export const MUTUAL_CONNECTIONS_FAIL = 'connections/MUTUAL_CONNECTIONS_FAIL';
export interface FacebookFriendsFailAction {
  type: typeof MUTUAL_CONNECTIONS_FAIL;
  payload: string;
}

export const MULTIPLE_CONNECTIONS_REQUEST = 'connections/MULTIPLE_CONNECTIONS_REQUEST';
export interface MultipleConnectionsRequestAction {
  type: typeof MULTIPLE_CONNECTIONS_REQUEST;
  payload: MultipleConnectionsRequestModel;
}

export const MULTIPLE_CONNECTIONS_SUCCESS = 'connections/MULTIPLE_CONNECTIONS_SUCCESS';
export interface MultipleConnectionsSuccessAction {
  type: typeof MULTIPLE_CONNECTIONS_SUCCESS;
  payload: string;
}

export const MULTIPLE_CONNECTIONS_FAIL = 'connections/MULTIPLE_CONNECTIONS_FAIL';
export interface MultipleConnectionsFailAction {
  type: typeof MULTIPLE_CONNECTIONS_FAIL;
  payload: string;
}

export const EXCLUSION_LIST_REQUEST = 'connections/EXCLUSION_LIST_REQUEST';
export interface ExclusionListRequestAction {
  type: typeof EXCLUSION_LIST_REQUEST;
}

export const EXCLUSION_LIST_SUCCESS = 'connections/EXCLUSION_LIST_SUCCESS';
export interface ExclusionListSuccessAction {
  type: typeof EXCLUSION_LIST_SUCCESS;
  payload: string[];
}

export const EXCLUSION_LIST_FAIL = 'connections/EXCLUSION_LIST_FAIL';
export interface ExclusionListFailAction {
  type: typeof EXCLUSION_LIST_FAIL;
  payload: string;
}

export const EXCLUSION_LIST_UPDATE = 'connections/EXCLUSION_LIST_UPDATE';
export interface ExclusionListUpdateAction {
  type: typeof EXCLUSION_LIST_UPDATE;
  payload: string[];
}

export type ConnectionsActionType =
  | FacebookFriendsRequestAction
  | FacebookFriendsSuccessAction
  | FacebookFriendsFailAction
  | MultipleConnectionsRequestAction
  | MultipleConnectionsSuccessAction
  | MultipleConnectionsFailAction
  | ExclusionListRequestAction
  | ExclusionListSuccessAction
  | ExclusionListFailAction
  | ExclusionListUpdateAction;
