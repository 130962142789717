import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FacebookFriendsState = {
  loading: boolean;
  scrapingSubmitted: boolean;
  scrapingError?: string;
  requestId?: string;
  requestError?: string;
  exclusionList: string[];
  exclusionListLoaded: boolean;
};

const initialState: FacebookFriendsState = {
  loading: false,
  scrapingSubmitted: false,
  scrapingError: undefined,
  requestId: undefined,
  exclusionList: [],
  exclusionListLoaded: false,
};

export const facebookFriends = createSlice({
  name: 'facebookFriends',
  initialState,
  reducers: {
    mutualConnectionsRequest: (state) => {
      state.loading = true;
      state.scrapingSubmitted = false;
      state.scrapingError = undefined;
    },
    mutualConnectionsSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.scrapingSubmitted = true;
      state.scrapingError = undefined;
      state.requestId = action.payload;
    },
    mutualConnectionsFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.scrapingSubmitted = false;
      state.scrapingError = action.payload;
    },
    multipleConnectionsRequest: (state) => {
      state.loading = true;
      state.requestId = undefined;
      state.requestError = undefined;
    },
    multipleConnectionsSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.requestId = action.payload;
      state.requestError = undefined;
    },
    multipleConnectionsFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.requestId = undefined;
      state.requestError = action.payload;
    },
    exclusionListRequest: (state) => {
      state.exclusionList = [];
      state.exclusionListLoaded = false;
    },
    exclusionListSuccess: (state, action: PayloadAction<string[]>) => {
      state.exclusionList = action.payload;
      state.exclusionListLoaded = true;
    },
    exclusionListFail: (state) => {
      state.exclusionList = [];
      state.exclusionListLoaded = true;
    },
    exclusionListUpdate: (state, action: PayloadAction<string[]>) => {
      state.exclusionList = action.payload;
    },
  },
});

export const {
  mutualConnectionsRequest,
  mutualConnectionsSuccess,
  mutualConnectionsFail,
  multipleConnectionsRequest,
  multipleConnectionsSuccess,
  multipleConnectionsFail,
  exclusionListRequest,
  exclusionListSuccess,
  exclusionListFail,
  exclusionListUpdate,
} = facebookFriends.actions;

export default facebookFriends.reducer;
