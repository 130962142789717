import { SEPARATOR } from 'config/constants';

export interface LineInputModel {
  modelPath: string[];
  type: 'number' | 'text';
  isCommaSeparated?: boolean;
}

export const generalSettings: (LineInputModel | String)[][] = [
  [
    'Points for experience of years: ',
    {
      modelPath: ['totalExpHighYears'],
      type: 'number',
    },
    ' yrs = ',
    {
      modelPath: ['totalExpHighPoints'],
      type: 'number',
    },
    ', ',
    {
      modelPath: ['totalExpMediumYears'],
      type: 'number',
    },
    ' yrs = ',
    {
      modelPath: ['totalExpMediumPoints'],
      type: 'number',
    },
    ', otherwise = ',
    {
      modelPath: ['totalExpLowPoints'],
      type: 'number',
    },
  ],
  [
    'Points given for aggregated years longer than ',
    {
      modelPath: ['aggregatedExpYears'],
      type: 'number',
    },
    ' years: ',
    {
      modelPath: ['aggregatedExpPoints'],
      type: 'number',
    },
  ],
  [
    'If talent stayed less than ',
    {
      modelPath: ['aggregatedExpMinYearsPeriod'],
      type: 'number',
    },
    ' in of the relevant position, do not count this position in the aggregated Points',
  ],
  [
    '[Secondary] Points given for aggregated years longer than ',
    {
      modelPath: ['secondaryAggregatedExpYears'],
      type: 'number',
    },
    ' years: ',
    {
      modelPath: ['secondaryAggregatedExpPoints'],
      type: 'number',
    },
  ],
  [
    '[Secondary] If talent stayed less than ',
    {
      modelPath: ['secondaryAggregatedExpMinYearsPeriod'],
      type: 'number',
    },
    ' in of the relevant position, do not count this position in the aggregated Points',
  ],
  [
    'Point given for top company and position: ',
    {
      modelPath: ['primaryTopCompanyPoints'],
      type: 'number',
    },
  ],
  [
    'Point given for top company/ position and stay between ',
    {
      modelPath: ['primaryTopCompanyHighYearsMin'],
      type: 'number',
    },
    ' and ',
    {
      modelPath: ['primaryTopCompanyHighYearsMax'],
      type: 'number',
    },
    ' : ',
    {
      modelPath: ['primaryTopCompanyHighPoints'],
      type: 'number',
    },
  ],
  [
    'Point given for top company 2/ position 2 and stay between ',
    {
      modelPath: ['secondaryTopCompanyHighYearsMin'],
      type: 'number',
    },
    ' and ',
    {
      modelPath: ['secondaryTopCompanyHighYearsMax'],
      type: 'number',
    },
    ' : ',
    {
      modelPath: ['secondaryTopCompanyHighPoints'],
      type: 'number',
    },
  ],
  [
    'If one of the experiences that counts towards a point (might it be top companies or just position for aggregation) is in location ',
    {
      modelPath: ['companyLocations'],
      type: 'text',
    },
    ', give ',
    {
      modelPath: ['companyLocationPoints'],
      type: 'number',
    },
    ' points',
  ],
  [
    'Point given for top 15 university for ',
    {
      modelPath: ['top15UniversitiesDegrees'],
      type: 'text',
    },
    ' degree: ',
    {
      modelPath: ['top15UniversitiesPoints'],
      type: 'number',
    },
  ],
  [
    'Point given for top 30 university for ',
    {
      modelPath: ['top30UniversitiesDegrees'],
      type: 'text',
    },
    ' degree: ',
    {
      modelPath: ['top30UniversitiesPoints'],
      type: 'number',
    },
  ],
  [
    'Point for education degree ',
    {
      modelPath: ['defaultDegrees'],
      type: 'text',
    },
    ' = ',
    {
      modelPath: ['defaultDegreesPoints'],
      type: 'number',
    },
  ],
  [
    'Point given for location (as returned by SH) ',
    {
      modelPath: ['locations'],
      type: 'text',
    },
    ' = ',
    {
      modelPath: ['locationsPoints'],
      type: 'number',
    },
  ],
];

export const independenceScoreSettings: (LineInputModel | String)[][] = [
  [
    'If ',
    {
      modelPath: ['independenceScoreExistingOverlappingExperiencesCount'],
      type: 'number',
    },
    ' or more overlapping experiences exist in the past ',
    {
      modelPath: ['independenceScoreExistingOverlappingExperiencesYears'],
      type: 'number',
    },
    ' years, give ',
    {
      modelPath: ['independenceScoreExistingOverlappingExperiencesPoints'],
      type: 'number',
    },
    ' points',
  ],
  [
    'If ',
    {
      modelPath: ['independenceScoreLastingOverlappingExperiencesCount'],
      type: 'number',
    },
    ' or more overlapping experience lasted more than ',
    {
      modelPath: ['independenceScoreLastingOverlappingExperiencesYears'],
      type: 'number',
    },
    ' years, give ',
    {
      modelPath: ['independenceScoreLastingOverlappingExperiencesPoints'],
      type: 'number',
    },
    ' points',
  ],
  [
    'If profile headline contains words ',
    {
      modelPath: ['independenceScoreProfileHeadlineWords'],
      type: 'text',
    },
    ', give ',
    {
      modelPath: ['independenceScoreProfileHeadlinePoints'],
      type: 'number',
    },
    ' points',
  ],
  [
    'If any positions in the past ',
    {
      modelPath: ['independenceScorePositionDescriptionYears'],
      type: 'number',
    },
    ' years contains ',
    {
      modelPath: ['independenceScorePositionDescriptionWords'],
      type: 'text',
    },
    ' give ',
    {
      modelPath: ['independenceScorePositionDescriptionPoints'],
      type: 'number',
    },
    ' points',
  ],
  [
    'If any current company name is one of the companies from the list, give ',
    {
      modelPath: ['independenceScoreTopCompaniesPoints'],
      type: 'number',
    },
    ' points',
  ],
];

export const defaultProfile: BaseScoringProfile = {
  name: 'New Profile',

  totalExpHighYears: 10,
  totalExpHighPoints: 2,

  totalExpMediumYears: 3,
  totalExpMediumPoints: 1,

  totalExpLowPoints: -1,

  aggregatedExpIncludedPositions: '',
  aggregatedExpExcludedPositions: '',
  aggregatedExpYears: 1,
  aggregatedExpPoints: 1,
  aggregatedExpMinYearsPeriod: 0,
  secondaryAggregatedExpYears: 2,
  secondaryAggregatedExpPoints: 1,
  secondaryAggregatedExpMinYearsPeriod: 0,

  primaryTopCompanies: [
    'Google',
    'Alphabet',
    'Microsoft',
    'Facebook',
    'Meta',
    'LinkedIn',
    'DocuSign',
    'Salesforce',
    'Zoom',
    'Robinhood',
    'Apple',
    'Intuit',
    'Cisco',
    'VMWare',
    'Workday Adobe',
    'Slack',
    'Dropbox',
    'Hubspot',
    'Asana',
    'Servicenow',
    'Autodesk',
    'Altimetrik',
    'Qualtrics',
    'Twilio',
    'Stripe',
    'Atlassian',
    'Shopify',
    'Cloudfare',
    'Stack Overflow',
    'Samsara',
    'Pantheon',
    'Grammarly',
    'Amazon',
    'Tesla',
    'Netflix',
    'Oracle',
    'Paypal',
    'Broadcom',
    'SAP',
    'Airbnb',
    'Booking.com',
    'Snap',
    'Coinbase',
    'Uber',
    'Datadog',
    'Twitter',
  ].join(SEPARATOR),
  primaryIncludedPositions: ['developer', 'engineer'].join(SEPARATOR),
  primaryExcludedPositions: '',
  primaryTopCompanyPoints: -1,

  primaryTopCompanyHighYearsMin: 1.5,
  primaryTopCompanyHighYearsMax: 3,
  primaryTopCompanyHighPoints: 1,

  primaryTopCompanyLowYearsMin: 3,
  primaryTopCompanyLowYearsMax: 10,
  primaryTopCompanyLowPoints: 2,

  secondaryTopCompanies: '',
  secondaryIncludedPositions: '',
  secondaryExcludedPositions: '',
  secondaryTopCompanyPoints: 0,

  secondaryTopCompanyHighYearsMin: 1,
  secondaryTopCompanyHighYearsMax: 2,
  secondaryTopCompanyHighPoints: 0,

  secondaryTopCompanyLowYearsMin: 1,
  secondaryTopCompanyLowYearsMax: 2,
  secondaryTopCompanyLowPoints: 0,

  companyLocations: '',
  companyLocationPoints: 0,

  top15Universities: [
    'Princeton',
    'Columbia',
    'Harvard',
    'MIT',
    'Massachusetts Institute of technology',
    'Yale',
    'Stanford',
    'University of Chicago',
    'University of Pennsylvania',
    'California Institute of technology',
    'Duke',
    'Northwestern',
    'Darmouth',
    'Brown',
    'Vanderbilt',
  ].join(SEPARATOR),
  top15UniversitiesDegrees: '',
  top15UniversitiesPoints: 0,

  top30Universities: [
    'Washington University St. Louis',
    'Cornell',
    'Rice University',
    'Berkely',
    'UCLA',
    'NYU',
    'John Hopkins',
    'Georgetown',
    'University of Southern California',
    'Carnegie Mellon',
    'University of Michigan-Ann Arbor',
    'Boston University',
    'Emory',
    'University of Washington',
    'University of Rochester',
    'University of Virgina',
  ].join(SEPARATOR),
  top30UniversitiesDegrees: '',
  top30UniversitiesPoints: 0,

  defaultDegrees: '',
  defaultDegreesPoints: 0,

  locations: '',
  locationsPoints: 0,

  independenceScoreExistingOverlappingExperiencesCount: 2,
  independenceScoreExistingOverlappingExperiencesYears: 4,
  independenceScoreExistingOverlappingExperiencesPoints: 1,

  independenceScoreLastingOverlappingExperiencesCount: 2,
  independenceScoreLastingOverlappingExperiencesYears: 2,
  independenceScoreLastingOverlappingExperiencesPoints: 1,

  independenceScoreProfileHeadlineWords: ['freelancer', 'freelance'].join(SEPARATOR),
  independenceScoreProfileHeadlinePoints: 1,

  independenceScorePositionDescriptionYears: 4,
  independenceScorePositionDescriptionWords: ['freelancer', 'freelance'].join(SEPARATOR),
  independenceScorePositionDescriptionPoints: 1,

  independenceScoreTopCompanies: '',
  independenceScoreTopCompaniesPoints: 0,
};

export interface BaseScoringProfile {
  name?: string;

  totalExpHighYears?: number;
  totalExpHighPoints?: number;

  totalExpMediumYears?: number;
  totalExpMediumPoints?: number;

  totalExpLowPoints?: number;

  aggregatedExpIncludedPositions?: string;
  aggregatedExpExcludedPositions?: string;
  aggregatedExpYears?: number;
  aggregatedExpPoints?: number;
  aggregatedExpMinYearsPeriod?: number;
  secondaryAggregatedExpYears?: number;
  secondaryAggregatedExpPoints?: number;
  secondaryAggregatedExpMinYearsPeriod?: number;

  primaryTopCompanies?: string;
  primaryIncludedPositions?: string;
  primaryExcludedPositions?: string;
  primaryTopCompanyPoints?: number;

  primaryTopCompanyHighYearsMin?: number;
  primaryTopCompanyHighYearsMax?: number;
  primaryTopCompanyHighPoints?: number;

  primaryTopCompanyLowYearsMin?: number;
  primaryTopCompanyLowYearsMax?: number;
  primaryTopCompanyLowPoints?: number;

  secondaryTopCompanies?: string;
  secondaryIncludedPositions?: string;
  secondaryExcludedPositions?: string;
  secondaryTopCompanyPoints?: number;

  secondaryTopCompanyHighYearsMin?: number;
  secondaryTopCompanyHighYearsMax?: number;
  secondaryTopCompanyHighPoints?: number;

  secondaryTopCompanyLowYearsMin?: number;
  secondaryTopCompanyLowYearsMax?: number;
  secondaryTopCompanyLowPoints?: number;

  companyLocations?: string;
  companyLocationPoints?: number;

  top15Universities?: string;
  top15UniversitiesDegrees?: string;
  top15UniversitiesPoints?: number;

  top30Universities?: string;
  top30UniversitiesDegrees?: string;
  top30UniversitiesPoints?: number;

  defaultDegrees?: string;
  defaultDegreesPoints: number;

  locations?: string;
  locationsPoints?: number;

  independenceScoreExistingOverlappingExperiencesCount?: number;
  independenceScoreExistingOverlappingExperiencesYears?: number;
  independenceScoreExistingOverlappingExperiencesPoints?: number;

  independenceScoreLastingOverlappingExperiencesCount?: number;
  independenceScoreLastingOverlappingExperiencesYears?: number;
  independenceScoreLastingOverlappingExperiencesPoints?: number;

  independenceScoreProfileHeadlineWords?: string;
  independenceScoreProfileHeadlinePoints?: number;

  independenceScorePositionDescriptionYears?: number;
  independenceScorePositionDescriptionWords?: string;
  independenceScorePositionDescriptionPoints?: number;

  independenceScoreTopCompanies?: string;
  independenceScoreTopCompaniesPoints?: number;
}

export interface DbGetScoringProfile extends BaseScoringProfile {
  id: string;
}
