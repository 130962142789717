import { AxiosError } from 'axios';

/**
 * Util function to extract error text message from axios error
 *
 * @param   {AxiosError} error - AxiosError object with response data
 * @returns {string} - error text
 */
export const extractErrorMessage = (error: AxiosError): string => {
  const responseData = error?.response?.data;
  if (responseData && Array.isArray(responseData?.errors)) {
    const error = responseData.errors[0];
    return error[Object.keys(error)[0]];
  }

  return responseData?.message || error?.message;
};
