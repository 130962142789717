import { FormItemConfig } from 'core/components/field-type';

export const SCRUBBING_SETTINGS_FORM_CONFIG: FormItemConfig[] = [
  {
    field: 'standalone',
    prefix: 'EVERY N HOURS',
    name: 'intervalHours',
    type: 'number',
    label:
      'Field used to represent the interval at which the scrubbing occurs. Every N hours the scrubbing will happen',
  },
  {
    field: 'standalone',
    prefix: 'SCRUB HOURS',
    name: 'scrubHours',
    type: 'number',
    label:
      'Field used to represent the amount of hours to look back from the moment when scrubbing occurs, if the builder has signed up during that period, the builder will be evaluated for scrubbing',
  },
  {
    field: 'standalone',
    prefix: 'OFAC RESTRICTED COUNTRY CODES',
    name: 'ofacRestrictedCountryCodes',
    type: 'text',
    label: 'Field used to represent which countries (their codes) are ofac restricted. Used for filtering on scrubbing',
  },
  {
    field: 'standalone',
    prefix: 'EXCLUDED COUNTRY CODES',
    name: 'excludedCountryCodes',
    type: 'text',
    label:
      'Field used to represent which countries (their codes) are excluded. Used for filtering on scrubbing, combined with big tech companies / portofolio rules.',
  },
  {
    field: 'standalone',
    prefix: 'MAIN ROLE EXCLUSION WORDS',
    name: 'mainRoleExclusionWords',
    type: 'text',
    label: 'Field used to represent the excluded main roles (keywords of roles). Used for filtering on scrubbing',
  },
  {
    field: 'standalone',
    prefix: 'BIG TECH COMPANIES',
    name: 'bigTechCompanies',
    type: 'text',
    label: 'Field used to represent the big tech companies list. Used for filtering on scrubbing',
  },
  {
    field: 'standalone',
    label: 'Exclude empty linkedin',
    name: 'excludeEmptyLinkedin',
    type: 'checkbox',
  },
  {
    field: 'standalone',
    label: 'Exclude empty main role',
    name: 'excludeEmptyMainRole',
    type: 'checkbox',
  },
  {
    field: 'standalone',
    label: 'Enforce suspicous name linkedin check',
    name: 'enforceSuspicousNameLinkedinCheck',
    type: 'checkbox',
  },
  {
    field: 'standalone',
    label: 'Enforce suspicous name email check',
    name: 'enforceSuspicousNameEmailCheck',
    type: 'checkbox',
  },
];

export const scrubbingSettingsInitialForm = (): ScrubbingSettingsForm => ({
  intervalHours: 4,
  scrubHours: 5,
  mainRoleExclusionWords: null,
  excludedCountryCodes: null,
  ofacRestrictedCountryCodes: null,
  bigTechCompanies: null,
  excludeEmptyLinkedin: false,
  excludeEmptyMainRole: false,
  enforceSuspicousNameLinkedinCheck: false,
  enforceSuspicousNameEmailCheck: false,
});

export interface ScrubbingSettingsForm {
  intervalHours: number;
  scrubHours: number;
  mainRoleExclusionWords?: string | null;
  excludedCountryCodes?: string | null;
  ofacRestrictedCountryCodes?: string | null;
  bigTechCompanies?: string | null;
  excludeEmptyLinkedin: boolean;
  excludeEmptyMainRole: boolean;
  enforceSuspicousNameLinkedinCheck: boolean;
  enforceSuspicousNameEmailCheck: boolean;
}
