export enum SupplyRequestsStatus {
  Started = 'STARTED',
  InProgress = 'IN_PROGRESS',
  Done = 'DONE',
}

export enum SupplyRequestSource {
  Main = 'MAIN',
  Scrubbing = 'SCRUBBING',
  WarmLeads = 'WARM_LEADS',
  OutboundSearch = 'OUTBOUND_SEARCH',
}

export interface SupplyRequest {
  id: string;
  filename: string;
  positions: string;
  skills: string;
  status: SupplyRequestsStatus;
  createdAt: string;
  updatedAt: string;
  scoringProfileId: string | null;
  requesterEmail: string;
  github: string;
  utmCampaign: string;
  parentRequestId: string | null;
  isEmailRequest: boolean;
  source: SupplyRequestSource | null;
  requestedcount: string;
  processedcount: string;
  successfulcount: string;
  resultsUrl: string;
  childrenRequests: SupplyRequest[];
}

export interface SupplyRequestsResponse {
  results: SupplyRequest[];
  count: number;
  current: number;
  total: number;
}

export interface SupplyRequestRequestQuery {
  requesterEmail?: string;
  status?: SupplyRequestsStatus;
  source?: SupplyRequestSource;
  page?: number;
  pageSize?: number;
}

export interface PaginationProps {
  /** Current page number*/
  page: number;
  /** The total amount of pages */
  total: number;
  /** Triggers when user selects previous or next page. */
  setPage: (number: number) => void;
}
