import { RootState } from 'core/store';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LoginForm } from './login-form';

export const Login: FC = () => {
  const userLoggedIn = useSelector((state: RootState) => state.auth.userLoggedIn);
  return userLoggedIn ? <Redirect to="/" /> : <LoginForm />;
};
