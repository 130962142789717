import { Typography } from '@a_team/ui-components';
import classNames from 'classnames';
import { WarmLeadsUtilsLocation } from 'core/routing/locations';
import { homepageConfig } from 'core/routing/navigation-config';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import logo from './assets/logo.svg';
import mixIcon from './assets/mixIcon.svg';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  columns: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 32,
    gap: 32,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  linkButton: {
    padding: '10px 14px',
    borderRadius: '4px',
    textDecoration: 'none',
    color: '#000',
    cursor: 'pointer',
    maxWidth: 250,
  },
  logo: {
    position: 'fixed',
    top: -40,
    right: -40,
  },
  csvFilesLink: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 8,
    whiteSpace: 'nowrap',
    marginTop: 64,
  },
});

const HomePage = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <img src={logo} alt="Logo" className={styles.logo} />
      <Typography variant="h1" margin="sm">
        Growth Engine
      </Typography>
      <Typography variant="textLarge">What do you want to do?</Typography>

      <div className={styles.columns}>
        {homepageConfig.map((column) => (
          <div key={column.title} className={styles.column}>
            <Typography variant="h2">{column.title}</Typography>
            {column.items.map((item, index) => (
              <Link to={item.path} className={styles.linkButton} key={index} style={{ backgroundColor: item.color }}>
                {item.label}
              </Link>
            ))}
          </div>
        ))}
      </div>
      <Link to={WarmLeadsUtilsLocation} className={classNames(styles.linkButton, styles.csvFilesLink)}>
        <img src={mixIcon} alt="Mix icon" />
        Csv files manipulation tools
      </Link>
    </div>
  );
};

export default HomePage;
