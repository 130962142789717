import { ScoreRequestMetadata } from 'core/models/SupplyScore';
import * as actions from 'features/sge/actions/sge-action-types';

export const handleUploadSearchTermsRequest = (
  searchTerm1: string,
  searchTerm2: string,
  linkedInCount: number,
  searchTermsFile: File | undefined,
  metadata: ScoreRequestMetadata
): actions.UploadSearchTermsRequestAction => ({
  type: actions.UPLOAD_SEARCH_TERMS_REQUEST,
  searchTerm1,
  searchTerm2,
  linkedInCount,
  searchTermsFile,
  metadata,
});

export const handleUploadSearchTermsSuccess = (requestId: string): actions.UploadSearchTermsSuccessAction => ({
  type: actions.UPLOAD_SEARCH_TERMS_SUCCESS,
  requestId,
});

export const handleUploadSearchTermsFail = (): actions.UploadSearchTermsFailAction => ({
  type: actions.UPLOAD_SEARCH_TERMS_FAIL,
});
