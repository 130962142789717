import axios from 'config/axios-config';
import { getFormData } from './helpers';

const postGoogleSearch = async (
  searchTerm1: string,
  searchTerm2: string,
  linkedinCount: number,
  file: File | undefined,
  utmCampaign: string,
  shouldGenerateCP: boolean,
  shouldAddConnections: boolean
) => {
  return axios.demandLeadsInstance({
    method: 'POST',
    url: '/v1/outbound-search',
    data: getFormData({
      searchTerm_1: searchTerm1,
      searchTerm_2: searchTerm2,
      linkedinCount,
      csv: file,
      utmCampaign,
      shouldGenerateCP,
      shouldAddConnections,
    }),
  });
};

export default {
  postGoogleSearch,
};
