import LqsApi from 'api/lqs-api';
import { BaseScoringProfile } from 'core/models/Profiles';
import store from 'core/store';
import * as actions from 'features/lqs/actions/lqs-action-types';
import { SetSelectedProfileAction } from 'features/lqs/actions/lqs-action-types';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { lqs } from '../reducers/lqs-reducers';

function* requestProfileOptions() {
  try {
    const res = yield call(LqsApi.getProfileOptions);

    yield put(lqs.actions.fetchProfileTypesSuccess(res.data));
  } catch (err: any) {
    toast.error(`Error fetching profile types: ${err.message}`);
    yield put(lqs.actions.fetchProfileTypesFail());
  }
}

function* requestProfile({ selectedProfile }: SetSelectedProfileAction) {
  try {
    const res = yield call(LqsApi.getProfile, selectedProfile.value);
    yield put(lqs.actions.fetchProfileSuccess(res.data));
  } catch (err: any) {
    toast.error(`Error fetching profile: ${err.message}`);
  }
}

function* saveProfileSettings() {
  try {
    const state = store.getState();
    const lqsSettings = state.lqs.profileSettings;
    if (!lqsSettings.selectedProfile) {
      yield call(LqsApi.postProfile, lqsSettings.currentProfile as BaseScoringProfile);
    } else {
      yield call(
        LqsApi.putProfile,
        lqsSettings.selectedProfile?.value as string,
        lqsSettings.currentProfile as BaseScoringProfile
      );
    }

    toast.success('Profile settings saved');
  } catch (err: any) {
    toast.error(`Error saving profile settings: ${err.message}`);
    yield put(lqs.actions.getSettingsFail());
  }
}

function* getSettings() {
  try {
    const res = yield call(LqsApi.getSettings);
    yield put(lqs.actions.getSettingsSuccess(res.data));
  } catch (err: any) {
    toast.error(`Error fetching settings: ${err.message}`);
    yield put(lqs.actions.getSettingsFail());
  }
}

function* saveSettings() {
  try {
    const state = store.getState();
    const settings = state.lqs.settings;
    yield call(LqsApi.putSettings, settings);
    toast.success('Settings saved successfully');
  } catch (err: any) {
    toast.error(`Error saving settings: ${err.message}`);
    yield put(lqs.actions.getSettingsFail());
  }
}

export default function* saga() {
  yield takeLatest(actions.FETCH_PROFILE_TYPES_REQUEST, requestProfileOptions);
  yield takeLatest(actions.SAVE_PROFILE_SETTINGS_SUCCESS, requestProfileOptions);

  yield takeLatest(actions.SET_SELECTED_PROFILE, requestProfile);

  yield takeLatest(actions.SAVE_PROFILE_SETTINGS_REQUEST, saveProfileSettings);

  yield takeLatest(actions.GET_SETTINGS_REQUEST, getSettings);
  yield takeLatest(actions.SAVE_SETTINGS_REQUEST, saveSettings);
}
