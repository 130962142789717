import { Typography } from '@a_team/ui-components';
import ErrorMessageDisplay from 'core/components/error-message-display';
import { RootState } from 'core/store';
import { handleLoadProfiles } from 'features/dqs/actions/profiles-actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DqsProfileCreateRow } from './dqs-profile-create-row';
import { DqsProfileListRow } from './dqs-profile-list-row';

export const DqsProfilesList = () => {
  const { profileStates, loading, errorMsg } = useSelector((state: RootState) => state.dqsProfileReducers);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      dispatch(handleLoadProfiles());
    }
  }, [dispatch, isFirstLoad]);

  if (loading) {
    return <p>Loading profiles...</p>;
  }

  return (
    <>
      <DqsProfileCreateRow />
      {profileStates.length ? (
        <>
          <hr />
          <Typography variant="h3">Profiles</Typography>
          {profileStates.map((entry, index) => {
            return (
              <DqsProfileListRow
                data={entry}
                key={entry.profile.id}
                rowIndex={index}
                totalRows={profileStates.length}
              />
            );
          })}
        </>
      ) : null}

      {errorMsg && <ErrorMessageDisplay msg={`Error: ${errorMsg}`} />}
    </>
  );
};
