import { createAsyncThunk } from '@reduxjs/toolkit';
import companyPagesApi from 'api/company-pages-api';
import { AppThunk } from 'core/store';
import { CompanyPageData } from 'features/company-pages-generation/company-pages-request-model';
import { companyPagesActions } from './slice';

export const createCompanyPageAction: AppThunk<{ url: string }> = createAsyncThunk(
  'createCompanyPage',
  async (data, { dispatch }) => {
    try {
      dispatch(companyPagesActions.startCreatingPage());
      const res = await companyPagesApi.createCompanyPage(data);
      dispatch(companyPagesActions.createCompanyPageSuccess({ slug: res.data.pageSlug }));
      dispatch(companyPagesActions.addNewPage(res.data));
    } catch (err: any) {
      dispatch(companyPagesActions.createCompanyPageFail(err?.response?.data?.message || err.message));
    }
  }
);

export const getCompanyPagesAction: AppThunk<{ domain: string }> = createAsyncThunk(
  'getCompanyPages',
  async ({ domain }, { dispatch }) => {
    try {
      dispatch(companyPagesActions.startLoadingPages());
      const res = await companyPagesApi.getCompanyPages({ domain });
      const pages = res.data as CompanyPageData[];
      if (pages.length === 0) {
        dispatch(createCompanyPageAction({ url: domain }));
      } else {
        dispatch(companyPagesActions.getCompanyPagesSuccess({ domain, items: res.data }));
      }
    } catch (err: any) {
      dispatch(companyPagesActions.getCompanyPagesFail(err?.response?.data?.message || err.message));
    }
  }
);

export const duplicateCompanyPageAction: AppThunk<{ slug: string }> = createAsyncThunk(
  'duplicateCompanyPage',
  async (data, { dispatch }) => {
    try {
      dispatch(companyPagesActions.startCreatingPage());
      const res = await companyPagesApi.duplicateCompanyPage(data);
      dispatch(companyPagesActions.createCompanyPageSuccess({ slug: res.data.pageSlug }));
      dispatch(companyPagesActions.addNewPage(res.data));
    } catch (err: any) {
      dispatch(companyPagesActions.createCompanyPageFail(err?.response?.data?.message || err.message));
    }
  }
);

export const getCompanyPagesCsvAction: AppThunk<{ csv: File }> = createAsyncThunk(
  'getCompanyPagesCsv',
  async (data, { dispatch }) => {
    try {
      await companyPagesApi.getCompanyPagesCsv(data);
      dispatch(companyPagesActions.getCompanyPagesCsvSuccess());
    } catch (err: any) {
      dispatch(companyPagesActions.getCompanyPagesCsvFail(err?.response?.data?.message || err.message));
    }
  }
);

export const createCompanyPageFromLinkedInAction: AppThunk<{ linkedinUrl: string }> = createAsyncThunk(
  'createCompanyPageFromLinkedIn',
  async (data, { dispatch }) => {
    try {
      dispatch(companyPagesActions.startCreatingPageFromLinkedIn());
      const res = await companyPagesApi.createCompanyPageFromLinkedIn(data);
      dispatch(companyPagesActions.createCompanyPageFromLinkedinSuccess({ slug: res.data.pageSlug }));
    } catch (err: any) {
      dispatch(companyPagesActions.createCompanyPageFromLinkedinFail(err?.response?.data?.message || err.message));
    }
  }
);
