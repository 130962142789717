import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type WarmLeadsFilesUtilsState = {
  loading: boolean;
  submitted: boolean;
  error?: string;
};

export const initialState: WarmLeadsFilesUtilsState = {
  loading: false,
  submitted: false,
  error: undefined,
};

export const warmLeadsUtils = createSlice({
  name: 'warmLeadsUtils',
  initialState,
  reducers: {
    filterWarmLeadsFileRequest: (state) => {
      state.loading = true;
    },
    filterWarmLeadsFileRequestSuccess: (state) => {
      state.loading = false;
    },
    filterWarmLeadsFileRequestFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    mixWarmLeadsFilesRequest: (state) => {
      state.loading = true;
    },
    mixWarmLeadsFilesRequestSuccess: (state) => {
      state.loading = false;
    },
    mixWarmLeadsFilesRequestFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  filterWarmLeadsFileRequest,
  filterWarmLeadsFileRequestSuccess,
  filterWarmLeadsFileRequestFail,
  mixWarmLeadsFilesRequest,
  mixWarmLeadsFilesRequestSuccess,
  mixWarmLeadsFilesRequestFail,
} = warmLeadsUtils.actions;

export default warmLeadsUtils.reducer;
