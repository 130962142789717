import { FC } from 'react';
import { Tab, Tabs, Typography } from '@a_team/ui-components';
import { ScrubbingProfiles } from './scrubbing-profiles';
import { ScrubbingSettings } from './scrubbing-settings';

export const Scrubbing: FC = () => {
  return (
    <>
      <Typography variant="h1">Supply Scrubbing Configuration</Typography>
      <Tabs>
        <Tab id="first" label={'Scrubbing Profiles'}>
          <ScrubbingProfiles />
        </Tab>
        <Tab id="second" label={'Scrubbing Settings'}>
          <ScrubbingSettings />
        </Tab>
      </Tabs>
    </>
  );
};
