import { AxiosPromise } from 'axios';
import axios from 'config/axios-config';
import {
  DegreeGroup,
  PositionGroup,
  RoleGroup,
  ScoringProfileRequestModel,
  ScoringProfileResponseModel,
} from 'features/scs/models/scoring-profile';
import { RequestScsScoringRequest } from 'features/scs/models/scs-request';
import { getFormData } from './helpers';

const getScoringProfile = (): AxiosPromise<ScoringProfileResponseModel[]> => {
  return axios.supplyInstance({
    method: 'GET',
    url: 'v1/connection-score/profiles',
  });
};

const createScoringProfile = (data: ScoringProfileRequestModel): AxiosPromise<ScoringProfileResponseModel> => {
  return axios.supplyInstance({
    method: 'POST',
    url: 'v1/connection-score/profiles',
    data,
  });
};

const updateScoringProfile = (
  id: string,
  data: ScoringProfileRequestModel
): AxiosPromise<ScoringProfileResponseModel> => {
  return axios.supplyInstance({
    method: 'PUT',
    url: `v1/connection-score/profiles/${id}`,
    data,
  });
};

const createPositionGroup = (profileId: string, data: PositionGroup): AxiosPromise<PositionGroup> => {
  return axios.supplyInstance({
    method: 'POST',
    url: `v1/connection-score/profiles/${profileId}/positions`,
    data,
  });
};

const updatePositionGroup = (id: string, profileId: string, data: PositionGroup): AxiosPromise<PositionGroup> => {
  return axios.supplyInstance({
    method: 'PUT',
    url: `v1/connection-score/profiles/${profileId}/positions/${id}`,
    data,
  });
};

const deletePositionGroup = (id: string, profileId: string): AxiosPromise<void> => {
  return axios.supplyInstance({
    method: 'DELETE',
    url: `v1/connection-score/profiles/${profileId}/positions/${id}`,
  });
};

const createRoleGroup = (profileId: string, data: RoleGroup): AxiosPromise<RoleGroup> => {
  return axios.supplyInstance({
    method: 'POST',
    url: `v1/connection-score/profiles/${profileId}/roles`,
    data,
  });
};

const updateRoleGroup = (id: string, profileId: string, data: RoleGroup): AxiosPromise<RoleGroup> => {
  return axios.supplyInstance({
    method: 'PUT',
    url: `v1/connection-score/profiles/${profileId}/roles/${id}`,
    data,
  });
};

const deleteRoleGroup = (id: string, profileId: string): AxiosPromise<void> => {
  return axios.supplyInstance({
    method: 'DELETE',
    url: `v1/connection-score/profiles/${profileId}/roles/${id}`,
  });
};

const createDegreeGroup = (profileId: string, data: DegreeGroup): AxiosPromise<DegreeGroup> => {
  return axios.supplyInstance({
    method: 'POST',
    url: `v1/connection-score/profiles/${profileId}/degrees`,
    data,
  });
};

const updateDegreeGroups = (profileId: string, data: DegreeGroup[]): AxiosPromise<DegreeGroup> => {
  return axios.supplyInstance({
    method: 'PUT',
    url: `v1/connection-score/profiles/${profileId}/degrees`,
    data,
  });
};

const postScoringSingleRequest = (data: RequestScsScoringRequest): AxiosPromise<string> => {
  return axios.demandConnectionsInstance({
    method: 'POST',
    url: 'v1/connection-score',
    data,
  });
};

const postScoringMultiRequest = (data: RequestScsScoringRequest): AxiosPromise<string> => {
  return axios.demandConnectionsInstance({
    method: 'POST',
    url: 'v1/connection-score/multi',
    data: getFormData(data),
  });
};

export default {
  getScoringProfile,
  createScoringProfile,
  updateScoringProfile,
  createPositionGroup,
  updatePositionGroup,
  deletePositionGroup,
  createRoleGroup,
  updateRoleGroup,
  deleteRoleGroup,
  createDegreeGroup,
  updateDegreeGroups,
  postScoringSingleRequest,
  postScoringMultiRequest,
};
