import React from 'react';
import { Tab, Tabs, Typography } from '@a_team/ui-components';
import { ScoringProfileSettings } from './scoring-profile-settings';
import { ScsScoringRequest } from './scs-scoring-request';

export const Scs: React.FC = () => (
  <>
    <Typography variant="h1">Social Connection Score</Typography>

    <Tabs>
      <Tab id="scoringRequest" label="Scoring Request">
        <ScsScoringRequest />
      </Tab>
      <Tab id="settings" label="Settings">
        <ScoringProfileSettings />
      </Tab>
    </Tabs>
  </>
);
