import { CreateWarmLeadsRequestModel, GetParamsSuccessModel } from 'features/warm-leads/warm-leads-request-model';

export const CREATE_WARM_LEADS_REQUEST = 'warmLeads/CREATE_WARM_LEADS_REQUEST';
export interface CreateWarmLeadsRequestAction {
  type: typeof CREATE_WARM_LEADS_REQUEST;
  payload: CreateWarmLeadsRequestModel;
}

export const CREATE_WARM_LEADS_SUCCESS = 'warmLeads/CREATE_WARM_LEADS_SUCCESS';
export interface CreateWarmLeadsSuccessAction {
  type: typeof CREATE_WARM_LEADS_SUCCESS;
  requestId: string;
}

export const CREATE_WARM_LEADS_FAIL = 'warmLeads/CREATE_WARM_LEADS_FAIL';
export interface CreateWarmLeadsFailAction {
  type: typeof CREATE_WARM_LEADS_FAIL;
  errorMessage: string;
}

export const GET_WARM_LEADS_PARAMS_REQUEST = 'warmLeads/GET_WARM_LEADS_PARAMS_REQUEST';
export interface GetParamsRequestAction {
  type: typeof GET_WARM_LEADS_PARAMS_REQUEST;
}

export const GET_WARM_LEADS_PARAMS_SUCCESS = 'warmLeads/GET_WARM_LEADS_PARAMS_SUCCESS';
export interface GetParamsSuccessAction {
  type: typeof GET_WARM_LEADS_PARAMS_SUCCESS;
  payload: GetParamsSuccessModel;
}

export const GET_WARM_LEADS_PARAMS_FAIL = 'warmLeads/GET_WARM_LEADS_PARAMS_FAIL';
export interface GetParamsFailAction {
  type: typeof GET_WARM_LEADS_PARAMS_FAIL;
  errorMessage: string;
}

export type WarmLeadsType =
  | CreateWarmLeadsRequestAction
  | CreateWarmLeadsSuccessAction
  | CreateWarmLeadsFailAction
  | GetParamsRequestAction
  | GetParamsSuccessAction
  | GetParamsFailAction;
