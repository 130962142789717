import dqsApi, { DqsSettings } from 'api/dqs-api';
import { AxiosResponse } from 'axios';
import * as actionsTypes from 'features/dqs/actions/settings-action-types';
import { toast } from 'react-toastify';
import { CallEffect, PutEffect, call, put, takeLatest } from 'redux-saga/effects';
import { dqsSettings } from '../reducers/settings-reducers';

function* loadSettings(): Generator<
  | CallEffect<AxiosResponse<DqsSettings, any>>
  | PutEffect<actionsTypes.LoadSettingsSuccessAction>
  | PutEffect<actionsTypes.LoadSettingsFailAction>,
  void,
  AxiosResponse<DqsSettings, any>
> {
  try {
    const res = yield call(dqsApi.getSettings);
    yield put(dqsSettings.actions.loadSettingsSuccess(res.data));
  } catch (err: any) {
    // validation errors will have more info on err.response.data.errors
    const message = err.response?.data?.errors ? JSON.stringify(err.response?.data?.errors) : err.message;
    yield put(dqsSettings.actions.loadSettingsFail(message));
  }
}

function* saveSettings({
  payload,
}: actionsTypes.SaveSettingsRequestAction): Generator<
  | CallEffect<AxiosResponse<DqsSettings, any>>
  | PutEffect<actionsTypes.SaveSettingsSuccessAction>
  | PutEffect<actionsTypes.SaveSettingsFailAction>,
  void,
  AxiosResponse<DqsSettings, any>
> {
  try {
    const res = yield call(dqsApi.saveSettings, payload);
    toast.success('Saved settings');
    yield put(dqsSettings.actions.saveSettingsSuccess(res.data));
  } catch (err: any) {
    // validation errors will have more info on err.response.data.errors
    const message = err.response?.data?.errors ? JSON.stringify(err.response?.data?.errors) : err.message;
    yield put(dqsSettings.actions.saveSettingsFail(message));
  }
}

export default function* saga() {
  yield takeLatest(actionsTypes.LOAD_SETTINGS_REQUEST, loadSettings);
  yield takeLatest(actionsTypes.SAVE_SETTINGS_REQUEST, saveSettings);
}
