import { Input } from '@a_team/ui-components';
import { FC } from 'react';

export interface Props {
  type: 'text' | 'number';
  value: string | number;
  handleChange: (val: string) => void;
}

const ProfileSettingsFormInput: FC<Props> = ({ value, type, handleChange }) => {
  return <Input type={type} value={value} onChange={(e) => handleChange(e.target.value)} />;
};

export default ProfileSettingsFormInput;
