import * as actionsTypes from 'features/enrich-companies/actions/enrich-companies-action-types';
import { CreateEnrichCompaniesRequestModel } from 'features/enrich-companies/enrich-companies-request-model';

export const handleCreateEnrichCompaniesRequestRequest = (
  payload: CreateEnrichCompaniesRequestModel
): actionsTypes.CreateEnrichCompaniesRequestAction => ({
  type: actionsTypes.CREATE_ENRICH_COMPANIES_REQUEST,
  payload,
});

export const handleCreateEnrichCompaniesRequestSuccess =
  (): actionsTypes.CreateEnrichCompaniesRequestSuccessAction => ({
    type: actionsTypes.CREATE_ENRICH_COMPANIES_REQUEST_SUCCESS,
  });

export const handleCreateEnrichCompaniesRequestFail = (
  payload: string
): actionsTypes.CreateEnrichCompaniesRequestFailAction => ({
  type: actionsTypes.CREATE_ENRICH_COMPANIES_REQUEST_FAIL,
  payload,
});
