import { Button, Input, Typography } from '@a_team/ui-components';
import RowContainer from 'core/components/row-container';
import { useAppDispatch, useAppSelector } from 'core/store';
import { getCompanyPageUrl } from 'features/company-pages-generation/helpers';
import {
  createCompanyPageAction,
  createCompanyPageFromLinkedInAction,
  duplicateCompanyPageAction,
  getCompanyPagesAction,
  getCompanyPagesCsvAction,
} from 'features/company-pages-generation/redux/actions';
import { companyPagesActions } from 'features/company-pages-generation/redux/slice';
import { useFormik } from 'formik';
import moment from 'moment';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import PageLinkCopy from './page-link-copy';

const useStyles = createUseStyles({
  pageData: {
    padding: '12px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    maxWidth: '480px',
  },
  linkContainer: {
    display: 'flex',
  },
  link: {
    fontSize: '14px',
  },
  duplicateButton: {
    alignSelf: 'flex-end',
  },
  companyPagesTitle: {
    fontSize: '16px',
    marginBottom: '20px',
  },
  labelValue: {
    fontSize: '13px',
  },
  label: {
    fontWeight: 500,
  },
  generatePageButton: {
    width: '220px',
    marginTop: '12px',
  },
  createPageButton: {
    marginBottom: '20px',
  },
  linkedInScrapingNote: {
    fontStyle: 'italic',
    marginTop: '4px',
    fontSize: '14px',
  },
  csvInputLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    fontSize: 12,
    textAlign: 'center',
  },
});

export const CompanyPagesForm: FC = () => {
  const dispatch = useAppDispatch();
  const styles = useStyles();

  const {
    loadingPages,
    creatingPage,
    loadedUrl,
    createdPages,
    error,
    createdPageUrl,
    linkedInRequestSuccess,
    linkedInRequestError,
    linkedInPageUrl,
    csvRequestSuccess,
    csvRequestError,
  } = useAppSelector((state) => state.companyPagesGeneration);
  const formik = useFormik({
    initialValues: {
      url: '',
      linkedinUrl: '',
      csv: null,
    },
    onSubmit: (values, actions) => {
      dispatch(companyPagesActions.resetState());
      if (values.url) {
        dispatch(getCompanyPagesAction({ domain: values.url }));
      } else if (values.linkedinUrl) {
        dispatch(createCompanyPageFromLinkedInAction({ linkedinUrl: values.url }));
      } else if (values.csv) {
        dispatch(getCompanyPagesCsvAction({ csv: values.csv as any }));
      }
      actions.resetForm();
    },
  });

  const duplicatePage = (slug: string) => {
    dispatch(duplicateCompanyPageAction({ slug }));
  };

  const generatePage = () => {
    dispatch(createCompanyPageAction({ url: formik.values.url }));
  };

  const errorMessage = error || linkedInRequestError || csvRequestError;

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <RowContainer separator="or">
          <Input
            placeholder="Enter Company Url"
            name="url"
            onChange={formik.handleChange}
            size="fit"
            value={formik.values.url}
          />
          <Input
            placeholder="Enter LinkedIn Profile Url"
            name="linkedinUrl"
            onChange={formik.handleChange}
            size="fit"
            value={formik.values.linkedinUrl}
          />
          <label className={styles.csvInputLabel}>
            <div>
              Upload a CSV file <br /> with 'domain' column
            </div>
            <Input type="file" name="csv" onChange={(e: any) => formik.setFieldValue('csv', e.target.files[0])} />
          </label>
        </RowContainer>
        <Button loading={loadingPages || creatingPage}>SUBMIT</Button>
      </form>
      <br />

      {errorMessage && (
        <Typography variant="textMedium" color="Red@600">
          Error submitting request: {errorMessage}
        </Typography>
      )}

      {linkedInRequestSuccess && linkedInPageUrl && (
        <>
          <div>
            Created new page:{' '}
            <a href={linkedInPageUrl} target="_blank" rel="noopener noreferrer" className={styles.link}>
              {linkedInPageUrl}
            </a>
          </div>
          <p className={styles.linkedInScrapingNote}>
            It should take up to a minute to fetch data for the LinkedIn profile and fill the page with builders. In
            case roles for this company exist on LinkedIn, it will take a few minutes for these roles to appear on the
            page - in the meanwhile, you will see placeholder roles.
          </p>
        </>
      )}

      {csvRequestSuccess && (
        <Typography variant="textMedium">
          Request successfully submitted. You should get result via email soon.
        </Typography>
      )}

      {createdPageUrl && (
        <>
          <div>
            Created new page:{' '}
            <a href={createdPageUrl} target="_blank" rel="noopener noreferrer" className={styles.link}>
              {createdPageUrl}
            </a>
            <PageLinkCopy url={createdPageUrl} name={createdPages?.[0]?.name || ''} />
          </div>
          <p className={styles.linkedInScrapingNote}>
            In case roles for this company exist on LinkedIn, it will take a few minutes for these roles to appear on
            the page - in the meanwhile, you will see placeholder roles.
          </p>
        </>
      )}
      {!error && loadedUrl && (
        <>
          <h5 className={styles.companyPagesTitle}>Existing pages for {loadedUrl}</h5>
          <Button loading={creatingPage} onClick={generatePage} className={styles.createPageButton}>
            Create a page from scratch
          </Button>
          {createdPages?.map((page) => (
            <div key={page.id} className={styles.pageData}>
              <div className={styles.linkContainer}>
                <a
                  href={getCompanyPageUrl(page.pageSlug)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  {getCompanyPageUrl(page.pageSlug)}
                </a>
                <PageLinkCopy url={getCompanyPageUrl(page.pageSlug)} name={page.name} />
              </div>
              <div className={styles.labelValue}>
                <span className={styles.label}>Created At: </span>
                {moment(page.createdAt).format('YYYY/MM/DD HH:mm')}
              </div>
              <div className={styles.labelValue}>
                <span className={styles.label}>Created By: </span>
                {page.createdBy}
              </div>
              <Button size="sm" onClick={() => duplicatePage(page.pageSlug)} className={styles.duplicateButton}>
                Duplicate Page
              </Button>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
