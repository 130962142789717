import * as actionsTypes from 'features/linkedin-job-postings/actions/linkedin-job-postings-action-types';
import {
  CreateLinkedInJobsCsvRequestModel,
  CreateLinkedInJobsRequestModel,
} from 'features/linkedin-job-postings/linkedin-job-postings-request-model';

// CREATE/UPDATE PARAMS
export const handleCreateLinkedInJobsRequestRequest = (
  payload: CreateLinkedInJobsRequestModel
): actionsTypes.CreateLinkedInJobsRequestAction => ({
  type: actionsTypes.CREATE_LINKEDIN_JOBS_REQUEST,
  payload,
});

export const handleCreateLinkedInJobsRequestSuccess = (payload: {
  requestId: number;
}): actionsTypes.CreateLinkedInJobsRequestSuccessAction => ({
  type: actionsTypes.CREATE_LINKEDIN_JOBS_REQUEST_SUCCESS,
  payload,
});

export const handleCreateLinkedInJobsRequestFail = (
  payload: string
): actionsTypes.CreateLinkedInJobsRequestFailAction => ({
  type: actionsTypes.CREATE_LINKEDIN_JOBS_REQUEST_FAIL,
  payload,
});

// CREATE/UPDATE PARAMS
export const handleCreateLinkedInJobsCsvRequestRequest = (
  payload: CreateLinkedInJobsCsvRequestModel
): actionsTypes.CreateLinkedInJobsCsvRequestAction => ({
  type: actionsTypes.CREATE_LINKEDIN_JOBS_CSV_REQUEST,
  payload,
});

export const handleCreateLinkedInJobsCsvRequestSuccess = (payload: {
  requestId: number;
}): actionsTypes.CreateLinkedInJobsCsvRequestSuccessAction => ({
  type: actionsTypes.CREATE_LINKEDIN_JOBS_CSV_REQUEST_SUCCESS,
  payload,
});

export const handleCreateLinkedInJobsCsvRequestFail = (
  payload: string
): actionsTypes.CreateLinkedInJobsCsvRequestFailAction => ({
  type: actionsTypes.CREATE_LINKEDIN_JOBS_CSV_REQUEST_FAIL,
  payload,
});
