import { createSlice } from '@reduxjs/toolkit';

export type ScoringRequestState = {
  loading: boolean;
};

const initialState: ScoringRequestState = {
  loading: false,
};

export const scoringRequest = createSlice({
  name: 'scoringRequest',
  initialState,
  reducers: {
    postScoringRequest: (state) => {
      state.loading = true;
    },
    postScoringRequestSuccess: (state) => {
      state.loading = false;
    },
    postScoringRequestFail: (state) => {
      state.loading = false;
    },
  },
});

export const { postScoringRequest, postScoringRequestSuccess, postScoringRequestFail } = scoringRequest.actions;

export default scoringRequest.reducer;
