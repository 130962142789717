import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BuilderLinkedinDataPage, SearchSettings } from 'features/intro-search/intro-search-request-model';

interface SearchHistoryEntry {
  query: string;
}

const SEARCH_HISTORY_LOCAL_STORAGE_KEY = 'introSearchHistory';
const SEARCH_HISTORY_MAX_SIZE = 100;

export type IntroSearchState = {
  error?: string;
  loading: boolean;
  requestId?: string;
  pageData?: BuilderLinkedinDataPage;
  settings?: SearchSettings;
  searchHistory: SearchHistoryEntry[];
  monitoringUsers: { email: string }[];
  monitoringLoading: boolean;
};

const initialState: IntroSearchState = {
  error: undefined,
  loading: false,
  requestId: undefined,
  searchHistory: [],
  monitoringUsers: [],
  monitoringLoading: false,
};

try {
  initialState.searchHistory = JSON.parse(localStorage.getItem(SEARCH_HISTORY_LOCAL_STORAGE_KEY) || '[]');
} catch {
  // ignore
}

const introSearchSlice = createSlice({
  name: 'introSearch',
  initialState: initialState,
  reducers: {
    startIntroSearch: (state) => {
      state.error = undefined;
      state.loading = true;
    },
    introSearchSuccess: (state, action: PayloadAction<BuilderLinkedinDataPage>) => {
      return {
        ...state,
        error: undefined,
        loading: false,
        pageData: action.payload,
      };
    },
    introSearchFail: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
        requestId: undefined,
        pageData: undefined,
      };
    },
    getIntroSearchSettings: (state) => {
      state.loading = true;
    },
    getIntroSearchSettingsSuccess: (state, action: PayloadAction<SearchSettings>) => {
      return {
        ...state,
        settings: action.payload,
        loading: false,
      };
    },
    getIntroSearchSettingsFail: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    },
    saveIntroSearchSettingsFail: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    addSearchHistoryEntry: (state, action: PayloadAction<SearchHistoryEntry>) => {
      state.searchHistory.push(action.payload);
      if (state.searchHistory.length > SEARCH_HISTORY_MAX_SIZE) {
        state.searchHistory.shift();
      }

      localStorage.setItem(SEARCH_HISTORY_LOCAL_STORAGE_KEY, JSON.stringify(state.searchHistory));
    },
    setMonitoringUsers: (state, action: PayloadAction<{ email: string }[]>) => {
      state.monitoringUsers = action.payload;
    },
    setMonitoringLoading: (state, action: PayloadAction<boolean>) => {
      state.monitoringLoading = action.payload;
    },
  },
});

export const introSearchActions = introSearchSlice.actions;

export const introSearchReducer = introSearchSlice.reducer;
