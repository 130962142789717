import './App.css';
import { ThemeProvider } from 'react-jss';
import { theme } from '@a_team/ui-components/lib/theme';
import routes from './routing/route-config';
import PrivateRoute from './routing/private-route';
import { Route, Switch } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_AUTH_CLIENT_ID } from 'config/constants';

function App() {
  const renderRoutes = () => {
    return routes.map((route, index) => {
      return route.requireAuth ? (
        <PrivateRoute component={route.component} routes={route.routes || []} key={index} />
      ) : (
        <Route key={index} {...route} />
      );
    });
  };

  return (
    <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
      <ThemeProvider theme={theme}>
        <Switch>{renderRoutes()}</Switch>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
