import targetedLeadsApi from 'api/targeted-leads-api';
import { SEPARATOR } from 'config/constants';
import { extractErrorMessage } from 'core/utils/extractErrorMessage';
import * as actionsTypes from 'features/targeted-leads/actions/targeted-leads-action-types';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { targetedLeads } from '../reducers/targeted-leads-reducers';

function* uploadTaggedProfiles({ taggedProfilesFile }: actionsTypes.UploadTaggedProfilesRequestAction) {
  try {
    yield call(targetedLeadsApi.postTaggedProfiles, taggedProfilesFile);
    yield put(targetedLeads.actions.uploadTaggedProfilesSuccess());
    toast.success('Tagged profiles submitted successfuly');
  } catch (err: any) {
    const errorMessage = extractErrorMessage(err);
    yield put(targetedLeads.actions.uploadTaggedProfilesFail(errorMessage));
    toast.error(`Error submitting request: ${errorMessage}`);
  }
}

function* requestTargetedLeads({ payload }: actionsTypes.RequestTargetedLeadsRequestAction) {
  try {
    if (payload.linkedinUrl?.length) {
      yield call(targetedLeadsApi.postTargetedLeadsSingle, payload.linkedinUrl);
    } else if (payload.companyName) {
      const includedRoles = payload.includedRoles?.length ? payload.includedRoles.split(SEPARATOR) : [];
      yield call(targetedLeadsApi.postTargetedLeadsCompany, payload.companyName, includedRoles);
    } else {
      const errorMessage = 'Neither linkedinUrl nor company name were provided!';
      yield put(targetedLeads.actions.requestTargetedLeadsFail(errorMessage));
      toast.error(`Error submitting request: ${errorMessage}`);
    }
    yield put(targetedLeads.actions.requestTargetedLeadsSuccess());
    toast.success('Targeted leads request submitted successfuly');
  } catch (err: any) {
    const errorMessage = extractErrorMessage(err);
    yield put(targetedLeads.actions.requestTargetedLeadsFail(errorMessage));
    toast.error(`Error submitting request: ${errorMessage}`);
  }
}

export default function* saga() {
  yield takeLatest(actionsTypes.UPLOAD_TAGGED_PROFILES_REQUEST, uploadTaggedProfiles);
  yield takeLatest(actionsTypes.REQUEST_TARGETED_LEADS_REQUEST, requestTargetedLeads);
}
