import {
  DegreeGroup,
  PositionGroup,
  RoleGroup,
  ScoringProfileRequestModel,
  ScoringProfileResponseModel,
} from 'features/scs/models/scoring-profile';

export const GET_SCORING_PROFILE_REQUEST = 'scs/GET_SCORING_PROFILE_REQUEST';
export interface GetScoringProfileRequestAction {
  type: typeof GET_SCORING_PROFILE_REQUEST;
}

export const GET_SCORING_PROFILE_SUCCESS = 'scs/GET_SCORING_PROFILE_SUCCESS';
export interface GetScoringProfileSuccessAction {
  type: typeof GET_SCORING_PROFILE_SUCCESS;
  data: ScoringProfileResponseModel;
}

export const GET_SCORING_PROFILE_FAIL = 'scs/GET_SCORING_PROFILE_FAIL';
export interface GetScoringProfileFailAction {
  type: typeof GET_SCORING_PROFILE_FAIL;
}

export const SAVE_SCORING_PROFILE_REQUEST = 'scs/SAVE_SCORING_PROFILE_REQUEST';
export interface SaveScoringProfileRequestAction {
  type: typeof SAVE_SCORING_PROFILE_REQUEST;
  data: ScoringProfileRequestModel;
  id?: string;
}

export const SAVE_SCORING_PROFILE_SUCCESS = 'scs/SAVE_SCORING_PROFILE_SUCCESS';
export interface SaveScoringProfileSuccessAction {
  type: typeof SAVE_SCORING_PROFILE_SUCCESS;
  data: ScoringProfileResponseModel;
}

export const SAVE_SCORING_PROFILE_FAIL = 'scs/SAVE_SCORING_PROFILE_FAIL';
export interface SaveScoringProfileFailAction {
  type: typeof SAVE_SCORING_PROFILE_FAIL;
}

export const SAVE_POSITION_GROUP_REQUEST = 'scs/SAVE_POSITION_GROUP_REQUEST';
export interface SavePositionGroupRequestAction {
  type: typeof SAVE_POSITION_GROUP_REQUEST;
  data: PositionGroup;
  profileId: string;
  id?: string;
}

export const DELETE_POSITION_GROUP_REQUEST = 'scs/DELETE_POSITION_GROUP_REQUEST';
export interface DeletePositionGroupRequestAction {
  type: typeof DELETE_POSITION_GROUP_REQUEST;
  profileId: string;
  id: string;
}
export const SAVE_ROLE_GROUP_REQUEST = 'scs/SAVE_ROLE_GROUP_REQUEST';
export interface SaveRoleGroupRequestAction {
  type: typeof SAVE_ROLE_GROUP_REQUEST;
  data: RoleGroup;
  profileId: string;
  id?: string;
}

export const DELETE_ROLE_GROUP_REQUEST = 'scs/DELETE_ROLE_GROUP_REQUEST';
export interface DeleteRoleGroupRequestAction {
  type: typeof DELETE_ROLE_GROUP_REQUEST;
  profileId: string;
  id: string;
}

export const SAVE_DEGREE_GROUPS_REQUEST = 'scs/SAVE_DEGREE_GROUPS_REQUEST';
export interface SaveDegreeGroupsRequestAction {
  type: typeof SAVE_DEGREE_GROUPS_REQUEST;
  data: DegreeGroup[];
  profileId: string;
}

export type ScoringProfileActionType =
  | GetScoringProfileRequestAction
  | GetScoringProfileSuccessAction
  | GetScoringProfileFailAction
  | SaveScoringProfileRequestAction
  | SaveScoringProfileSuccessAction
  | SaveScoringProfileFailAction
  | SavePositionGroupRequestAction
  | DeletePositionGroupRequestAction
  | SaveRoleGroupRequestAction
  | DeleteRoleGroupRequestAction
  | SaveDegreeGroupsRequestAction;
