import { Typography } from '@a_team/ui-components';
import PageHeader from 'core/components/page-header';
import { RootState } from 'core/store';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { WarmLeadsJobState } from '../reducers/warm-leads-reducer';
import { WarmLeadsForm } from './warm-leads-form';

export const WarmLeads: FC = () => {
  const state = useSelector((state: RootState) => state.warmLeads);
  return (
    <>
      <PageHeader
        title="Warm Leads"
        description={
          <>
            <div>
              This tool allows to generate a CSV file loaded with relevant and personalized information about leads.
            </div>
            <div>Input = the full name or linkedin URL of anyone</div>
            <div>
              Output = we output the same file but filtered for our ICP. The output file will include LinkedIn
              information, how long is the lead at their job, if they used to work somewhere where an A.Team core team
              member also worked at, investors information (in case they are the same as A.Team), past co-workers they
              have, # of open roles and their LinkedIn board. That file should be uploaded into a outreach platform and
              used with a relevant templates to leverage all the different variables.
            </div>
            <p>
              Example of a use case: we take a Facebook output file (list of 1000 friends of a friend of Kobi named
              Jessica) and upload it here. We will then know who out of these 1000 is a ICP and we will be able to email
              them saying that “we have a mutual friend (Jessica), let's talk."
            </p>
          </>
        }
      />
      <WarmLeadsForm loading={state.warmLeadsJobState === WarmLeadsJobState.SubmittingJob} />
      {!state.requestId && !state.requestFailed && <Typography variant="h4">Status: Idle</Typography>}
      {state.requestId && !state.requestFailed && (
        <Typography variant="h4">
          Request with id {state.requestId} created. You should get an email with csv file once request is processed.
        </Typography>
      )}
      {state.requestFailed && (
        <Typography variant="h4">
          Status: Request Failed, requestId is {state.requestId}, please try again, change your input csv or parameters.
        </Typography>
      )}
    </>
  );
};
