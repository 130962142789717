export const UPLOAD_SEARCH_TERMS_REQUEST = 'sge-outbound/UPLOAD_SEARCH_TERMS_REQUEST';

export interface OutboundUploadSearchTermsRequestAction {
  type: typeof UPLOAD_SEARCH_TERMS_REQUEST;
  searchTerm1: string;
  searchTerm2: string;
  linkedInCount: number;
  searchTermsFile: File | undefined;
  utmCampaign: string;
  shouldGenerateCP: boolean;
  shouldAddConnections: boolean;
}

export const UPLOAD_SEARCH_TERMS_SUCCESS = 'sge-outbound/UPLOAD_SEARCH_TERMS_SUCCESS';
export interface OutboundUploadSearchTermsSuccessAction {
  type: typeof UPLOAD_SEARCH_TERMS_SUCCESS;
  requestId: string;
}

export const UPLOAD_SEARCH_TERMS_FAIL = 'sge-outbound/UPLOAD_SEARCH_TERMS_FAIL';
export interface OutboundUploadSearchTermsFailAction {
  type: typeof UPLOAD_SEARCH_TERMS_FAIL;
}

export type SgeOutboundAction =
  | OutboundUploadSearchTermsRequestAction
  | OutboundUploadSearchTermsSuccessAction
  | OutboundUploadSearchTermsFailAction;
