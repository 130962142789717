import { Button, Icon, Input, Table, theme } from '@a_team/ui-components';
import { OptionalSection } from 'core/components/optional-section';
import { TagInputList } from 'core/components/tag-input-list';
import { EmployeeData } from 'features/warm-leads/warm-leads-request-model';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  tableDiv: {
    '& table': {
      width: '100%',
      '& td': {
        padding: '4px',
        '& input:first-child, & input:last-child': {
          borderLeft: `1px solid ${theme.colors.Grey[800]}`,
          borderRight: `1px solid ${theme.colors.Grey[800]}`,
        },
        '& input': {
          width: '100%',
        },
      },
      '& button': {
        width: '100%',
      },
    },
  },
  addButton: {
    marginTop: '16px',
    marginLeft: 'auto',
  },
  deleteButton: {
    background: 'none',
    border: 'none',
    '&, & svg': {
      cursor: 'pointer',
    },
  },
});

enum ACCESSORS {
  fullName = 'fullName',
  title = 'title',
  companies = 'companies',
  controls = 'controls',
}

const COLUMNS = [
  { accessor: ACCESSORS.fullName, Header: 'Full name' },
  { accessor: ACCESSORS.title, Header: 'Title' },
  { accessor: ACCESSORS.companies, Header: 'Companies' },
  { accessor: ACCESSORS.controls, Header: '' },
];

enum InputNames {
  fullName = 'fullName',
  title = 'title',
  companies = 'companies',
}

export const WarmLeadsEmployeesForm: FC<{
  formik: any;
}> = ({ formik }) => {
  const { tableDiv, addButton, deleteButton } = useStyles();

  const data: EmployeeData[] = formik.values.employeesData ?? [];
  const handleChange =
    (index: number) =>
    ({ target: { name, value } }: { target: { name: string; value: unknown } }) => {
      if (name === InputNames.companies) {
        data[index][name] = value as string[];
      } else if (name === InputNames.fullName || name === InputNames.title) {
        data[index][name] = value as string;
      }
      formik.setFieldValue('employeesData', data);
    };

  const onAddClick = () => {
    formik.setFieldValue('employeesData', [
      ...data,
      {
        fullName: '',
        title: '',
        companies: [],
      },
    ]);
  };

  const onDeleteClick = (index: number) => {
    return () => {
      data.splice(index, 1);
      formik.setFieldValue('employeesData', data);
    };
  };

  const tableData = data.map(({ fullName, title, companies }, index) => ({
    [ACCESSORS.fullName]: (
      <Input type="text" label="" name={InputNames.fullName} onChange={handleChange(index)} value={fullName} />
    ),
    [ACCESSORS.title]: (
      <Input type="text" label="" name={InputNames.title} onChange={handleChange(index)} value={title} />
    ),
    [ACCESSORS.companies]: (
      <TagInputList
        tagContainer=""
        values={companies}
        label=""
        name={InputNames.companies}
        onChange={handleChange(index)}
      />
    ),
    [ACCESSORS.controls]: (
      <button className={deleteButton} onClick={onDeleteClick(index)} type="button">
        <Icon name="delete" />
      </button>
    ),
  }));

  return (
    <OptionalSection
      title="A.Team employees core team past experiences"
      description="Write here who used to work where from A.Team core team. We will notify if one of the leads on the CSV also used to work there."
    >
      <div className={tableDiv}>
        <Table columns={COLUMNS} data={tableData} />
      </div>
      <Button onClick={onAddClick} className={addButton}>
        Add new row
      </Button>
    </OptionalSection>
  );
};
