import axios from 'config/axios-config';
import { getFormData } from './helpers';

const createCompanyPage = async (payload: any) => {
  return axios.demandLeadsInstance({
    method: 'POST',
    url: 'v1/company-pages',
    data: payload,
  });
};

const getCompanyPages = async ({ domain }: { domain: string }) => {
  return axios.demandLeadsInstance({
    method: 'GET',
    url: `/v1/company-pages/list`,
    params: {
      url: domain,
    },
  });
};

const duplicateCompanyPage = async ({ slug }: { slug: string }) => {
  return axios.demandLeadsInstance({
    method: 'POST',
    url: `/v1/company-pages/duplicate/${slug}`,
  });
};

const getCompanyPagesCsv = async (payload: any) => {
  return axios.demandLeadsInstance({
    method: 'POST',
    url: 'v1/company-pages/get-csv',
    data: getFormData(payload),
  });
};

const createCompanyPageFromLinkedIn = async (payload: { linkedinUrl: string }) => {
  return axios.demandLeadsInstance({
    method: 'POST',
    url: '/v1/company-pages/linkedin-profile',
    data: payload,
  });
};

export default {
  createCompanyPage,
  getCompanyPages,
  getCompanyPagesCsv,
  createCompanyPageFromLinkedIn,
  duplicateCompanyPage,
};
