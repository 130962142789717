import {
  Button,
  ButtonVariants,
  Column,
  Icon,
  Input,
  Row,
  Select,
  SelectOption,
  Spinner,
  Table,
  TableColumn,
  Tooltip,
  Typography,
} from '@a_team/ui-components';
import { RootState } from 'core/store';
import { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleFetchSupplyRequestsRequest,
  handleForceCloseSupplyRequestsRequest,
} from '../actions/supply-requests-actions';
import { PaginationProps, SupplyRequest, SupplyRequestSource, SupplyRequestsStatus } from '../models/supply-request';
import { SupplyRequestsState } from '../reducers/supply-requests-reducers';

const DateTimeFormatter: FC<{ value: string }> = ({ value }) => {
  const date = new Date(value);
  return (
    <Tooltip placement={'top'} text={value}>
      <span>{date.toUTCString()}</span>
    </Tooltip>
  );
};

const StatusFormatter: FC<{ status: SupplyRequestsStatus }> = ({ status }) => {
  let icon;
  switch (status) {
    case SupplyRequestsStatus.Done:
      icon = <Icon name="statusPositive" color="Green@800" />;
      break;
    case SupplyRequestsStatus.InProgress:
      icon = <Icon name="clock" color="Murdock@800" />;
      break;
    case SupplyRequestsStatus.Started:
      icon = <Icon name="speedometer" color="Murdock@600" />;
      break;
    default:
      icon = <Icon name="faq" color="Grey@500" />;
  }
  return (
    <Tooltip placement={'top'} text={status}>
      <span>{icon}</span>
    </Tooltip>
  );
};

const ActionFormatter: FC<{ rowData: SupplyRequest }> = ({ rowData }) => {
  const dispatch = useDispatch();
  const forceCloseRequests = useSelector<
    RootState,
    {
      requested: string[];
      success: string[];
      failed: string[];
    }
  >((state) => state.supplyRequests.forceClose);
  if (rowData.status === SupplyRequestsStatus.Done && rowData.resultsUrl) {
    return (
      <Button size="sm" variant={'positive'} iconProps={{ name: 'download' }}>
        <a href={rowData.resultsUrl} download style={{ color: 'inherit' }}>
          Download results
        </a>
      </Button>
    );
  }

  if (rowData.source) {
    const { id: parentId, source, childrenRequests } = rowData;

    const id = childrenRequests.length ? childrenRequests[0].id : parentId;

    let buttonLabel = 'Close request';
    let buttonVariant: ButtonVariants = 'main';
    let buttonLoading: boolean = false;
    let buttonDisabled: boolean = false;
    if (forceCloseRequests.requested.includes(id)) {
      buttonLoading = true;
      buttonLabel = 'Closing request...';
    } else if (forceCloseRequests.success.includes(id)) {
      buttonDisabled = true;
    } else if (forceCloseRequests.failed.includes(id)) {
      buttonVariant = 'negative';
      buttonLabel = 'Retry closing request';
    }

    const button = (
      <Button
        size="sm"
        variant={buttonVariant}
        iconProps={{ name: 'delete' }}
        loading={buttonLoading}
        disabled={buttonDisabled}
        onClick={() => dispatch(handleForceCloseSupplyRequestsRequest(id, source))}
      >
        {buttonLabel}
      </Button>
    );

    if (rowData.status === SupplyRequestsStatus.Done) {
      return (
        <Tooltip placement="top" text="The request has to be closed first as the result is missing">
          <span>{button}</span>
        </Tooltip>
      );
    } else {
      return button;
    }
  }
  return null;
};

const Pagination: FC<PaginationProps> = ({ setPage, page, total }) => {
  const canPreviousPage = page - 1 > 0;
  const canNextPage = page < total;

  return (
    <Row align="center" style={{ gap: '1rem', marginLeft: '20px', marginRight: '20px', marginTop: '1rem' }}>
      <Button
        variant={'secondary'}
        size={'md'}
        iconProps={{ name: 'arrowClose' }}
        disabled={!canPreviousPage}
        onClick={() => setPage(1)}
      >
        First
      </Button>
      <Button
        variant={'secondary'}
        size={'md'}
        iconProps={{ name: 'arrowBack' }}
        disabled={!canPreviousPage}
        onClick={() => setPage(page - 1)}
      >
        Prev
      </Button>
      <Button
        variant={'secondary'}
        size={'md'}
        iconProps={{ name: 'arrowForward' }}
        disabled={!canNextPage}
        onClick={() => setPage(page + 1)}
      >
        Next
      </Button>
      <Button
        variant={'secondary'}
        size={'md'}
        iconProps={{ name: 'arrowOpen' }}
        disabled={!canNextPage}
        onClick={() => setPage(total)}
      >
        Last
      </Button>
      <span>
        Page <strong>{page}</strong> of <strong>{total}</strong>
      </span>
    </Row>
  );
};

const useStyles = createUseStyles({
  table: {
    width: '100%',
  },
});

export const SupplyRequests: FC = () => {
  const dispatch = useDispatch();
  const { items, loading, current, total } = useSelector<RootState, SupplyRequestsState>(
    (state) => state.supplyRequests
  );

  const styles = useStyles();

  const [filterByStatus, setFilterByStatus] = useState<SelectOption>({ label: 'All', value: '' });
  const [filterBySource, setFilterBySource] = useState<SelectOption>({ label: 'All', value: '' });
  const [searchByRequester, setSearchByRequester] = useState('');

  useEffect(() => {
    dispatchFetchSupplyRequestsRequest();
  }, [filterByStatus.value, filterBySource.value, searchByRequester]);

  const dispatchFetchSupplyRequestsRequest = (page: number = 1) => {
    dispatch(
      handleFetchSupplyRequestsRequest({
        requesterEmail: searchByRequester || undefined,
        source: (filterBySource.value as SupplyRequestSource) || undefined,
        status: (filterByStatus.value as SupplyRequestsStatus) || undefined,
        page,
      })
    );
  };

  const columns: TableColumn<SupplyRequest>[] = [
    { Header: 'Request Id', accessor: 'id', Cell: ({ value }: { value: SupplyRequest['id'] }) => <code>{value}</code> },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: { value: SupplyRequest['status'] }) => <StatusFormatter status={value} />,
    },
    {
      Header: 'Builders requested no.',
      accessor: 'requestedcount',
    },
    {
      Header: 'Builders processed no.',
      accessor: 'processedcount',
    },
    {
      Header: 'Builders waiting no.',
      accessor: '',
      Cell: ({ rowData }) => <>{Number(rowData.requestedcount) - Number(rowData.processedcount)}</>,
    },
    {
      Header: 'Source',
      accessor: 'source',
    },
    {
      Header: 'Requested By',
      accessor: 'requesterEmail',
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      Cell: ({ value }: { value: SupplyRequest['createdAt'] }) => <DateTimeFormatter value={value} />,
    },
    {
      Header: 'Actions',
      accessor: '',
      Cell: ({ rowData }) => <ActionFormatter rowData={rowData} />,
    },
  ];
  return (
    <>
      <Typography variant="h1">Supply requests</Typography>

      <Row align="end">
        <Column sm={2}>
          <Select
            value={filterByStatus}
            placeholder={'Status'}
            label={'Filter by status'}
            onChange={(value) => setFilterByStatus(value as SelectOption)}
            options={[
              { label: 'All', value: '' },
              ...Object.keys(SupplyRequestsStatus).map((key) => ({
                label: key,
                value: SupplyRequestsStatus[key as keyof typeof SupplyRequestsStatus],
              })),
            ]}
          />
        </Column>
        <Column sm={2}>
          <Select
            value={filterBySource}
            placeholder={'Source'}
            label={'Filter by source'}
            onChange={(value) => setFilterBySource(value as SelectOption)}
            options={[
              { label: 'All', value: '' },
              ...Object.keys(SupplyRequestSource).map((key) => ({
                label: key,
                value: SupplyRequestSource[key as keyof typeof SupplyRequestSource],
              })),
            ]}
          />
        </Column>
        <Column sm={2}>
          <Input
            value={searchByRequester}
            placeholder={'Requester email'}
            label={'Search by requester'}
            onChange={({ target }) => setSearchByRequester(target.value)}
          />
        </Column>
        <Column>
          <Button
            onClick={() => dispatchFetchSupplyRequestsRequest()}
            iconProps={{ name: 'refresh' }}
            size="md"
            loading={loading}
          >
            Refresh
          </Button>
        </Column>
      </Row>

      <Table data={loading ? [] : items} columns={columns} className={styles.table} />

      {loading && (
        <Row
          align="center"
          justify="center"
          style={{ margin: '1rem', height: loading && items.length ? '272px' : 'auto' }}
        >
          <Spinner />
        </Row>
      )}

      <Pagination page={current} total={total} setPage={dispatchFetchSupplyRequestsRequest} />
    </>
  );
};
