import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LinkedinConnectionsState = {
  loading: boolean;
  requestError?: string;
};

const initialState: LinkedinConnectionsState = {
  loading: false,
  requestError: undefined,
};

export const linkedinConnections = createSlice({
  name: 'linkedinConnections',
  initialState,
  reducers: {
    uploadLinkedinConnectionsRequest: (state) => {
      state.loading = true;
      state.requestError = undefined;
    },
    uploadLinkedinConnectionsSuccess: (state) => {
      state.loading = false;
      state.requestError = undefined;
    },
    uploadLinkedinConnectionsFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.requestError = action.payload;
    },
  },
});

export const { uploadLinkedinConnectionsRequest, uploadLinkedinConnectionsSuccess, uploadLinkedinConnectionsFail } =
  linkedinConnections.actions;

export default linkedinConnections.reducer;
