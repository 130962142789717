import { DqsSettings } from 'api/dqs-api';
import * as actionsTypes from 'features/dqs/actions/settings-action-types';

export const handleLoadSettingsRequest = (): actionsTypes.LoadSettingsRequestAction => ({
  type: actionsTypes.LOAD_SETTINGS_REQUEST,
});

export const handleLoadSettingsSuccess = (payload: DqsSettings): actionsTypes.LoadSettingsSuccessAction => ({
  type: actionsTypes.LOAD_SETTINGS_SUCCESS,
  payload,
});

export const handleLoadSettingsFail = (payload: string): actionsTypes.LoadSettingsFailAction => ({
  type: actionsTypes.LOAD_SETTINGS_FAIL,
  payload,
});

export const handleSaveSettings = (payload: DqsSettings): actionsTypes.SaveSettingsRequestAction => ({
  type: actionsTypes.SAVE_SETTINGS_REQUEST,
  payload,
});

export const handleSaveSettingsSuccess = (payload: DqsSettings): actionsTypes.SaveSettingsSuccessAction => ({
  type: actionsTypes.SAVE_SETTINGS_SUCCESS,
  payload,
});

export const handleSaveSettingsFail = (payload: string): actionsTypes.SaveSettingsFailAction => ({
  type: actionsTypes.SAVE_SETTINGS_FAIL,
  payload,
});
