import { Button, Input, Typography } from '@a_team/ui-components';
import { RootState } from 'core/store';
import { useFormik } from 'formik';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

import { handleRequestTargetedLeadsRequest } from 'features/targeted-leads/actions/targeted-leads-actions';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = createUseStyles({
  container: {
    maxWidth: '320px',
  },
});

const RequestTargetedLeads: FC = () => {
  const styles = useStyles();

  const dispatch = useDispatch();

  const requestTargetedLeadsInProgress = useSelector<RootState, boolean>(
    (state) => state.targetedLeads.requestTargetedLeadsInProgress
  );

  const formik = useFormik({
    initialValues: {
      linkedinUrl: '',
      companyName: '',
      includedRoles: '',
    },
    onSubmit: (values) => {
      dispatch(handleRequestTargetedLeadsRequest(values));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.container}>
        <Typography variant="h3">Get targeted leads for a person by linkedin urls:</Typography>
        <Input
          type="text"
          name="linkedinUrl"
          placeholder="Enter target node linkedin url"
          onChange={formik.handleChange}
          value={formik.values.linkedinUrl}
          size="stretch"
          disabled={!!formik.values.companyName?.length || !!formik.values.includedRoles?.length}
        />

        <Button
          margin="sm"
          loading={requestTargetedLeadsInProgress}
          disabled={!formik.values.linkedinUrl && !formik.values.companyName}
        >
          SUBMIT
        </Button>
      </div>
    </form>
  );
};

export default RequestTargetedLeads;
