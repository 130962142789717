import { Button, Input, Typography } from '@a_team/ui-components';
import PageHeader from 'core/components/page-header';
import { RootState } from 'core/store';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleCreateEnrichCompaniesRequestRequest } from '../actions/enrich-companies-utils-actions';

interface FormikValues {
  file: File | undefined;
}

export const EnrichCompanies: FC = () => {
  const { loading } = useSelector((state: RootState) => state.enrichCompanies);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      file: undefined,
    },
    onSubmit: (values: FormikValues) => {
      dispatch(handleCreateEnrichCompaniesRequestRequest(values));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <PageHeader
        title="Enrich companies"
        description={
          <div style={{ maxWidth: 500 }}>
            This tool allows you to input the work email of people or the company url or company name of any company. It
            will return data about the company.
          </div>
        }
      />
      <Typography variant="textSmall" component="p" style={{ marginBottom: '8px' }}>
        Submit file with 'company_url', 'company_name' or 'email' column
      </Typography>
      <Input
        type="file"
        accept=".csv"
        name="file"
        onChange={(e: any) => formik.setFieldValue('file', e.target.files[0])}
      />
      <br />
      <Button loading={loading}>SUBMIT</Button>
    </form>
  );
};
