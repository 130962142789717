import SgeApi from 'api/sge-api';
import * as actions from 'features/sge/actions/sge-action-types';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { sge } from '../reducers/sge-reducers';

function* requestUploadSearchTerms({
  searchTerm1,
  searchTerm2,
  linkedInCount,
  searchTermsFile,
  metadata,
}: actions.UploadSearchTermsRequestAction) {
  try {
    const res = yield call(SgeApi.postGoogleSearch, searchTerm1, searchTerm2, linkedInCount, searchTermsFile, metadata);
    yield put(sge.actions.uploadSearchTermsSuccess(res.data.requestId));
    toast.success(`Request ${res.data.requestId} submitted succesfully`);
  } catch (err: any) {
    toast.error(`Error submitting request: ${err.message}`);
    yield put(sge.actions.uploadSearchTermsFail());
  }
}

export default function* saga() {
  yield takeLatest(actions.UPLOAD_SEARCH_TERMS_REQUEST, requestUploadSearchTerms);
}
