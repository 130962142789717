import { Button, Input, Typography } from '@a_team/ui-components';
import { RootState } from 'core/store';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleMixWarmLeadsFilesRequestRequest } from '../actions/warm-leads-utils-actions';

interface FormikValues {
  csvs: File[] | undefined;
}

export const WarmLeadsUtilsMixFiles: FC = () => {
  const { loading } = useSelector((state: RootState) => state.warmLeadsUtils);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      csvs: undefined,
    },
    onSubmit: (values: FormikValues) => {
      dispatch(handleMixWarmLeadsFilesRequestRequest(values));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h3">Concatenate & Mix CSV files</Typography>
      <Typography variant="textMedium" component="p" margin="xs">
        If you have multiple CSV files and you wish to randomly mix between rows, just upload them here (each CSV needs
        to have a header) and we will do the rest.
      </Typography>
      <Input
        type="file"
        accept=".csv"
        name="csvs"
        multiple
        onChange={(e: any) => formik.setFieldValue('csvs', e.target.files)}
      />
      <br />
      <Button loading={loading}>Submit</Button>
    </form>
  );
};
