import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TokenData } from 'features/auth/models/auth-data';
import moment from 'moment';

export type AuthState = {
  userLoggedIn: boolean;
  errorMessage: string;
  token: string;
  expiresOn: Date;
};

const getAuthInitialState = (): AuthState => {
  const tokenString = localStorage.getItem('token') || '{}';
  const tokenData = <TokenData>JSON.parse(tokenString);
  const expiresOn = tokenData.expiresOn;
  let userLoggedIn = true;
  if (Object.keys(tokenData).length === 0 || moment(new Date()).isAfter(expiresOn)) {
    userLoggedIn = false;
  }
  return {
    userLoggedIn,
    errorMessage: '',
    token: tokenData.token,
    expiresOn,
  };
};

export const auth = createSlice({
  name: 'auth',
  initialState: getAuthInitialState(),
  reducers: {
    signinSuccess: (state, action: PayloadAction<{ token: string }>) => {
      return {
        ...state,
        userLoggedIn: true,
        token: action.payload.token,
      };
    },
    signinReset: () => getAuthInitialState(),
    signinSetError: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
  },
});
