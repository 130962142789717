import {
  DegreeGroup,
  PositionGroup,
  RoleGroup,
  ScoringProfileRequestModel,
  ScoringProfileResponseModel,
} from 'features/scs/models/scoring-profile';
import * as actionsTypes from './scoring-profile-action-types';

export const handleGetScoringProfileRequest = (): actionsTypes.GetScoringProfileRequestAction => ({
  type: actionsTypes.GET_SCORING_PROFILE_REQUEST,
});

export const handleGetScoringProfileSuccess = (
  data: ScoringProfileResponseModel
): actionsTypes.GetScoringProfileSuccessAction => ({
  type: actionsTypes.GET_SCORING_PROFILE_SUCCESS,
  data,
});

export const handleGetScoringProfileFail = (): actionsTypes.GetScoringProfileFailAction => ({
  type: actionsTypes.GET_SCORING_PROFILE_FAIL,
});

export const handleSaveScoringProfileRequest = (
  data: ScoringProfileRequestModel,
  id?: string
): actionsTypes.SaveScoringProfileRequestAction => ({
  type: actionsTypes.SAVE_SCORING_PROFILE_REQUEST,
  data,
  id,
});

export const handleSaveScoringProfileSuccess = (
  data: ScoringProfileResponseModel
): actionsTypes.SaveScoringProfileSuccessAction => ({
  type: actionsTypes.SAVE_SCORING_PROFILE_SUCCESS,
  data,
});

export const handleSaveScoringProfileFail = (): actionsTypes.SaveScoringProfileFailAction => ({
  type: actionsTypes.SAVE_SCORING_PROFILE_FAIL,
});

export const handleSavePositionGroupRequest = (
  data: PositionGroup,
  profileId: string,
  id?: string
): actionsTypes.SavePositionGroupRequestAction => ({
  type: actionsTypes.SAVE_POSITION_GROUP_REQUEST,
  data,
  profileId,
  id,
});

export const handleDeletePositionGroupRequest = (
  profileId: string,
  id: string
): actionsTypes.DeletePositionGroupRequestAction => ({
  type: actionsTypes.DELETE_POSITION_GROUP_REQUEST,
  profileId,
  id,
});

export const handleSaveRoleGroupRequest = (
  data: RoleGroup,
  profileId: string,
  id?: string
): actionsTypes.SaveRoleGroupRequestAction => ({
  type: actionsTypes.SAVE_ROLE_GROUP_REQUEST,
  data,
  profileId,
  id,
});

export const handleDeleteRoleGroupRequest = (
  profileId: string,
  id: string
): actionsTypes.DeleteRoleGroupRequestAction => ({
  type: actionsTypes.DELETE_ROLE_GROUP_REQUEST,
  profileId,
  id,
});

export const handleSaveDegreeGroupsRequest = (
  data: DegreeGroup[],
  profileId: string
): actionsTypes.SaveDegreeGroupsRequestAction => ({
  type: actionsTypes.SAVE_DEGREE_GROUPS_REQUEST,
  data,
  profileId,
});
