import * as actions from './supply-requests-actions-types';
import { SupplyRequestRequestQuery, SupplyRequestSource, SupplyRequestsResponse } from '../models/supply-request';

export const handleFetchSupplyRequestsRequest = (
  query?: SupplyRequestRequestQuery
): actions.FetchSupplyRequestsRequestAction => ({
  type: actions.FETCH_SUPPLY_REQUESTS_REQUEST,
  query,
});

export const handleFetchSupplyRequestsSuccess = (
  data: SupplyRequestsResponse
): actions.FetchSupplyRequestsSuccessAction => ({
  type: actions.FETCH_SUPPLY_REQUESTS_SUCCESS,
  data,
});

export const handleFetchSupplyRequestsFail = (): actions.FetchSupplyRequestsFailAction => ({
  type: actions.FETCH_SUPPLY_REQUESTS_FAIL,
});

export const handleForceCloseSupplyRequestsRequest = (
  requestId: string,
  source: SupplyRequestSource
): actions.ForceCloseSupplyRequestsRequestAction => ({
  type: actions.FORCE_CLOSE_SUPPLY_REQUESTS_REQUEST,
  requestId,
  source,
});

export const handleForceCloseSupplyRequestsSuccess = (
  requestId: string
): actions.ForceCloseSupplyRequestsSuccessAction => ({
  type: actions.FORCE_CLOSE_SUPPLY_REQUESTS_SUCCESS,
  requestId,
});

export const handleForceCloseSupplyRequestsFail = (requestId: string): actions.ForceCloseSupplyRequestsFailAction => ({
  type: actions.FORCE_CLOSE_SUPPLY_REQUESTS_FAIL,
  requestId,
});
