export const UPLOAD_LINKEDIN_CONNECTIONS_REQUEST = 'connections/UPLOAD_LINKEDIN_CONNECTIONS_REQUEST';
export interface UploadLinkedinConnectionsRequestAction {
  type: typeof UPLOAD_LINKEDIN_CONNECTIONS_REQUEST;
  primaryNodeLinkedinUrl: string;
  secondaryLinkedinUrlsFile: File | undefined;
}

export const UPLOAD_LINKEDIN_CONNECTIONS_SUCCESS = 'connections/UPLOAD_LINKEDIN_CONNECTIONS_SUCCESS';
export interface UploadLinkedinConnectionsSuccessAction {
  type: typeof UPLOAD_LINKEDIN_CONNECTIONS_SUCCESS;
  payload: string;
}

export const UPLOAD_LINKEDIN_CONNECTIONS_FAIL = 'connections/UPLOAD_LINKEDIN_CONNECTIONS_FAIL';
export interface UploadLinkedinConnectionsFailAction {
  type: typeof UPLOAD_LINKEDIN_CONNECTIONS_FAIL;
  payload: string;
}
export type LinkedinConnectionsActionType =
  | UploadLinkedinConnectionsRequestAction
  | UploadLinkedinConnectionsSuccessAction
  | UploadLinkedinConnectionsFailAction;
