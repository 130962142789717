import { WarmLeadsParamsModel } from 'features/warm-leads/warm-leads-request-model';

export const WARM_LEAD_DEFAULT_PARAMS: WarmLeadsParamsModel = {
  excludedCompanies: 'meta,google,bairesdev,fiverr,guru',
  excludedEntities: '',
  totalRaisedLowerThreshold1Millions: 4,
  totalRaisedUpperThreshold1Millions: 20,
  employeeCountLowerThreshold1: 20,
  employeeCountUpperThreshold1: 200,
  totalRaisedLowerThreshold2Millions: 20,
  totalRaisedUpperThreshold2Millions: 9999999,
  employeeCountLowerThreshold2: 200,
  employeeCountUpperThreshold2: 9999999,
  positionIncludesRange1: 'vp,head,director,ceo,cto,manager,vice president,cro,cpo,officer,lead',
  positionExcludesRange1: 'portfolio,practice',
  positionIncludesRange2: 'vp,head,director,ceo,cto,manager,vice president,cro,cpo,officer,lead',
  positionExcludesRange2: 'portfolio,practice',
  mutualVCFilters: '',
  partnershipVCFilters: '',
  employeesData: [],
  jobPostingsMaxEmployeeCount: 100,
  jobPostingsIncludeRange1: 'product,head',
  jobPostingsExcludeRange1: 'portfolio',
  jobPostingsIncludeRange2: 'engineer,developer,designer',
  jobPostingsExcludeRange2: 'civil,electrical',
  jobPostingsLimit: 10,
};

export const MUTUAL_AND_PARTNER_VCS_DEFAULT_VALUES = [
  {
    mutualVC: 'Firstminute Capital',
    mutualVCPartner: 'Spencer Crawley',
    partnershipVC: 'Andreessen Horowitz',
    partnershipVCPartner: '',
  },
  { mutualVC: 'BoxGroup', mutualVCPartner: '', partnershipVC: 'Greylock', partnershipVCPartner: '' },
  { mutualVC: 'Global Founders Capital', mutualVCPartner: '', partnershipVC: 'Thrive', partnershipVCPartner: '' },
  {
    mutualVC: 'Village Global',
    mutualVCPartner: 'Anne Dwane',
    partnershipVC: 'Social Capital',
    partnershipVCPartner: '',
  },
  { mutualVC: 'GGV', mutualVCPartner: '', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: 'FJ Labs', mutualVCPartner: '', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: 'Roc Nation', mutualVCPartner: '', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: 'SemperVirens', mutualVCPartner: 'Allison Baum Gates', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: 'Spruce House Capital', mutualVCPartner: '', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: 'NFX', mutualVCPartner: '', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: 'Insight', mutualVCPartner: 'Inbar Kodesh', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: 'Tiger Global', mutualVCPartner: '', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: '', mutualVCPartner: '', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: '', mutualVCPartner: '', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: '', mutualVCPartner: '', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: '', mutualVCPartner: '', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: '', mutualVCPartner: '', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: '', mutualVCPartner: '', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: '', mutualVCPartner: '', partnershipVC: '', partnershipVCPartner: '' },
  { mutualVC: '', mutualVCPartner: '', partnershipVC: '', partnershipVCPartner: '' },
];
