import { Button, Input, Typography } from '@a_team/ui-components';
import { IntroSearchResultsLocation } from 'core/routing/locations';
import { useAppDispatch } from 'core/store';
import { useFormik } from 'formik';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { introSearchActions } from '../redux/slice';
import logoImage from './assets/logo.svg';
import IntroSearchHistory from './intro-search-history';

const useStyles = createUseStyles({
  container: {
    padding: '0 20px',
    height: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  inputContainer: {
    maxWidth: 200,
    marginBottom: 16,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& label': {
      fontSize: 12,
      textTransform: 'none',
      fontWeight: 400,
    },
  },
  heading: {
    marginBottom: 0,
    fontSize: 40,
    lineHeight: 1.5,
    fontWeight: 600,
  },
  subtitle: {
    display: 'block',
    marginBottom: 24,
    fontSize: 18,
    lineHeight: 1.5,
    fontWeight: 400,
    textAlign: 'center',
  },
  button: {
    '& span': {
      fontWeight: 400,
    },
  },
  logo: {
    position: 'fixed',
    top: -40,
    right: -40,
  },
});

export const IntroSearchIndex: FC = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      companyUrl: '',
    },
    onSubmit: (values) => {
      dispatch(introSearchActions.addSearchHistoryEntry({ query: values.companyUrl }));
      history.push({
        pathname: IntroSearchResultsLocation,
        search: `?query=${values.companyUrl}`,
      });
    },
  });

  return (
    <>
      <img src={logoImage} alt="logo" className={styles.logo} />
      <div className={styles.container}>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <Typography variant="h1" className={styles.heading}>
            Intro Search Engine
          </Typography>
          <Typography variant="textMedium" className={styles.subtitle}>
            Find the warmest path from A.Team's network to more than 120,000 companies
          </Typography>
          <div className={styles.inputContainer}>
            <Input
              label="Enter your target account's url:"
              name="companyUrl"
              onChange={formik.handleChange}
              size="stretch"
              value={formik.values.companyUrl}
            />
          </div>
          <Button className={styles.button}>FIND PATH</Button>
        </form>
      </div>
      <IntroSearchHistory />
    </>
  );
};
