import { createAsyncThunk } from '@reduxjs/toolkit';
import teamRequestPagesApi from 'api/team-request-pages-api';
import { AppThunk } from 'core/store';
import { teamRequestPagesActions } from './slice';

export const createTeamRequestPageAction: AppThunk<{ url: string }> = createAsyncThunk(
  'createTeamRequestPage',
  async (data, { dispatch }) => {
    try {
      dispatch(teamRequestPagesActions.startCreatingPage());

      const res = await teamRequestPagesApi.createTeamRequestPage(data);

      dispatch(teamRequestPagesActions.createPageSuccess({ slug: res.data.pageSlug }));
      dispatch(teamRequestPagesActions.addCreatedPage(res.data));
    } catch (err: any) {
      dispatch(teamRequestPagesActions.createPageFail(err?.response?.data?.message || err.message));
    }
  }
);
