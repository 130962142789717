import { Icon, Typography } from '@a_team/ui-components';
import { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles<string, { isOpen: boolean }>({
  collapseContainer: {
    display: (props) => (props.isOpen ? 'initial' : 'none'),
  },
  title: {
    cursor: 'pointer',
    display: 'flex',
    gap: 8,
  },
  description: {
    display: 'inline-block',
    marginTop: '-12px',
    marginBottom: '16px',
  },
  iconContainer: {
    width: '20px',
    height: '20px',
    fontSize: 12,
    transition: 'transform 0.2s',
    '&, & span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& svg': {
      cursor: 'pointer',
      width: '16px',
      height: '16px',
    },
  },
});

interface Props {
  title: string;
  defaultOpen?: boolean;
  children: React.ReactNode;
  description?: string;
}

export const OptionalSection: FC<Props> = ({ title, defaultOpen, children, description }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen ?? false);
  const styles = useStyles({ isOpen });

  return (
    <div>
      <Typography variant="h3" className={styles.title} onClick={() => setIsOpen(!isOpen)}>
        {title}
        <div className={styles.iconContainer} style={{ transform: `rotate(${isOpen ? 0 : -90}deg)` }}>
          <Icon name="arrowDown" />
        </div>
      </Typography>
      {description && (
        <Typography variant="textMedium" className={styles.description}>
          {description}
        </Typography>
      )}
      <div className={styles.collapseContainer}>{children}</div>
    </div>
  );
};
