import * as actions from './lqs-action-types';
import { SelectOption } from '@a_team/ui-components';
import { BaseScoringProfile } from 'core/models/Profiles';
import { ScoreRequestMetadata } from 'core/models/SupplyScore';

export const handleFetchProfileTypesRequest = (): actions.FetchProfileTypesRequestAction => ({
  type: actions.FETCH_PROFILE_TYPES_REQUEST,
});

export const handleFetchProfileTypesSuccess = (
  profileOptions: SelectOption[]
): actions.FetchProfileTypesSuccessAction => ({
  type: actions.FETCH_PROFILE_TYPES_SUCCESS,
  profileOptions,
});

export const handleFetchProfileTypesFail = (): actions.FetchProfileTypesFailAction => ({
  type: actions.FETCH_PROFILE_TYPES_FAIL,
});

export const handleSetSelectedProfile = (selectedProfile: SelectOption): actions.SetSelectedProfileAction => ({
  type: actions.SET_SELECTED_PROFILE,
  selectedProfile,
});

export const handleFetchProfileSuccess = (profile: BaseScoringProfile): actions.FetchProfileSuccessAction => ({
  type: actions.FETCH_PROFILE_SUCCESS,
  currentProfile: profile,
});

export const handleFetchProfileFail = (): actions.FetchProfileFailAction => ({
  type: actions.FETCH_PROFILE_FAIL,
});

export const handleAddNewProfile = (): actions.AddNewProfileAction => ({
  type: actions.ADD_NEW_PROFILE,
});

export const handleSaveProfileSettingsRequest = (): actions.SaveProfileSettingsRequestAction => ({
  type: actions.SAVE_PROFILE_SETTINGS_REQUEST,
});

export const handleSaveProfileSettingsSuccess = (): actions.SaveProfileSettingsSuccessAction => ({
  type: actions.SAVE_PROFILE_SETTINGS_SUCCESS,
});

export const handleSaveProfileSettingsFail = (): actions.SaveProfileSettingsFailAction => ({
  type: actions.SAVE_PROFILE_SETTINGS_FAIL,
});

export const handleUploadNamesCsvRequest = (
  selectedProfile: string,
  file: File,
  metadata: ScoreRequestMetadata
): actions.UploadNamesCsvRequestAction => ({
  type: actions.UPLOAD_NAME_CSV_REQUEST,
  selectedProfile,
  file,
  metadata,
});

export const handleUploadNamesCsvSuccess = (requestId: string): actions.UploadNamesCsvSuccessAction => ({
  type: actions.UPLOAD_NAME_CSV_SUCCESS,
  requestId,
});

export const handleUploadNamesCsvFail = (): actions.UploadNamesCsvFailAction => ({
  type: actions.UPLOAD_NAME_CSV_FAIL,
});

export const handleUploadLinkedinUrlsFileRequest = (
  selectedProfile: string,
  file: File,
  metadata: ScoreRequestMetadata
): actions.UploadLinkedinUrlsFileRequestAction => ({
  type: actions.UPLOAD_LINKEDIN_URLS_FILE_REQUEST,
  selectedProfile,
  file,
  metadata,
});

export const handleUploadLinkedinUrlsFileSuccess = (
  requestId: string
): actions.UploadLinkedinUrlsFileSuccessAction => ({
  type: actions.UPLOAD_LINKEDIN_URLS_FILE_SUCCESS,
  requestId,
});

export const handleUploadLinkedinUrlsFileFail = (): actions.UploadLinkedinUrlsFileFailAction => ({
  type: actions.UPLOAD_LINKEDIN_URLS_FILE_FAIL,
});

export const handleRunQueryRequest = (
  query: string,
  selectedProfile: string,
  metadata: ScoreRequestMetadata
): actions.RunQueryRequestAction => ({
  type: actions.RUN_QUERY_REQUEST,
  query,
  selectedProfile,
  metadata,
});

export const handleRunQuerySuccess = (requestId: string): actions.RunQuerySuccessAction => ({
  type: actions.RUN_QUERY_SUCCESS,
  requestId,
});

export const handleRunQueryFail = (): actions.RunQueryFailAction => ({
  type: actions.RUN_QUERY_FAIL,
});

export const handleGetQueryResultsRequest = (requestId: string): actions.GetQueryResultsRequestAction => ({
  type: actions.GET_QUERY_RESULTS_REQUEST,
  requestId,
});

export const handleGetQueryResultsSuccess = (result: any): actions.GetQueryResultsSuccessAction => ({
  type: actions.GET_QUERY_RESULTS_SUCCESS,
  result,
});

export const handleGetQueryResultsFail = (): actions.GetQueryResultsFailAction => ({
  type: actions.GET_QUERY_RESULTS_FAIL,
});

export const handleGetSettingsRequest = (): actions.GetSettingsRequestAction => ({
  type: actions.GET_SETTINGS_REQUEST,
});

export const handleGetSettingsSuccess = (result: any): actions.GetSettingsSuccessAction => ({
  type: actions.GET_SETTINGS_SUCCESS,
  result,
});

export const handleGetSettingsFail = (): actions.GetSettingsFailAction => ({
  type: actions.GET_SETTINGS_FAIL,
});

export const handleSaveSettingsRequest = (): actions.SaveSettingsRequestAction => ({
  type: actions.SAVE_SETTINGS_REQUEST,
});

export const handleSaveSettingsSuccess = (): actions.SaveSettingsSuccessAction => ({
  type: actions.SAVE_SETTINGS_SUCCESS,
});

export const handleSaveSettingsFail = (): actions.SaveSettingsFailAction => ({
  type: actions.SAVE_SETTINGS_FAIL,
});

export const handleSignalHireEmailCallLqsThresholdChange = (
  value: number
): actions.SignalHireEmailCallLqsThresholdChangeAction => ({
  type: actions.SIGNAL_HIRE_EMAIL_CALL_LQS_THRESHOLD_CHANGE,
  value,
});

export const handleExcludedCompaniesChange = (value: string): actions.ExcludedCompaniesChangeAction => ({
  type: actions.EXCLUDED_COMPANIES_CHANGE,
  value,
});

export const handleExcludedSchoolsChange = (value: string): actions.ExcludedSchoolsChangeAction => ({
  type: actions.EXCLUDED_SCHOOLS_CHANGE,
  value,
});
