import {
  CreateLinkedInJobsCsvRequestModel,
  CreateLinkedInJobsRequestModel,
} from 'features/linkedin-job-postings/linkedin-job-postings-request-model';

// Create Request
export const CREATE_LINKEDIN_JOBS_REQUEST = 'linkedin-job-postings/CREATE_LINKEDIN_JOBS_REQUEST';
export interface CreateLinkedInJobsRequestAction {
  type: typeof CREATE_LINKEDIN_JOBS_REQUEST;
  payload: CreateLinkedInJobsRequestModel;
}

export const CREATE_LINKEDIN_JOBS_REQUEST_SUCCESS = 'linkedin-job-postings/CREATE_LINKEDIN_JOBS_REQUEST_SUCCESS';
export interface CreateLinkedInJobsRequestSuccessAction {
  type: typeof CREATE_LINKEDIN_JOBS_REQUEST_SUCCESS;
  payload: { requestId: number };
}

export const CREATE_LINKEDIN_JOBS_REQUEST_FAIL = 'linkedin-job-postings/CREATE_LINKEDIN_JOBS_REQUEST_FAIL';
export interface CreateLinkedInJobsRequestFailAction {
  type: typeof CREATE_LINKEDIN_JOBS_REQUEST_FAIL;
  payload: string;
}

// Create Request
export const CREATE_LINKEDIN_JOBS_CSV_REQUEST = 'linkedin-job-postings/CREATE_LINKEDIN_JOBS_CSV_REQUEST';
export interface CreateLinkedInJobsCsvRequestAction {
  type: typeof CREATE_LINKEDIN_JOBS_CSV_REQUEST;
  payload: CreateLinkedInJobsCsvRequestModel;
}

export const CREATE_LINKEDIN_JOBS_CSV_REQUEST_SUCCESS =
  'linkedin-job-postings/CREATE_LINKEDIN_JOBS_CSV_REQUEST_SUCCESS';
export interface CreateLinkedInJobsCsvRequestSuccessAction {
  type: typeof CREATE_LINKEDIN_JOBS_CSV_REQUEST_SUCCESS;
  payload: { requestId: number };
}

export const CREATE_LINKEDIN_JOBS_CSV_REQUEST_FAIL = 'linkedin-job-postings/CREATE_LINKEDIN_JOBS_CSV_REQUEST_FAIL';
export interface CreateLinkedInJobsCsvRequestFailAction {
  type: typeof CREATE_LINKEDIN_JOBS_CSV_REQUEST_FAIL;
  payload: string;
}

export type LinkedInJobsActionType =
  | CreateLinkedInJobsRequestAction
  | CreateLinkedInJobsRequestSuccessAction
  | CreateLinkedInJobsRequestFailAction
  | CreateLinkedInJobsCsvRequestAction
  | CreateLinkedInJobsCsvRequestSuccessAction
  | CreateLinkedInJobsCsvRequestFailAction;
