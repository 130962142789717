import axios from 'config/axios-config';
import { getFormData } from './helpers';

const createEnrichCompaniesRequest = (payload: any) => {
  return axios.demandInstance({
    method: 'POST',
    url: '/v1/companies/enrich',
    data: getFormData(payload),
  });
};

export default {
  createEnrichCompaniesRequest,
};
