import { Button, Checkbox, Input, Typography } from '@a_team/ui-components';
import { RootState } from 'core/store';
import { ChangeEvent, FC, useState } from 'react';
import { createUseStyles } from 'react-jss';

import { OptionalSection } from 'core/components/optional-section';
import PageHeader from 'core/components/page-header';
import { handleUploadSearchTermsRequest } from 'features/sge/actions/sge-actions';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = createUseStyles({
  container: {
    maxWidth: '320px',
  },
});

const Sge: FC = () => {
  const { container } = useStyles();

  const dispatch = useDispatch();

  const uploadSearchTermsInProgress = useSelector<RootState, boolean>((state) => state.sge.uploadSearchTermsInProgress);
  const requestId = useSelector<RootState, string>((state) => state.sge.requestId);

  const [searchTerm1, setSearchTerm1] = useState('');
  const [searchTerm2, setSearchTerm2] = useState('');
  const [linkedInCount, setLinkedInCount] = useState(0);
  const [searchTermsFile, setSearchTermsFile] = useState<File | undefined>();
  const [skills, setSkills] = useState('');
  const [positions, setPositions] = useState('');
  const [github, setGithub] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [includeEmail, setIncludeEmail] = useState(false);
  const [includeClosestConnections, setIncludeClosestConnections] = useState(false);
  const [includeEmployeeCount, setIncludeEmployeeCount] = useState(false);
  const handleFileChange = (e: ChangeEvent) => {
    const files = (e.target as HTMLInputElement)?.files;
    if (files !== null && files.length > 0) {
      setSearchTermsFile(files[0]);
    }
  };

  const handleOnSubmit = () => {
    dispatch(
      handleUploadSearchTermsRequest(searchTerm1, searchTerm2, linkedInCount, searchTermsFile, {
        skills,
        positions,
        github,
        utmCampaign,
        includeEmail,
        includeClosestConnections,
        includeEmployeeCount,
      })
    );
  };

  return (
    <>
      <PageHeader
        title="Find Linkedin profiles of potential builders (per tech stack)"
        description="This tool allows you to pull a list of talent based on geography and tech stacks. It will output LinkedIn urls + data about the profiles of relevant people. How does it work? We use SERP (Google Search) by creating multiple queries for popular first names we pre-loaded in your target region and the tech stacks mentioned below. Once you get the output file, filter LQS and load the file into an outreach platform to invite the builders."
      />
      <div className={container}>
        <Typography variant="h3" style={{ fontSize: 15 }}>
          Upload a CSV file with search terms
        </Typography>
        <Input type="file" accept=".csv" onChange={handleFileChange} />
        <Typography variant="h3" style={{ fontSize: 15 }}>
          Enter your queries
        </Typography>
        <Input
          type="text"
          placeholder="Enter your queries (tech stacks)"
          onChange={(e) => setSearchTerm1(e.target.value)}
          value={searchTerm1}
          size="stretch"
        />
        <OptionalSection title="More options">
          <Input type="text" label="Positions" value={positions} onChange={(e) => setPositions(e.target.value)} />
          <Input type="text" label="Skills" value={skills} onChange={(e) => setSkills(e.target.value)} />
          <Input type="text" label="Github" value={github} onChange={(e) => setGithub(e.target.value)} />
          <Input
            type="text"
            label="Utm Campaign"
            value={utmCampaign}
            onChange={(e) => setUtmCampaign(e.target.value)}
          />
          <Checkbox
            label="Include emails"
            onChange={(e) => setIncludeEmail(e.target.checked)}
            checked={includeEmail}
            style={{ marginTop: 32, display: 'block' }}
          ></Checkbox>
          <Checkbox
            label="Include connections"
            onChange={(e) => setIncludeClosestConnections(e.target.checked)}
            checked={includeClosestConnections}
            style={{ marginTop: 32, display: 'block' }}
          ></Checkbox>
          <Checkbox
            label="Include employee count"
            onChange={(e) => setIncludeEmployeeCount(e.target.checked)}
            checked={includeEmployeeCount}
            style={{ marginTop: 32, display: 'block' }}
          ></Checkbox>
          <Input
            type="text"
            label="Search term 2"
            placeholder="Enter search term 2"
            onChange={(e) => setSearchTerm2(e.target.value)}
            value={searchTerm2}
            size="stretch"
          />
          <Input
            type="number"
            label="Number of linkeding urls to capture per search"
            onChange={(e) => setLinkedInCount(parseInt(e.target.value))}
            value={linkedInCount}
            size="stretch"
          />
        </OptionalSection>
        <Button margin="sm" onClick={handleOnSubmit} loading={uploadSearchTermsInProgress}>
          SUBMIT
        </Button>
      </div>
      {requestId && (
        <>
          <p>
            Your request{' '}
            <small>
              <code>{requestId}</code>
            </small>{' '}
            is created now. You&apos;ll get a new email soon.
            <br />
            If not, please contact the support team with your request id.
          </p>
        </>
      )}
    </>
  );
};

export default Sge;
