import { RequestScsScoringRequest } from '../models/scs-request';

export const POST_SCORING_REQUEST = 'scs/POST_SCORING_REQUEST';
export interface PostScoringRequestAction {
  type: typeof POST_SCORING_REQUEST;
  data: RequestScsScoringRequest;
}

export const POST_SCORING_REQUEST_SUCCESS = 'scs/POST_SCORING_REQUEST_SUCCESS';
export interface PostScoringRequestSuccessAction {
  type: typeof POST_SCORING_REQUEST_SUCCESS;
  data: string;
}

export const POST_SCORING_REQUEST_FAIL = 'scs/POST_SCORING_REQUEST_FAIL';
export interface PostScoringRequestFailAction {
  type: typeof POST_SCORING_REQUEST_FAIL;
}

export type ScoringRequestActionType =
  | PostScoringRequestAction
  | PostScoringRequestSuccessAction
  | PostScoringRequestFailAction;
