import { Button, Modal, Spinner, Table, Typography } from '@a_team/ui-components';
import supplySearchApi from 'api/supply-search-api';
import { useAppDispatch, useAppSelector } from 'core/store';
import { MonitoringEntry } from 'features/intro-search/intro-search-request-model';
import { getIntroSearchMonitoringUsersAction } from 'features/intro-search/redux/actions';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { toast } from 'react-toastify';

const useStyles = createUseStyles({
  container: {
    padding: '0 20px',
  },
  emailsTable: {},
  modal: {
    maxHeight: '70vh',
    overflow: 'hidden',
  },
  entriesContainer: {
    maxHeight: 'calc(70vh - 90px)',
    overflowY: 'auto',
    marginRight: '-20px',
  },
  table: {
    width: '100%',
  },
  searchButton: {
    marginLeft: 'auto',
  },
});

export const IntroSearchMonitoring: FC = () => {
  const dispatch = useAppDispatch();
  const [showedUser, setShowedUser] = useState<string | null>(null);
  const [monitoringEntries, setMonitoringEntries] = useState<MonitoringEntry[]>([]);

  const { monitoringUsers, monitoringLoading } = useAppSelector((state) => state.introSearch);

  const styles = useStyles();

  useEffect(() => {
    dispatch(getIntroSearchMonitoringUsersAction());
  }, []);

  const loadEntries = async (email: string) => {
    try {
      const res = await supplySearchApi.getIntroSearchMonitoringEntries(email);
      setMonitoringEntries(res.data);
    } catch (e) {
      toast.error('Error while fetching monitoring entries');
    }
  };

  useEffect(() => {
    if (showedUser) {
      loadEntries(showedUser);
    }
  }, [showedUser]);

  return (
    <div className={styles.container}>
      <Typography variant="h1">Intro Search Monitoring</Typography>
      <Typography variant="textMedium" component="p" style={{ marginBottom: '8px' }}>
        List of users who used intro search engine
      </Typography>
      {monitoringLoading ? (
        <Spinner />
      ) : (
        <Table
          className={styles.emailsTable}
          columns={[
            {
              accessor: 'email',
              Header: 'Email',
            },
            {
              accessor: 'showSearches',
              Header: '',
            },
          ]}
          data={monitoringUsers.map((user) => ({
            email: user.email,
            showSearches: (
              <Button onClick={() => setShowedUser(user.email)} className={styles.searchButton}>
                Show searches
              </Button>
            ),
          }))}
        />
      )}

      <Modal isOpen={!!showedUser} onClose={() => setShowedUser(null)} className={styles.modal}>
        <h4>Searches for {showedUser}</h4>
        <div className={styles.entriesContainer}>
          <Table
            className={styles.table}
            columns={[
              {
                accessor: 'companyUrl',
                Header: 'Url searched',
              },
              {
                accessor: 'searchedAt',
                Header: 'Searched at',
              },
            ]}
            data={monitoringEntries.map((user) => ({
              companyUrl: user.companyUrl,
              searchedAt: moment(user.searchedAt).format('lll'),
            }))}
          />
        </div>
      </Modal>
    </div>
  );
};
