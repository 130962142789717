import SgeOutboundApi from 'api/sge-outbound-api';
import * as actions from 'features/sge-outbound/actions/sge-outbound-action-types';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { sgeOutbound } from '../reducers/sge-outbound-reducer';

function* requestUploadSearchTerms({
  searchTerm1,
  searchTerm2,
  linkedInCount,
  searchTermsFile,
  utmCampaign,
  shouldGenerateCP,
  shouldAddConnections,
}: actions.OutboundUploadSearchTermsRequestAction) {
  try {
    const res = yield call(
      SgeOutboundApi.postGoogleSearch,
      searchTerm1,
      searchTerm2,
      linkedInCount,
      searchTermsFile,
      utmCampaign,
      shouldGenerateCP,
      shouldAddConnections
    );
    yield put(sgeOutbound.actions.uploadSearchTermsSuccess(res.data.requestId));
    toast.success('Request succesfully submitted');
  } catch (err: any) {
    yield put(sgeOutbound.actions.uploadSearchTermsFail());
    toast.error(`Error submitting request: ${err.message}`);
  }
}

export default function* saga() {
  yield takeLatest(actions.UPLOAD_SEARCH_TERMS_REQUEST, requestUploadSearchTerms);
}
