/**
 * Converts a number in millions into a 'millions' display value.
 * The result is rounded to the nearest 2 decimal places.
 * eg: 3500000 becomes 3.5, 2175000 becomes 2.18
 *
 * @param {number} millions
 * @returns The value as a display value in millions
 */
export const millionsToDisplayValue = (millions: number): number => Math.round(millions / 1e4) / 100;

/**
 * Converts a 'millions' display value into the actual number
 * eg: 3.5 becomes 3500000
 * @param {number} displayValue
 * @returns The actual numeric value
 */
export const displayValueToMillions = (displayValue: number): number => displayValue * 1e6;
