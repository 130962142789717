import { Button, Input, Typography } from '@a_team/ui-components';
import PageHeader from 'core/components/page-header';
import { RootState } from 'core/store';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleCreateColdIdeasRequestRequest } from '../actions/cold-ideas-utils-actions';

interface FormikValues {
  csv: File | undefined;
}

export const ColdIdeas: FC = () => {
  const { loading } = useSelector((state: RootState) => state.coldIdeas);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      csv: undefined,
    },
    onSubmit: (values: FormikValues) => {
      dispatch(handleCreateColdIdeasRequestRequest(values));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <PageHeader
        title="Cold Ideas"
        description="This tool allows you to input the LinkedIn URL of anyone and will output a few ideas they could work on. We leverage GPT, the person's role and the description of their company in order to generate these ideas."
      />
      <Typography variant="h3">Upload a CSV file with 'linkedin' column</Typography>
      <Input
        type="file"
        accept=".csv"
        name="csv"
        onChange={(e: any) => formik.setFieldValue('csv', e.target.files[0])}
        style={{ marginBottom: '16px' }}
      />
      <Button loading={loading}>SUBMIT</Button>
    </form>
  );
};
