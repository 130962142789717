import { createSlice } from '@reduxjs/toolkit';

export type ScsState = {
  loading: boolean;
};

const initialState: ScsState = {
  loading: false,
};

const scsSlice = createSlice({
  name: 'scs',
  initialState: initialState,
  reducers: {
    postScoringRequest: (state) => {
      state.loading = true;
    },
    postScoringRequestSuccess: (state) => {
      state.loading = false;
    },
    postScoringRequestFail: (state) => {
      state.loading = false;
    },
  },
});

export const scsActions = scsSlice.actions;

export const scsReducer = scsSlice.reducer;
