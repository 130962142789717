import demandLeadsApi from 'api/demand-leads-api';
import * as actionsTypes from 'features/warm-leads-utils/actions/warm-leads-utils-action-types';

import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { warmLeadsUtils } from '../reducers/warm-leads-utils-reducer';

function* createFilterWarmLeadsFileRequest({ payload }: actionsTypes.FilterWarmLeadsFileRequestAction) {
  try {
    yield call(demandLeadsApi.filterWarmLeadsFile, payload);
    yield put(warmLeadsUtils.actions.filterWarmLeadsFileRequestSuccess());
    toast.success(`Successfully submitted the file`);
  } catch (err: any) {
    toast.error(`Error: ${err.message}`);
    yield put(warmLeadsUtils.actions.filterWarmLeadsFileRequestFail(err?.response?.data?.message || err.message));
  }
}

function* createMixWarmLeadsFilesRequest({ payload }: actionsTypes.FilterWarmLeadsFileRequestAction) {
  try {
    yield call(demandLeadsApi.mixWarmLeadsFile, payload);
    yield put(warmLeadsUtils.actions.mixWarmLeadsFilesRequestSuccess());
    toast.success(`Successfully submitted the file`);
  } catch (err: any) {
    toast.error(`Error: ${err.message}`);
    yield put(warmLeadsUtils.actions.mixWarmLeadsFilesRequestFail(err?.response?.data?.message || err.message));
  }
}

export default function* saga() {
  yield takeLatest(actionsTypes.FILTER_WARM_LEADS_FILE_REQUEST, createFilterWarmLeadsFileRequest);
  yield takeLatest(actionsTypes.MIX_WARM_LEADS_FILES_REQUEST, createMixWarmLeadsFilesRequest);
}
