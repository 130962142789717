import demandLeadsApi from 'api/demand-leads-api';
import * as actionsTypes from 'features/cold-ideas/actions/cold-ideas-action-types';

import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  handleCreateColdIdeasRequestFail,
  handleCreateColdIdeasRequestSuccess,
} from '../actions/cold-ideas-utils-actions';

function* createColdIdeasRequest({ payload }: actionsTypes.CreateColdIdeasRequestAction) {
  try {
    const { data } = yield call(demandLeadsApi.createColdIdeasRequest, payload);
    yield put(handleCreateColdIdeasRequestSuccess());
    toast.success(`Successfully submitted the request, id: ${data.requestId}`);
  } catch (err: any) {
    toast.error(`Error: ${err.message}`);
    yield put(handleCreateColdIdeasRequestFail(err?.response?.data?.message || err.message));
  }
}

export default function* saga() {
  yield takeLatest(actionsTypes.CREATE_COLD_IDEAS_REQUEST, createColdIdeasRequest);
}
