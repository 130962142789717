import { CompanyPageParams } from 'features/company-pages/company-pages-request-model';

// Get company page params
export const GET_COMPANY_PAGE_PARAMS_REQUEST = 'company-pages/GET_COMPANY_PAGE_PARAMS_REQUEST';
export interface GetCompanyPageParamsRequestAction {
  type: typeof GET_COMPANY_PAGE_PARAMS_REQUEST;
}

export const GET_COMPANY_PAGE_PARAMS_SUCCESS = 'company-pages/GET_COMPANY_PAGE_PARAMS_SUCCESS';
export interface GetCompanyPageParamsSuccessAction {
  type: typeof GET_COMPANY_PAGE_PARAMS_SUCCESS;
  payload: CompanyPageParams;
}

export const GET_COMPANY_PAGE_PARAMS_FAIL = 'company-pages/GET_COMPANY_PAGE_PARAMS_FAIL';
export interface GetCompanyPageParamsFailAction {
  type: typeof GET_COMPANY_PAGE_PARAMS_FAIL;
  payload: string;
}

// Create/Update company page params
export const UPDATE_COMPANY_PAGE_PARAMS_REQUEST = 'company-pages/UPDATE_COMPANY_PAGE_PARAMS_REQUEST';
export interface UpdateCompanyPageParamsRequestAction {
  type: typeof UPDATE_COMPANY_PAGE_PARAMS_REQUEST;
  payload: CompanyPageParams;
}

export const UPDATE_COMPANY_PAGE_PARAMS_SUCCESS = 'company-pages/UPDATE_COMPANY_PAGE_PARAMS_SUCCESS';
export interface UpdateCompanyPageParamsSuccessAction {
  type: typeof UPDATE_COMPANY_PAGE_PARAMS_SUCCESS;
  payload: string;
}

export const UPDATE_COMPANY_PAGE_PARAMS_FAIL = 'company-pages/UPDATE_COMPANY_PAGE_PARAMS_FAIL';
export interface UpdateCompanyPageParamsFailAction {
  type: typeof UPDATE_COMPANY_PAGE_PARAMS_FAIL;
  payload: string;
}

export type CompanyPagesActionType =
  | GetCompanyPageParamsRequestAction
  | GetCompanyPageParamsSuccessAction
  | GetCompanyPageParamsFailAction
  | UpdateCompanyPageParamsRequestAction
  | UpdateCompanyPageParamsSuccessAction
  | UpdateCompanyPageParamsFailAction;
