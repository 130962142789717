import { Button } from '@a_team/ui-components';
import { GroupField } from 'core/components/group-field';
import { StandaloneField } from 'core/components/standalone-field';
import { RootState } from 'core/store';
import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { handleScrubbingSettingsGetRequest, handleScrubbingSettingsUpdate } from '../actions/scrubbing-actions';
import { SCRUBBING_SETTINGS_FORM_CONFIG, ScrubbingSettingsForm } from '../forms/scrubbing-settings-form-config';

const useStyles = createUseStyles({
  tagContainer: {
    margin: '8px',
  },
  checkboxStyle: {
    fontSize: '17px',
    display: 'flex',
  },
  section: {
    marginTop: '20px',
  },
});

export const ScrubbingSettings: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const scrubbingSettings = useSelector((state: RootState) => state.scrubbing.settings);
  const loading = useSelector((state: RootState) => state.scrubbing.loading);

  useEffect(() => {
    dispatch(handleScrubbingSettingsGetRequest());
  }, []);

  const formik = useFormik({
    initialValues: scrubbingSettings,
    enableReinitialize: true,
    onSubmit: (values) => {
      const newSettings: ScrubbingSettingsForm = {
        intervalHours: values.intervalHours,
        scrubHours: values.scrubHours,
        mainRoleExclusionWords: values.mainRoleExclusionWords ?? null,
        excludedCountryCodes: values.excludedCountryCodes ?? null,
        ofacRestrictedCountryCodes: values.ofacRestrictedCountryCodes ?? null,
        excludeEmptyLinkedin: values.excludeEmptyLinkedin,
        excludeEmptyMainRole: values.excludeEmptyMainRole,
        enforceSuspicousNameLinkedinCheck: values.enforceSuspicousNameLinkedinCheck,
        enforceSuspicousNameEmailCheck: values.enforceSuspicousNameEmailCheck,
        bigTechCompanies: values.bigTechCompanies,
      };

      dispatch(handleScrubbingSettingsUpdate(newSettings));
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {SCRUBBING_SETTINGS_FORM_CONFIG.map((formField, key) => {
          if (formField.field === 'standalone') {
            const isCheckbox = formField.type === 'checkbox';

            return (
              <div className={styles.section}>
                <StandaloneField
                  key={key}
                  formField={formField}
                  formik={formik}
                  tagContainer={isCheckbox ? styles.checkboxStyle : styles.tagContainer}
                  showErrors={true}
                />
              </div>
            );
          }

          if (formField.field === 'group') {
            return (
              <GroupField key={key} groupFields={formField.fields} formik={formik} tagContainer={styles.tagContainer} />
            );
          }
        })}
        <br />
        <Button loading={loading}>Save</Button>
      </form>
    </>
  );
};
