import { FC } from 'react';
import { Checkbox, Input, Select, SelectOption, Typography, InputProps } from '@a_team/ui-components';
import CurrencyInput from 'react-currency-input-field';
import { FormItemConfig } from './field-type';
import { TagInputList } from './tag-input-list';

export const StandaloneField: FC<{
  formik: any;
  formField: FormItemConfig;
  tagContainer: string;
  spanClassName?: string;
  options?: SelectOption[];
  size?: InputProps['size'];
  disabled?: Function | boolean;
  showErrors?: boolean;
}> = ({ formik, formField, tagContainer, spanClassName, options, disabled, showErrors, size }) => {
  const name = formField.name || '';
  let value = formik.values[name];

  if (formField.type === 'select') {
    value = options?.find((option) => option.value === value);
  }

  if (typeof value === 'undefined' || value === null) {
    value = '';
  }

  let isDisabled = false;
  if (typeof disabled === 'function') {
    isDisabled = disabled(formik.values);
  } else if (typeof disabled === 'boolean') {
    isDisabled = disabled;
  }

  const handleSelectChange = (newValue: SelectOption) => {
    // This is done this way due to how the common library handles Select events...
    formik.setFieldValue(name, newValue.value);
  };

  const handleCheckboxChange = (checked: boolean) => {
    formik.setFieldValue(name, checked);
  };

  if (formField.type === 'multi-tag') {
    return (
      <>
        {formField.prefix && <span className={spanClassName}>{formField?.prefix}</span>}
        <TagInputList
          key={formField.name}
          tagContainer={tagContainer}
          values={value}
          label={formField.label || ''}
          name={name}
          onChange={formik.handleChange}
          disabled={isDisabled}
        />
      </>
    );
  } else if (formField.type === 'hidden') {
    return <input type="hidden" key={formField.name} name={formField.name} value={value} />;
  } else if (formField.type === 'select') {
    return (
      <>
        {formField.prefix && <span className={spanClassName}>{formField?.prefix}</span>}
        <Select
          key={formField.name}
          label={formField.label || ''}
          name={name}
          value={value}
          onChange={(e) => handleSelectChange(e as SelectOption)}
          options={options}
          isDisabled={isDisabled}
        />
      </>
    );
  } else if (formField.type === 'checkbox') {
    return (
      <>
        {formField.prefix && <span className={spanClassName}>{formField?.prefix}</span>}
        <Checkbox
          key={formField.name}
          label={formField.label || ''}
          className={tagContainer}
          checked={Boolean(value)}
          onChange={(e) => handleCheckboxChange(e.target.checked)}
          disabled={isDisabled}
        />
      </>
    );
  } else if (formField.type === 'currency') {
    return (
      <>
        {formField.prefix && <span className={spanClassName}>{formField?.prefix}</span>}
        <Input
          onChange={() => {}}
          label={formField.label}
          name={formField.name}
          component={CurrencyInput}
          // @ts-ignore
          onValueChange={(value, name) => formik.setFieldValue(name, value)}
          defaultValue={value}
          decimalsLimit={1}
          disableAbbreviations={true}
          prefix="$"
          suffix="M"
          disabled={isDisabled}
        />
      </>
    );
  } else {
    const isFile = formField.type === 'file';

    const formikError =
      formField.name && (formik.touched as any)[formField.name] ? (formik.errors as any)[formField.name] : null;

    return (
      <>
        {formField.prefix && <span className={spanClassName}>{formField.prefix}</span>}
        <Input
          key={formField.name}
          type={formField.type}
          label={formField.label}
          name={formField.name}
          onChange={isFile ? (e: any) => formik.setFieldValue(formField.name, e.target.files[0]) : formik.handleChange}
          value={isFile ? undefined : value}
          size={size || 'fit'}
          disabled={isDisabled}
        />
        {showErrors && formikError && (
          <Typography variant="textSmall" component="p" color="Red@700">
            {formikError}
          </Typography>
        )}
      </>
    );
  }
};
