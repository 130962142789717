import { Input, Typography } from '@a_team/ui-components';
import { OptionalSection } from 'core/components/optional-section';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  inlineInputNumber: {
    margin: '5px',
    display: 'flex',
    width: '100px',
  },
  inlineInputText: {
    margin: '5px',
    display: 'flex',
    width: '500px',
  },
  inlineRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: '8px',
  },
});

export const WarmLeadsJobPostingsForm: FC<{
  formik: any;
}> = ({ formik }) => {
  const { inlineInputNumber, inlineInputText, inlineRow } = useStyles();

  return (
    <OptionalSection
      title="Job postings settings"
      description="If a company has open roles on LinkedIn, we will notify it in the CSV. You have the option to filter the roles that will be shown (for example, to those only relevant to A.Team)."
    >
      <div className={inlineRow}>
        <Typography variant="h4">Include companies with employee count less than:</Typography>
        <Input
          type="number"
          name="jobPostingsMaxEmployeeCount"
          className={inlineInputNumber}
          onChange={formik.handleChange}
          value={formik.values.jobPostingsMaxEmployeeCount}
        />
      </div>
      <div className={inlineRow}>
        <Typography variant="h4">Include roles that include the following words in the title</Typography>
        <Input
          type="text"
          name="jobPostingsIncludeRange1"
          className={inlineInputText}
          onChange={formik.handleChange}
          value={formik.values.jobPostingsIncludeRange1}
        />
        <Typography variant="h4">and don't contain the following words in the title</Typography>
        <Input
          type="text"
          name="jobPostingsExcludeRange1"
          className={inlineInputText}
          onChange={formik.handleChange}
          value={formik.values.jobPostingsExcludeRange1}
        />
      </div>
      <div className={inlineRow}>
        <Typography variant="h4">Or, include roles that include the following words in the title</Typography>
        <Input
          type="text"
          name="jobPostingsIncludeRange2"
          className={inlineInputText}
          onChange={formik.handleChange}
          value={formik.values.jobPostingsIncludeRange2}
        />
        <Typography variant="h4">and don't contain the following words in the title</Typography>
        <Input
          type="text"
          name="jobPostingsExcludeRange2"
          className={inlineInputText}
          onChange={formik.handleChange}
          value={formik.values.jobPostingsExcludeRange2}
        />
      </div>
      <div className={inlineRow}>
        <Typography variant="h4">Maximum number of job postings to show in csv: </Typography>
        <Input
          type="number"
          name="jobPostingsLimit"
          className={inlineInputNumber}
          onChange={formik.handleChange}
          value={formik.values.jobPostingsLimit}
        />
      </div>
      <Typography variant="h4">Otherwise, job postings for company won't be shown in the final csv.</Typography>
    </OptionalSection>
  );
};
