import { DqsProfile, DqsProfileBase } from 'api/dqs-api';

export interface ProfileMessagePayload {
  id: number;
  msg: string;
}

export const LOAD_PROFILES_REQUEST = 'demand/dqs/LOAD_PROFILES_REQUEST';
export interface LoadProfilesRequestAction {
  type: string;
}

export const LOAD_PROFILES_SUCCESS = 'demand/dqs/LOAD_PROFILES_SUCCESS';
export interface LoadProfilesSuccessAction {
  type: string;
  payload: DqsProfile[];
}

export const LOAD_PROFILES_FAIL = 'demand/dqs/LOAD_PROFILES_FAIL';
export interface LoadProfilesFailAction {
  type: string;
  payload: string;
}

export const CREATE_PROFILE = 'demand/dqs/CREATE_PROFILE';
export interface CreateProfileAction {
  type: string;
  payload: DqsProfileBase;
}

export const CREATE_PROFILE_SUCCESS = 'demand/dqs/CREATE_PROFILE_SUCCESS';
export interface CreateProfileSuccessAction {
  type: string;
  payload: DqsProfile;
}

export const CREATE_PROFILE_FAIL = 'demand/dqs/CREATE_PROFILE_FAIL';
export interface CreateProfileFailAction {
  type: string;
  payload: string;
}

export const CREATE_PROFILE_CLEAR_ERROR = 'demand/dqs/CREATE_PROFILE_CLEAR_ERROR';
export interface CreateProfileClearErrorAction {
  type: string;
}

export const START_EDIT_PROFILE = 'demand/dqs/START_EDIT_PROFILE';
export interface StartEditProfileAction {
  type: string;
  payload: number;
}

export const STOP_EDIT_PROFILE = 'demand/dqs/STOP_EDIT_PROFILE';
export interface StopEditProfileAction {
  type: string;
  payload: number;
}

export const SAVE_PROFILE = 'demand/dqs/SAVE_PROFILE';
export interface SaveProfileAction {
  type: string;
  payload: DqsProfile;
}

export const SAVE_PROFILE_SUCCESS = 'demand/dqs/SAVE_PROFILE_SUCCESS';
export interface SaveProfileSuccessAction {
  type: string;
  payload: DqsProfile;
}

export const SAVE_PROFILE_FAIL = 'demand/dqs/SAVE_PROFILE_FAIL';
export interface SaveProfileFailAction {
  type: string;
  payload: ProfileMessagePayload;
}

export const MOVE_PROFILE_UP = 'demand/dqs/MOVE_PROFILE_UP';
export interface MoveProfileUpAction {
  type: string;
  payload: number;
}

export const MOVE_PROFILE_UP_SUCCESS = 'demand/dqs/MOVE_PROFILE_UP_SUCCESS';
export interface MoveProfileUpSuccessAction {
  type: string;
  payload: DqsProfile[];
}

export const MOVE_PROFILE_UP_FAIL = 'demand/dqs/MOVE_PROFILE_UP_FAIL';
export interface MoveProfileUpFailAction {
  type: string;
  payload: ProfileMessagePayload;
}

export const MOVE_PROFILE_DOWN = 'demand/dqs/MOVE_PROFILE_DOWN';
export interface MoveProfileDownAction {
  type: string;
  payload: number;
}

export const MOVE_PROFILE_DOWN_SUCCESS = 'demand/dqs/MOVE_PROFILE_DOWN_SUCCESS';
export interface MoveProfileDownSuccessAction {
  type: string;
  payload: DqsProfile[];
}

export const MOVE_PROFILE_DOWN_FAIL = 'demand/dqs/MOVE_PROFILE_DOWN_FAIL';
export interface MoveProfileDownFailAction {
  type: string;
  payload: ProfileMessagePayload;
}

export const DELETE_PROFILE = 'demand/dqs/DELETE_PROFILE';
export interface DeleteProfileAction {
  type: string;
  payload: number;
}

export const DELETE_PROFILE_SUCCESS = 'demand/dqs/DELETE_PROFILE_SUCCESS';
export interface DeleteProfileSuccessAction {
  type: string;
  payload: number;
}

export const DELETE_PROFILE_FAIL = 'demand/dqs/DELETE_PROFILE_FAIL';
export interface DeleteProfileFailAction {
  type: string;
  payload: ProfileMessagePayload;
}

export type DqsProfileActionsType =
  | LoadProfilesRequestAction
  | LoadProfilesSuccessAction
  | LoadProfilesFailAction
  | CreateProfileAction
  | CreateProfileSuccessAction
  | CreateProfileFailAction
  | CreateProfileClearErrorAction
  | SaveProfileAction
  | SaveProfileSuccessAction
  | SaveProfileFailAction
  | MoveProfileUpAction
  | MoveProfileUpSuccessAction
  | MoveProfileUpFailAction
  | MoveProfileDownAction
  | MoveProfileDownSuccessAction
  | MoveProfileDownFailAction
  | DeleteProfileAction
  | DeleteProfileSuccessAction
  | DeleteProfileFailAction;
