import { SupplyRequestRequestQuery, SupplyRequestSource, SupplyRequestsResponse } from '../models/supply-request';

export const FETCH_SUPPLY_REQUESTS_REQUEST = 'supply-requests/FETCH_SUPPLY_REQUESTS_REQUEST';
export interface FetchSupplyRequestsRequestAction {
  type: typeof FETCH_SUPPLY_REQUESTS_REQUEST;
  query?: SupplyRequestRequestQuery;
}

export const FETCH_SUPPLY_REQUESTS_SUCCESS = 'supply-requests/FETCH_SUPPLY_REQUESTS_SUCCESS';
export interface FetchSupplyRequestsSuccessAction {
  type: typeof FETCH_SUPPLY_REQUESTS_SUCCESS;
  data: SupplyRequestsResponse;
}

export const FETCH_SUPPLY_REQUESTS_FAIL = 'supply-requests/FETCH_SUPPLY_REQUESTS_FAIL';
export interface FetchSupplyRequestsFailAction {
  type: typeof FETCH_SUPPLY_REQUESTS_FAIL;
}

export const FORCE_CLOSE_SUPPLY_REQUESTS_REQUEST = 'supply-requests/FORCE_CLOSE_SUPPLY_REQUESTS_REQUEST';
export interface ForceCloseSupplyRequestsRequestAction {
  type: typeof FORCE_CLOSE_SUPPLY_REQUESTS_REQUEST;
  requestId: string;
  source: SupplyRequestSource;
}

export const FORCE_CLOSE_SUPPLY_REQUESTS_SUCCESS = 'supply-requests/FORCE_CLOSE_SUPPLY_REQUESTS_SUCCESS';
export interface ForceCloseSupplyRequestsSuccessAction {
  type: typeof FORCE_CLOSE_SUPPLY_REQUESTS_SUCCESS;
  requestId: string;
}

export const FORCE_CLOSE_SUPPLY_REQUESTS_FAIL = 'supply-requests/FORCE_CLOSE_SUPPLY_REQUESTS_FAIL';
export interface ForceCloseSupplyRequestsFailAction {
  type: typeof FORCE_CLOSE_SUPPLY_REQUESTS_FAIL;
  requestId: string;
}

export type SupplyRequestsAction =
  | FetchSupplyRequestsRequestAction
  | FetchSupplyRequestsSuccessAction
  | FetchSupplyRequestsFailAction
  | ForceCloseSupplyRequestsRequestAction
  | ForceCloseSupplyRequestsSuccessAction
  | ForceCloseSupplyRequestsFailAction;
