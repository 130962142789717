import { AxiosPromise } from 'axios';
import axios from '../config/axios-config';
import { SupplyRequestsResponse, SupplyRequestRequestQuery } from '../features/supply-requests/models/supply-request';

const getSupplyRequests = (params: SupplyRequestRequestQuery): AxiosPromise<SupplyRequestsResponse> => {
  return axios.supplyInstance({
    method: 'GET',
    url: 'v1/fetch-requests',
    params,
  });
};

const forceCloseSupplyRequest = (requestId: string): AxiosPromise<{ message: string }> => {
  return axios.supplyInstance({
    method: 'POST',
    url: `v1/fetch-requests/force-close/${requestId}`,
  });
};

export default {
  getSupplyRequests,
  forceCloseSupplyRequest,
};
