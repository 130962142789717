import builderOnboardingApi from 'api/builder-onboarding-api';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { WaitlistedRole, waitlistedRoles } from '../reducers/waitlisted-roles-reducer';

function* updateWaitlistedRolesRequest(
  action: ReturnType<typeof waitlistedRoles.actions.updateWaitlistedRolesRequest>
) {
  try {
    yield call(builderOnboardingApi.saveWaitlistedRoles, action.payload);
    yield put(waitlistedRoles.actions.updateWaitlistedRolesSuccess());
  } catch (err: any) {
    yield put(waitlistedRoles.actions.updateWaitlistedRolesFail(err.message));
    toast.error(`Error updating roles: ${err.message}`);
  }
}
function* getWaitlistedRolesRequest() {
  try {
    const res: { data: WaitlistedRole[] } = yield call(builderOnboardingApi.getWaitlistedRoles);

    yield put(waitlistedRoles.actions.getWaitlistedRolesSuccess(res.data.sort((a, b) => a.name.localeCompare(b.name))));
  } catch (err: any) {
    yield put(waitlistedRoles.actions.getWaitlistedRolesFail(err.message));
    toast.error(`Error fetching roles: ${err.message}`);
  }
}

export default function* saga() {
  yield all([
    takeLatest(waitlistedRoles.actions.updateWaitlistedRolesRequest.type, updateWaitlistedRolesRequest),
    takeLatest(waitlistedRoles.actions.getWaitlistedRolesRequest.type, getWaitlistedRolesRequest),
  ]);
}
