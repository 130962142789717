import { Button, Input, Typography } from '@a_team/ui-components';
import { useAppDispatch, useAppSelector } from 'core/store';
import PageLinkCopy from 'features/company-pages-generation/components/page-link-copy';
import { createTeamRequestPageAction } from 'features/team-request-pages/redux/actions';
import { useFormik } from 'formik';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  pageData: {
    padding: '12px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    maxWidth: '480px',
  },
  linkContainer: {
    display: 'flex',
  },
  link: {
    fontSize: '14px',
  },
  duplicateButton: {
    alignSelf: 'flex-end',
  },
  companyPagesTitle: {
    fontSize: '16px',
    marginBottom: '20px',
  },
  labelValue: {
    fontSize: '13px',
  },
  label: {
    fontWeight: 500,
  },
  loadPagesButton: {
    textTransform: 'uppercase',
  },
  generatePageButton: {
    width: '220px',
    marginTop: '12px',
  },
  createPageButton: {
    marginBottom: '20px',
  },
  linkedInScrapingNote: {
    fontStyle: 'italic',
    marginTop: '4px',
    fontSize: '14px',
  },
});

export const TeamRequestPagesForm: FC = () => {
  const dispatch = useAppDispatch();
  const styles = useStyles();

  const { loadingPages, creatingPage, createdPages, error, createdPageUrl } = useAppSelector(
    (state) => state.teamRequestPages
  );
  const formik = useFormik({
    initialValues: {
      url: '',
    },
    onSubmit: (values) => {
      dispatch(createTeamRequestPageAction({ url: values.url }));
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Input
          placeholder="Enter Company Url"
          name="url"
          onChange={formik.handleChange}
          size="fit"
          style={{ marginBottom: '16px' }}
        />
        <Button loading={loadingPages || creatingPage} className={styles.loadPagesButton}>
          SUBMIT
        </Button>
      </form>
      <br />
      {error && (
        <Typography variant="textMedium" color="Red@600">
          Error submitting request: {error}
        </Typography>
      )}
      {createdPageUrl && (
        <>
          <div>
            Created new page:{' '}
            <a href={createdPageUrl} target="_blank" rel="noopener noreferrer" className={styles.link}>
              {createdPageUrl}
            </a>
            <PageLinkCopy url={createdPageUrl} name={createdPages?.[0]?.name || ''} />
          </div>
          <p className={styles.linkedInScrapingNote}>
            In case roles for this company exist on LinkedIn, it will take a few minutes for these roles to appear on
            the page - meanwhile, you will see old roles.
          </p>
        </>
      )}
    </div>
  );
};
