import { Button, Input, Typography } from '@a_team/ui-components';
import { INTRO_SEARCH_NETWORK_LIST } from 'config/constants';
import { useAppDispatch, useAppSelector } from 'core/store';
import { getIntroSearchSettingsAction, saveIntroSearchSettingsAction } from 'features/intro-search/redux/actions';
import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    padding: '0 20px',
  },
  button: {
    width: '220px',
  },
});

export const IntroSearchSettings: FC = () => {
  const dispatch = useAppDispatch();

  const { loading, error, settings } = useAppSelector((state) => state.introSearch);

  const styles = useStyles();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      scsTreshold: settings?.scsTreshold || '',
      excludedCompanies: settings?.excludedCompanies?.join(', ') || '',
    },
    onSubmit: (values) => {
      dispatch(
        saveIntroSearchSettingsAction({
          scsTreshold: Number(values.scsTreshold),
          excludedCompanies: values.excludedCompanies
            .split(',')
            .map((s) => s.trim())
            .filter((s) => s),
        })
      );
    },
  });

  useEffect(() => {
    dispatch(getIntroSearchSettingsAction());
  }, []);

  return (
    <div className={styles.container}>
      <Typography variant="h1" style={{ marginBottom: 0 }}>
        Intro Search Engine Settings
      </Typography>
      <hr />
      <Typography variant="textLarge" component="p" style={{ margin: '20px 0' }}>
        A.Team network list can be found{' '}
        <a href={INTRO_SEARCH_NETWORK_LIST} target="_blank">
          here
        </a>
        .
      </Typography>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <Input
          label="SCS Treshold (Only connections with SCS above this value will be shown in search results)"
          name="scsTreshold"
          value={formik.values.scsTreshold}
          onChange={formik.handleChange}
          size="fit"
          type="number"
        />
        <br />
        <Input
          label="Excluded companies (comma separated list of company names/keywords)"
          name="excludedCompanies"
          value={formik.values.excludedCompanies}
          onChange={formik.handleChange}
          size="fit"
        />
        <br />
        <Button loading={loading} className={styles.button}>
          Save
        </Button>
      </form>
      {error && (
        <Typography variant="textMedium" color="Red@600">
          Error submitting request: {error}
        </Typography>
      )}
    </div>
  );
};
