import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LqsState {
  uploadSearchTermsInProgress: boolean;
  requestId: string;
}

const initialState: LqsState = {
  uploadSearchTermsInProgress: false,
  requestId: '',
};

export const sge = createSlice({
  name: 'sge',
  initialState,
  reducers: {
    uploadSearchTermsRequest: (state) => {
      state.uploadSearchTermsInProgress = true;
    },
    uploadSearchTermsSuccess: (state, action: PayloadAction<string>) => {
      state.uploadSearchTermsInProgress = false;
      state.requestId = action.payload;
    },
    uploadSearchTermsFail: (state) => {
      state.uploadSearchTermsInProgress = false;
    },
  },
});

export const { uploadSearchTermsRequest, uploadSearchTermsSuccess, uploadSearchTermsFail } = sge.actions;

export default sge.reducer;
