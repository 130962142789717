import { Typography } from '@a_team/ui-components';
import PageHeader from 'core/components/page-header';
import { TeamRequestPagesForm } from 'features/team-request-pages/components/team-request-pages-form';
import { FC } from 'react';
import { CompanyPagesForm } from './company-pages-form';

export const CompanyPagesGeneration: FC = () => {
  return (
    <>
      <PageHeader
        title="Landing Pages for Companies"
        description="This tool allows you to create two types of landing pages: Company Pages or Team Request pages. You simply need to input the company URL (and in the case of Company pages, LinkedIn URL of a person’s profile also works) and it will generate the pages using the companies' current open roles on LinkedIn."
      />
      <Typography variant="h3" margin="xs">
        Company pages
      </Typography>
      <Typography variant="textMedium" component="p" style={{ marginBottom: 32 }}>
        Example: https://create.a.team/for/lemonade-14d2b9874254
      </Typography>
      <CompanyPagesForm />
      <br />
      <Typography variant="h3" margin="xs">
        Team request pages
      </Typography>
      <Typography variant="textMedium" component="p" style={{ marginBottom: 32 }}>
        Example: https://request.a.team/for/discord-d7fb4ca89c47
      </Typography>
      <TeamRequestPagesForm />
    </>
  );
};
