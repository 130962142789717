import { Button, Input, Typography } from '@a_team/ui-components';
import { RootState } from 'core/store';
import { ChangeEvent, FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleUploadLinkedinConnectionsRequest } from '../actions/linkedin-connections-actions';

export const LinkedinConnections: FC = () => {
  const { loading } = useSelector((state: RootState) => state.linkedinConnections);
  const dispatch = useDispatch();
  const [primaryNodeLinkedinUrl, setPrimaryNodeLinkedinUrl] = useState('');
  const [searchTermsFile, setSearchTermsFile] = useState<File | undefined>();
  const handleFileChange = (e: ChangeEvent) => {
    const files = (e.target as HTMLInputElement)?.files;
    if (files !== null && files.length > 0) {
      setSearchTermsFile(files[0]);
    }
  };
  const handleOnSubmit = () => {
    dispatch(handleUploadLinkedinConnectionsRequest(primaryNodeLinkedinUrl, searchTermsFile));
  };

  return (
    <>
      <Typography variant="h1">Linkedin connections - manual upload</Typography>

      <Input
        type="text"
        label="Primary node linkedin url"
        value={primaryNodeLinkedinUrl}
        onChange={(e) => setPrimaryNodeLinkedinUrl(e.target.value)}
      />
      <Input
        type="file"
        accept=".csv"
        label="Secondary nodes linkedin urls file"
        onChange={handleFileChange}
        description="CSV requires linkedinUrl to be the first column"
      />
      <Button size="sm" margin="sm" loading={loading} onClick={handleOnSubmit}>
        Upload connections
      </Button>
    </>
  );
};
