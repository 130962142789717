import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupplyRequest } from '../models/supply-request';

export type SupplyRequestsState = {
  forceClose: {
    requested: string[];
    success: string[];
    failed: string[];
  };
  loading: boolean;
  items: SupplyRequest[];
  count: number;
  total: number;
  current: number;
};

const initialState = (): SupplyRequestsState => ({
  forceClose: {
    requested: [],
    success: [],
    failed: [],
  },
  loading: false,
  items: [],
  count: 0,
  total: 0,
  current: 1,
});

export const supplyRequests = createSlice({
  name: 'supplyRequests',
  initialState: initialState(),
  reducers: {
    fetchSupplyRequestsRequest(state) {
      state.loading = true;
    },
    fetchSupplyRequestsSuccess(
      state,
      action: PayloadAction<{ results: SupplyRequest[]; total: number; count: number; current: number }>
    ) {
      const { results, total, count, current } = action.payload;

      state.items = results.map((item) => {
        if (item.childrenRequests.length) {
          return {
            ...item,
            status: item.childrenRequests[0].status,
            resultsUrl: item.childrenRequests[0].resultsUrl,
          };
        }
        return item;
      });

      state.loading = false;
      state.count = count;
      state.total = total;
      state.current = current;
    },
    fetchSupplyRequestsFail(state) {
      state.loading = false;
    },
    forceCloseSupplyRequestsRequest(state, action: PayloadAction<string>) {
      const requestId = action.payload;

      state.forceClose.requested.push(requestId);
      state.forceClose.success = state.forceClose.success.filter((i) => i !== requestId);
      state.forceClose.failed = state.forceClose.failed.filter((i) => i !== requestId);
    },
    forceCloseSupplyRequestsSuccess(state, action: PayloadAction<string>) {
      const requestId = action.payload;

      state.forceClose.requested = state.forceClose.requested.filter((i) => i !== requestId);
      state.forceClose.success.push(requestId);
    },
    forceCloseSupplyRequestsFail(state, action: PayloadAction<string>) {
      const requestId = action.payload;

      state.forceClose.requested = state.forceClose.requested.filter((i) => i !== requestId);
      state.forceClose.failed.push(requestId);
    },
  },
});

export const {
  fetchSupplyRequestsRequest,
  fetchSupplyRequestsSuccess,
  fetchSupplyRequestsFail,
  forceCloseSupplyRequestsRequest,
  forceCloseSupplyRequestsSuccess,
  forceCloseSupplyRequestsFail,
} = supplyRequests.actions;

export default supplyRequests.reducer;
