import { AxiosResponse } from 'axios';
import { extractErrorMessage } from 'core/utils/extractErrorMessage';
import * as actionsTypes from '../actions/linkedin-connections-action-types';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import linkedinConnectionsApi from 'api/linkedin-connections-api';
import {
  handleUploadLinkedinConnectionsFail,
  handleUploadLinkedinConnectionsSuccess,
} from '../actions/linkedin-connections-actions';

function* uploadLinkedinConnections({
  primaryNodeLinkedinUrl,
  secondaryLinkedinUrlsFile,
}: actionsTypes.UploadLinkedinConnectionsRequestAction) {
  try {
    const res: AxiosResponse = yield call(
      linkedinConnectionsApi.uploadLinkedinConnections,
      primaryNodeLinkedinUrl,
      secondaryLinkedinUrlsFile
    );
    yield put(handleUploadLinkedinConnectionsSuccess(res.data.url));
    toast.success('Linkedin connections submitted successfuly');
  } catch (err: any) {
    const errorMessage = extractErrorMessage(err);
    yield put(handleUploadLinkedinConnectionsFail(errorMessage));
    toast.error(`Error submitting request: ${errorMessage}`);
  }
}

export default function* saga() {
  yield takeLatest(actionsTypes.UPLOAD_LINKEDIN_CONNECTIONS_REQUEST, uploadLinkedinConnections);
}
