import { Tab, Tabs, Typography } from '@a_team/ui-components';
import { DqsProfilesList } from 'features/dqs/components/dqs-profiles-list';
import { DqsSettingsForm } from 'features/dqs/components/dqs-settings-form';
import { DqsTestbedForm } from 'features/dqs/components/dqs-testbed-form';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  layout: {
    '&> hr': {
      margin: '24px 0',
    },
  },
});

export const DqsView: FC = () => {
  const { layout } = useStyles();

  return (
    <>
      <Typography variant="h1">DQS - Demand Qualification Score</Typography>
      <Tabs>
        <Tab id="first" label={'Score Profiles'}>
          <div className={layout}>
            <DqsProfilesList />
            <hr />
            <DqsTestbedForm />
          </div>
        </Tab>
        <Tab id="second" label={'Score Settings'}>
          <DqsSettingsForm />
        </Tab>
      </Tabs>
    </>
  );
};
