import dqsApi, { DqsProfile } from 'api/dqs-api';
import { AxiosResponse } from 'axios';
import * as actionsTypes from 'features/dqs/actions/profiles-action-types';
import { toast } from 'react-toastify';
import { CallEffect, PutEffect, call, put, takeLatest } from 'redux-saga/effects';
import { profile } from '../reducers/profile-reducers';

function* loadProfiles(): Generator<
  | CallEffect<AxiosResponse<DqsProfile[], any>>
  | PutEffect<actionsTypes.LoadProfilesSuccessAction>
  | PutEffect<actionsTypes.LoadProfilesFailAction>,
  void,
  AxiosResponse<DqsProfile[], any>
> {
  try {
    const res = yield call(dqsApi.getProfiles);
    yield put(profile.actions.loadProfilesSuccess(res.data));
  } catch (err: any) {
    // validation errors will have more info on err.response.data.errors
    const message = err.response?.data?.errors ? JSON.stringify(err.response?.data?.errors) : err.message;
    yield put(profile.actions.loadProfilesFail(message));
  }
}

function* createProfile({
  payload,
}: actionsTypes.CreateProfileAction): Generator<
  | CallEffect<AxiosResponse<DqsProfile, any>>
  | PutEffect<actionsTypes.CreateProfileSuccessAction>
  | PutEffect<actionsTypes.CreateProfileFailAction>,
  void,
  AxiosResponse<DqsProfile, any>
> {
  try {
    const res = yield call(dqsApi.createProfile, payload);
    toast.success(`Created client score profile ${res.data.id}`);
    yield put(profile.actions.createProfileSuccess(res.data));
  } catch (err: any) {
    // validation errors will have more info on err.response.data.errors
    const message = err.response?.data?.errors ? JSON.stringify(err.response?.data?.errors) : err.message;
    yield put(profile.actions.createProfileFail(message));
  }
}

function* saveProfile({
  payload,
}: actionsTypes.SaveProfileAction): Generator<
  | CallEffect<AxiosResponse<DqsProfile, any>>
  | PutEffect<actionsTypes.SaveProfileSuccessAction>
  | PutEffect<actionsTypes.SaveProfileFailAction>,
  void,
  AxiosResponse<DqsProfile, any>
> {
  try {
    const res = yield call(dqsApi.saveProfile, payload);
    toast.success(`Saved client score profile ${payload.id}`);
    yield put(profile.actions.saveProfileSuccess(res.data));
  } catch (err: any) {
    // validation errors will have more info on err.response.data.errors
    const message = err.response?.data?.errors ? JSON.stringify(err.response?.data?.errors) : err.message;
    yield put(
      profile.actions.saveProfileFail({
        msg: message,
        id: payload.id,
      })
    );
  }
}

function* moveProfileUp({
  payload,
}: actionsTypes.MoveProfileUpAction): Generator<
  | CallEffect<AxiosResponse<DqsProfile[], any>>
  | PutEffect<actionsTypes.MoveProfileUpSuccessAction>
  | PutEffect<actionsTypes.MoveProfileUpFailAction>,
  void,
  AxiosResponse<DqsProfile[], any>
> {
  try {
    const res = yield call(dqsApi.moveProfileUp, payload);
    toast.success(`Moved profile ${payload} up`);
    yield put(profile.actions.moveProfileUpSuccess(res.data));
  } catch (err: any) {
    // validation errors will have more info on err.response.data.errors
    const message = err.response?.data?.errors ? JSON.stringify(err.response?.data?.errors) : err.message;
    yield put(profile.actions.moveProfileUpFail({ id: payload, msg: message }));
  }
}

function* moveProfileDown({
  payload,
}: actionsTypes.MoveProfileDownAction): Generator<
  | CallEffect<AxiosResponse<DqsProfile[], any>>
  | PutEffect<actionsTypes.MoveProfileDownSuccessAction>
  | PutEffect<actionsTypes.MoveProfileDownFailAction>,
  void,
  AxiosResponse<DqsProfile[], any>
> {
  try {
    const res = yield call(dqsApi.moveProfileDown, payload);
    toast.success(`Moved profile ${payload} down`);
    yield put(profile.actions.moveProfileDownSuccess(res.data));
  } catch (err: any) {
    // validation errors will have more info on err.response.data.errors
    const message = err.response?.data?.errors ? JSON.stringify(err.response?.data?.errors) : err.message;
    yield put(profile.actions.moveProfileDownFail({ id: payload, msg: message }));
  }
}

function* deleteProfile({
  payload,
}: actionsTypes.DeleteProfileAction): Generator<
  | CallEffect<AxiosResponse<boolean, any>>
  | PutEffect<actionsTypes.DeleteProfileSuccessAction>
  | PutEffect<actionsTypes.DeleteProfileFailAction>,
  void,
  AxiosResponse<boolean, any>
> {
  try {
    yield call(dqsApi.deleteProfile, payload);
    toast.success(`Deleted profile ${payload}`);
    yield put(profile.actions.deleteProfileSuccess(payload));
  } catch (err: any) {
    // validation errors will have more info on err.response.data.errors
    const message = err.response?.data?.errors ? JSON.stringify(err.response?.data?.errors) : err.message;
    yield put(profile.actions.deleteProfileFail({ id: payload, msg: message }));
  }
}

export default function* saga() {
  yield takeLatest(actionsTypes.LOAD_PROFILES_REQUEST, loadProfiles);
  yield takeLatest(actionsTypes.CREATE_PROFILE, createProfile);
  yield takeLatest(actionsTypes.SAVE_PROFILE, saveProfile);
  yield takeLatest(actionsTypes.MOVE_PROFILE_UP, moveProfileUp);
  yield takeLatest(actionsTypes.MOVE_PROFILE_DOWN, moveProfileDown);
  yield takeLatest(actionsTypes.DELETE_PROFILE, deleteProfile);
}
