import { Typography } from '@a_team/ui-components';
import { FC } from 'react';

const ErrorMessageDisplay: FC<{ msg?: string }> = ({ msg }) =>
  msg ? (
    <Typography variant="textMedium" color="Red@700">
      Error: {msg}
    </Typography>
  ) : null;
export default ErrorMessageDisplay;
