import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DqsResult } from 'api/dqs-api';

export type DqsTestbedState = {
  loading: boolean;
  errorMsg: string;
  result?: DqsResult;
};

export const initialState = (): DqsTestbedState => {
  return {
    loading: false,
    errorMsg: '',
  };
};

export const testbed = createSlice({
  name: 'testbed',
  initialState,
  reducers: {
    runTestbedRequest: (state) => {
      state.loading = true;
      state.errorMsg = '';
      state.result = undefined;
    },
    runTestbedSuccess: (state, action: PayloadAction<DqsResult>) => {
      state.loading = false;
      state.errorMsg = '';
      state.result = action.payload;
    },
    runTestbedFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMsg = action.payload;
    },
  },
});

export const { runTestbedRequest, runTestbedSuccess, runTestbedFail } = testbed.actions;

export default testbed.reducer;
