import * as actionsTypes from 'features/cold-ideas/actions/cold-ideas-action-types';
import { CreateColdIdeasRequestModel } from 'features/cold-ideas/cold-ideas-request-model';

export const handleCreateColdIdeasRequestRequest = (
  payload: CreateColdIdeasRequestModel
): actionsTypes.CreateColdIdeasRequestAction => ({
  type: actionsTypes.CREATE_COLD_IDEAS_REQUEST,
  payload,
});

export const handleCreateColdIdeasRequestSuccess = (): actionsTypes.CreateColdIdeasRequestSuccessAction => ({
  type: actionsTypes.CREATE_COLD_IDEAS_REQUEST_SUCCESS,
});

export const handleCreateColdIdeasRequestFail = (payload: string): actionsTypes.CreateColdIdeasRequestFailAction => ({
  type: actionsTypes.CREATE_COLD_IDEAS_REQUEST_FAIL,
  payload,
});
