import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TargetedLeadsState = {
  uploadTaggedProfilesInProgress: boolean;
  requestTargetedLeadsInProgress: boolean;
  requestError?: string;
};

const initialState: TargetedLeadsState = {
  uploadTaggedProfilesInProgress: false,
  requestTargetedLeadsInProgress: false,
  requestError: undefined,
};

export const targetedLeads = createSlice({
  name: 'targetedLeads',
  initialState,
  reducers: {
    uploadTaggedProfilesRequest: (state) => {
      state.requestError = undefined;
      state.uploadTaggedProfilesInProgress = true;
    },
    uploadTaggedProfilesSuccess: (state) => {
      state.requestError = undefined;
      state.uploadTaggedProfilesInProgress = false;
    },
    uploadTaggedProfilesFail: (state, action: PayloadAction<string>) => {
      state.uploadTaggedProfilesInProgress = false;
      state.requestError = action.payload;
    },
    requestTargetedLeadsRequest: (state) => {
      state.requestError = undefined;
      state.requestTargetedLeadsInProgress = true;
    },
    requestTargetedLeadsSuccess: (state) => {
      state.requestError = undefined;
      state.requestTargetedLeadsInProgress = false;
    },
    requestTargetedLeadsFail: (state, action: PayloadAction<string>) => {
      state.requestTargetedLeadsInProgress = false;
      state.requestError = action.payload;
    },
  },
});

export const {
  uploadTaggedProfilesRequest,
  uploadTaggedProfilesSuccess,
  uploadTaggedProfilesFail,
  requestTargetedLeadsRequest,
  requestTargetedLeadsSuccess,
  requestTargetedLeadsFail,
} = targetedLeads.actions;

export default targetedLeads.reducer;
