import axios from 'config/axios-config';
import { getFormData } from './helpers';

const warmLeadsUrlPrefix = 'v1/warm-leads';

const createWarmLeads = async (payload: any) => {
  return axios.demandLeadsInstance({
    method: 'POST',
    url: `${warmLeadsUrlPrefix}/create`,
    data: getFormData({ ...payload, params: JSON.stringify(payload.params) }),
  });
};

export enum ConnectionsParamsType {
  WarmLeadsParams = 'WARM_LEADS_PARAMS',
  ExclusionList = 'EXCLUSION_LIST',
  CompanyPageConfig = 'COMPANY_PAGE_CONFIG',
}

const saveParams = async (payload: any) => {
  return axios.demandLeadsInstance({
    method: 'POST',
    url: 'v1/params',
    data: payload,
  });
};

const getParams = async (payload: { type: ConnectionsParamsType }) => {
  return axios.demandLeadsInstance({
    method: 'GET',
    url: `v1/params/${payload.type}`,
  });
};

const createLinkedInJobsRequest = async (payload: { companyName: string; companyUrl: string }) => {
  return axios.demandLeadsInstance({
    method: 'POST',
    url: 'v1/job-postings/requests',
    params: payload,
  });
};

const createLinkedInJobsCsvRequest = async (payload: { csv?: File }) => {
  return axios.demandLeadsInstance({
    method: 'POST',
    url: 'v1/job-postings/csv',
    data: getFormData(payload),
  });
};

const filterWarmLeadsFile = (payload: any) => {
  return axios.demandLeadsInstance({
    method: 'POST',
    url: `${warmLeadsUrlPrefix}/company-page-filter-file`,
    data: getFormData(payload),
  });
};

const mixWarmLeadsFile = (payload: any) => {
  return axios.demandLeadsInstance({
    method: 'POST',
    url: `${warmLeadsUrlPrefix}/mix-files`,
    data: getFormData(payload),
  });
};

const createColdIdeasRequest = (payload: any) => {
  return axios.demandLeadsInstance({
    method: 'POST',
    url: '/v1/cold-ideas',
    data: getFormData(payload),
  });
};

export default {
  createWarmLeads,
  saveParams,
  getParams,
  createLinkedInJobsRequest,
  createLinkedInJobsCsvRequest,
  filterWarmLeadsFile,
  mixWarmLeadsFile,
  createColdIdeasRequest,
};
