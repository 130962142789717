import LqsApi from 'api/lqs-api';
import * as actions from 'features/lqs/actions/lqs-action-types';
import {
  handleGetQueryResultsRequest,
  handleRunQueryFail,
  handleRunQuerySuccess,
} from 'features/lqs/actions/lqs-actions';
import { toast } from 'react-toastify';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { lqs } from '../reducers/lqs-reducers';

function* requestUploadNamesCsv({ selectedProfile, file, metadata }: actions.UploadNamesCsvRequestAction) {
  try {
    const res = yield call(LqsApi.postNamesFile, selectedProfile, file, metadata);
    yield put(lqs.actions.uploadNameCsvSuccess(res.data.requestId));
    toast.success(`Request ${res.data.requestId} submitted succesfully`);
  } catch (err: any) {
    toast.error(`Error submitting request: ${err.message}`);
    yield put(lqs.actions.uploadNameCsvFail());
  }
}

function* requestUploadLinkedinUrlsFile({
  selectedProfile,
  file,
  metadata,
}: actions.UploadLinkedinUrlsFileRequestAction) {
  try {
    const res = yield call(LqsApi.postLinkedInUrlsFile, selectedProfile, file, metadata);
    yield put(lqs.actions.uploadLinkedinUrlsFileSuccess(res.data.requestId));
    toast.success(`Request ${res.data.requestId} submitted succesfully`);
  } catch (err: any) {
    toast.error(`Error submitting request: ${err.message}`);
    yield put(lqs.actions.uploadLinkedinUrlsFileFail());
  }
}

function* requestRunQuerySearch({ query, selectedProfile, metadata }: actions.RunQueryRequestAction) {
  try {
    const res = yield call(LqsApi.postQuery, query, selectedProfile, metadata);
    yield put(handleRunQuerySuccess(res.data.requestId));
    yield put(handleGetQueryResultsRequest(res.data.requestId));
    toast.success(`Request ${res.data.requestId} submitted succesfully`);
  } catch (err: any) {
    toast.error(`Error submitting request: ${err.message}`);
    yield put(handleRunQueryFail());
  }
}

function* requestGetQueryResults({ requestId }: actions.GetQueryResultsRequestAction) {
  try {
    const res = yield call(LqsApi.getQuery, requestId);
    if (res.data.success) {
      yield put(lqs.actions.getQueryResultsSuccess(res.data));
    } else {
      yield delay(1000);
      yield put(lqs.actions.getQueryResultsSuccess(requestId));
    }
  } catch (err: any) {
    toast.error(`Error fetching results: ${err.message}`);
    yield put(lqs.actions.getQueryResultsSuccess(''));
  }
}

export default function* saga() {
  yield takeLatest(actions.GET_QUERY_RESULTS_REQUEST, requestGetQueryResults);
  yield takeLatest(actions.RUN_QUERY_REQUEST, requestRunQuerySearch);
  yield takeLatest(actions.UPLOAD_NAME_CSV_REQUEST, requestUploadNamesCsv);
  yield takeLatest(actions.UPLOAD_LINKEDIN_URLS_FILE_REQUEST, requestUploadLinkedinUrlsFile);
}
