import { HomePageLocation, MonitoringLocation, SettingsLocation } from 'core/routing/locations';
import { checkIfUserIsAdmin } from 'core/utils/auth';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import homeIcon from './assets/homeIcon.svg';
import monitoringIcon from './assets/monitoringIcon.svg';
import settingsIcon from './assets/settingsIcon.svg';

const useStyles = createUseStyles({
  container: {
    padding: '0 20px',
    display: 'flex',
    height: 56,
    '& a': {
      padding: '8px 12px',
      display: 'flex',
      gap: 8,
      alignItems: 'center',
      textDecoration: 'none',
      color: 'inherit',
      cursor: 'pointer',
    },
  },
});

const Navigation = () => {
  const styles = useStyles();
  const isAdmin = checkIfUserIsAdmin();

  return (
    <div className={styles.container}>
      <Link to={HomePageLocation}>
        <img src={homeIcon} alt="Home" />
        Home
      </Link>
      {isAdmin && (
        <Link to={SettingsLocation}>
          <img src={settingsIcon} alt="Settings" />
          Settings
        </Link>
      )}
      {isAdmin && (
        <Link to={MonitoringLocation}>
          <img src={monitoringIcon} alt="Monitoring" />
          Monitoring
        </Link>
      )}
    </div>
  );
};

export default Navigation;
