import axios from 'config/axios-config';

const createTeamRequestPage = async (payload: any) => {
  return axios.demandLeadsInstance({
    method: 'POST',
    url: '/v1/team-request-pages',
    data: payload,
  });
};

export default {
  createTeamRequestPage,
};
