import { Button, Input, Spinner, Table, Typography, theme } from '@a_team/ui-components';
import { IntroSearchFAQLocation } from 'core/routing/locations';
import { useAppDispatch, useAppSelector } from 'core/store';
import { IntroFrom } from 'features/intro-search/intro-search-request-model';
import { introSearchAction } from 'features/intro-search/redux/actions';
import { introSearchActions } from 'features/intro-search/redux/slice';
import { useFormik } from 'formik';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import ReactPaginate from 'react-paginate';
import { Link, useHistory, useLocation } from 'react-router-dom';
import IntroSearchHistory from './intro-search-history';

const useStyles = createUseStyles({
  container: {
    padding: '0 20px 32px',
  },
  introFormContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  showingResultsText: {
    fontSize: 12,
    fontWeight: 700,
  },
  haveQuestionsText: {
    display: 'block',
    fontSize: 12,
  },
  faqLink: {
    fontWeight: 700,
    color: '#000',
  },
  pageData: {
    padding: '12px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    maxWidth: '480px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
    '& label': {
      textTransform: 'none',
      fontSize: '14px',
    },
  },
  inputButtonContainer: {
    display: 'flex',
    gap: 8,
    width: '100%',
    marginTop: 8,
  },
  button: {
    height: '40px',
    whiteSpace: 'nowrap',
    borderRadius: 4,
    '& span': {
      fontWeight: 400,
    },
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.3)',
    zIndex: 100,
  },
  tableData: {
    fontSize: '12px',
    width: '100%',
  },
  table: {
    width: '100%',
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    listStyleType: 'none',
    padding: '0 5rem',
    '& li': {
      margin: '0 2px',
    },
    '& li a': {
      borderRadius: '7px',
      padding: '0.1rem 1rem',
      border: 'gray 1px solid',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    },
    '& li.previous a, & li.next a, & li.break a': {
      borderColor: 'transparent',
    },
    '& li.selected a': {
      backgroundColor: '#0366d6',
      borderColor: 'transparent',
      color: 'white',
      minWidth: '32px',
    },
  },
});

const useConfidenceLevelStyles = createUseStyles({
  confidenceLevel: {
    padding: '2px 4px',
    background: theme.colors.Green[500],
    display: 'inline-block',
    borderRadius: '4px',
    marginLeft: '4px',
    lineHeight: '14px',
  },
});

const ConfidenceLevel = ({ scs }: { scs: number }) => {
  let level = 0;
  let color = '';
  if (scs >= 4) {
    level = 90;
    color = theme.colors.Green[500];
  } else if (scs >= 3) {
    level = 70;
    color = theme.colors.Baracus[400];
  } else if (scs >= 2) {
    level = 50;
    color = theme.colors.Baracus[700];
  }

  const styles = useConfidenceLevelStyles();

  return (
    <span style={{ background: color }} className={styles.confidenceLevel}>
      {level}% confidence
    </span>
  );
};

const useIntroFromStyles = createUseStyles({
  intro: {
    lineHeight: '20px',
  },
});

const IntroFromData = ({ introFrom, fullName }: { introFrom: IntroFrom; fullName: string }) => {
  const styles = useIntroFromStyles();

  const introName = introFrom.email ? <a href={`mailto:${introFrom.email}`}>{introFrom.name}</a> : introFrom.name;
  const commonExperience = introFrom.commonExperience;
  const introCompany = commonExperience?.companyLinkedInUrl ? (
    <a href={commonExperience?.companyLinkedInUrl} target="_blank" rel="noopener noreferrer">
      {commonExperience?.companyName}
    </a>
  ) : (
    commonExperience?.companyName
  );

  return (
    <span className={styles.intro}>
      Intro from {introName}
      {introFrom?.companyName ? ` - ${introFrom?.companyName}` : ''}
      {introFrom?.type ? ` (${introFrom?.type})` : ''}
      {commonExperience?.companyName ? (
        <>
          {' '}
          who worked with {fullName} at {introCompany}
        </>
      ) : (
        ''
      )}
      <ConfidenceLevel scs={introFrom?.scs} />
    </span>
  );
};

export const IntroSearchResults: FC = () => {
  const [page, setPage] = useState(0);

  const dispatch = useAppDispatch();
  const { pageData, loading, error } = useAppSelector((state) => state.introSearch);

  const styles = useStyles();

  const loadData = (values: { companyUrl: string }) => {
    dispatch(
      introSearchAction({
        ...values,
        pageNumber: page,
        pageSize: 10,
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      companyUrl: '',
    },
    onSubmit: (values) => {
      dispatch(introSearchActions.addSearchHistoryEntry({ query: values.companyUrl }));
      if (page === 0) {
        loadData(values);
      } else {
        setPage(0);
      }
    },
  });

  useEffect(() => {
    if (formik.values.companyUrl) {
      loadData(formik.values);
    }
  }, [page]);

  const history = useHistory();
  const location = useLocation();

  const [urlLoaded, setUrlLoaded] = useState(false);
  useEffect(() => {
    if (urlLoaded) {
      history.replace({
        pathname: location.pathname,
        search: `?${new URLSearchParams({ query: formik.values.companyUrl }).toString()}`,
      });
    }
  }, [formik.values.companyUrl]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const query = urlParams.get('query');
    if (query && query !== formik.values.companyUrl) {
      formik.setValues({ companyUrl: query || '' });

      if (page === 0) {
        loadData({ companyUrl: query || '' });
      } else {
        setPage(0);
      }
    }
    setUrlLoaded(true);
  }, [location.search]);

  return (
    <div className={styles.container}>
      <div className={styles.introFormContainer}>
        <div className={styles.leftContainer}>
          <Typography variant="textLarge" className={styles.showingResultsText}>
            Showing results for {pageData?.companyName}
          </Typography>
          <Typography variant="textMedium" className={styles.haveQuestionsText}>
            Have questions about the results? Check out our{' '}
            <Link to={IntroSearchFAQLocation} className={styles.faqLink}>
              FAQ
            </Link>
            .
          </Typography>
        </div>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <Typography variant="textMedium" className={styles.haveQuestionsText}>
            New query? Enter your Target Account's URL:
          </Typography>
          <div className={styles.inputButtonContainer}>
            <Input name="companyUrl" onChange={formik.handleChange} size="stretch" value={formik.values.companyUrl} />
            <Button loading={loading} className={styles.button}>
              FIND PATH
            </Button>
          </div>
          {error && (
            <Typography variant="textMedium" color="Red@600">
              Error submitting request: {error}
            </Typography>
          )}
        </form>
      </div>
      {loading && (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}
      {pageData && pageData.items.length > 0 && (
        <div className={styles.tableData}>
          <Table
            columns={[
              { accessor: 'type', Header: 'Type' },
              { accessor: 'fullName', Header: 'Full name' },
              { accessor: 'position', Header: `Position at ${pageData?.companyName}` },
              { accessor: 'fromDate', Header: 'From' },
              { accessor: 'toDate', Header: 'To' },
              { accessor: 'nowAt', Header: 'Now at' },
            ]}
            data={pageData.items.map((item) => ({
              type:
                item?.type ||
                (item.introFrom ? <IntroFromData fullName={item.fullName} introFrom={item.introFrom} /> : ''),
              fullName: (
                <a href={item.primaryLinkedInUrl} target="_blank" rel="noopener noreferrer">
                  {item.fullName}
                </a>
              ),
              position: item?.positionName,
              fromDate: moment(item?.startedOn).format('MMM YYYY'),
              toDate: item?.endedOn ? moment(item?.endedOn).format('MMM YYYY') : 'Present',
              nowAt: item?.endedOn ? item?.currentCompany?.name : '',
            }))}
            className={styles.table}
          />
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={({ selected }) => setPage(selected)}
            pageRangeDisplayed={5}
            pageCount={pageData?.totalPages}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            className={styles.pagination}
          />
        </div>
      )}
      {!loading && pageData && pageData.items.length === 0 && (
        <Typography variant="textMedium">There are no matches for this company</Typography>
      )}
      <IntroSearchHistory />
    </div>
  );
};
