import { Button, Input, Typography } from '@a_team/ui-components';
import { RootState } from 'core/store';
import { handleCreateWarmLeadsRequest, handleGetParamsRequest } from 'features/warm-leads/actions/warm-leads-actions';
import { MUTUAL_AND_PARTNER_VCS_DEFAULT_VALUES, WARM_LEAD_DEFAULT_PARAMS } from 'features/warm-leads/constants';
import { EmployeeData, WarmLeadsParamsModel } from 'features/warm-leads/warm-leads-request-model';
import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { WarmLeadsEmployeesForm } from './warm-leads-employees-form';
import { WarmLeadsJobPostingsForm } from './warm-leads-job-postings-form';
import { WarmLeadsRolesForm } from './warm-leads-roles-form';
import { WarmLeadsSaveParams } from './warm-leads-save-params';
import { WarmLeadsVCsForm } from './warm-leads-vcs-form';

interface FormikValues {
  csv: File | undefined;
  excludedCompanies: string;
  excludedEntities: string;
  totalRaisedLowerThreshold1Millions: number;
  totalRaisedUpperThreshold1Millions: number;
  employeeCountLowerThreshold1: number;
  employeeCountUpperThreshold1: number;
  totalRaisedLowerThreshold2Millions: number;
  totalRaisedUpperThreshold2Millions: number;
  employeeCountLowerThreshold2: number;
  employeeCountUpperThreshold2: number;
  positionIncludesRange1: string;
  positionExcludesRange1: string;
  positionIncludesRange2: string;
  positionExcludesRange2: string;
  vcs: {
    mutualVC: string;
    mutualVCPartner: string;
    partnershipVC: string;
    partnershipVCPartner: string;
  }[];
  employeesData: EmployeeData[];
  jobPostingsMaxEmployeeCount: number;
  jobPostingsIncludeRange1: string;
  jobPostingsExcludeRange1: string;
  jobPostingsIncludeRange2: string;
  jobPostingsExcludeRange2: string;
  jobPostingsLimit: number;
  shouldGenerateCP: boolean;
  shouldAddConnections: boolean;
}

const updateVCString = (filter: string, vc: string, partner: string): string => {
  if (vc.length > 0) {
    const partnerStr = typeof partner === 'string' ? partner : '';
    return `${filter}${vc},${partnerStr};`;
  }
  return `${filter},;`;
};

const getUpdatedVCValues = (mutualVCFilters: string, partnershipVCFilters: string) => {
  const mutualVCAndPartners = mutualVCFilters.split(';');
  const partnershipVCAndPartners = partnershipVCFilters.split(';');
  let updatedVCValues = [];
  for (let i = 0; i < MUTUAL_AND_PARTNER_VCS_DEFAULT_VALUES.length; i++) {
    try {
      const [mutualVC, mutualVCPartner] = i < mutualVCAndPartners.length ? mutualVCAndPartners[i].split(',') : ['', ''];
      const [partnershipVC, partnershipVCPartner] =
        i < partnershipVCAndPartners.length ? partnershipVCAndPartners[i].split(',') : ['', ''];
      updatedVCValues.push({
        mutualVC,
        mutualVCPartner,
        partnershipVC,
        partnershipVCPartner,
      });
    } catch (e) {
      continue;
    }
  }
  return updatedVCValues;
};

export const convertFormikValuesToParams = (values: FormikValues): WarmLeadsParamsModel => {
  let mutualVCs = '';
  let partnershipVCs = '';
  for (const vc of values.vcs) {
    mutualVCs = updateVCString(mutualVCs, vc.mutualVC, vc.mutualVCPartner);
    partnershipVCs = updateVCString(partnershipVCs, vc.partnershipVC, vc.partnershipVCPartner);
  }
  // Remove trailing semicolon
  mutualVCs = mutualVCs.length > 0 ? mutualVCs.slice(0, -1) : '';
  partnershipVCs = partnershipVCs.length > 0 ? partnershipVCs.slice(0, -1) : '';
  return {
    ...values,
    excludedCompanies: values.excludedCompanies,
    excludedEntities: values.excludedEntities,
    totalRaisedLowerThreshold1Millions: values.totalRaisedLowerThreshold1Millions,
    totalRaisedUpperThreshold1Millions: values.totalRaisedUpperThreshold1Millions,
    employeeCountLowerThreshold1: values.employeeCountLowerThreshold1,
    employeeCountUpperThreshold1: values.employeeCountUpperThreshold1,
    totalRaisedLowerThreshold2Millions: values.totalRaisedLowerThreshold2Millions,
    totalRaisedUpperThreshold2Millions: values.totalRaisedUpperThreshold2Millions,
    employeeCountLowerThreshold2: values.employeeCountLowerThreshold2,
    employeeCountUpperThreshold2: values.employeeCountUpperThreshold2,
    positionIncludesRange1: values.positionIncludesRange1,
    positionExcludesRange1: values.positionExcludesRange1,
    positionIncludesRange2: values.positionIncludesRange2,
    positionExcludesRange2: values.positionExcludesRange2,
    mutualVCFilters: mutualVCs,
    partnershipVCFilters: partnershipVCs,
    employeesData: values.employeesData,
  };
};

const useStyles = createUseStyles({
  csvDescription: {
    display: 'block',
    marginTop: '8px',
  },
  inputLabel: {
    display: 'flex',
    fontSize: '14px',
    marginTop: '16px',
    gap: '6px',
  },
  horizontalDivider: {
    maxWidth: '1200px',
    marginLeft: '0px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  buttonsContainer: {
    display: 'flex',
    marginTop: '-48px',
    marginLeft: '150px',
    '& button': {
      marginRight: '32px',
    },
  },
});

export const WarmLeadsForm: FC<{ loading: boolean }> = ({ loading }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { params, warmLeadsParamsLoaded } = useSelector((state: RootState) => state.warmLeads);

  const formik = useFormik({
    initialValues: {
      ...WARM_LEAD_DEFAULT_PARAMS,
      vcs: MUTUAL_AND_PARTNER_VCS_DEFAULT_VALUES,
      csv: undefined,
      shouldGenerateCP: true,
      shouldAddConnections: true,
    },
    onSubmit: (values: FormikValues) => {
      const params = convertFormikValuesToParams(formik.values);
      dispatch(
        handleCreateWarmLeadsRequest({
          csv: values.csv,
          shouldGenerateCP: values.shouldGenerateCP,
          shouldAddConnections: values.shouldAddConnections,
          params,
        })
      );
    },
  });

  useEffect(() => {
    dispatch(handleGetParamsRequest());
  }, []);

  useEffect(() => {
    if (warmLeadsParamsLoaded) {
      const updatedVCValues = getUpdatedVCValues(params.mutualVCFilters, params.partnershipVCFilters);
      formik.setValues({
        ...WARM_LEAD_DEFAULT_PARAMS,
        ...params,
        vcs: updatedVCValues,
        csv: undefined,
        shouldGenerateCP: true,
        shouldAddConnections: true,
      });
    }
  }, [warmLeadsParamsLoaded]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h2" weight="bold">
          Connections File Upload
        </Typography>
        <Input
          type="file"
          accept=".csv"
          name="csv"
          onChange={(e: any) => formik.setFieldValue('csv', e.target.files[0])}
        />
        <Typography variant="textSmall" className={styles.csvDescription}>
          CSV file must have at least one column called "full_name", "Name", "fullName" or "Full Name", and optionally
          can contain column "linkedin".
        </Typography>
        <label className={styles.inputLabel}>
          <Input
            type="checkbox"
            name="shouldGenerateCP"
            onChange={(e: any) => formik.setFieldValue('shouldGenerateCP', e.target.checked)}
            checked={formik.values.shouldGenerateCP}
          />
          <span>Include company page link in result</span>
        </label>
        <label className={styles.inputLabel}>
          <Input
            type="checkbox"
            name="shouldAddConnections"
            onChange={(e: any) => formik.setFieldValue('shouldAddConnections', e.target.checked)}
            checked={formik.values.shouldAddConnections}
          />
          <span>Mention closes past co-workers in the result</span>
        </label>
        <WarmLeadsRolesForm formik={formik} />

        <WarmLeadsVCsForm formik={formik} />

        <WarmLeadsEmployeesForm formik={formik} />

        <WarmLeadsJobPostingsForm formik={formik} />

        <Button loading={loading}>SUBMIT</Button>
      </form>
      <div className={styles.buttonsContainer}>
        <WarmLeadsSaveParams loading={loading} formik={formik} />
      </div>
    </>
  );
};
