import { Login } from 'features/auth/components/login';
import { ColdIdeas } from 'features/cold-ideas/components/cold-ideas';
import { CompanyPagesGeneration } from 'features/company-pages-generation/components/company-pages';
import { CompanyPages } from 'features/company-pages/components/company-pages';
import { Dashboard } from 'features/dashboard/components/dashboard';
import HomePage from 'features/dashboard/components/homepage';
import TabsLayout from 'features/dashboard/components/tabs-layout';
import { DqsView } from 'features/dqs/components/dqs-view';
import { EnrichCompanies } from 'features/enrich-companies/components/enrich-companies';
import { FacebookFriendsForm } from 'features/facebook-friends/components/facebook-friends-form';
import { IntroSearchFAQ } from 'features/intro-search/components/intro-search-faq';
import { IntroSearchIndex } from 'features/intro-search/components/intro-search-index';
import { IntroSearchMonitoring } from 'features/intro-search/components/intro-search-monitoring';
import { IntroSearchResults } from 'features/intro-search/components/intro-search-results';
import { IntroSearchSettings } from 'features/intro-search/components/intro-search-settings';
import { LinkedinConnections } from 'features/linkedin-connections/components/linkedin-connections';
import { LinkedInJobPostings } from 'features/linkedin-job-postings/components/linkedin-job-postings';
import LqsSettingsAndProfiles from 'features/lqs/components/lqs-settings-profiles';
import { LqsScore } from 'features/lqs/components/score';
import { Scrubbing } from 'features/scrubbing/components/scrubbing';
import { ScoringProfileSettings } from 'features/scs/components/scoring-profile-settings';
import { Scs } from 'features/scs/components/scs';
import { ScsScoringRequest } from 'features/scs/components/scs-scoring-request';
import SgeOutbound from 'features/sge-outbound/components/sge-outbound';
import Sge from 'features/sge/components/sge';
import { SupplyRequests } from 'features/supply-requests/components/supply-requests';
import { TargetedLeads } from 'features/targeted-leads/components/targeted-leads';
import { TeamRequestPages } from 'features/team-request-pages/components/team-request-pages';
import { WaitlistedRoles } from 'features/waitlisted-roles/components/waitlisted-roles';
import { WarmLeadsUtils } from 'features/warm-leads-utils/components/warm-leads-utils';
import { WarmLeads } from 'features/warm-leads/components/warm-leads';
import { Redirect } from 'react-router-dom';
import {
  ColdIdeasLocation,
  CompanyPagesGenerationLocation,
  CompanyPagesSettingsLocation,
  DqsLocation,
  EnrichCompaniesLocation,
  FacebookFriendsLocation,
  HomePageLocation,
  IntroSearchFAQLocation,
  IntroSearchLocation,
  IntroSearchMonitoringLocation,
  IntroSearchResultsLocation,
  IntroSearchSettingsLocation,
  LinkedInJobPostingsLocation,
  LinkedinConnectionsLocation,
  LqsLocation,
  LqsSettingsLocation,
  MonitoringLocation,
  OutboundLocation,
  ScrubbingLocation,
  ScsLocation,
  ScsSettingsLocation,
  SettingsLocation,
  SgeLocation,
  SigninLocation,
  SocialConnectionsScoreLocation,
  SupplyRequestsLocation,
  TargetedLeadsLocation,
  TeamRequestPagesLocation,
  WaitlistedRolesLocation,
  WarmLeadsLocation,
  WarmLeadsUtilsLocation,
} from './locations';
import { monitoringTabs, settingsTabs } from './navigation-config';

const dashboardRoutes = [
  {
    path: LqsLocation,
    component: LqsScore,
    exact: true,
  },
  {
    path: SgeLocation,
    component: Sge,
    exact: true,
  },
  {
    path: ScsLocation,
    component: Scs,
    exact: true,
  },
  {
    path: FacebookFriendsLocation,
    component: FacebookFriendsForm,
    exact: true,
  },
  {
    path: LinkedinConnectionsLocation,
    component: LinkedinConnections,
    exact: true,
  },
  {
    path: TargetedLeadsLocation,
    component: TargetedLeads,
    exact: true,
  },
  {
    path: WarmLeadsLocation,
    component: WarmLeads,
    exact: true,
  },
  {
    path: OutboundLocation,
    component: SgeOutbound,
    exact: true,
  },
  {
    path: LinkedInJobPostingsLocation,
    component: LinkedInJobPostings,
    exact: true,
  },
  {
    path: WarmLeadsUtilsLocation,
    component: WarmLeadsUtils,
    exact: true,
  },
  {
    path: ColdIdeasLocation,
    component: ColdIdeas,
    exact: true,
  },
  {
    path: EnrichCompaniesLocation,
    component: EnrichCompanies,
    exact: true,
  },
  {
    path: HomePageLocation,
    component: HomePage,
    exact: true,
  },
  {
    path: IntroSearchLocation,
    component: IntroSearchIndex,
    exact: true,
  },
  {
    path: IntroSearchResultsLocation,
    component: IntroSearchResults,
    exact: true,
  },
  {
    path: CompanyPagesGenerationLocation,
    component: CompanyPagesGeneration,
    exact: true,
  },
  {
    path: TeamRequestPagesLocation,
    component: TeamRequestPages,
    exact: true,
  },
  {
    path: SocialConnectionsScoreLocation,
    component: ScsScoringRequest,
    exact: true,
  },
  {
    path: IntroSearchFAQLocation,
    component: IntroSearchFAQ,
    exact: true,
  },
  {
    path: SettingsLocation,
    component: () => <TabsLayout pageTitle="Settings" tabs={settingsTabs} routes={settingsRoutes} />,
    exact: false,
  },
  {
    path: MonitoringLocation,
    component: () => <TabsLayout pageTitle="Monitoring" tabs={monitoringTabs} routes={monitoringRoutes} />,
    exact: false,
  },
  {
    path: '/',
    component: () => <Redirect to={HomePageLocation} />,
  },
];

const routes = [
  {
    path: SigninLocation,
    component: Login,
    exact: true,
    requireAuth: false,
  },
  {
    path: '/',
    component: Dashboard,
    requireAuth: true,
    routes: dashboardRoutes,
  },
];

export const settingsRoutes = [
  {
    path: ScrubbingLocation,
    component: Scrubbing,
    exact: true,
  },
  {
    path: WaitlistedRolesLocation,
    component: WaitlistedRoles,
    exact: true,
  },
  {
    path: LqsSettingsLocation,
    component: LqsSettingsAndProfiles,
    exact: true,
  },
  {
    path: DqsLocation,
    component: DqsView,
    exact: true,
  },
  {
    path: ScsSettingsLocation,
    component: ScoringProfileSettings,
    exact: true,
  },
  {
    path: IntroSearchSettingsLocation,
    component: IntroSearchSettings,
    exact: true,
  },
  {
    path: CompanyPagesSettingsLocation,
    component: CompanyPages,
    exact: true,
  },
  {
    path: '/',
    component: () => <Redirect to={ScrubbingLocation} />,
  },
];

export const monitoringRoutes = [
  {
    path: SupplyRequestsLocation,
    component: SupplyRequests,
    exact: true,
  },
  {
    path: IntroSearchMonitoringLocation,
    component: IntroSearchMonitoring,
    exact: true,
  },
  {
    path: '/',
    component: () => <Redirect to={SupplyRequestsLocation} />,
  },
];

export default routes;
