import { createUseStyles } from 'react-jss';
import { FC } from 'react';
import { StandaloneField } from './standalone-field';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: '1rem',
    marginTop: '1rem',
    border: '1px solid #8080804f',
    borderRadius: '4px',
    padding: '1rem',
    gap: '8px',
  },
  span: {
    marginLeft: '10px',
    marginRight: '10px',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
});
export const GroupField: FC<{
  groupFields: any[] | undefined;
  formik: any;
  tagContainer: string;
  disabled?: Function | boolean;
}> = ({ groupFields, formik, tagContainer, disabled }) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      {groupFields?.map((formField, key) => {
        return (
          <StandaloneField
            spanClassName={styles.span}
            key={'f' + key}
            formField={formField}
            formik={formik}
            tagContainer={tagContainer}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};
