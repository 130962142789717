import { Button, Input, Typography } from '@a_team/ui-components';
import { RootState } from 'core/store';
import {
  handleGetCompanyPageParamsRequest,
  handleUpdateCompanyPageParamsRequest,
} from 'features/company-pages/actions/company-pages-actions';
import { CompanyPageParams } from 'features/company-pages/company-pages-request-model';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyPagesExcludedCompanies } from './company-pages-excluded-companies';
import { TableData, TechStackTable } from './tech-stack-table';

const initialFormValues: CompanyPageParams = {
  numberOfRoles: 5,
  initialTeamSize: 3,
  maxJobPostingAge: 14,
  excludeWords: '',
  techStackMappings: [],
  excludedCompanies: [],
};

export const CompanyPagesConfigForm: FC<{ loading: boolean }> = ({ loading }) => {
  const dispatch = useDispatch();
  const { params, paramsError, paramsSubmitted } = useSelector((state: RootState) => state.companyPages);
  const [paramsLoaded, setParamsLoaded] = useState(false);

  useEffect(() => {
    dispatch(handleGetCompanyPageParamsRequest());
  }, []);

  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit: (values) => {
      dispatch(handleUpdateCompanyPageParamsRequest(values));
    },
  });

  useEffect(() => {
    if (params) {
      formik.setValues({ ...initialFormValues, ...params });
      setParamsLoaded(true);
    }
  }, [params]);

  const onTechStackTableChange = (data: TableData) => {
    formik.setFieldValue(
      'techStackMappings',
      data.map(([keyword, roleName, techStack, isEngineeringRole]) => ({
        keyword,
        roleName,
        techStack,
        isEngineeringRole,
      }))
    );
  };

  const onExcludedCompaniesChange = (data: string[]) => {
    formik.setFieldValue('excludedCompanies', data);
  };

  const initialTableData: TableData =
    params && Array.isArray(params?.techStackMappings)
      ? params.techStackMappings.map(({ keyword, roleName, techStack, isEngineeringRole }) => [
          keyword,
          roleName,
          techStack,
          isEngineeringRole,
        ])
      : [];

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="textLarge" weight="bold">
          Job role filters
        </Typography>
        <Input
          label="Number of roles from linkedin to include"
          name="numberOfRoles"
          onChange={formik.handleChange}
          size="stretch"
          type="number"
          value={formik.values.numberOfRoles}
        />
        <Input
          label="Initial team size"
          name="initialTeamSize"
          onChange={formik.handleChange}
          size="stretch"
          type="number"
          value={formik.values.initialTeamSize}
        />
        <Input
          label="Maximum job posting age (in days)"
          name="maxJobPostingAge"
          onChange={formik.handleChange}
          size="stretch"
          type="number"
          value={formik.values.maxJobPostingAge}
        />
        <Input
          label="Exclude roles containing following words"
          name="excludeWords"
          onChange={formik.handleChange}
          size="stretch"
          value={formik.values.excludeWords}
        />
        <br />
        <br />
        <Typography variant="textLarge" weight="bold">
          Roles Config
        </Typography>
        {params && <TechStackTable onChange={onTechStackTableChange} initialData={initialTableData} />}
        <br />
        <hr />
        {paramsLoaded && (
          <CompanyPagesExcludedCompanies
            onChange={onExcludedCompaniesChange}
            initialData={formik.values.excludedCompanies}
          />
        )}
        <hr />
        <br />
        <Button loading={loading}>Save</Button>
        <br />
        {paramsSubmitted && <span>Params saved!</span>}
        {paramsError && <span>Error saving params: {paramsError}</span>}
      </form>
    </>
  );
};
