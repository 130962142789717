import axios from 'config/axios-config';

const introSearch = async (payload: any) => {
  return axios.supplySearchInstance({
    method: 'POST',
    url: '/v1/intro-search',
    data: payload,
  });
};

const getIntroSearchSettings = async () => {
  return axios.supplySearchInstance({
    method: 'GET',
    url: '/v1/intro-search-settings',
  });
};

const getIntroSearchMonitoringUsers = async () => {
  return axios.supplySearchInstance({
    method: 'GET',
    url: '/v1/intro-search/monitoring/users',
  });
};

const getIntroSearchMonitoringEntries = async (email: string) => {
  return axios.supplySearchInstance({
    method: 'GET',
    url: '/v1/intro-search/monitoring/entries',
    params: { email },
  });
};

const saveIntroSearchSettings = async (payload: any) => {
  return axios.supplySearchInstance({
    method: 'POST',
    url: '/v1/intro-search-settings',
    data: payload,
  });
};

export default {
  introSearch,
  getIntroSearchSettings,
  saveIntroSearchSettings,
  getIntroSearchMonitoringUsers,
  getIntroSearchMonitoringEntries,
};
