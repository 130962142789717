import { Card, Checkbox, Input, Typography } from '@a_team/ui-components';
import { MultipleInputFieldSet } from 'core/components/multiple-input-field-set';
import { ReorderableMultipleInputFieldSet } from 'core/components/reorderable-multiple-input-field-set';
import { RootState } from 'core/store';
import {
  handleDeletePositionGroupRequest,
  handleGetScoringProfileRequest,
  handleSaveDegreeGroupsRequest,
  handleSavePositionGroupRequest,
  handleSaveScoringProfileRequest,
} from 'features/scs/actions/scoring-profile-actions';
import { DegreeGroup, ScoringProfileResponseModel } from 'features/scs/models/scoring-profile';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { ScoringProfileForm } from './scoring-profile-form';

const useStyles = createUseStyles({
  degreeGroupItem: {
    width: '223px',
    display: 'flex',
    justifyContent: 'center',
  },
});

export const ScoringProfileSettings: React.FC = () => {
  const dispatch = useDispatch();

  const profile = useSelector<RootState, ScoringProfileResponseModel>((state) => state.scsScoringProfile.data);
  const loading = useSelector<RootState, boolean>((state) => state.scsScoringProfile.loading);

  const degreeGroupsHeaders = [
    'Degrees',
    'Include Faculty Check',
    'Primary Min Overlap Years',
    'Primary Min Overlap Points',
    'Secondary Min Overlap Years',
    'Secondary Min Overlap Points',
  ];

  const styles = useStyles();
  useEffect(() => {
    dispatch(handleGetScoringProfileRequest());
  }, []);

  const positionGroups = profile.connectionsPositionGroups || [];
  const degreeGroups = profile.connectionsDegreeGroups || [];

  const renderDegreeGroup = (
    index: number,
    value: DegreeGroup,
    onChange: (index: number, key: string, value: string | number | boolean) => void
  ): JSX.Element => {
    return (
      <>
        <Input
          key={`degrees_${index}`}
          name={`degrees_${index}`}
          onChange={(e) => onChange(index, 'degrees', e.target.value)}
          value={value.degrees}
        />
        <Checkbox
          onChange={(e) => onChange(index, 'shouldCheckIdenticalFaculty', e.target.checked)}
          checked={value.shouldCheckIdenticalFaculty}
          className={styles.degreeGroupItem}
        ></Checkbox>
        {[
          'primaryMinOverlapYears',
          'primaryMinOverlapPoints',
          'secondaryMinOverlapYears',
          'secondaryMinOverlapPoints',
        ].map((prop) => (
          <Input
            key={`${prop}_${index}`}
            name={`${prop}_${index}`}
            onChange={(e) => onChange(index, prop, e.target.value)}
            value={value[prop as keyof DegreeGroup] as string | number | undefined}
            type="number"
          />
        ))}
      </>
    );
  };

  return (
    <>
      <Card>
        <Typography variant="h2">Profile</Typography>

        <Typography variant="h3">General</Typography>
        <Input label="Profile Id" name="id" onChange={() => {}} disabled value={profile?.id} size="stretch" />

        <ScoringProfileForm
          onSubmit={(values) => dispatch(handleSaveScoringProfileRequest(values, profile?.id))}
          initialValues={profile || {}}
          submitting={loading}
        />
      </Card>

      <Card style={{ marginTop: '1rem' }}>
        <Typography variant="h2">Position groups</Typography>

        {!profile.id ? (
          <Typography variant="h3">To create position groups, you must first create a profile.</Typography>
        ) : (
          <MultipleInputFieldSet
            values={positionGroups.map((i) => ({ id: i.id, value: i.positions }))}
            onDelete={(id) => dispatch(handleDeletePositionGroupRequest(profile.id as string, id))}
            onSave={({ id, value }) =>
              dispatch(handleSavePositionGroupRequest({ positions: value }, profile.id as string, id))
            }
          />
        )}
      </Card>

      <Card style={{ marginTop: '1rem' }}>
        <Typography variant="h2">Degree groups</Typography>

        {!profile.id ? (
          <Typography variant="h3">To create degree groups, you must first create a profile.</Typography>
        ) : (
          <ReorderableMultipleInputFieldSet
            values={degreeGroups}
            onSave={(items) => dispatch(handleSaveDegreeGroupsRequest(items, profile.id as string))}
            renderChild={renderDegreeGroup}
            getEmptyItem={() => ({
              degrees: '',
              shouldCheckIdenticalFaculty: false,
              primaryMinOverlapYears: 0,
              primaryMinOverlapPoints: 0,
              secondaryMinOverlapYears: 0,
              secondaryMinOverlapPoints: 0,
            })}
            headers={degreeGroupsHeaders}
          />
        )}
      </Card>
    </>
  );
};
