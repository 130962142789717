import PageHeader from 'core/components/page-header';
import { createUseStyles } from 'react-jss';
import { Link, Route, Switch, useLocation } from 'react-router-dom';

const useStyles = createUseStyles({
  linksContainer: {
    display: 'flex',
    marginBottom: 16,
    '& a': {
      padding: '8px 16px',
      textDecoration: 'none',
      color: 'inherit',
      '&:not(:last-child)': {
        borderRight: '2px solid #777',
      },
      '&:hover': {
        opacity: 0.8,
      },
    },
  },
});

interface Props {
  pageTitle: string;
  tabs: { label: string; path: string }[];
  routes: { path: string; exact?: boolean; component: any }[];
}

const TabsLayout = ({ pageTitle, tabs, routes }: Props) => {
  const styles = useStyles();
  const location = useLocation();

  return (
    <div>
      <PageHeader title={pageTitle} />
      <div className={styles.linksContainer}>
        {tabs.map((setting) => (
          <Link
            to={setting.path}
            key={`${location.pathname}-${setting.path}`}
            style={{ opacity: setting.path === location.pathname ? 0.5 : 'undefined' }}
          >
            {setting.label}
          </Link>
        ))}
      </div>
      <div>
        <Switch>
          {routes.map((route, index) => {
            return <Route key={index} {...route} />;
          })}
        </Switch>
      </div>
    </div>
  );
};

export default TabsLayout;
