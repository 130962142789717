import { FC, KeyboardEvent, useState } from 'react';
import { Input, Tag } from '@a_team/ui-components';

type Props = {
  onChange?: (ev: { target: { name: string; value: string[] } }) => void;
  label: string;
  name: string;
  values: string[];
  tagContainer?: string;
  disabled?: boolean;
  hint?: string;
};

export const TagInputList: FC<Props> = ({ onChange, name, label, values, tagContainer, disabled, hint = '' }) => {
  const [selectedValues, setValues] = useState(values);
  const [input, setInput] = useState('');
  const sendChange = (values: string[]) => {
    if (onChange) {
      onChange({
        target: {
          name,
          value: values,
        },
      });
    }
  };
  return (
    <>
      <Input
        type="text"
        label={label}
        value={input}
        onChange={(change) => {
          setInput(change.target.value);
        }}
        onKeyDown={(keyboard: KeyboardEvent) => {
          if (keyboard.code === 'Enter') {
            if ((input && input.length === 0) || input === '') {
              return;
            }
            keyboard.stopPropagation();
            keyboard.preventDefault();
            setValues((prevState) => {
              setInput('');
              const newState = [...prevState, input];
              sendChange(newState);
              return newState;
            });
          }
        }}
        disabled={disabled}
      />
      {hint && <i style={{ fontSize: '10px' }}>{hint}</i>}
      <div className={tagContainer}>
        {selectedValues.map((value) => {
          return (
            <Tag
              key={value}
              id={value}
              onRemove={(v, id) => {
                setValues((prevState) => {
                  const newState = prevState.filter((pv) => pv !== id);
                  sendChange(newState);
                  return newState;
                });
              }}
              label={value}
            />
          );
        })}
      </div>
    </>
  );
};
