import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { ColorNames, theme, Typography } from '@a_team/ui-components';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import logo from 'assets/images/logo.png';
import { useDispatch } from 'react-redux';
import { handleSignInFail, handleSignInSuccess } from 'features/auth/actions/auth-actions';

const useStyles = createUseStyles(() => ({
  container: {
    width: '100vw',
    display: 'flex',
    alignItems: 'stretch',
    height: '100vh',
  },
  logoSection: {
    backgroundImage: `radial-gradient(circle at top left, ${theme.colors[ColorNames.Hannibal][1000]} 0%, ${
      theme.colors[ColorNames.Hannibal][600]
    } 85%, ${theme.colors[ColorNames.Hannibal][400]} 100% )`,
    width: '60%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  logoContainer: {
    width: '100%',
    height: '100%',
    backgroundImage: `url("${logo}")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  loginButtonContainer: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '15% 0',
  },
}));

export const LoginForm: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const login = (response: CredentialResponse) => {
    if (response.credential) {
      dispatch(handleSignInSuccess(response.credential, new Date(new Date().getTime() + 3599 * 1000)));
    }
  };

  const onFailure = () => {
    dispatch(handleSignInFail('Login error', ''));
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoSection}>
        <div className={styles.logoContainer} />
      </div>
      <div className={styles.loginButtonContainer}>
        <Typography variant="h1">A.Team Growth Tools</Typography>
        <GoogleLogin onSuccess={login} onError={onFailure} theme="filled_blue" size="large" width="250px" />
      </div>
    </div>
  );
};
