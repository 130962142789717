import { Tab, Tabs } from '@a_team/ui-components';
import LqsProfileSettings from './profiles';
import LqsSettings from './settings';

const LqsSettingsAndProfiles = () => {
  return (
    <Tabs>
      <Tab label="Settings">
        <LqsSettings />
      </Tab>
      <Tab label="Profiles">
        <LqsProfileSettings />
      </Tab>
    </Tabs>
  );
};

export default LqsSettingsAndProfiles;
