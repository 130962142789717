import { RequestScsScoringRequest } from '../models/scs-request';
import * as actionsTypes from './scoring-request-action-types';

export const handlePostScoringRequest = (data: RequestScsScoringRequest): actionsTypes.PostScoringRequestAction => ({
  type: actionsTypes.POST_SCORING_REQUEST,
  data,
});

export const handlePostScoringRequestSuccess = (data: string): actionsTypes.PostScoringRequestSuccessAction => ({
  type: actionsTypes.POST_SCORING_REQUEST_SUCCESS,
  data,
});

export const handlePostScoringRequestFail = (): actionsTypes.PostScoringRequestFailAction => ({
  type: actionsTypes.POST_SCORING_REQUEST_FAIL,
});
