import { COMPANY_PAGES_BASE_URL } from 'config/constants';

/**
 * Helper function to get company page url from slug
 *
 * @param   {string} slug - Page slug
 * @returns {string} Company page url
 */
export const getCompanyPageUrl = (slug: string): string => {
  return `${COMPANY_PAGES_BASE_URL}/${slug}`;
};
