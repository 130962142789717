import { ScrubbingProfileForm } from '../forms/scrubbing-profiles-form-config';
import { ScrubbingSettingsForm } from '../forms/scrubbing-settings-form-config';
import { ScrubbingProfilesGetResponseItem } from '../models/scrubbing-profiles-get-response';
import { ScrubbingSettingsModel } from '../models/scrubbing-settings-get-response';

export const SCRUBBING_SETTINGS_UPDATE_REQUEST = 'scrubbing/SCRUBBING_SETTINGS_UPDATE_REQUEST';
export interface ScrubbingSettingsUpdateRequestAction {
  type: typeof SCRUBBING_SETTINGS_UPDATE_REQUEST;
  payload: ScrubbingSettingsForm;
}

export const SCRUBBING_SETTINGS_UPDATE_SUCCESS = 'scrubbing/SCRUBBING_SETTINGS_UPDATE_SUCCESS';
export interface ScrubbingSettingsUpdateSuccessAction {
  type: typeof SCRUBBING_SETTINGS_UPDATE_SUCCESS;
}

export const SCRUBBING_SETTINGS_UPDATE_FAIL = 'scrubbing/SCRUBBING_SETTINGS_UPDATE_FAIL';
export interface ScrubbingSettingsUpdateFailAction {
  type: typeof SCRUBBING_SETTINGS_UPDATE_FAIL;
}

export const SCRUBBING_SETTINGS_GET_REQUEST = 'scrubbing/SCRUBBING_SETTINGS_GET_REQUEST';
export interface ScrubbingSettingsGetRequestAction {
  type: typeof SCRUBBING_SETTINGS_GET_REQUEST;
}

export const SCRUBBING_SETTINGS_GET_SUCCESS = 'scrubbing/SCRUBBING_SETTINGS_GET_SUCCESS';
export interface ScrubbingSettingsGetSuccessAction {
  type: typeof SCRUBBING_SETTINGS_GET_SUCCESS;
  payload: ScrubbingSettingsModel;
}

export const SCRUBBING_SETTINGS_GET_FAIL = 'scrubbing/SCRUBBING_SETTINGS_GET_FAIL';
export interface ScrubbingSettingsGetFailAction {
  type: typeof SCRUBBING_SETTINGS_GET_FAIL;
}

export const SCRUBBING_PROFILES_GET_REQUEST = 'scrubbing/SCRUBBING_PROFILES_GET_REQUEST';
export interface ScrubbingProfilesGetRequestAction {
  type: typeof SCRUBBING_PROFILES_GET_REQUEST;
}

export const SCRUBBING_PROFILES_GET_SUCCESS = 'scrubbing/SCRUBBING_PROFILES_GET_SUCCESS';
export interface ScrubbingProfilesGetSuccessAction {
  type: typeof SCRUBBING_PROFILES_GET_SUCCESS;
  payload: ScrubbingProfilesGetResponseItem[];
}

export const SCRUBBING_PROFILES_GET_FAIL = 'scrubbing/SCRUBBING_PROFILES_GET_FAIL';
export interface ScrubbingProfilesGetFailAction {
  type: typeof SCRUBBING_PROFILES_GET_FAIL;
}

export const SCRUBBING_PROFILE_UPSERT_REQUEST = 'scrubbing/SCRUBBING_PROFILES_UPSERT_REQUEST';
export interface ScrubbingProfileUpsertRequestAction {
  type: typeof SCRUBBING_PROFILE_UPSERT_REQUEST;
  payload: ScrubbingProfileForm;
}

export const SCRUBBING_PROFILE_UPSERT_SUCCESS = 'scrubbing/SCRUBBING_PROFILES_UPSERT_SUCCESS';
export interface ScrubbingProfileUpsertSuccessAction {
  type: typeof SCRUBBING_PROFILE_UPSERT_SUCCESS;
}

export const SCRUBBING_PROFILE_UPSERT_FAIL = 'scrubbing/SCRUBBING_PROFILES_UPSERT_FAIL';
export interface ScrubbingProfileUpsertFailAction {
  type: typeof SCRUBBING_PROFILE_UPSERT_FAIL;
}

export const ADD_NEW_SCRUBBING_PROFILE = 'scrubbing/ADD_NEW_SCRUBBING_PROFILE';
export interface AddNewScrubbingProfileAction {
  type: typeof ADD_NEW_SCRUBBING_PROFILE;
}

export const SET_CURRENT_SCRUBBING_PROFILE_REQUEST = 'scrubbing/SET_CURRENT_PROFILE_REQUEST';
export interface SetCurrentScrubbingProfileRequestAction {
  type: typeof SET_CURRENT_SCRUBBING_PROFILE_REQUEST;
  profileId: string;
}

export const SET_CURRENT_SCRUBBING_PROFILE_SUCCESS = 'scrubbing/SET_CURRENT_SCRUBBING_PROFILE_SUCCESS';
export interface SetCurrentScrubbingProfileSuccessAction {
  type: typeof SET_CURRENT_SCRUBBING_PROFILE_SUCCESS;
  payload: ScrubbingProfileForm;
}

export const SET_CURRENT_SCRUBBING_PROFILE_FAIL = 'scrubbing/SET_CURRENT_SCRUBBING_PROFILE_FAIL';
export interface SetCurrentScrubbingProfileFailAction {
  type: typeof SET_CURRENT_SCRUBBING_PROFILE_FAIL;
}

export const DELETE_SCRUBBING_PROFILE_REQUEST = 'scrubbing/DELETE_SCRUBBING_PROFILE_REQUEST';
export interface DeleteScrubbingProfileRequestAction {
  type: typeof DELETE_SCRUBBING_PROFILE_REQUEST;
  profileId: string;
}

export const DELETE_SCRUBBING_PROFILE_SUCCESS = 'scrubbing/DELETE_SCRUBBING_PROFILE_SUCCESS';
export interface DeleteScrubbingProfileSuccessAction {
  type: typeof DELETE_SCRUBBING_PROFILE_SUCCESS;
}

export const DELETE_SCRUBBING_PROFILE_FAIL = 'scrubbing/DELETE_SCRUBBING_PROFILE_FAIL';
export interface DeleteScrubbingProfileFailAction {
  type: typeof DELETE_SCRUBBING_PROFILE_FAIL;
}

export type ScrubbingAction =
  | ScrubbingSettingsUpdateRequestAction
  | ScrubbingSettingsUpdateSuccessAction
  | ScrubbingSettingsUpdateFailAction
  | ScrubbingSettingsGetRequestAction
  | ScrubbingSettingsGetSuccessAction
  | ScrubbingSettingsGetFailAction
  | ScrubbingProfilesGetRequestAction
  | ScrubbingProfilesGetSuccessAction
  | ScrubbingProfilesGetFailAction
  | ScrubbingProfileUpsertRequestAction
  | ScrubbingProfileUpsertSuccessAction
  | ScrubbingProfileUpsertFailAction
  | AddNewScrubbingProfileAction
  | SetCurrentScrubbingProfileRequestAction
  | SetCurrentScrubbingProfileSuccessAction
  | SetCurrentScrubbingProfileFailAction
  | DeleteScrubbingProfileRequestAction
  | DeleteScrubbingProfileSuccessAction
  | DeleteScrubbingProfileFailAction;
