import { Button, Typography } from '@a_team/ui-components';
import demandLeadsApi, { ConnectionsParamsType } from 'api/demand-leads-api';
import { FC, useEffect, useState } from 'react';
import { convertFormikValuesToParams } from './warm-leads-form';

export const WarmLeadsSaveParams: FC<{ loading: boolean; formik: any }> = ({ loading, formik }) => {
  const [saveParamsMessage, setSaveParamsMessage] = useState('');
  const saveParamsFn = async (formik: any) => {
    const params = convertFormikValuesToParams(formik.values);
    const resp = await demandLeadsApi.saveParams({ params, type: ConnectionsParamsType.WarmLeadsParams });
    setSaveParamsMessage(resp.status === 200 ? 'Params saved' : 'Failed to save params');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSaveParamsMessage('');
    }, 3000);
    return () => clearInterval(interval);
  }, [saveParamsMessage]);

  return (
    <>
      <Button loading={loading} onClick={() => saveParamsFn(formik)}>
        SAVE PARAMS
      </Button>
      <Typography variant="h4">{saveParamsMessage}</Typography>
    </>
  );
};
