import clientScoreApi, { DqsResult } from 'api/dqs-api';
import { AxiosResponse } from 'axios';
import * as actionsTypes from 'features/dqs/actions/testbed-action-types';
import { CallEffect, PutEffect, call, put, takeLatest } from 'redux-saga/effects';
import { testbed } from '../reducers/testbed-reducers';

function* runTestbed({
  payload,
}: actionsTypes.RunTestbedRequestAction): Generator<
  | CallEffect<AxiosResponse<DqsResult, any>>
  | PutEffect<actionsTypes.RunTestbedSuccessAction>
  | PutEffect<actionsTypes.RunTestbedFailAction>,
  void,
  AxiosResponse<DqsResult, any>
> {
  try {
    const res = yield call(clientScoreApi.getScore, payload);
    yield put(testbed.actions.runTestbedSuccess(res.data));
  } catch (err: any) {
    // validation errors will have more info on err.response.data.errors
    const message = err.response?.data?.errors ? JSON.stringify(err.response?.data?.errors) : err.message;
    yield put(testbed.actions.runTestbedFail(message));
  }
}

export default function* saga() {
  yield takeLatest(actionsTypes.RUN_TESTBED_REQUEST, runTestbed);
}
