import { Button } from '@a_team/ui-components';
import { DqsProfileBase } from 'api/dqs-api';
import ErrorMessageDisplay from 'core/components/error-message-display';
import { millionsToDisplayValue } from 'core/utils/convert';
import {
  handleDeleteProfile,
  handleMoveProfileDown,
  handleMoveProfileUp,
  handleSaveProfile,
  handleStartEditProfile,
  handleStopEditProfile,
} from 'features/dqs/actions/profiles-actions';
import { IndividualProfileState } from 'features/dqs/reducers/profile-reducers';
import { FC, useCallback, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { requiresDelay, requiresHubspotCalendar } from '../utils';
import { DqsProfileForm } from './dqs-profile-form';

const gutter = '8px';
const doubleGutter = '16px';

const useStyles = createUseStyles({
  row: {
    '&:nth-child(even)': {
      backgroundColor: '#efefef',
    },
    padding: gutter,
  },
  info: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  text: {
    width: '80%',
  },
  actions: {
    marginTop: doubleGutter,
    display: 'flex',
  },
  button: {
    margin: `0 ${gutter}`,
  },
});

type ProfileRowProps = {
  data: IndividualProfileState;
  rowIndex: number;
  totalRows: number;
};

export const DqsProfileListRow: FC<ProfileRowProps> = ({
  data: { profile, editing, saving, saveErrorMsg, deleting, deleteErrorMsg, movingDown, movingUp, moveErrorMessage },
  rowIndex,
  totalRows,
}) => {
  const { row, info, text, actions, button } = useStyles();
  const dispatch = useDispatch();

  const handleEdit = useCallback(() => {
    dispatch(handleStartEditProfile(profile.id));
  }, [dispatch]);

  const handleSave = useCallback(
    (values: DqsProfileBase) => {
      dispatch(handleSaveProfile({ ...values, id: profile.id }));
    },
    [dispatch]
  );

  const handleCancel = useCallback(() => {
    dispatch(handleStopEditProfile(profile.id));
  }, [dispatch]);

  const handleDelete = useCallback(() => {
    if (confirm(`Are you sure you want to delete profile ${profile.id}?`)) {
      dispatch(handleDeleteProfile(profile.id));
    }
  }, [dispatch]);

  const handleGoUp = useCallback(() => {
    dispatch(handleMoveProfileUp(profile.id));
  }, [dispatch]);

  const handleGoDown = useCallback(() => {
    dispatch(handleMoveProfileDown(profile.id));
  }, [dispatch]);

  const isProcessing = useMemo(
    () => saving || deleting || movingUp || movingDown,
    [saving, deleting, movingUp, movingDown]
  );

  const canGoUp = useMemo(() => !isProcessing && !editing && rowIndex > 0, [isProcessing, editing, rowIndex]);

  const canGoDown = useMemo(
    () => !isProcessing && !editing && rowIndex < totalRows - 1,
    [isProcessing, editing, rowIndex, totalRows]
  );

  const canEdit = useMemo(() => !editing && !isProcessing, [editing, isProcessing]);

  const canDelete = useMemo(() => !editing && !isProcessing, [editing, isProcessing]);

  return (
    <div className={row}>
      <div className={info}>
        <div className={text}>
          <p>
            {profile.name} ({profile.id})<br />
            Raised from {millionsToDisplayValue(profile.minRaise)} to {millionsToDisplayValue(profile.maxRaise)} mill{' '}
            <em>or</em> Employees from {profile.minEmployees} to {profile.maxEmployees}
            {profile.nameKeywordsCSV && (
              <>
                <br />
                <em>or</em> name includes: {profile.nameKeywordsCSV}
              </>
            )}
            {profile.industryKeywordsCSV && (
              <>
                <br />
                <em>or</em> industry includes: {profile.industryKeywordsCSV}
              </>
            )}
            {profile.withCountriesCSV && (
              <>
                <br />
                <em>and</em> countries: {profile.withCountriesCSV}
              </>
            )}
            <br />
            ---&gt; {profile.decision} {requiresDelay(profile.decision) ? `(${profile.passDelayHours} hours) ` : ''}
            {requiresHubspotCalendar(profile.decision) ? `[calendar: ${profile.hubspotCalendarUrl}]` : ''}
          </p>
        </div>
        <div className={actions}>
          <Button size="sm" className={button} onClick={handleGoUp} disabled={!canGoUp || movingUp}>
            Up
          </Button>
          <Button size="sm" className={button} onClick={handleGoDown} disabled={!canGoDown || movingDown}>
            Down
          </Button>
          <Button size="sm" className={button} onClick={handleEdit} disabled={!canEdit || saving}>
            Edit
          </Button>
          <Button
            variant="negative"
            size="sm"
            className={button}
            onClick={handleDelete}
            disabled={!canDelete}
            loading={deleting}
          >
            Delete
          </Button>
        </div>
      </div>
      {editing && (
        <DqsProfileForm
          title={`Edit Profile ${profile.id}`}
          data={profile}
          saving={saving}
          saveErrorMsg={saveErrorMsg}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
      {moveErrorMessage && <ErrorMessageDisplay msg={`Move error: ${moveErrorMessage}`} />}
      {deleteErrorMsg && <ErrorMessageDisplay msg={`Delete error: ${deleteErrorMsg}`} />}
    </div>
  );
};
