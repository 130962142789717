import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyPageParams } from 'features/company-pages/company-pages-request-model';

export type CompanyPagesState = {
  loading: boolean;
  submitted: boolean;
  error?: string;
  params?: CompanyPageParams;
  paramsSubmitted: boolean;
  paramsError?: string;
};

const initialState: CompanyPagesState = {
  loading: false,
  submitted: false,
  paramsSubmitted: false,
};

export const companyPages = createSlice({
  name: 'companyPages',
  initialState,
  reducers: {
    getCompanyPageParamsRequest: (state) => {
      state.loading = true;
    },
    getCompanyPageParamsSuccess: (state, action: PayloadAction<CompanyPageParams>) => {
      state.loading = false;
      state.params = action.payload;
    },
    getCompanyPageParamsFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.paramsError = action.payload;
    },
    updateCompanyPageParamsRequest: (state) => {
      state.loading = true;
      state.paramsSubmitted = false;
      state.paramsError = undefined;
    },
    updateCompanyPageParamsSuccess: (state) => {
      state.loading = false;
      state.paramsSubmitted = true;
      state.paramsError = undefined;
    },
    updateCompanyPageParamsFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.paramsSubmitted = true;
      state.paramsError = action.payload;
    },
  },
});

export const {
  getCompanyPageParamsRequest,
  getCompanyPageParamsSuccess,
  getCompanyPageParamsFail,
  updateCompanyPageParamsRequest,
  updateCompanyPageParamsSuccess,
  updateCompanyPageParamsFail,
} = companyPages.actions;

export default companyPages.reducer;
