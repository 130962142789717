import { Input, Row } from '@a_team/ui-components';
import { OptionalSection } from 'core/components/optional-section';
import { FieldArray } from 'formik';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  tableRow: {
    maxWidth: '100%',
    width: '100%',
    display: 'grid',
  },
  tableInput: {
    maxWidth: '90%',
    width: '100%',
    display: 'flex',
  },
  tableDiv: {
    marginLeft: '20px',
  },
});

export const WarmLeadsVCsForm: FC<{
  formik: any;
}> = ({ formik }) => {
  const { tableRow, tableDiv, tableInput } = useStyles();

  return (
    <OptionalSection
      title="Mutual and Partnership VCs"
      description="Mention here the VCs with which A.Team is connected to somehow and will notify this on the output CSV in a specific column."
    >
      <div className={tableDiv}>
        <FieldArray
          name="vcs"
          validateOnChange={false}
          render={() => (
            <div>
              {formik.values.vcs && formik.values.vcs.length > 0
                ? formik.values.vcs.map(
                    (
                      vc: {
                        mutualVC: string;
                        mutualVCPartner: string;
                        partnershipVC: string;
                        partnershipVCPartner: string;
                      },
                      index: number
                    ) => (
                      <Row className={tableRow} key={index}>
                        <div className="col-sm-3">
                          <Input
                            type="text"
                            label={`Mutual VC ${index + 1}`}
                            className={tableInput}
                            name={`vcs.${index}.mutualVC`}
                            onChange={formik.handleChange}
                            value={vc.mutualVC}
                          />
                        </div>
                        <div className="col-sm-3">
                          <Input
                            type="text"
                            label={`Mutual VC Partner ${index + 1}`}
                            className={tableInput}
                            name={`vcs.${index}.mutualVCPartner`}
                            onChange={formik.handleChange}
                            value={vc.mutualVCPartner}
                          />
                        </div>
                        <div className="col-sm-3">
                          <Input
                            type="text"
                            label={`Partnership VC ${index + 1}`}
                            className={tableInput}
                            name={`vcs.${index}.partnershipVC`}
                            onChange={formik.handleChange}
                            value={vc.partnershipVC}
                          />
                        </div>
                        <div className="col-sm-3">
                          <Input
                            type="text"
                            label={`Partnership VC Partner ${index + 1}`}
                            className={tableInput}
                            name={`vcs.${index}.partnershipVCPartner`}
                            onChange={formik.handleChange}
                            value={vc.partnershipVCPartner}
                          />
                        </div>
                      </Row>
                    )
                  )
                : null}
            </div>
          )}
        />
      </div>
    </OptionalSection>
  );
};
