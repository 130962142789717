import axios from 'config/axios-config';
import { AxiosPromise } from 'axios';
import { SelectOption } from '@a_team/ui-components';
import { BaseScoringProfile } from 'core/models/Profiles';
import { getFormData } from './helpers';
import { ScoreRequestMetadata } from 'core/models/SupplyScore';
import { Settings } from 'features/lqs/models/settings';

const getProfileOptions = (): AxiosPromise<SelectOption[]> => {
  return axios.supplyInstance({
    method: 'GET',
    url: 'scoring-profile',
  });
};

const getProfile = (profileId: string): AxiosPromise<SelectOption[]> => {
  return axios.supplyInstance({
    method: 'GET',
    url: `scoring-profile/${profileId}`,
  });
};

const putProfile = (profileId: string, profile: BaseScoringProfile) => {
  return axios.supplyInstance({
    method: 'PUT',
    url: `scoring-profile/${profileId}`,
    data: profile,
  });
};

const postProfile = (profile: BaseScoringProfile) => {
  return axios.supplyInstance({
    method: 'POST',
    url: 'scoring-profile',
    data: profile,
  });
};

const postNamesFile = (profileId: String, file: File, metadata: ScoreRequestMetadata) => {
  return axios.supplyInstance({
    url: 'score/names',
    method: 'POST',
    data: getFormData({
      profileId,
      csv: file,
      ...metadata,
    }),
  });
};

const postLinkedInUrlsFile = (profileId: String, file: File, metadata: ScoreRequestMetadata) =>
  axios.supplyInstance({
    url: 'score/linkedin-urls',
    method: 'POST',
    data: getFormData({
      profileId,
      csv: file,
      ...metadata,
    }),
  });

const postQuery = (query: String, profileId: String, metadata: ScoreRequestMetadata) =>
  axios.supplyInstance({
    url: 'score/query',
    method: 'POST',
    data: {
      query,
      profileId,
      ...metadata,
    },
  });

const getQuery = (requestId: String) =>
  axios.supplyInstance({
    url: `score/query?requestId=${requestId}&getFlattenedData=true`,
    method: 'GET',
  });

const putSettings = (settings: Settings) =>
  axios.supplyInstance({
    url: 'settings',
    method: 'PUT',
    data: settings,
  });

const getSettings = () =>
  axios.supplyInstance({
    url: 'settings',
    method: 'GET',
  });

export default {
  getProfileOptions,
  getProfile,
  putProfile,
  postProfile,
  postNamesFile,
  postLinkedInUrlsFile,
  postQuery,
  getQuery,
  putSettings,
  getSettings,
};
