import * as actions from 'features/auth/actions/auth-action-types';

export const handleSignInSuccess = (token: string, expiresOn: Date): actions.SigninSuccessAction => {
  return {
    type: actions.SIGNIN_SUCCESS,
    token,
    expiresOn,
  };
};

export const handleSignInFail = (errorCode: string, details: string): actions.SigninFailAction => {
  return {
    type: actions.SIGNIN_FAIL,
    errorCode,
    details,
  };
};

export const handleSignInReset = (): actions.SigninResetAction => {
  return {
    type: actions.SIGNIN_RESET,
  };
};

export const handleSignInSetError = (message: string): actions.SigninSetErrorAction => {
  return {
    type: actions.SIGNIN_SET_ERROR,
    message,
  };
};
