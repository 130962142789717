import { theme } from '@a_team/ui-components';
import copyIcon from 'assets/icons/copyIcon.svg';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { toast } from 'react-toastify';

const useStyles = createUseStyles({
  copyButton: {
    background: theme.colors.Grey[100],
    border: `1px solid ${theme.colors.Grey[300]}`,
    borderRadius: '8px',
    marginLeft: '8px',
    width: '32px',
    height: '32px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
});

interface Props {
  name: string;
  url: string;
}

const PageLinkCopy: FC<Props> = ({ name, url }) => {
  const styles = useStyles();

  const onCopyClick = () => {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = url;
    link.innerHTML = `${name} x A.Team`;
    const range = document.createRange();
    range.selectNode(link);
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }

    // no alternative
    document.execCommand('copy');
    toast('Page url copied to clipboard', {
      theme: 'colored',
      type: 'success',
    });
    document.body.removeChild(link);
  };

  return (
    <button onClick={onCopyClick} type="button" className={styles.copyButton}>
      <img src={copyIcon} alt="copy" />
    </button>
  );
};

export default PageLinkCopy;
