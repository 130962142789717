import { FC } from 'react';
import { WarmLeadsUtilsFilterFile } from './warm-leads-utils-filter-file';
import { WarmLeadsUtilsMixFiles } from './warm-leads-utils-mix-files';

export const WarmLeadsUtils: FC = () => {
  return (
    <>
      <WarmLeadsUtilsFilterFile />
      <WarmLeadsUtilsMixFiles />
    </>
  );
};
