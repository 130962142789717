import { Button, Input, Typography } from '@a_team/ui-components';
import PageHeader from 'core/components/page-header';
import RowContainer from 'core/components/row-container';
import { RootState } from 'core/store';
import {
  handleFacebookFriendsRequest,
  handleMultipleConnectionsRequest,
} from 'features/facebook-friends/actions/facebook-friends-actions';
import {
  FacebookFriendsRequestModel,
  MultipleConnectionsRequestModel,
} from 'features/facebook-friends/facebook-friends-request-model';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const FacebookFriendsForm: FC<{ loading: boolean }> = ({ loading }) => {
  const dispatch = useDispatch();

  const { scrapingSubmitted, scrapingError } = useSelector<RootState, RootState['facebookFriends']>(
    (state) => state.facebookFriends
  );

  const formik = useFormik({
    initialValues: {
      nodeId: undefined,
      firstName: undefined,
      lastName: undefined,
      fbLink: undefined,
      refresh: false,
      username: '',
      password: '',
      exclusionList: [],
      csv: undefined,
    },
    onSubmit: (values, actions) => {
      if (values.csv) {
        dispatch(handleMultipleConnectionsRequest(values as MultipleConnectionsRequestModel));
      } else {
        dispatch(handleFacebookFriendsRequest(values as FacebookFriendsRequestModel));
      }
      actions.resetForm();
    },
  });

  return (
    <>
      <PageHeader
        title="Scrape FB connections"
        description="This tool allows you to scrape Facebook connections of specific profiles. Not all friends connections are “open” so the results will be partial - only profiles with open connections list will be output. The output will be the full name of people found in the friends lists. Username/ password to log into Facebook is required."
      />
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h3">Upload a CSV file with multiple profiles or enter single profile</Typography>
        <RowContainer>
          <Input
            type="file"
            accept=".csv"
            name="csv"
            onChange={(e: any) => formik.setFieldValue('csv', e.target.files[0])}
            style={{ width: '680px' }}
          />
          or
        </RowContainer>
        <RowContainer separator="and">
          <Input placeholder={'First name'} name={'firstName'} onChange={formik.handleChange} size="fit" />
          <Input placeholder={'Last name'} name={'lastName'} onChange={formik.handleChange} size="fit" />
          <Input placeholder={'FB Link'} name={'fbLink'} onChange={formik.handleChange} size="fit" />
        </RowContainer>
        <Typography variant="h3">Facebook login (required)</Typography>
        <RowContainer>
          <Input placeholder={'Username'} name={'username'} onChange={formik.handleChange} size="fit" />
          <Input
            placeholder={'Password'}
            name={'password'}
            onChange={formik.handleChange}
            type={'password'}
            size="fit"
          />
        </RowContainer>
        <Button loading={loading}>SUBMIT</Button>
        {scrapingSubmitted && (
          <span>Your request has been submitted, you should get an email with csv file after scraping is done</span>
        )}
        {scrapingError && <span>Error submitting request: {scrapingError}</span>}
      </form>
    </>
  );
};
