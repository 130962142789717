import axios from 'config/axios-config';
import { getFormData } from './helpers';

const fetchFacebookFriends = async (payload: any) => {
  return axios.demandConnectionsInstance({
    method: 'POST',
    url: 'v1/facebook-friends/',
    data: payload,
  });
};

const createFacebookFriendsRequest = async (payload: any) => {
  return axios.demandConnectionsInstance({
    method: 'POST',
    url: 'v1/facebook-friends/multi',
    data: getFormData(payload),
  });
};

export default {
  fetchFacebookFriends,
  createFacebookFriendsRequest,
};
