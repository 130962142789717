import axios from 'config/axios-config';
import { ScoreRequestMetadata } from 'core/models/SupplyScore';
import { getFormData } from './helpers';

const postGoogleSearch = async (
  searchTerm1: string,
  searchTerm2: string,
  linkedInCount: number,
  file: File | undefined,
  metadata: ScoreRequestMetadata
) => {
  return axios.supplyInstance({
    method: 'POST',
    url: 'v1/google',
    data: getFormData({
      searchTerm_1: searchTerm1,
      searchTerm_2: searchTerm2,
      linkedInCount,
      csv: file,
      ...metadata,
    }),
  });
};

export default {
  postGoogleSearch,
};
