import { theme } from '@a_team/ui-components';
import { IntroSearchResultsLocation } from 'core/routing/locations';
import { useAppSelector } from 'core/store';
import { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';

const useStyles = createUseStyles({
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    boxSizing: 'border-box',
    width: '100vw',
    padding: '8px 0',
    borderTop: `2px solid ${theme.colors.Grey[400]}`,
    backgroundColor: theme.colors.Grey[100],
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    fontSize: '16px',
    margin: '0 8px 0 16px',
    display: 'inline-block',
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflowY: 'auto',
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  entry: {
    fontSize: '14px',
    padding: '0 0 0 8px',
    transition: 'opacity 0.2s',
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer',
    },
    '&:not(:last-child)::after': {
      content: '","',
    },
    '&:last-child:not(first-child)::after': {
      content: '",..."',
    },
  },
});

const MAX_ENTRIES_TO_SHOW = 10;

const IntroSearchHistory = () => {
  const styles = useStyles();
  const listRef = useRef<HTMLUListElement>(null);

  const searchHistory = useAppSelector((state) => state.introSearch.searchHistory);
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  }, [searchHistory]);

  const history = useHistory();

  const onQueryClick = (query: string) => {
    history.replace({
      pathname: IntroSearchResultsLocation,
      search: `?${new URLSearchParams({ query }).toString()}`,
    });
  };

  return (
    <>
      <div className={styles.container}>
        <h6 className={styles.heading}>Search history</h6>
        <ul ref={listRef} className={styles.list}>
          {[...searchHistory]
            .reverse()
            .slice(0, MAX_ENTRIES_TO_SHOW)
            .map((entry) => (
              <li className={styles.entry} onClick={() => onQueryClick(entry.query)}>
                {entry.query}
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default IntroSearchHistory;
