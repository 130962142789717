import { Button, Input, Typography } from '@a_team/ui-components';
import PageHeader from 'core/components/page-header';
import { RootState } from 'core/store';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleCreateLinkedInJobsCsvRequestRequest } from '../actions/linkedin-job-postings-actions';

interface FormikValues {
  csv: File | undefined;
}

export const LinkedinJobPostingsCsv: FC = () => {
  const { loading, csvRequestId, error } = useSelector((state: RootState) => state.linkedInJobPostings);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      csv: undefined,
    },
    onSubmit: (values: FormikValues) => {
      dispatch(handleCreateLinkedInJobsCsvRequestRequest(values));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <PageHeader
        title="Scrape LinkedIn jobs"
        description="This tool allows you to input the URLs or name of companies and it will output data found on their LinkedIn job board. Data output includes: number of open roles, number of open roles that are a match for a.team, when was the last open role that is a match for a.team published, and a few open roles relevant for a.team info (role, link, data of publishing)."
      />

      <Typography variant="textSmall">CSV should have company_name or company_url column, or both.</Typography>
      <br />
      <Input
        type="file"
        accept=".csv"
        name="csv"
        onChange={(e: any) => formik.setFieldValue('csv', e.target.files[0])}
      />
      <br />
      {csvRequestId && <Typography variant="textMedium">Created request with id: {csvRequestId}</Typography>}
      {error && (
        <Typography variant="textMedium" color="Red@800">
          {error}
        </Typography>
      )}
      <Button loading={loading}>SUBMIT</Button>
    </form>
  );
};
