import { DqsProfile, DqsProfileBase } from 'api/dqs-api';
import * as actionsTypes from 'features/dqs/actions/profiles-action-types';

export const handleLoadProfiles = (): actionsTypes.LoadProfilesRequestAction => ({
  type: actionsTypes.LOAD_PROFILES_REQUEST,
});

export const handleLoadProfilesSuccess = (payload: DqsProfile[]): actionsTypes.LoadProfilesSuccessAction => ({
  type: actionsTypes.LOAD_PROFILES_SUCCESS,
  payload,
});

export const handleLoadProfilesFail = (payload: string): actionsTypes.LoadProfilesFailAction => ({
  type: actionsTypes.LOAD_PROFILES_FAIL,
  payload,
});

export const handleCreateProfile = (payload: DqsProfileBase): actionsTypes.CreateProfileAction => ({
  type: actionsTypes.CREATE_PROFILE,
  payload,
});

export const handleCreateProfileSuccess = (payload: DqsProfile): actionsTypes.CreateProfileSuccessAction => ({
  type: actionsTypes.CREATE_PROFILE_SUCCESS,
  payload,
});

export const handleCreateProfileFail = (payload: string): actionsTypes.CreateProfileFailAction => ({
  type: actionsTypes.CREATE_PROFILE_FAIL,
  payload,
});

export const handleCreateProfileClearError = (): actionsTypes.CreateProfileClearErrorAction => ({
  type: actionsTypes.CREATE_PROFILE_CLEAR_ERROR,
});

export const handleStartEditProfile = (id: number): actionsTypes.StartEditProfileAction => ({
  type: actionsTypes.START_EDIT_PROFILE,
  payload: id,
});

export const handleStopEditProfile = (id: number): actionsTypes.StopEditProfileAction => ({
  type: actionsTypes.STOP_EDIT_PROFILE,
  payload: id,
});

export const handleSaveProfile = (payload: DqsProfile): actionsTypes.SaveProfileAction => ({
  type: actionsTypes.SAVE_PROFILE,
  payload,
});

export const handleSaveProfileSuccess = (payload: DqsProfile): actionsTypes.SaveProfileSuccessAction => ({
  type: actionsTypes.SAVE_PROFILE_SUCCESS,
  payload,
});

export const handleSaveProfileFail = (
  payload: actionsTypes.ProfileMessagePayload
): actionsTypes.SaveProfileFailAction => ({
  type: actionsTypes.SAVE_PROFILE_FAIL,
  payload,
});

export const handleMoveProfileUp = (payload: number): actionsTypes.MoveProfileUpAction => ({
  type: actionsTypes.MOVE_PROFILE_UP,
  payload,
});

export const handleMoveProfileUpSuccess = (payload: DqsProfile[]): actionsTypes.MoveProfileUpSuccessAction => ({
  type: actionsTypes.MOVE_PROFILE_UP_SUCCESS,
  payload,
});

export const handleMoveProfileUpFail = (id: number, msg: string): actionsTypes.MoveProfileUpFailAction => ({
  type: actionsTypes.MOVE_PROFILE_UP_FAIL,
  payload: {
    id,
    msg,
  },
});

export const handleMoveProfileDown = (payload: number): actionsTypes.MoveProfileDownAction => ({
  type: actionsTypes.MOVE_PROFILE_DOWN,
  payload,
});

export const handleMoveProfileDownSuccess = (payload: DqsProfile[]): actionsTypes.MoveProfileDownSuccessAction => ({
  type: actionsTypes.MOVE_PROFILE_DOWN_SUCCESS,
  payload,
});

export const handleMoveProfileDownFail = (id: number, msg: string): actionsTypes.MoveProfileDownFailAction => ({
  type: actionsTypes.MOVE_PROFILE_DOWN_FAIL,
  payload: { id, msg },
});

export const handleDeleteProfile = (id: number): actionsTypes.DeleteProfileAction => ({
  type: actionsTypes.DELETE_PROFILE,
  payload: id,
});

export const handleDeleteProfileSuccess = (id: number): actionsTypes.DeleteProfileSuccessAction => ({
  type: actionsTypes.DELETE_PROFILE_SUCCESS,
  payload: id,
});

export const handleDeleteProfileFail = (id: number, msg: string): actionsTypes.DeleteProfileFailAction => ({
  type: actionsTypes.DELETE_PROFILE,
  payload: { id, msg },
});
