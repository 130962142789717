import demandMainApi from 'api/demand-main-api';
import * as actionsTypes from 'features/enrich-companies/actions/enrich-companies-action-types';

import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { enrichCompanies } from '../reducers/enrich-companies-reducer';

function* createEnrichCompaniesRequest({ payload }: actionsTypes.CreateEnrichCompaniesRequestAction) {
  try {
    yield call(demandMainApi.createEnrichCompaniesRequest, payload);
    yield put(enrichCompanies.actions.createEnrichCompaniesRequestSuccess());
    toast.success(`Successfully submitted the request, you will get an email with new csv file`);
  } catch (err: any) {
    toast.error(`Error: ${err.message}`);
    yield put(enrichCompanies.actions.createEnrichCompaniesRequestFail(err?.response?.data?.message || err.message));
  }
}

export default function* saga() {
  yield takeLatest(actionsTypes.CREATE_ENRICH_COMPANIES_REQUEST, createEnrichCompaniesRequest);
}
