import * as actionsTypes from './linkedin-connections-action-types';

export const handleUploadLinkedinConnectionsRequest = (
  primaryNodeLinkedinUrl: string,
  secondaryLinkedinUrlsFile: File | undefined
): actionsTypes.UploadLinkedinConnectionsRequestAction => ({
  type: actionsTypes.UPLOAD_LINKEDIN_CONNECTIONS_REQUEST,
  primaryNodeLinkedinUrl,
  secondaryLinkedinUrlsFile,
});

export const handleUploadLinkedinConnectionsSuccess = (
  payload: string
): actionsTypes.UploadLinkedinConnectionsSuccessAction => ({
  type: actionsTypes.UPLOAD_LINKEDIN_CONNECTIONS_SUCCESS,
  payload,
});

export const handleUploadLinkedinConnectionsFail = (
  payload: string
): actionsTypes.UploadLinkedinConnectionsFailAction => ({
  type: actionsTypes.UPLOAD_LINKEDIN_CONNECTIONS_FAIL,
  payload,
});
