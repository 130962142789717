import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyPageData } from 'features/company-pages-generation/company-pages-request-model';
import { getCompanyPageUrl } from 'features/company-pages-generation/helpers';

export type CompanyPagesState = {
  loadingPages: boolean;
  error?: string;

  loadedUrl?: string;
  createdPages?: CompanyPageData[];

  creatingPage: boolean;
  createdPageUrl?: string;

  csvRequestSuccess: boolean;
  csvRequestError?: string;

  linkedInRequestSuccess: boolean;
  linkedInRequestError?: string;
  linkedInPageUrl?: string;
};

const initialState: CompanyPagesState = {
  loadingPages: false,
  error: undefined,
  loadedUrl: undefined,
  createdPages: undefined,

  creatingPage: false,
  createdPageUrl: undefined,

  csvRequestSuccess: false,
  csvRequestError: undefined,

  linkedInRequestSuccess: false,
  linkedInRequestError: undefined,
  linkedInPageUrl: undefined,
};

const companyPagesSlice = createSlice({
  name: 'companyPages',
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
    startCreatingPage: (state) => {
      state.creatingPage = true;
      state.error = undefined;
      state.createdPageUrl = undefined;
    },
    createCompanyPageSuccess: (state, action: PayloadAction<{ slug: string }>) => {
      return {
        ...state,
        error: undefined,
        loadingPages: false,
        creatingPage: false,
        loadedUrl: undefined,
        createdPageUrl: getCompanyPageUrl(action.payload.slug),
      };
    },
    createCompanyPageFail: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        error: action.payload,
        loadingPages: false,
        creatingPage: false,
      };
    },
    startLoadingPages: (state) => {
      state.loadingPages = true;
      state.error = undefined;
    },
    getCompanyPagesSuccess: (state, action: PayloadAction<{ domain: string; items: CompanyPageData[] }>) => {
      return {
        ...state,
        loadingPages: false,
        error: undefined,
        loadedUrl: action.payload.domain,
        createdPages: action.payload.items,
        createdPageUrl: undefined,
      };
    },
    getCompanyPagesFail: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        loadingPages: false,
        error: action.payload,
        loadedUrl: undefined,
      };
    },
    addNewPage: (state, action: PayloadAction<CompanyPageData>) => {
      if (state.createdPages) {
        state.createdPages.push(action.payload);
      }
    },
    getCompanyPagesCsvSuccess: (state) => {
      state.csvRequestSuccess = true;
      state.csvRequestError = undefined;
    },
    getCompanyPagesCsvFail: (state, action: PayloadAction<string>) => {
      state.csvRequestSuccess = false;
      state.csvRequestError = action.payload;
    },
    startCreatingPageFromLinkedIn: (state) => {
      state.creatingPage = true;
      state.linkedInRequestSuccess = false;
      state.linkedInRequestError = undefined;
    },
    createCompanyPageFromLinkedinSuccess: (state, action: PayloadAction<{ slug: string }>) => {
      state.creatingPage = false;
      state.linkedInRequestSuccess = true;
      state.linkedInRequestError = undefined;
      state.linkedInPageUrl = getCompanyPageUrl(action.payload.slug);
    },
    createCompanyPageFromLinkedinFail: (state, action: PayloadAction<string>) => {
      state.creatingPage = false;
      state.linkedInRequestSuccess = false;
      state.linkedInRequestError = action.payload;
    },
  },
});

export const companyPagesActions = companyPagesSlice.actions;

export const companyPagesGenerationReducer = companyPagesSlice.reducer;
