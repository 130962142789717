import * as actions from './sge-outbound-action-types';

interface OutboundRequest {
  searchTerm1: string;
  searchTerm2: string;
  linkedInCount: number;
  searchTermsFile: File | undefined;
  utmCampaign: string;
  shouldGenerateCP: boolean;
  shouldAddConnections: boolean;
}

export const handleOutboundUploadSearchTermsRequest = (
  request: OutboundRequest
): actions.OutboundUploadSearchTermsRequestAction => ({
  type: actions.UPLOAD_SEARCH_TERMS_REQUEST,
  ...request,
});

export const handleOutboundUploadSearchTermsSuccess = (
  requestId: string
): actions.OutboundUploadSearchTermsSuccessAction => ({
  type: actions.UPLOAD_SEARCH_TERMS_SUCCESS,
  requestId,
});

export const handleOutboundUploadSearchTermsFail = (): actions.OutboundUploadSearchTermsFailAction => ({
  type: actions.UPLOAD_SEARCH_TERMS_FAIL,
});
