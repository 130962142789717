import { SelectOption } from '@a_team/ui-components';
import { FormItemConfig } from 'core/components/field-type';

const disabled = (formModel: ScrubbingProfileForm) =>
  (typeof formModel.id !== 'string' || formModel.id.length === 0) && !formModel.isNew;

export const getScrubbingProfilesFormConfig = (
  scrubbingProfiles: SelectOption[],
  scoringProfiles: SelectOption[]
): FormItemConfig[] => {
  return [
    {
      field: 'standalone',
      name: 'id',
      type: 'select',
      label: 'Scrubbing Profile',
      options: scrubbingProfiles,
    },
    {
      field: 'standalone',
      name: 'name',
      type: 'text',
      label: 'Profile name',
      disabled,
    },
    {
      field: 'standalone',
      name: 'profileId',
      type: 'select',
      label: 'Scoring Profile',
      options: scoringProfiles,
      disabled,
    },
    {
      field: 'standalone',
      name: 'matchKeywords',
      type: 'text',
      label: 'Profile matching keywords',
      disabled,
    },
    {
      field: 'group',
      fields: [
        {
          prefix: 'Exceptional score from lqs: ',
          name: 'exceptionalScoreFrom',
          type: 'number',
        },
        {
          prefix: 'to lqs: ',
          name: 'exceptionalScoreTo',
          type: 'number',
        },
      ],
      disabled,
    },
    {
      field: 'group',
      fields: [
        {
          prefix: 'Very Good score from lqs: ',
          name: 'veryGoodScoreFrom',
          type: 'number',
        },
        {
          prefix: 'to lqs: ',
          name: 'veryGoodScoreTo',
          type: 'number',
        },
      ],
      disabled,
    },
    {
      field: 'group',
      fields: [
        {
          prefix: 'Not Sure score from lqs: ',
          name: 'notSureScoreFrom',
          type: 'number',
        },
        {
          prefix: 'to lqs: ',
          name: 'notSureScoreTo',
          type: 'number',
        },
      ],
      disabled,
    },
    {
      field: 'group',
      fields: [
        {
          prefix: 'Not Good score from lqs: ',
          name: 'notGoodScoreFrom',
          type: 'number',
        },
        {
          prefix: 'to lqs: ',
          name: 'notGoodScoreTo',
          type: 'number',
        },
      ],
      disabled,
    },
  ];
};

export const scrubbingProfileInitialForm = () => ({
  id: '',
  profileId: '',
  name: '',
  exceptionalScoreFrom: null,
  exceptionalScoreTo: null,
  veryGoodScoreFrom: null,
  veryGoodScoreTo: null,
  notSureScoreFrom: null,
  notSureScoreTo: null,
  notGoodScoreFrom: null,
  notGoodScoreTo: null,
  matchKeywords: null,
  isNew: false,
});

export interface ScrubbingProfileForm {
  id?: string;
  profileId: string;
  name: string;
  exceptionalScoreFrom: number | null;
  exceptionalScoreTo: number | null;
  veryGoodScoreFrom: number | null;
  veryGoodScoreTo: number | null;
  notSureScoreFrom: number | null;
  notSureScoreTo: number | null;
  notGoodScoreFrom: number | null;
  notGoodScoreTo: number | null;
  matchKeywords: string | null;
  isNew: boolean;
}
