export const DemandLocation = '/demand';
export const SupplyLocation = '/supply';
export const MonitoringLocation = '/monitoring';
export const SettingsLocation = '/settings';

export const LqsPathName = '/lqs';
export const SgePathName = '/sge';
export const ScrubbingPathName = '/scrubbing';
export const ScsPathName = '/scs';
export const IntroSearchPathName = '/intro-search';

export const FacebookFriendsPathName = '/facebook-friends';
export const LinkedinConnectionsPathName = '/linkedin-connections';
export const TargetedLeadsPathName = '/targeted-leads';
export const WarmLeadsPathName = '/warm-leads';
export const WarmLeadsUtilsPathName = '/warm-leads-utils';
export const OutboundPathName = '/outbound';
export const DqsPathName = '/dqs';
export const CompanyPagesPathName = '/company-pages';
export const LinkedInJobPostingsPathName = '/linkedin-job-postings';
export const ColdIdeasPathName = '/cold-ideas';
export const EnrichCompaniesPathName = '/enrich-companies';

export const SupplyRequestsPathName = '/supply-requests';
export const WaitlistedRolesPathName = '/waitlisted-roles';

export const LqsLocation = `${SupplyLocation}${LqsPathName}`;

export const SgeLocation = `${SupplyLocation}${SgePathName}`;

export const FacebookFriendsLocation = `${DemandLocation}${FacebookFriendsPathName}`;
export const LinkedinConnectionsLocation = `${DemandLocation}${LinkedinConnectionsPathName}`;
export const TargetedLeadsLocation = `${DemandLocation}${TargetedLeadsPathName}`;
export const WarmLeadsLocation = `${DemandLocation}${WarmLeadsPathName}`;
export const WarmLeadsUtilsLocation = `${DemandLocation}${WarmLeadsUtilsPathName}`;
export const OutboundLocation = `${DemandLocation}${OutboundPathName}`;
export const LinkedInJobPostingsLocation = `${DemandLocation}${LinkedInJobPostingsPathName}`;
export const ColdIdeasLocation = `${DemandLocation}${ColdIdeasPathName}`;
export const ScsLocation = `${DemandLocation}${ScsPathName}`;
export const EnrichCompaniesLocation = `${DemandLocation}${EnrichCompaniesPathName}`;

export const SigninLocation = '/auth/signin';
export const HomePageLocation = '/home';

export const ScrubbingLocation = `${SettingsLocation}${SupplyLocation}${ScrubbingPathName}`;
export const WaitlistedRolesLocation = `${SettingsLocation}${SupplyLocation}${WaitlistedRolesPathName}`;
export const LqsSettingsLocation = `${SettingsLocation}${LqsLocation}`;
export const DqsLocation = `${SettingsLocation}${DemandLocation}${DqsPathName}`;
export const ScsSettingsLocation = `${SettingsLocation}${DemandLocation}${ScsPathName}`;
export const CompanyPagesSettingsLocation = `${SettingsLocation}${DemandLocation}${CompanyPagesPathName}`;
export const IntroSearchSettingsLocation = `${SettingsLocation}${IntroSearchPathName}`;

export const SupplyRequestsLocation = `${MonitoringLocation}${SupplyRequestsPathName}`;
export const IntroSearchMonitoringLocation = `${MonitoringLocation}${IntroSearchPathName}`;

export const CompanyPagesGenerationLocation = '/company-pages';
export const TeamRequestPagesLocation = '/team-request-pages';
export const SocialConnectionsScoreLocation = '/social-connections-score';
export const IntroSearchLocation = '/intro-search';
export const IntroSearchResultsLocation = '/intro-search-results';
export const IntroSearchFAQLocation = '/intro-search-faq';
