import React from 'react';
import { useFormik } from 'formik';
import { Button, Checkbox, Input, Row, Typography } from '@a_team/ui-components';
import { ScoringProfileRequestModel } from 'features/scs/models/scoring-profile';

export const ScoringProfileForm: React.FC<{
  initialValues: ScoringProfileRequestModel;
  submitting: boolean;
  onSubmit: (values: ScoringProfileRequestModel) => void;
}> = ({ initialValues, onSubmit, submitting }) => {
  const formik = useFormik<ScoringProfileRequestModel>({
    initialValues,
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        label="Profile name"
        name="name"
        onChange={formik.handleChange}
        value={formik.values.name}
        size="stretch"
        required
      />
      <Typography variant="h3">Score for work experience</Typography>
      <Row align="center" noGutters style={{ gap: 5 }}>
        If both builders started in the first{' '}
        <Input
          type="number"
          name="expEarlyStageYearsSinceFounding"
          onChange={formik.handleChange}
          value={formik.values.expEarlyStageYearsSinceFounding}
        />{' '}
        years from company founding
      </Row>
      <ul>
        <li>
          <Row align="center" noGutters style={{ gap: 10 }}>
            If there is at least{' '}
            <Input
              type="number"
              name="expEarlyStageMinOverlapMonths"
              onChange={formik.handleChange}
              value={formik.values.expEarlyStageMinOverlapMonths}
            />{' '}
            months overlap with any companyName, give{' '}
            <Input
              type="number"
              name="expEarlyStageMinOverlapPoints"
              onChange={formik.handleChange}
              value={formik.values.expEarlyStageMinOverlapPoints}
              step="0.01"
            />{' '}
            points
          </Row>
        </li>
        <ul>
          <li>
            <Row align="center" noGutters style={{ gap: 10 }}>
              <Checkbox
                onChange={(e) => formik.setFieldValue('expEarlyStageCommonLocationIsRequired', e.target.checked)}
                checked={formik.values.expEarlyStageCommonLocationIsRequired}
              ></Checkbox>
              If overlap is true and location is the same, give{' '}
              <Input
                type="number"
                name="expEarlyStageCommonLocationPoints"
                onChange={formik.handleChange}
                value={formik.values.expEarlyStageCommonLocationPoints}
                step="0.01"
              />
              points
            </Row>
          </li>
          <li>
            <Row align="center" noGutters style={{ gap: 10 }}>
              <Checkbox
                onChange={(e) => formik.setFieldValue('expEarlyStageMinStartDateDistanceIsRequired', e.target.checked)}
                checked={formik.values.expEarlyStageMinStartDateDistanceIsRequired}
              ></Checkbox>
              If overlap is true and starting date of that experience between the two people is smaller than{' '}
              <Input
                type="number"
                name="expEarlyStageMinStartDateDistanceMonths"
                onChange={formik.handleChange}
                value={formik.values.expEarlyStageMinStartDateDistanceMonths}
              />{' '}
              months, give{' '}
              <Input
                type="number"
                name="expEarlyStageMinStartDateDistancePoints"
                onChange={formik.handleChange}
                value={formik.values.expEarlyStageMinStartDateDistancePoints}
                step="0.01"
              />
              points
            </Row>
          </li>
          <li>
            <Row align="center" noGutters style={{ gap: 10 }}>
              <Checkbox
                onChange={(e) => formik.setFieldValue('expEarlyStagePositionNameIsRequired', e.target.checked)}
                checked={formik.values.expEarlyStagePositionNameIsRequired}
              ></Checkbox>
              If overlap is true and positionName is identical, give{' '}
              <Input
                type="number"
                name="expEarlyStageIdenticalPositionNamePoints"
                onChange={formik.handleChange}
                value={formik.values.expEarlyStageIdenticalPositionNamePoints}
                step="0.01"
              />{' '}
              points. Otherwise, if overlap is true and positionNames are included in the same group, give{' '}
              <Input
                type="number"
                name="expEarlyStageSamePositionGroupPoints"
                onChange={formik.handleChange}
                value={formik.values.expEarlyStageSamePositionGroupPoints}
                step="0.01"
              />{' '}
              points
            </Row>
          </li>
        </ul>
      </ul>
      <Row align="center" noGutters style={{ gap: 5 }}>
        If any of the builders started after the first {formik.values.expEarlyStageYearsSinceFounding} years from
        company founding
      </Row>
      <ul>
        <li>
          <Row align="center" noGutters style={{ gap: 10 }}>
            If there is at least{' '}
            <Input
              type="number"
              name="expEstablishedMinOverlapMonths"
              onChange={formik.handleChange}
              value={formik.values.expEstablishedMinOverlapMonths}
            />{' '}
            months overlap with any companyName, give{' '}
            <Input
              type="number"
              name="expEstablishedMinOverlapPoints"
              onChange={formik.handleChange}
              value={formik.values.expEstablishedMinOverlapPoints}
              step="0.01"
            />{' '}
            points
          </Row>
        </li>
        <ul>
          <li>
            <Row align="center" noGutters style={{ gap: 10 }}>
              <Checkbox
                onChange={(e) => formik.setFieldValue('expEstablishedCommonLocationIsRequired', e.target.checked)}
                checked={formik.values.expEstablishedCommonLocationIsRequired}
              ></Checkbox>
              If overlap is true and location is the same, give{' '}
              <Input
                type="number"
                name="expEstablishedCommonLocationPoints"
                onChange={formik.handleChange}
                value={formik.values.expEstablishedCommonLocationPoints}
                step="0.01"
              />
              points
            </Row>
          </li>
          <li>
            <Row align="center" noGutters style={{ gap: 10 }}>
              <Checkbox
                onChange={(e) => formik.setFieldValue('expEstablishedMinStartDateDistanceIsRequired', e.target.checked)}
                checked={formik.values.expEstablishedMinStartDateDistanceIsRequired}
              ></Checkbox>
              If overlap is true and starting date of that experience between the two people is smaller than{' '}
              <Input
                type="number"
                name="expEstablishedMinStartDateDistanceMonths"
                onChange={formik.handleChange}
                value={formik.values.expEstablishedMinStartDateDistanceMonths}
              />{' '}
              months, give{' '}
              <Input
                type="number"
                name="expEstablishedMinStartDateDistancePoints"
                onChange={formik.handleChange}
                value={formik.values.expEstablishedMinStartDateDistancePoints}
                step="0.01"
              />
              points
            </Row>
          </li>
          <li>
            <Row align="center" noGutters style={{ gap: 10 }}>
              <Checkbox
                onChange={(e) => formik.setFieldValue('expEstablishedPositionNameIsRequired', e.target.checked)}
                checked={formik.values.expEstablishedPositionNameIsRequired}
              ></Checkbox>
              If overlap is true and positionName is identical, give{' '}
              <Input
                type="number"
                name="expEstablishedIdenticalPositionNamePoints"
                onChange={formik.handleChange}
                value={formik.values.expEstablishedIdenticalPositionNamePoints}
                step="0.01"
              />{' '}
              points. Otherwise, if overlap is true and positionNames are included in the same group, give{' '}
              <Input
                type="number"
                name="expEstablishedSamePositionGroupPoints"
                onChange={formik.handleChange}
                value={formik.values.expEstablishedSamePositionGroupPoints}
                step="0.01"
              />{' '}
              points
            </Row>
          </li>
        </ul>
      </ul>
      <hr />
      <ul>
        <li>
          <Row align="center" noGutters style={{ gap: 10 }}>
            We should include negative keywords{' '}
            <Input
              type="text"
              name="expExcludedCompanyNames"
              onChange={formik.handleChange}
              value={formik.values.expExcludedCompanyNames}
            />{' '}
            for companyName. Those companies will be excluded from scoring.
          </Row>
        </li>
      </ul>
      <Button loading={submitting}>Save</Button>
    </form>
  );
};
