import { CreateColdIdeasRequestModel } from 'features/cold-ideas/cold-ideas-request-model';

export const CREATE_COLD_IDEAS_REQUEST = 'cold-ideas/CREATE_COLD_IDEAS_REQUEST';
export interface CreateColdIdeasRequestAction {
  type: typeof CREATE_COLD_IDEAS_REQUEST;
  payload: CreateColdIdeasRequestModel;
}

export const CREATE_COLD_IDEAS_REQUEST_SUCCESS = 'cold-ideas/CREATE_COLD_IDEAS_REQUEST_SUCCESS';
export interface CreateColdIdeasRequestSuccessAction {
  type: typeof CREATE_COLD_IDEAS_REQUEST_SUCCESS;
}

export const CREATE_COLD_IDEAS_REQUEST_FAIL = 'cold-ideas/CREATE_COLD_IDEAS_REQUEST_FAIL';
export interface CreateColdIdeasRequestFailAction {
  type: typeof CREATE_COLD_IDEAS_REQUEST_FAIL;
  payload: string;
}

export type ColdIdeasActionType =
  | CreateColdIdeasRequestAction
  | CreateColdIdeasRequestSuccessAction
  | CreateColdIdeasRequestFailAction;
