import { Button, Typography } from '@a_team/ui-components';
import ErrorMessageDisplay from 'core/components/error-message-display';
import FieldGenerator from 'core/components/field-generator';
import { FormItemConfig } from 'core/components/field-type';
import { RootState } from 'core/store';
import { displayValueToMillions } from 'core/utils/convert';
import { handleScoreRequest } from 'features/dqs/actions/testbed-actions';
import { useFormik } from 'formik';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

const useStyles = createUseStyles({
  fields: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    columnGap: '24px',
  },
  tagContainer: {
    margin: '8px',
  },
});

const FORM_CONFIG: FormItemConfig[] = [
  {
    field: 'standalone',
    name: 'companyDomain',
    type: 'text',
    label: 'Domain',
  },
  {
    field: 'standalone',
    name: 'companyName',
    type: 'text',
    label: 'Name (optional)',
  },
  {
    field: 'standalone',
    name: 'industry',
    type: 'text',
    label: 'Industry (optional)',
  },
  {
    field: 'standalone',
    name: 'totalMoneyRaisedAmount',
    type: 'number',
    label: 'Millions Raised',
  },
  {
    field: 'standalone',
    name: 'numberOfEmployees',
    type: 'number',
    label: 'No of employees',
  },
  {
    field: 'standalone',
    name: 'companyCountryISO2',
    type: 'text',
    label: `Country (optional, 2-char code)`,
  },
];

export const DqsTestbedForm = () => {
  const { fields, tagContainer } = useStyles();
  const dispatch = useDispatch();
  const { loading, errorMsg, result } = useSelector((state: RootState) => state.dqsTestbedReducers);
  const formik = useFormik({
    initialValues: {
      companyDomain: 'a.team',
      totalMoneyRaisedAmount: 3,
      numberOfEmployees: 100,
      companyCountryISO2: 'US',
    },
    validationSchema: Yup.object().shape({
      companyDomain: Yup.string().required('Required'),
      totalMoneyRaisedAmount: Yup.number().required('Required'),
      numberOfEmployees: Yup.number().required('Required'),
      companyCountryISO2: Yup.string(),
    }),
    onSubmit: (values) => {
      dispatch(
        handleScoreRequest({
          ...values,
          totalMoneyRaisedAmount: displayValueToMillions(values.totalMoneyRaisedAmount),
        })
      );
    },
  });

  const resultText = result
    ? `Result: ${result.reason}${
        result.matchedProfileId ? ` with profile "${result.matchedProfileName}" (id: ${result.matchedProfileId})` : ''
      }. Decision: ${result.decision}. Hubspot: ${result.hubspotCalendarUrl}`
    : '';

  return (
    <>
      <Typography variant="h3">Testbed</Typography>
      <form onSubmit={formik.handleSubmit}>
        <div className={fields}>
          <FieldGenerator formik={formik} tagContainer={tagContainer} fieldConfig={FORM_CONFIG} />
        </div>
        <p>
          <Button size="sm" loading={loading}>
            Submit
          </Button>
        </p>
      </form>
      {!!errorMsg && <ErrorMessageDisplay msg={`Error: ${errorMsg}`} />}
      {resultText && (
        <Typography variant="textMedium" weight="bold">
          {resultText}
        </Typography>
      )}
    </>
  );
};
