import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type State = {
  uploadSearchTermsInProgress: boolean;
  requestId: string;
};

export const initialState: State = {
  uploadSearchTermsInProgress: false,
  requestId: '',
};

export const sgeOutbound = createSlice({
  name: 'sgeOutbound',
  initialState,
  reducers: {
    uploadSearchTermsRequest: (state) => {
      state.uploadSearchTermsInProgress = true;
    },
    uploadSearchTermsSuccess: (state, action: PayloadAction<string>) => {
      state.uploadSearchTermsInProgress = false;
      state.requestId = action.payload;
    },
    uploadSearchTermsFail: (state) => {
      state.uploadSearchTermsInProgress = false;
    },
  },
});

export const { uploadSearchTermsRequest, uploadSearchTermsSuccess, uploadSearchTermsFail } = sgeOutbound.actions;

export default sgeOutbound.reducer;
