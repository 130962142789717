import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DqsProfile } from 'api/dqs-api';

export type IndividualProfileState = {
  profile: DqsProfile;
  editing: boolean;
  saving: boolean;
  saveErrorMsg: string;
  deleting: boolean;
  deleteErrorMsg: string;
  movingUp: boolean;
  movingDown: boolean;
  moveErrorMessage: string;
};

export type DqsProfileState = {
  loading: boolean;
  errorMsg: string;
  creating: boolean;
  createErrorMsg: string;
  profileStates: IndividualProfileState[];
};

export const initialState = (): DqsProfileState => {
  return {
    loading: false,
    errorMsg: '',
    creating: false,
    createErrorMsg: '',
    profileStates: [],
  };
};

/**
 * Creates a new individual state entry for a given profile.
 * The state keeps track of what is happening with a single profile entry in the list.
 * @param {DqsProfile} profile
 * @returns {IndividualProfileState} A newly-initialised state object for the given profile
 */
const createNewIndividualEntry = (profile: DqsProfile): IndividualProfileState => ({
  profile,
  editing: false,
  saving: false,
  saveErrorMsg: '',
  deleting: false,
  deleteErrorMsg: '',
  movingUp: false,
  movingDown: false,
  moveErrorMessage: '',
});

/**
 * Recreates the list of individual profile states, with an updated state object for a particular profile
 * @param {IndividualProfileState[]} all All existing profile states
 * @param {IndividualProfileState} updated The updated profile state
 * @returns The new list, with the updated state replacing the old one.
const insertUpdatedState = (all: IndividualProfileState[], updated: IndividualProfileState) =>
all.map((el) => (el.profile.id === updated.profile.id ? updated : el));
*/

/**
 * Removes an individual item from the list of profiles.
 * @param  {IndividualProfileState[]} all All existing profile states
 * @param  {number} idToRemove
 * @returns The new list, with the element removed.
 */
const removeProfile = (all: IndividualProfileState[], idToRemove: number) =>
  all.filter((el) => el.profile.id !== idToRemove);

/**
 * Returns the individual profile state for a given id
 * @param {IndividualProfileState[]} all All existing profile states
 * @param {number} id
 * @returns The individual profile state for the given id.
 * @throws If no entry can be found for this id
 */
const getIndividualEntryForProfileId = (all: IndividualProfileState[], id: number): IndividualProfileState => {
  const entry = all.find((el) => el.profile.id === id);
  if (!entry) {
    throw new Error(`Failed to find individual profile state for id ${id}`);
  }
  return entry;
};

export const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    loadProfilesRequest: (state) => {
      state.profileStates = [];
      state.loading = true;
      state.errorMsg = '';
    },
    loadProfilesSuccess: (state, action: PayloadAction<DqsProfile[]>) => {
      state.loading = false;
      state.errorMsg = '';
      state.profileStates = action.payload.map(createNewIndividualEntry);
    },
    loadProfilesFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMsg = action.payload;
    },
    createProfile: (state) => {
      state.creating = true;
      state.createErrorMsg = '';
    },
    createProfileSuccess: (state, action: PayloadAction<DqsProfile>) => {
      state.profileStates.push(createNewIndividualEntry(action.payload));
      state.creating = false;
      state.createErrorMsg = '';
    },
    createProfileFail: (state, action: PayloadAction<string>) => {
      state.creating = false;
      state.createErrorMsg = action.payload;
    },
    createProfileClearError: (state) => {
      state.createErrorMsg = '';
    },
    startEditProfile: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      const profileState = getIndividualEntryForProfileId(state.profileStates, id);
      profileState.editing = true;
    },
    stopEditProfile: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      const profileState = getIndividualEntryForProfileId(state.profileStates, id);
      profileState.editing = false;
      profileState.saveErrorMsg = '';
    },
    saveProfile: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      const profileState = getIndividualEntryForProfileId(state.profileStates, id);
      profileState.saving = true;
      profileState.editing = false;
      profileState.saveErrorMsg = '';
    },
    saveProfileSuccess: (state, action: PayloadAction<DqsProfile>) => {
      const updatedProfile = action.payload;
      const profileState = getIndividualEntryForProfileId(state.profileStates, updatedProfile.id);
      profileState.saving = false;
      profileState.editing = false;
      profileState.saveErrorMsg = '';
      profileState.profile = updatedProfile;
    },
    saveProfileFail: (state, action: PayloadAction<{ id: number; msg: string }>) => {
      const { id, msg } = action.payload;
      const profileState = getIndividualEntryForProfileId(state.profileStates, id);
      profileState.saving = false;
      profileState.editing = true;
      profileState.saveErrorMsg = msg;
    },
    moveProfileDown: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      const profileState = getIndividualEntryForProfileId(state.profileStates, id);
      profileState.movingDown = true;
      profileState.moveErrorMessage = '';
    },
    moveProfileDownSuccess: (state, action: PayloadAction<DqsProfile[]>) => {
      state.profileStates = action.payload.map(createNewIndividualEntry);
    },
    moveProfileDownFail: (state, action: PayloadAction<{ id: number; msg: string }>) => {
      const { id, msg } = action.payload;
      const profileState = getIndividualEntryForProfileId(state.profileStates, id);
      profileState.movingDown = false;
      profileState.moveErrorMessage = msg;
    },
    moveProfileUp: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      const profileState = getIndividualEntryForProfileId(state.profileStates, id);
      profileState.movingUp = true;
      profileState.moveErrorMessage = '';
    },
    moveProfileUpSuccess: (state, action: PayloadAction<DqsProfile[]>) => {
      state.profileStates = action.payload.map(createNewIndividualEntry);
    },
    moveProfileUpFail: (state, action: PayloadAction<{ id: number; msg: string }>) => {
      const { id, msg } = action.payload;
      const profileState = getIndividualEntryForProfileId(state.profileStates, id);
      profileState.movingUp = false;
      profileState.moveErrorMessage = msg;
    },
    deleteProfile: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      const profileState = getIndividualEntryForProfileId(state.profileStates, id);
      profileState.deleting = true;
      profileState.deleteErrorMsg = '';
    },
    deleteProfileSuccess: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      state.profileStates = removeProfile(state.profileStates, id);
    },
    deleteProfileFail: (state, action: PayloadAction<{ id: number; msg: string }>) => {
      const { id, msg } = action.payload;
      const profileState = getIndividualEntryForProfileId(state.profileStates, id);
      profileState.deleting = false;
      profileState.deleteErrorMsg = msg;
    },
  },
});

export const {
  loadProfilesRequest,
  loadProfilesSuccess,
  loadProfilesFail,
  createProfile,
  createProfileSuccess,
  createProfileFail,
  createProfileClearError,
  startEditProfile,
  stopEditProfile,
  saveProfile,
  saveProfileSuccess,
  saveProfileFail,
  moveProfileDown,
  moveProfileDownSuccess,
  moveProfileDownFail,
  moveProfileUp,
  moveProfileUpSuccess,
  moveProfileUpFail,
  deleteProfile,
  deleteProfileSuccess,
  deleteProfileFail,
} = profile.actions;

export default profile.reducer;
