import { TargetedLeadsRequest } from 'features/targeted-leads/models/targeted-leads-reqeust';

export const UPLOAD_TAGGED_PROFILES_REQUEST = 'targeted-leads/UPLOAD_TAGGED_PROFILES_REQUEST';

export interface UploadTaggedProfilesRequestAction {
  type: typeof UPLOAD_TAGGED_PROFILES_REQUEST;
  taggedProfilesFile: File | undefined;
}

export const UPLOAD_TAGGED_PROFILES_SUCCESS = 'targeted-leads/UPLOAD_TAGGED_PROFILES_SUCCESS';
export interface UploadTaggedProfilesSuccessAction {
  type: typeof UPLOAD_TAGGED_PROFILES_SUCCESS;
}

export const UPLOAD_TAGGED_PROFILES_FAIL = 'targeted-leads/UPLOAD_TAGGED_PROFILES_FAIL';
export interface UploadTaggedProfilesFailAction {
  type: typeof UPLOAD_TAGGED_PROFILES_FAIL;
  payload: string;
}

export const REQUEST_TARGETED_LEADS_REQUEST = 'targeted-leads/REQUEST_TARGETED_LEADS_REQUEST';

export interface RequestTargetedLeadsRequestAction {
  type: typeof REQUEST_TARGETED_LEADS_REQUEST;
  payload: TargetedLeadsRequest;
}

export const REQUEST_TARGETED_LEADS_SUCCESS = 'targeted-leads/REQUEST_TARGETED_LEADS_SUCCESS';
export interface RequestTargetedLeadsSuccessAction {
  type: typeof REQUEST_TARGETED_LEADS_SUCCESS;
}

export const REQUEST_TARGETED_LEADS_FAIL = 'targeted-leads/REQUEST_TARGETED_LEADS_FAIL';
export interface RequestTargetedLeadsFailAction {
  type: typeof REQUEST_TARGETED_LEADS_FAIL;
  payload: string;
}

export type SgeOutboundAction =
  | UploadTaggedProfilesRequestAction
  | UploadTaggedProfilesSuccessAction
  | UploadTaggedProfilesFailAction
  | RequestTargetedLeadsRequestAction
  | RequestTargetedLeadsSuccessAction
  | RequestTargetedLeadsFailAction;
