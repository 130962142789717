import ScsApi from 'api/scs-api';
import * as actions_types from 'features/scs/actions/scoring-profile-action-types';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { scsScoringProfile } from '../reducers/scoring-profile-reducers';

function* getScoringProfile() {
  try {
    const res = yield call(ScsApi.getScoringProfile);

    yield put(scsScoringProfile.actions.getScoringProfileSuccess(res.data && res.data.length ? res.data[0] : {}));
  } catch (err: any) {
    toast.error(`Error fetching scoring profile: ${err.message}`);
    yield put(scsScoringProfile.actions.getScoringProfileFail());
  }
}

function* saveScoringProfile({ data, id }: actions_types.SaveScoringProfileRequestAction) {
  try {
    let res;
    if (id) {
      res = yield call(ScsApi.updateScoringProfile, id, data);
    } else {
      res = yield call(ScsApi.createScoringProfile, data);
    }

    yield put(scsScoringProfile.actions.saveScoringProfileSuccess(res.data));
    toast.success('Successfully saved scoring profile');
  } catch (err: any) {
    toast.error(`Error saving scoring profile: ${err.message}`);
    yield put(scsScoringProfile.actions.saveScoringProfileFail());
  }
}

function* savePositionGroup({ data, profileId, id }: actions_types.SavePositionGroupRequestAction) {
  try {
    if (id) {
      yield call(ScsApi.updatePositionGroup, id, profileId, data);
    } else {
      yield call(ScsApi.createPositionGroup, profileId, data);
    }
    yield call(getScoringProfile);
    toast.success('Position group saved successfully');
  } catch (err: any) {
    toast.error(`Error saving position group: ${err.message}`);
    yield put(scsScoringProfile.actions.getScoringProfileFail());
  }
}

function* deletePositionGroup({ id, profileId }: actions_types.DeletePositionGroupRequestAction) {
  try {
    yield call(ScsApi.deletePositionGroup, id, profileId);
    yield call(getScoringProfile);
    toast.success('Position group deleted successfully');
  } catch (err: any) {
    toast.error(`Error deleting position group: ${err.message}`);
    yield put(scsScoringProfile.actions.getScoringProfileFail());
  }
}

function* saveRoleGroup({ data, profileId }: actions_types.SaveRoleGroupRequestAction) {
  try {
    if (data.id) {
      yield call(ScsApi.updateRoleGroup, data.id, profileId, data);
    } else {
      yield call(ScsApi.createRoleGroup, profileId, data);
    }
    yield call(getScoringProfile);
    toast.success('Role group saved successfully');
  } catch (err: any) {
    toast.error(`Error saving role group: ${err.message}`);
    yield put(scsScoringProfile.actions.getScoringProfileFail());
  }
}

function* deleteRoleGroup({ id, profileId }: actions_types.DeleteRoleGroupRequestAction) {
  try {
    yield call(ScsApi.deleteRoleGroup, id, profileId);
    yield call(getScoringProfile);
    toast.success('Role group deleted successfully');
  } catch (err: any) {
    toast.error(`Error deleting role group: ${err.message}`);
    yield put(scsScoringProfile.actions.getScoringProfileFail());
  }
}

function* saveDegreeGroups({ data, profileId }: actions_types.SaveDegreeGroupsRequestAction) {
  try {
    const dataWithOrder = data.map((item, index) => ({ ...item, orderNumber: index }));
    yield call(ScsApi.updateDegreeGroups, profileId, dataWithOrder);
    yield call(getScoringProfile);
    toast.success('Degree group saved successfully');
  } catch (err: any) {
    toast.error(`Error saving degree group: ${err.message}`);
    yield put(scsScoringProfile.actions.getScoringProfileFail());
  }
}

export default function* saga() {
  yield takeLatest(actions_types.GET_SCORING_PROFILE_REQUEST, getScoringProfile);
  yield takeLatest(actions_types.SAVE_SCORING_PROFILE_REQUEST, saveScoringProfile);
  yield takeLatest(actions_types.SAVE_POSITION_GROUP_REQUEST, savePositionGroup);
  yield takeLatest(actions_types.DELETE_POSITION_GROUP_REQUEST, deletePositionGroup);
  yield takeLatest(actions_types.SAVE_ROLE_GROUP_REQUEST, saveRoleGroup);
  yield takeLatest(actions_types.DELETE_ROLE_GROUP_REQUEST, deleteRoleGroup);
  yield takeLatest(actions_types.SAVE_DEGREE_GROUPS_REQUEST, saveDegreeGroups);
}
