import SupplyRequestsApi from 'api/supply-requests-api';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions_types from '../actions/supply-requests-actions-types';
import { SupplyRequestSource } from '../models/supply-request';
import { supplyRequests } from '../reducers/supply-requests-reducers';

function* requestSupplyRequests({ query }: actions_types.FetchSupplyRequestsRequestAction) {
  try {
    const res = yield call(SupplyRequestsApi.getSupplyRequests, query || {});

    yield put(supplyRequests.actions.fetchSupplyRequestsSuccess(res.data));
  } catch (err: any) {
    toast.error(`Error fetching supply requests: ${err.message}`);
    yield put(supplyRequests.actions.fetchSupplyRequestsFail());
  }
}

function* forceCloseSupplyRequest({ requestId, source }: actions_types.ForceCloseSupplyRequestsRequestAction) {
  try {
    switch (source) {
      case SupplyRequestSource.Main: {
        yield call(SupplyRequestsApi.forceCloseSupplyRequest, requestId);
        yield put(supplyRequests.actions.forceCloseSupplyRequestsSuccess(requestId));
        break;
      }
    }
    toast.success(`Request ${requestId} successfully closed`);
  } catch (err: any) {
    toast.error(`Error closing request: ${err.message}`);
    yield put(supplyRequests.actions.forceCloseSupplyRequestsFail(requestId));
  }
}

export default function* saga() {
  yield takeLatest(actions_types.FETCH_SUPPLY_REQUESTS_REQUEST, requestSupplyRequests);
  yield takeLatest(actions_types.FORCE_CLOSE_SUPPLY_REQUESTS_REQUEST, forceCloseSupplyRequest);
}
