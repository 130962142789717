import * as ScrubbingApi from 'api/scrubbing-api';
import * as actionTypes from 'features/scrubbing/actions/scrubbing-action-types';
import * as uuid from 'uuid';

import store from 'core/store';
import { getCronFromHours, getHoursFromCron } from 'core/utils/api';
import { find } from 'lodash';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ScrubbingProfileForm } from '../forms/scrubbing-profiles-form-config';
import { ScrubbingProfilesGetResponseItem } from '../models/scrubbing-profiles-get-response';
import { ScrubbingSettingsGetResponse, ScrubbingSettingsModel } from '../models/scrubbing-settings-get-response';
import { scrubbing } from '../reducers/scrubbing-reducer';

function* requestSettings() {
  try {
    const res: { data: ScrubbingSettingsGetResponse } = yield call(ScrubbingApi.getSettings);
    const model: ScrubbingSettingsModel = { ...res.data, intervalHours: getHoursFromCron(res.data.cron) };

    yield put(scrubbing.actions.scrubbingSettingsGetSuccess(model));
  } catch (err: any) {
    toast.error(`Error fetching settings: ${err.message}`);
    yield put(scrubbing.actions.scrubbingSettingsGetFail());
  }
}

function* updateSettings({ payload }: actionTypes.ScrubbingSettingsUpdateRequestAction) {
  try {
    const requestId = uuid.v4().toString();
    const id = store.getState().scrubbing.settings.id;
    if (typeof id != 'string') {
      throw new Error();
    }
    const mappedPayload = { ...payload, cron: getCronFromHours(payload.intervalHours) };

    yield call(ScrubbingApi.putSettings, requestId, id, mappedPayload);
    yield put(scrubbing.actions.scrubbingSettingsUpdateSuccess());
    toast.success('Settings updated successfully');
  } catch (err: any) {
    toast.error(`Error updating settings: ${err.message}`);
    yield put(scrubbing.actions.scrubbingSettingsUpdateFail());
  }
}

function* requestProfiles() {
  try {
    const res: { data: ScrubbingProfilesGetResponseItem[] } = yield call(ScrubbingApi.getProfiles);
    yield put(scrubbing.actions.scrubbingProfilesGetSuccess(res.data));
  } catch (err: any) {
    toast.error(`Error fetching profiles: ${err.message}`);
    yield put(scrubbing.actions.scrubbingProfilesGetFail());
  }
}

function* upsertProfile({ payload }: actionTypes.ScrubbingProfileUpsertRequestAction) {
  try {
    const requestId = uuid.v4().toString();
    if (payload.isNew) {
      yield call(ScrubbingApi.postProfile, requestId, payload);
    } else if (typeof payload.id === 'string') {
      yield call(ScrubbingApi.putProfile, requestId, payload.id, payload);
    } else {
      throw new Error('[upsert scrubbing profile]Id is not a string and the profile is not new!');
    }

    yield put(scrubbing.actions.scrubbingProfileUpsertSuccess());
    yield put(scrubbing.actions.scrubbingProfilesGetRequest());
    toast.success('Profile saved successfully');
  } catch (err: any) {
    toast.error(`Error saving profile: ${err.message}`);
    yield put(scrubbing.actions.scrubbingProfileUpsertFail());
  }
}

function* setCurrentProfile({ profileId }: actionTypes.SetCurrentScrubbingProfileRequestAction) {
  const profiles = store.getState().scrubbing.profiles;
  const currentProfile: ScrubbingProfileForm = yield call(
    find,
    profiles,
    (profile: ScrubbingProfilesGetResponseItem) => profile.id === profileId
  );
  if (typeof currentProfile === 'object') {
    yield put(scrubbing.actions.setCurrentScrubbingProfileSuccess(currentProfile));
  }
}

function* deleteProfile({ profileId }: actionTypes.DeleteScrubbingProfileRequestAction) {
  const profiles = store.getState().scrubbing.profiles;
  const currentProfile: ScrubbingProfileForm = yield call(
    find,
    profiles,
    (profile: ScrubbingProfilesGetResponseItem) => profile.id === profileId
  );

  try {
    const requestId = uuid.v4().toString();
    yield call(ScrubbingApi.deleteProfile, requestId, currentProfile.id as string);

    yield put(scrubbing.actions.deleteScrubbingProfileSuccess());
    yield put(scrubbing.actions.scrubbingProfilesGetRequest());
    toast.success('Successfully deleted profile');
  } catch (err: any) {
    toast.error(`Error deleting profile: ${err.message}`);
    yield put(scrubbing.actions.deleteScrubbingProfileFail());
  }
}

export default function* saga() {
  yield takeLatest(actionTypes.SCRUBBING_SETTINGS_GET_REQUEST, requestSettings);
  yield takeLatest(actionTypes.SCRUBBING_SETTINGS_UPDATE_REQUEST, updateSettings);
  yield takeLatest(actionTypes.SCRUBBING_PROFILES_GET_REQUEST, requestProfiles);
  yield takeLatest(actionTypes.SCRUBBING_PROFILE_UPSERT_REQUEST, upsertProfile);
  yield takeLatest(actionTypes.SET_CURRENT_SCRUBBING_PROFILE_REQUEST, setCurrentProfile);
  yield takeLatest(actionTypes.DELETE_SCRUBBING_PROFILE_REQUEST, deleteProfile);
}
