import { Button } from '@a_team/ui-components';
import { DqsProfileBase } from 'api/dqs-api';
import { RootState } from 'core/store';
import { handleCreateProfile, handleCreateProfileClearError } from 'features/dqs/actions/profiles-actions';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DqsProfileForm } from './dqs-profile-form';

export const DqsProfileCreateRow = () => {
  const { creating, createErrorMsg } = useSelector((state: RootState) => state.dqsProfileReducers);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const dispatch = useDispatch();

  const handleSave = useCallback(
    (values: DqsProfileBase) => {
      dispatch(handleCreateProfile(values));
      setIsFormOpen(false);
    },
    [dispatch]
  );

  const handleCancel = useCallback(() => {
    setIsFormOpen(false);
    dispatch(handleCreateProfileClearError());
  }, [dispatch]);

  const showForm = isFormOpen || creating || !!createErrorMsg;

  return (
    <>
      <Button size="sm" onClick={() => setIsFormOpen(true)} disabled={showForm} loading={creating}>
        Add New Profile
      </Button>
      {showForm && (
        <DqsProfileForm
          title={`New Profile`}
          saving={creating}
          saveErrorMsg={createErrorMsg}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};
