import ScsApi from 'api/scs-api';
import * as actions_types from 'features/scs/actions/scoring-request-action-types';
import * as actions from 'features/scs/actions/scoring-request-actions';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { scoringRequest } from '../reducers/scoring-request-reducers';

function* postScoringRequest({ data }: actions_types.PostScoringRequestAction) {
  try {
    if (data.csv) {
      yield call(ScsApi.postScoringMultiRequest, data);

      yield put(scoringRequest.actions.postScoringRequestSuccess());
    } else {
      yield call(ScsApi.postScoringSingleRequest, data);

      yield put(scoringRequest.actions.postScoringRequestFail());
    }
    toast.success('Request submitted successfully');
  } catch (err: any) {
    toast.error(`Error submitting request: ${err.message}`);
    yield put(actions.handlePostScoringRequestFail());
  }
}

export default function* saga() {
  yield takeLatest(actions_types.POST_SCORING_REQUEST, postScoringRequest);
}
