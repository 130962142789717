import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScrubbingProfileForm, scrubbingProfileInitialForm } from '../forms/scrubbing-profiles-form-config';
import { scrubbingSettingsInitialForm } from '../forms/scrubbing-settings-form-config';
import { ScrubbingProfilesGetResponseItem } from '../models/scrubbing-profiles-get-response';
import { ScrubbingSettingsModel } from '../models/scrubbing-settings-get-response';

export type ScrubbingState = {
  settings: ScrubbingSettingsModel;
  profiles: ScrubbingProfilesGetResponseItem[];
  loading: boolean;
  currentProfile: ScrubbingProfileForm;
};

const initialState: ScrubbingState = {
  settings: scrubbingSettingsInitialForm(),
  profiles: [],
  loading: false,
  currentProfile: scrubbingProfileInitialForm(),
};

export const scrubbing = createSlice({
  name: 'scrubbing',
  initialState,
  reducers: {
    scrubbingSettingsGetSuccess: (state, action: PayloadAction<ScrubbingSettingsModel>) => {
      state.settings = action.payload;
      state.loading = false;
    },
    scrubbingSettingsGetRequest: (state) => {
      state.loading = true;
    },
    scrubbingSettingsGetFail: (state) => {
      state.loading = false;
    },
    scrubbingSettingsUpdateRequest: (state) => {
      state.loading = true;
    },
    scrubbingSettingsUpdateSuccess: (state) => {
      state.loading = false;
    },
    scrubbingSettingsUpdateFail: (state) => {
      state.loading = false;
    },
    scrubbingProfilesGetRequest: (state) => {
      state.loading = true;
    },
    scrubbingProfilesGetSuccess: (state, action: PayloadAction<ScrubbingProfilesGetResponseItem[]>) => {
      state.profiles = action.payload;
      state.loading = false;
    },
    scrubbingProfilesGetFail: (state) => {
      state.loading = false;
    },
    addNewScrubbingProfile: (state) => {
      state.currentProfile = {
        ...scrubbingProfileInitialForm(),
        isNew: true,
      };
    },
    scrubbingProfileUpsertRequest: (state) => {
      state.loading = true;
    },
    scrubbingProfileUpsertSuccess: (state) => {
      state.currentProfile = scrubbingProfileInitialForm();
      state.loading = false;
    },
    scrubbingProfileUpsertFail: (state) => {
      state.loading = false;
    },
    setCurrentScrubbingProfileSuccess: (state, action: PayloadAction<ScrubbingProfileForm>) => {
      state.currentProfile = action.payload;
    },
    deleteScrubbingProfileRequest: (state) => {
      state.loading = true;
    },
    deleteScrubbingProfileSuccess: (state) => {
      state.currentProfile = scrubbingProfileInitialForm();
      state.loading = false;
    },
    deleteScrubbingProfileFail: (state) => {
      state.loading = false;
    },
  },
});

export const {
  scrubbingSettingsGetSuccess,
  scrubbingSettingsGetRequest,
  scrubbingSettingsGetFail,
  scrubbingSettingsUpdateRequest,
  scrubbingSettingsUpdateSuccess,
  scrubbingSettingsUpdateFail,
  scrubbingProfilesGetRequest,
  scrubbingProfilesGetSuccess,
  scrubbingProfilesGetFail,
  addNewScrubbingProfile,
  scrubbingProfileUpsertRequest,
  scrubbingProfileUpsertSuccess,
  scrubbingProfileUpsertFail,
  setCurrentScrubbingProfileSuccess,
  deleteScrubbingProfileRequest,
  deleteScrubbingProfileSuccess,
  deleteScrubbingProfileFail,
} = scrubbing.actions;

export default scrubbing.reducer;
