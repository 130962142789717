import { Button, Spinner, Table } from '@a_team/ui-components';
import { RootState } from 'core/store';
import {
  getWaitlistedRolesRequest,
  saveWaitlistedRoles,
  updateWaitlistedRolesRequest,
} from 'features/waitlisted-roles/reducers/waitlisted-roles-reducer'; // Update import path
import { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = createUseStyles({
  container: {
    padding: '0 20px',
  },
  table: {
    width: '100%',
  },
  button: {
    margin: '16px auto 0 0',
  },
  rolesChangedNote: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    paddingRight: '16px',
    fontSize: '14px',
  },
});

export const TeamRequestPagesForm: FC = () => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const [rolesChanged, setRolesChanged] = useState(false);

  const { roles = [], loading } = useSelector((state: RootState) => state.waitlistedRoles);

  useEffect(() => {
    dispatch(getWaitlistedRolesRequest());
  }, [dispatch]);

  const onChange = (index: number, value: boolean) => {
    const newRoles = [...roles];
    newRoles[index] = { ...newRoles[index], markedAsWaitlisted: value };

    dispatch(saveWaitlistedRoles(newRoles));
    setRolesChanged(true);
  };

  const saveRoles = () => {
    dispatch(updateWaitlistedRolesRequest(roles));
    setRolesChanged(false);
  };

  return (
    <div className={styles.container}>
      {loading && <Spinner />}
      {loading || (
        <>
          <Table
            columns={[
              { accessor: 'name', Header: 'Role name' },
              { accessor: 'waitlistedCheckBox', Header: 'Waitlisted' },
            ]}
            data={roles.map((role, index) => ({
              name: role.name,
              waitlistedCheckBox: (
                <div>
                  <input
                    type="checkbox"
                    checked={role.markedAsWaitlisted}
                    onChange={(e) => onChange(index, e.target.checked)}
                  />
                </div>
              ),
            }))}
            className={styles.table}
          />
          <Button onClick={saveRoles} className={styles.button}>
            Save roles
          </Button>
        </>
      )}
      {rolesChanged && <div className={styles.rolesChangedNote}>Save changes by clicking on "Save roles" button</div>}
    </div>
  );
};
