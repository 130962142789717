import demandConnectionsApi from 'api/demand-connections-api';
import demandLeadsApi, { ConnectionsParamsType } from 'api/demand-leads-api';
import { AxiosResponse } from 'axios';
import { extractErrorMessage } from 'core/utils/extractErrorMessage';
import * as actionsTypes from 'features/facebook-friends/actions/facebook-friends-action-types';
import { GetParamsSagaSuccessResponse } from 'features/warm-leads/warm-leads-request-model';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as uuid from 'uuid';
import { facebookFriends } from '../reducers/facebook-friends-reducer';

function* requestFacebookFriends({ payload }: actionsTypes.FacebookFriendsRequestAction) {
  try {
    const requestId = uuid.v4().toString();
    const res: AxiosResponse = yield call(demandConnectionsApi.fetchFacebookFriends, {
      ...payload,
      requestId,
    });
    yield put(facebookFriends.actions.mutualConnectionsSuccess(res.data.url));
    toast.success('Request submitted. You should get an email with scraping results');
  } catch (err: any) {
    const errorMessage = extractErrorMessage(err);
    yield put(facebookFriends.actions.mutualConnectionsFail(errorMessage));
    toast.error(`Error submitting request: ${errorMessage}`);
  }
}

function* createMultipleConnectionsRequest({ payload }: actionsTypes.FacebookFriendsRequestAction) {
  try {
    const requestId = uuid.v4().toString();
    const res: AxiosResponse = yield call(demandConnectionsApi.createFacebookFriendsRequest, {
      ...payload,
      exclusionList: payload.exclusionList.join(','),
      requestId,
    });
    yield put(facebookFriends.actions.multipleConnectionsSuccess(res.data.request.id));
    toast.success('Request submitted. You should get an email with scraping results');
  } catch (err: any) {
    const errorMessage = extractErrorMessage(err);
    yield put(facebookFriends.actions.multipleConnectionsFail(errorMessage));
    toast.error(`Error submitting request: ${errorMessage}`);
  }
}

function* getParamsRequest() {
  try {
    const res: GetParamsSagaSuccessResponse = yield call(demandLeadsApi.getParams, {
      type: ConnectionsParamsType.ExclusionList,
    });
    yield put(facebookFriends.actions.exclusionListSuccess(res.data.params as unknown as string[]));
  } catch (err: any) {
    yield put(facebookFriends.actions.exclusionListFail());
    toast.error(`Error fetching params: ${err.message}`);
  }
}

export default function* saga() {
  yield takeLatest(actionsTypes.MUTUAL_CONNECTIONS_REQUEST, requestFacebookFriends);
  yield takeLatest(actionsTypes.MULTIPLE_CONNECTIONS_REQUEST, createMultipleConnectionsRequest);
  yield takeLatest(actionsTypes.EXCLUSION_LIST_REQUEST, getParamsRequest);
}
