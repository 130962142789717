import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type EnrichCompaniesState = {
  loading: boolean;
  submitted: boolean;
  error?: string;
};

const initialState: EnrichCompaniesState = {
  loading: false,
  submitted: false,
  error: undefined,
};

export const enrichCompanies = createSlice({
  name: 'enrichCompanies',
  initialState,
  reducers: {
    createEnrichCompaniesRequest: (state) => {
      state.loading = true;
    },
    createEnrichCompaniesRequestSuccess: (state) => {
      state.loading = false;
    },
    createEnrichCompaniesRequestFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { createEnrichCompaniesRequest, createEnrichCompaniesRequestSuccess, createEnrichCompaniesRequestFail } =
  enrichCompanies.actions;

export default enrichCompanies.reducer;
