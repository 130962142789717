import { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles<string, { isOpen: boolean }>({
  collapseContainer: {
    display: (props) => (props.isOpen ? 'initial' : 'none'),
  },
});

export const Collapse: FC<{ isOpen: boolean }> = ({ isOpen, children }) => {
  const { collapseContainer } = useStyles({ isOpen });
  return <div className={collapseContainer}>{children}</div>;
};
