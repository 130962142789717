import { Button, Input, Textarea } from '@a_team/ui-components';
import { RootState } from 'core/store';
import {
  handleExcludedCompaniesChange,
  handleExcludedSchoolsChange,
  handleGetSettingsRequest,
  handleSaveSettingsRequest,
  handleSignalHireEmailCallLqsThresholdChange,
} from 'features/lqs/actions/lqs-actions';
import { FC, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Settings } from '../models/settings';

const useStyles = createUseStyles({
  container: {
    maxWidth: '600px',
  },
  buttonContainer: {
    display: 'flex',
  },
});

const LqsSettings: FC = () => {
  const { container, buttonContainer } = useStyles();

  const settings = useSelector<RootState, Settings>((state) => state.lqs.settings);
  const loading = useSelector<RootState, boolean>((state) => state.lqs.getSettingsInProgress);
  const dispatch = useDispatch();

  const onSignalHireEmailCallLqsThresholdChange = (value: string) => {
    dispatch(handleSignalHireEmailCallLqsThresholdChange(parseInt(value)));
  };

  const onExcludedCompaniesChange = (value: string) => {
    dispatch(handleExcludedCompaniesChange(value));
  };

  const onExcludedSchoolsChange = (value: string) => {
    dispatch(handleExcludedSchoolsChange(value));
  };

  useEffect(() => {
    dispatch(handleGetSettingsRequest());
  }, []);

  return (
    <div className={container}>
      <Input
        label="Signal hire email call LQS threshold"
        type="number"
        name="signalHireEmailCallLqsThreshold"
        value={settings?.signalHireEmailCallLqsThreshold || ''}
        onChange={(e) => onSignalHireEmailCallLqsThresholdChange(e.target.value)}
        disabled={loading}
      />
      <Textarea
        label="Companies excluded from connections"
        name="excludedCompanyNames"
        value={settings?.excludedCompanyNames || ''}
        onChange={(e) => onExcludedCompaniesChange(e.target.value)}
        disabled={loading}
      />
      <Textarea
        label="Schools excluded from connections"
        name="excludedSchoolNames"
        value={settings?.excludedSchoolNames || ''}
        onChange={(e) => onExcludedSchoolsChange(e.target.value)}
        disabled={loading}
      />
      <div className={buttonContainer}>
        <Button size="sm" onClick={() => dispatch(handleSaveSettingsRequest())} margin="sm">
          Save
        </Button>
      </div>
    </div>
  );
};

export default LqsSettings;
