import { isArray } from 'lodash';

export const getFormData = (params: any) => {
  const formData = new FormData();

  for (const key in params) {
    if (isArray(params[key]) || params[key] instanceof FileList) {
      for (let i = 0; i < params[key].length; i++) {
        formData.append(key, params[key][i]);
      }
    } else {
      formData.append(key, params[key]);
    }
  }
  return formData;
};
