import { ScoreRequestMetadata } from 'core/models/SupplyScore';

export const UPLOAD_SEARCH_TERMS_REQUEST = 'lqs/UPLOAD_SEARCH_TERMS_REQUEST';

export interface UploadSearchTermsRequestAction {
  type: typeof UPLOAD_SEARCH_TERMS_REQUEST;
  searchTerm1: string;
  searchTerm2: string;
  linkedInCount: number;
  searchTermsFile: File | undefined;
  metadata: ScoreRequestMetadata;
}

export const UPLOAD_SEARCH_TERMS_SUCCESS = 'lqs/UPLOAD_SEARCH_TERMS_SUCCESS';
export interface UploadSearchTermsSuccessAction {
  type: typeof UPLOAD_SEARCH_TERMS_SUCCESS;
  requestId: string;
}

export const UPLOAD_SEARCH_TERMS_FAIL = 'lqs/UPLOAD_SEARCH_TERMS_FAIL';
export interface UploadSearchTermsFailAction {
  type: typeof UPLOAD_SEARCH_TERMS_FAIL;
}

export type SgeAction = UploadSearchTermsRequestAction | UploadSearchTermsSuccessAction | UploadSearchTermsFailAction;
