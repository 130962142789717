import { Typography } from '@a_team/ui-components';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import leftArrow from './assets/leftArrow.svg';
import IntroSearchHistory from './intro-search-history';

const faqs: { question: string; answer: string }[] = [
  {
    question: 'Do you use LinkedIn to find these connections?',
    answer:
      'No, we do not. We used a growth tool called SCS and check if two people overlap at the same workplace in the past. Our algorithm calculates the likelihood of two people knowing each other by taking into account how long they overlapped, the type of company it was (huge company vs. small startup), their seniority at the company and their location.',
  },
  {
    question: "Who is included in A.Team's network?",
    answer:
      'We currently include all builders who have been on missions and builders with which we have good relationships. We also included the CXOs and our clients. These lists are static for now, so be aware (some clients might have churn).',
  },
  {
    question: 'How accurate are the results?',
    answer:
      'The results should be pretty accurate but you need to take into account the confidence level and the company at which both people overlapped at. For ex: if John and Jessica overlapped at Microsoft they are less likely to know each other than having overlapped at a small startup. We typically take this into account in the confidence level.',
  },
  {
    question: 'I got the results. What should I do?',
    answer:
      'If you think the two people likely know each other, you should email the person from our network that can introduce you to the target. A.Team offers referral rewards of $3,000 for successful introductions.',
  },
];

const useStyles = createUseStyles({
  container: {
    padding: '0 20px',
  },
  heading: {
    marginTop: 0,
  },
  faqs: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  faq: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  faqHeading: {
    marginTop: 0,
    marginBottom: 0,
  },
  backButton: {
    background: 'transparent',
    border: 'none',
    outline: 'none',
    marginRight: 16,
    cursor: 'pointer',
  },
});

export const IntroSearchFAQ: FC = () => {
  const styles = useStyles();
  const history = useHistory();

  console.log({ history });

  return (
    <div className={styles.container}>
      <Typography variant="h2" className={styles.heading}>
        <button className={styles.backButton} onClick={() => history.go(-1)}>
          <img src={leftArrow} alt="Back" />
        </button>
        FAQ
      </Typography>
      <div className={styles.faqs}>
        {faqs.map(({ question, answer }) => (
          <div className={styles.faq} key={question}>
            <Typography variant="h4" component="p" className={styles.faqHeading}>
              {question}
            </Typography>
            <Typography variant="textSmall" component="p" color="Grey@500">
              {answer}
            </Typography>
          </div>
        ))}
      </div>

      <IntroSearchHistory />
    </div>
  );
};
