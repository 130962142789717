import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTeamRequestPageUrl } from 'features/team-request-pages/helpers';
import { TeamRequestPageData } from 'features/team-request-pages/team-request-pages-request-model';

export type TeamRequestPagesState = {
  loadingPages: boolean;
  error?: string;

  loadedUrl?: string;
  createdPages?: TeamRequestPageData[];

  creatingPage: boolean;
  createdPageUrl?: string;
};

const initialState: TeamRequestPagesState = {
  loadingPages: false,
  error: undefined,
  loadedUrl: undefined,
  createdPages: [],

  creatingPage: false,
  createdPageUrl: undefined,
};

const teamRequestPagesSlice = createSlice({
  name: 'teamRequestPages',
  initialState: initialState,
  reducers: {
    startCreatingPage: (state) => {
      state.creatingPage = true;
      state.error = undefined;
      state.createdPageUrl = undefined;
    },
    createPageSuccess: (state, action: PayloadAction<{ slug: string }>) => {
      return {
        ...state,
        error: undefined,
        loadingPages: false,
        creatingPage: false,
        loadedUrl: undefined,
        createdPageUrl: getTeamRequestPageUrl(action.payload.slug),
      };
    },
    createPageFail: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        error: action.payload,
        loadingPages: false,
        creatingPage: false,
      };
    },
    addCreatedPage: (state, action: PayloadAction<TeamRequestPageData>) => {
      state.createdPages = [action.payload];
    },
  },
});

export const teamRequestPagesActions = teamRequestPagesSlice.actions;

export const teamRequestPagesReducer = teamRequestPagesSlice.reducer;
