import { Button, Typography } from '@a_team/ui-components';
import { DqsDecision } from 'api/dqs-api';
import ErrorMessageDisplay from 'core/components/error-message-display';
import FieldGenerator from 'core/components/field-generator';
import { FormItemConfig } from 'core/components/field-type';
import { RootState } from 'core/store';
import { handleLoadSettingsRequest, handleSaveSettings } from 'features/dqs/actions/settings-actions';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

const useStyles = createUseStyles({
  tagContainer: {
    margin: '8px',
  },
});

const FORM_CONFIG: FormItemConfig[] = [
  {
    field: 'standalone',
    name: 'excludedDomainsCSV',
    type: 'text',
    label: 'List of excluded domains (optional, comma separated, eg. apple.com,test.com)',
    size: 'stretch',
  },
  {
    field: 'standalone',
    name: 'excludedDomainDecision',
    label: `Decision if a domain is excluded (waitlist, pass or passdelay)`,
    type: 'text',
    size: 'stretch',
    disabled: true,
  },
  {
    field: 'standalone',
    name: 'excludedDomainHubspotCalendarUrl',
    type: 'text',
    label: `Hubspot url when a domain is excluded`,
    size: 'stretch',
  },
  {
    field: 'standalone',
    name: 'unmatchedDecision',
    label: `Decision if no profile was matched (waitlist, pass or passdelay)`,
    type: 'text',
    size: 'stretch',
    disabled: true,
  },
  {
    field: 'standalone',
    name: 'unmatchedHubspotCalendarUrl',
    type: 'text',
    label: `Hubspot url when no profile was matched`,
    size: 'stretch',
  },
];

const validationSchema = Yup.object().shape({
  excludedDomainsCSV: Yup.string(),
  excludedDomainDecision: Yup.string().required('Required'),
  excludedDomainHubspotCalendarUrl: Yup.string().url('Must be a valid url with https://').required('Required'),
  unmatchedDecision: Yup.string().required('Required'),
  unmatchedHubspotCalendarUrl: Yup.string().url('Must be a valid url with https://').required('Required'),
});

export const DqsSettingsForm = () => {
  const { tagContainer } = useStyles();
  const dispatch = useDispatch();
  const { loading, errorMsg, result } = useSelector((state: RootState) => state.dqsSettingsReducers);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: result?.id || '',
      excludedDomainsCSV: result?.excludedDomainsCSV || '',
      excludedDomainDecision: result?.excludedDomainDecision || DqsDecision.WAITLIST,
      excludedDomainHubspotCalendarUrl: result?.excludedDomainHubspotCalendarUrl || '',
      unmatchedDecision: result?.unmatchedDecision || DqsDecision.WAITLIST,
      unmatchedHubspotCalendarUrl: result?.unmatchedHubspotCalendarUrl || '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(handleSaveSettings({ ...values }));
    },
  });

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      dispatch(handleLoadSettingsRequest());
    }
  }, [isFirstLoad]);

  if (loading) {
    return <p>Loading settings...</p>;
  }

  if (!result?.id) {
    return <p>First create a settings entry in the database.</p>;
  }

  return (
    <>
      <Typography variant="h3">Edit Settings</Typography>
      <form onSubmit={formik.handleSubmit}>
        <FieldGenerator formik={formik} tagContainer={tagContainer} fieldConfig={FORM_CONFIG} />
        <p>
          <Button size="sm" loading={loading}>
            Save
          </Button>
        </p>
      </form>
      {!!errorMsg && <ErrorMessageDisplay msg={`Error: ${errorMsg}`} />}
    </>
  );
};
