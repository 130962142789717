import { Typography } from '@a_team/ui-components';
import { Collapse } from 'core/components/collapse';
import { TagInputList } from 'core/components/tag-input-list';
import { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  title: {
    cursor: 'pointer',
    margin: '16px 0',
  },
});

export const CompanyPagesExcludedCompanies: FC<{ onChange: (data: string[]) => void; initialData: string[] }> = ({
  onChange,
  initialData = [],
}) => {
  const styles = useStyles();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [data, setData] = useState<string[]>(initialData);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <Typography
        variant="textLarge"
        weight="bold"
        component="h2"
        className={styles.title}
        onClick={() => setIsCollapseOpen(!isCollapseOpen)}
      >
        Excluded Domains
        <Typography variant="textSmall" component="p" onClick={() => setIsCollapseOpen(!isCollapseOpen)}>
          Add domains to exclude while creating the company pages (after editing the field click Save button).
        </Typography>
      </Typography>
      <Collapse isOpen={isCollapseOpen}>
        {data && (
          <TagInputList
            values={data}
            label=""
            name="excludedCompanies"
            onChange={({ target: { value } }) => setData(value)}
          />
        )}
      </Collapse>
    </>
  );
};
