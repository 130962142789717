import authSagas from 'features/auth/sagas/auth-sagas';
import coldIdeasSagas from 'features/cold-ideas/sagas/cold-ideas-sagas';
import companyPagesSagas from 'features/company-pages/sagas/company-pages-sagas';
import clientScoreProfileSagas from 'features/dqs/sagas/profiles-sagas';
import clientScoreSettingsSagas from 'features/dqs/sagas/settings-saga';
import clientScoreSagas from 'features/dqs/sagas/testbed-sagas';
import enrichCompaniesSagas from 'features/enrich-companies/sagas/enrich-companies-sagas';
import facebookFriendsSagas from 'features/facebook-friends/sagas/facebook-friends-sagas';
import linkedinConnectionsSagas from 'features/linkedin-connections/sagas/linkedin-connections-sagas';
import linkedInJobPostingsSagas from 'features/linkedin-job-postings/sagas/linkedin-job-postings-sagas';
import lqsSagas from 'features/lqs/sagas/lqs-sagas';
import lqsScoreSagas from 'features/lqs/sagas/lqs-score-sagas';
import scrubbingSagas from 'features/scrubbing/sagas/scrubbing-sagas';
import scsScoringProfileSagas from 'features/scs/sagas/scoring-profile-sagas';
import scsScoringRequestSagas from 'features/scs/sagas/scoring-request-sagas';
import sgeOutboundSagas from 'features/sge-outbound/sagas/sge-outbound-sagas';
import sgeSagas from 'features/sge/sagas/sge-sagas';
import supplyRequestsSagas from 'features/supply-requests/sagas/supply-requests-sagas';
import targetedLeadsSagas from 'features/targeted-leads/sagas/targeted-leads-sagas';
import waitlistedRolesSagas from 'features/waitlisted-roles/sagas/waitlisted-roles-sagas';
import warmLeadsUtils from 'features/warm-leads-utils/sagas/warm-leads-utils-sagas';
import warmLeadsSagas from 'features/warm-leads/sagas/warm-leads-sagas';

import { all, fork } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(lqsSagas),
    fork(sgeSagas),
    fork(lqsScoreSagas),
    fork(scrubbingSagas),
    fork(facebookFriendsSagas),
    fork(linkedinConnectionsSagas),
    fork(warmLeadsSagas),
    fork(warmLeadsUtils),
    fork(sgeOutboundSagas),
    fork(supplyRequestsSagas),
    fork(clientScoreSagas),
    fork(clientScoreSettingsSagas),
    fork(clientScoreProfileSagas),
    fork(scsScoringProfileSagas),
    fork(scsScoringRequestSagas),
    fork(companyPagesSagas),
    fork(linkedInJobPostingsSagas),
    fork(targetedLeadsSagas),
    fork(coldIdeasSagas),
    fork(enrichCompaniesSagas),
    fork(waitlistedRolesSagas),
  ]);
}
