import { DqsInput, DqsResult } from 'api/dqs-api';
import * as actionsTypes from 'features/dqs/actions/testbed-action-types';

export const handleScoreRequest = (payload: DqsInput): actionsTypes.RunTestbedRequestAction => ({
  type: actionsTypes.RUN_TESTBED_REQUEST,
  payload,
});

export const handleScoreSuccess = (payload: DqsResult): actionsTypes.RunTestbedSuccessAction => ({
  type: actionsTypes.RUN_TESTBED_SUCCESS,
  payload,
});

export const handleScoreFail = (payload: string): actionsTypes.RunTestbedFailAction => ({
  type: actionsTypes.RUN_TESTBED_FAIL,
  payload,
});
