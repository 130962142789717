import { Typography } from '@a_team/ui-components';
import leftArrow from 'assets/leftArrow.svg';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';

interface Props {
  title: string;
  description?: React.ReactNode;
}

const useStyles = createUseStyles({
  pageHeader: {
    marginBottom: 32,
  },
  heading: {
    marginTop: 0,
    marginBottom: 16,
    fontSize: 29,
  },
  backButton: {
    background: 'transparent',
    border: 'none',
    outline: 'none',
    marginRight: 16,
    cursor: 'pointer',
  },
  description: {
    maxWidth: 1200,
  },
});

const PageHeader = ({ title, description }: Props) => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <div className={styles.pageHeader}>
      <Typography variant="h2" className={styles.heading}>
        <button className={styles.backButton} onClick={() => history.go(-1)} type="button">
          <img src={leftArrow} alt="Back" />
        </button>
        {title}
      </Typography>
      {description && (
        <Typography variant="textMedium" component="div" className={styles.description}>
          {description}
        </Typography>
      )}
    </div>
  );
};

export default PageHeader;
