import { AxiosResponse } from 'axios';
import axios from 'config/axios-config';
import { WaitlistedRole } from 'features/waitlisted-roles/reducers/waitlisted-roles-reducer';

const getWaitlistedRoles = async (): Promise<AxiosResponse<WaitlistedRole[]>> => {
  return axios.builderOnboardingApiInstance({
    method: 'GET',
    url: '/v1/waitlisted-roles',
  });
};

const saveWaitlistedRoles = async (payload: any) => {
  return axios.builderOnboardingApiInstance({
    method: 'PUT',
    url: '/v1/waitlisted-roles',
    data: payload,
  });
};

export default {
  getWaitlistedRoles,
  saveWaitlistedRoles,
};
