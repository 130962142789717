import axios from 'config/axios-config';
import { getFormData } from './helpers';

const postTaggedProfiles = async (taggedProfilesFile: File | undefined) => {
  return axios.demandConnectionsInstance({
    method: 'POST',
    url: 'v1/tagged-profiles',
    data: getFormData({ csv: taggedProfilesFile }),
  });
};

const postTargetedLeadsSingle = async (linkedinUrl: string) => {
  return axios.demandConnectionsInstance({
    method: 'POST',
    url: `v1/targeted-leads/single`,
    params: { linkedinUrl },
  });
};

const postTargetedLeadsCompany = async (companyName: string, includedRoles: string[]) => {
  return axios.demandConnectionsInstance({
    method: 'POST',
    url: `v1/targeted-leads/company`,
    data: { companyName, includedRoles },
  });
};

export default {
  postTaggedProfiles,
  postTargetedLeadsSingle,
  postTargetedLeadsCompany,
};
