import { Button, Checkbox, Input, Typography } from '@a_team/ui-components';
import { OptionalSection } from 'core/components/optional-section';
import PageHeader from 'core/components/page-header';
import RowContainer from 'core/components/row-container';
import { RootState } from 'core/store';
import { useFormik } from 'formik';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { handlePostScoringRequest } from '../actions/scoring-request-actions';

const useStyles = createUseStyles({
  checkbox: {
    marginTop: '20px',
    marginBottom: '20px',
    display: 'block',
  },
});

export const ScsScoringRequest: React.FC = () => {
  const { checkbox } = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector<RootState, boolean>((state) => state.scsScoringRequest.loading);

  const formik = useFormik({
    initialValues: {
      query: '',
      includedRoles: '',
      excludedRoles: '',
      minCompanySize: 0,
      maxCompanySize: 0,
      companyHqLocationCountries: '',
      industries: '',
      excludeEducationConnections: true,
      maxEarlyStageCompanyEmployees: '2000',
      csv: null,
      includePastCoworkersRequest: true,
      pastCoworkersMaxCompanySize: '800',
      linkedInToken: '',
      minimumScsScore: '0',
      sendCsvInCondensedFormat: false,
    },
    onSubmit: (values) => {
      dispatch(
        handlePostScoringRequest({
          ...values,
          maxEarlyStageCompanyEmployees:
            values.maxEarlyStageCompanyEmployees?.length === 0
              ? null
              : parseInt(values.maxEarlyStageCompanyEmployees, 10),
          pastCoworkersMaxCompanySize:
            values.pastCoworkersMaxCompanySize?.length === 0 || !values.includePastCoworkersRequest
              ? null
              : parseInt(values.pastCoworkersMaxCompanySize, 10),
          minimumScsScore: values.minimumScsScore?.length === 0 ? null : parseInt(values.minimumScsScore, 10),
          sendCsvInCondensedFormat: values.sendCsvInCondensedFormat,
        })
      );
    },
  });

  return (
    <>
      <PageHeader
        title="Find connections fo people (SCS)"
        description="This tool allows to find connections (specifically past co-workers) of specific people, only by inputting their LinkedIn urls. The engine behind it (SCS) will try to find a match between experiences mentioned on LinkedIn of your input profile and the rest of the people in our database. If two people worked together at the same company, at the same time, we will give a score based on the likelihood of them knowing each other. The score will be based on the size of the company (people at smaller company are more likely to know each other), similar location, similar roles, etc.. You can find the logic score settings here."
      />
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h3">Upload a CSV file with multiple profiles or enter single profile</Typography>
        <RowContainer>
          <Input
            type="text"
            placeholder="Enter Linkedin URL or name"
            name="query"
            onChange={formik.handleChange}
            value={formik.values.query}
            disabled={!!formik.values.csv}
          />
          <Typography variant="h4">or</Typography>
          <Input
            type="file"
            accept=".csv"
            name="csv"
            onChange={(e: any) => formik.setFieldValue('csv', e.target.files[0])}
          />
        </RowContainer>
        <OptionalSection title="Results magnifier (Optional)">
          <Typography variant="textMedium">
            To improve the results, we can scrape the list of all past employees at the companies your input list worked
            at. To do this, we need a LinkedIn token. For efficacy purposes, we will only scrap companies with up to X
            number of employees. This can be change in Settings. If you don't chose this option, we will only show
            results of people we currently have in our database.
          </Typography>
          <Typography variant="textMedium" component="p" margin="md">
            {
              'How to find LinkedIn token? VIA: CHROME DEV TOOLS -> APPLICATION -> COOKIES -> WWW.LINKEDIN.COM -> LI_AT TOKEN'
            }
          </Typography>
          <Checkbox
            label="Enable results magnifier"
            onChange={(e) => formik.setFieldValue('includePastCoworkersRequest', e.target.checked)}
            checked={formik.values.includePastCoworkersRequest}
            className={checkbox}
          ></Checkbox>
          <Input
            type="text"
            placeholder="LinkedIn token"
            name="linkedInToken"
            onChange={formik.handleChange}
            value={formik.values.linkedInToken}
            disabled={!formik.values.includePastCoworkersRequest}
            required={formik.values.includePastCoworkersRequest}
            style={{ width: '400px' }}
          />
          <br />
        </OptionalSection>
        <OptionalSection title="Filters optional">
          <Input
            type="text"
            label="Included Roles"
            placeholder="Enter comma-separated list of roles"
            name="includedRoles"
            onChange={formik.handleChange}
            value={formik.values.includedRoles}
            style={{ width: '500px' }}
          />
          <Input
            type="text"
            label="Excluded Roles"
            placeholder="Enter comma-separated list of roles"
            name="excludedRoles"
            onChange={formik.handleChange}
            value={formik.values.excludedRoles}
            style={{ width: '500px' }}
          />
          <Input
            type="number"
            label="Min Company Size"
            name="minCompanySize"
            onChange={formik.handleChange}
            value={formik.values.minCompanySize}
            style={{ width: '80px' }}
          />
          <Input
            type="number"
            label="Max Company Size"
            name="maxCompanySize"
            onChange={formik.handleChange}
            value={formik.values.maxCompanySize}
            style={{ width: '80px' }}
          />
          <Input
            type="text"
            label="HQ of the company (country codes)"
            placeholder="Enter comma-separated list of country codes (i.e. 'DE, ES')"
            name="companyHqLocationCountries"
            onChange={formik.handleChange}
            value={formik.values.companyHqLocationCountries}
            style={{ width: '500px' }}
          />
          <Input
            type="text"
            label="Industries"
            placeholder="Enter comma-separated list of industries"
            name="industries"
            onChange={formik.handleChange}
            value={formik.values.industries}
            style={{ width: '450px' }}
          />
        </OptionalSection>
        <Button margin="sm" loading={loading}>
          SUBMIT
        </Button>
      </form>
    </>
  );
};
