import { Button, SelectOption } from '@a_team/ui-components';
import { GroupField } from 'core/components/group-field';
import { StandaloneField } from 'core/components/standalone-field';
import { RootState } from 'core/store';
import { handleFetchProfileTypesRequest } from 'features/lqs/actions/lqs-actions';
import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleAddNewScrubbingProfile,
  handleDeleteScrubbingProfileRequest,
  handleScrubbingProfileUpsertRequest,
  handleScrubbingProfilesGetRequest,
  handleSetCurrentScrubbingProfileRequest,
} from '../actions/scrubbing-actions';
import { ScrubbingProfileForm, getScrubbingProfilesFormConfig } from '../forms/scrubbing-profiles-form-config';
import { ScrubbingProfilesGetResponseItem } from '../models/scrubbing-profiles-get-response';

const useStyles = createUseStyles({
  tagContainer: {
    margin: '8px',
  },
  buttonGroup: {
    display: 'flex',
  },
  button: {
    margin: '0 8px',
  },
});

export const ScrubbingProfiles: FC = () => {
  const { tagContainer, buttonGroup, button } = useStyles();
  const dispatch = useDispatch();

  const profileOptions = useSelector<RootState, SelectOption[]>((state) => state.lqs.profileSettings.profileOptions);
  const scrubbingProfiles = useSelector<RootState, ScrubbingProfilesGetResponseItem[]>(
    (state) => state.scrubbing.profiles
  );
  const scrubbingProfileOptions = scrubbingProfiles.map(
    (profile): SelectOption => ({
      label: profile.name,
      value: profile.id,
    })
  );
  const currentProfile = useSelector<RootState, ScrubbingProfileForm>((state) => state.scrubbing.currentProfile);

  useEffect(() => {
    dispatch(handleFetchProfileTypesRequest());
    dispatch(handleScrubbingProfilesGetRequest());
  }, []);

  const formik = useFormik({
    initialValues: currentProfile,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(handleScrubbingProfileUpsertRequest(values));
    },
  });

  useEffect(() => {
    if (typeof formik.values.id === 'string') {
      dispatch(handleSetCurrentScrubbingProfileRequest(formik.values.id));
    }
  }, [formik.values.id]);

  const addNew = (e: any) => {
    e.preventDefault();
    dispatch(handleAddNewScrubbingProfile());
  };

  const deleteProfile = (e: any) => {
    e.preventDefault();
    if (typeof formik.values.id === 'string') {
      dispatch(handleDeleteScrubbingProfileRequest(formik.values.id));
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {getScrubbingProfilesFormConfig(scrubbingProfileOptions, profileOptions).map((formField, key) => {
          if (formField.field === 'standalone') {
            return (
              <StandaloneField
                key={key}
                formField={formField}
                formik={formik}
                tagContainer={tagContainer}
                disabled={formField.disabled}
                options={formField.options}
              />
            );
          }
          if (formField.field === 'group') {
            return (
              <GroupField
                key={key}
                groupFields={formField.fields}
                formik={formik}
                tagContainer={tagContainer}
                disabled={formField.disabled}
              />
            );
          }
        })}
        <br />
        <div className={buttonGroup}>
          <Button size="md" margin="sm" className={button}>
            Save
          </Button>
          <Button size="md" margin="sm" onClick={addNew} className={button}>
            New
          </Button>
          <Button
            size="md"
            margin="sm"
            variant="negative"
            onClick={deleteProfile}
            className={button}
            disabled={typeof formik.values?.id !== 'string'}
          >
            Delete
          </Button>
        </div>
      </form>
    </>
  );
};
