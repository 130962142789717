import { Button, Input, Typography } from '@a_team/ui-components';
import PageHeader from 'core/components/page-header';
import RowContainer from 'core/components/row-container';
import { RootState } from 'core/store';
import { CreateLinkedInJobsRequestModel } from 'features/linkedin-job-postings/linkedin-job-postings-request-model';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleCreateLinkedInJobsRequestRequest } from '../actions/linkedin-job-postings-actions';

export const LinkedInJobPostingsSingleCompany: FC = () => {
  const dispatch = useDispatch();
  const { loading, requestId } = useSelector((state: RootState) => state.linkedInJobPostings);

  const formik = useFormik({
    initialValues: {
      companyName: '',
      companyUrl: '',
    } as CreateLinkedInJobsRequestModel,
    onSubmit: (values) => {
      dispatch(handleCreateLinkedInJobsRequestRequest(values));
    },
  });
  return (
    <>
      <PageHeader
        title="Scrape LinkedIn jobs"
        description="This tool allows you to input the URLs or name of companies and it will output data found on their LinkedIn job board. Data output includes: number of open roles, number of open roles that are a match for a.team, when was the last open role that is a match for a.team published, and a few open roles relevant for a.team info (role, link, data of publishing)."
      />
      <form onSubmit={formik.handleSubmit}>
        <RowContainer separator="or">
          <Input
            placeholder="Enter company Url"
            name="companyUrl"
            onChange={formik.handleChange}
            value={formik.values.companyUrl}
          />
          <Input
            placeholder="Enter company name"
            name="companyName"
            onChange={formik.handleChange}
            value={formik.values.companyName}
          />
        </RowContainer>
        <Button loading={loading}>SUBMIT</Button>
        <br />
        {requestId && <Typography variant="textMedium">Submitted request with id {requestId}</Typography>}
      </form>
    </>
  );
};
