import { CreateEnrichCompaniesRequestModel } from 'features/enrich-companies/enrich-companies-request-model';

export const CREATE_ENRICH_COMPANIES_REQUEST = 'enrich-companies/CREATE_ENRICH_COMPANIES_REQUEST';
export interface CreateEnrichCompaniesRequestAction {
  type: typeof CREATE_ENRICH_COMPANIES_REQUEST;
  payload: CreateEnrichCompaniesRequestModel;
}

export const CREATE_ENRICH_COMPANIES_REQUEST_SUCCESS = 'enrich-companies/CREATE_ENRICH_COMPANIES_REQUEST_SUCCESS';
export interface CreateEnrichCompaniesRequestSuccessAction {
  type: typeof CREATE_ENRICH_COMPANIES_REQUEST_SUCCESS;
}

export const CREATE_ENRICH_COMPANIES_REQUEST_FAIL = 'enrich-companies/CREATE_ENRICH_COMPANIES_REQUEST_FAIL';
export interface CreateEnrichCompaniesRequestFailAction {
  type: typeof CREATE_ENRICH_COMPANIES_REQUEST_FAIL;
  payload: string;
}

export type EnrichCompaniesActionType =
  | CreateEnrichCompaniesRequestAction
  | CreateEnrichCompaniesRequestSuccessAction
  | CreateEnrichCompaniesRequestFailAction;
