import axios, { AxiosInstance } from 'axios';
import store from 'core/store';
import { handleSignInReset } from 'features/auth/actions/auth-actions';
import {
  API_BUILDER_ONBOARDING_BASE_PATH,
  API_CONNECTIONS_BASE_PATH,
  API_DEMAND_BASE_PATH,
  API_DEMAND_CONNECTIONS_BASE_PATH,
  API_SCRUBBING_BASE_PATH,
  API_SUPPLY_BASE_PATH,
  API_SUPPLY_SEARCH_BASE_PATH,
} from './constants';

const supplyInstance = axios.create({ baseURL: API_SUPPLY_BASE_PATH });
const demandInstance = axios.create({ baseURL: API_DEMAND_BASE_PATH });
const scrubbingInstance = axios.create({ baseURL: API_SCRUBBING_BASE_PATH });
const demandLeadsInstance = axios.create({ baseURL: API_CONNECTIONS_BASE_PATH });
const demandConnectionsInstance = axios.create({ baseURL: API_DEMAND_CONNECTIONS_BASE_PATH });
const builderOnboardingApiInstance = axios.create({ baseURL: API_BUILDER_ONBOARDING_BASE_PATH });
const supplySearchInstance = axios.create({ baseURL: API_SUPPLY_SEARCH_BASE_PATH });

const instances: AxiosInstance[] = [
  supplyInstance,
  demandInstance,
  scrubbingInstance,
  demandLeadsInstance,
  demandConnectionsInstance,
  builderOnboardingApiInstance,
  supplySearchInstance,
];

const authInterceptor = (config: any) => {
  const state = store.getState();
  const token = state?.auth?.token;

  if (!config.headers) {
    config.headers = {};
  }
  config.headers['Authorization'] = `Bearer ${token}`;

  return config;
};

const authExpiredInterceptor = (error: any) => {
  if (error.response?.status === 401) {
    store.dispatch(handleSignInReset());
    location.reload();
  }
  return Promise.reject(error);
};

instances.forEach((instance) => {
  instance.interceptors.request.use(authInterceptor, (error) => {
    Promise.reject(error);
  });
  instance.interceptors.response.use((config) => config, authExpiredInterceptor);
});

export default {
  demandInstance,
  supplyInstance,
  scrubbingInstance,
  demandLeadsInstance,
  demandConnectionsInstance,
  builderOnboardingApiInstance,
  supplySearchInstance,
};
