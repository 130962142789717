import { DqsSettings } from 'api/dqs-api';

export const LOAD_SETTINGS_REQUEST = 'demand/dqs/LOAD_SETTINGS_REQUEST';
export interface LoadSettingsRequestAction {
  type: string;
}

export const LOAD_SETTINGS_SUCCESS = 'demand/dqs/LOAD_SETTINGS_SUCCESS';
export interface LoadSettingsSuccessAction {
  type: string;
  payload: DqsSettings;
}

export const LOAD_SETTINGS_FAIL = 'demand/dqs/LOAD_SETTINGS_FAIL';
export interface LoadSettingsFailAction {
  type: string;
  payload: string;
}

export const SAVE_SETTINGS_REQUEST = 'demand/dqs/SAVE_SETTINGS_REQUEST';
export interface SaveSettingsRequestAction {
  type: string;
  payload: DqsSettings;
}

export const SAVE_SETTINGS_SUCCESS = 'demand/dqs/SAVE_SETTINGS_SUCCESS';
export interface SaveSettingsSuccessAction {
  type: string;
  payload: DqsSettings;
}

export const SAVE_SETTINGS_FAIL = 'demand/dqs/SAVE_SETTINGS_FAIL';
export interface SaveSettingsFailAction {
  type: string;
  payload: string;
}

export type DqsSettingsActionsType =
  | LoadSettingsRequestAction
  | LoadSettingsSuccessAction
  | LoadSettingsFailAction
  | SaveSettingsRequestAction
  | SaveSettingsSuccessAction
  | SaveSettingsFailAction;
