import * as actionsTypes from 'features/scrubbing/actions/scrubbing-action-types';
import { ScrubbingProfileForm } from '../forms/scrubbing-profiles-form-config';
import { ScrubbingSettingsForm } from '../forms/scrubbing-settings-form-config';
import { ScrubbingProfilesGetResponseItem } from '../models/scrubbing-profiles-get-response';
import { ScrubbingSettingsModel } from '../models/scrubbing-settings-get-response';

export const handleScrubbingSettingsUpdate = (
  payload: ScrubbingSettingsForm
): actionsTypes.ScrubbingSettingsUpdateRequestAction => ({
  type: actionsTypes.SCRUBBING_SETTINGS_UPDATE_REQUEST,
  payload,
});

export const handleScrubbingSettingsUpdateSuccess = (): actionsTypes.ScrubbingSettingsUpdateSuccessAction => ({
  type: actionsTypes.SCRUBBING_SETTINGS_UPDATE_SUCCESS,
});

export const handleScrubbingSettingsUpdateFail = (): actionsTypes.ScrubbingSettingsUpdateFailAction => ({
  type: actionsTypes.SCRUBBING_SETTINGS_UPDATE_FAIL,
});

export const handleScrubbingSettingsGetRequest = (): actionsTypes.ScrubbingSettingsGetRequestAction => ({
  type: actionsTypes.SCRUBBING_SETTINGS_GET_REQUEST,
});

export const handleScrubbingSettingsGetSuccess = (
  payload: ScrubbingSettingsModel
): actionsTypes.ScrubbingSettingsGetSuccessAction => ({
  type: actionsTypes.SCRUBBING_SETTINGS_GET_SUCCESS,
  payload,
});

export const handleScrubbingSettingsGetFail = (): actionsTypes.ScrubbingSettingsGetFailAction => ({
  type: actionsTypes.SCRUBBING_SETTINGS_GET_FAIL,
});

export const handleScrubbingProfilesGetRequest = (): actionsTypes.ScrubbingProfilesGetRequestAction => ({
  type: actionsTypes.SCRUBBING_PROFILES_GET_REQUEST,
});

export const handleScrubbingProfilesGetSuccess = (
  payload: ScrubbingProfilesGetResponseItem[]
): actionsTypes.ScrubbingProfilesGetSuccessAction => ({
  type: actionsTypes.SCRUBBING_PROFILES_GET_SUCCESS,
  payload,
});

export const handleScrubbingProfilesGetFail = (): actionsTypes.ScrubbingProfilesGetFailAction => ({
  type: actionsTypes.SCRUBBING_PROFILES_GET_FAIL,
});

export const handleScrubbingProfileUpsertRequest = (
  payload: ScrubbingProfileForm
): actionsTypes.ScrubbingProfileUpsertRequestAction => ({
  type: actionsTypes.SCRUBBING_PROFILE_UPSERT_REQUEST,
  payload,
});

export const handleScrubbingProfileUpsertSuccess = (): actionsTypes.ScrubbingProfileUpsertSuccessAction => ({
  type: actionsTypes.SCRUBBING_PROFILE_UPSERT_SUCCESS,
});

export const handleScrubbingProfileUpsertFail = (): actionsTypes.ScrubbingProfileUpsertFailAction => ({
  type: actionsTypes.SCRUBBING_PROFILE_UPSERT_FAIL,
});

export const handleAddNewScrubbingProfile = (): actionsTypes.AddNewScrubbingProfileAction => ({
  type: actionsTypes.ADD_NEW_SCRUBBING_PROFILE,
});

export const handleSetCurrentScrubbingProfileRequest = (
  profileId: string
): actionsTypes.SetCurrentScrubbingProfileRequestAction => ({
  type: actionsTypes.SET_CURRENT_SCRUBBING_PROFILE_REQUEST,
  profileId,
});

export const handleSetCurrentScrubbingProfileSuccess = (
  payload: ScrubbingProfileForm
): actionsTypes.SetCurrentScrubbingProfileSuccessAction => ({
  type: actionsTypes.SET_CURRENT_SCRUBBING_PROFILE_SUCCESS,
  payload,
});

export const handleSetCurrentScrubbingProfileFail = (): actionsTypes.SetCurrentScrubbingProfileFailAction => ({
  type: actionsTypes.SET_CURRENT_SCRUBBING_PROFILE_FAIL,
});

export const handleDeleteScrubbingProfileRequest = (
  profileId: string
): actionsTypes.DeleteScrubbingProfileRequestAction => ({
  type: actionsTypes.DELETE_SCRUBBING_PROFILE_REQUEST,
  profileId,
});

export const handleDeleteScrubbingProfileSuccess = (): actionsTypes.DeleteScrubbingProfileSuccessAction => ({
  type: actionsTypes.DELETE_SCRUBBING_PROFILE_SUCCESS,
});

export const handleDeleteScrubbingProfileFail = (): actionsTypes.DeleteScrubbingProfileFailAction => ({
  type: actionsTypes.DELETE_SCRUBBING_PROFILE_FAIL,
});
