import demandLeadsApi, { ConnectionsParamsType } from 'api/demand-leads-api';
import { AxiosResponse } from 'axios';
import * as actionsTypes from 'features/company-pages/actions/company-pages-action-types';
import { CompanyPageParams } from 'features/company-pages/company-pages-request-model';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { companyPages } from '../reducers/company-pages-reducer';

function* getCompanyPageParams() {
  try {
    const res: AxiosResponse<{ params: CompanyPageParams }> = yield call(demandLeadsApi.getParams, {
      type: ConnectionsParamsType.CompanyPageConfig,
    });
    yield put(companyPages.actions.getCompanyPageParamsSuccess(res.data.params));
  } catch (err: any) {
    yield put(companyPages.actions.getCompanyPageParamsFail(err.message));
    toast.error(`Error fetching params: ${err.message}`);
  }
}

function* updateCompanyPageParams({ payload }: actionsTypes.UpdateCompanyPageParamsRequestAction) {
  try {
    yield call(demandLeadsApi.saveParams, {
      type: ConnectionsParamsType.CompanyPageConfig,
      params: payload,
    });
    yield put(companyPages.actions.updateCompanyPageParamsSuccess());
    toast.success('Params updated successfully');
  } catch (err: any) {
    toast.error(`Error updating params: ${err.message}`);
    yield put(companyPages.actions.updateCompanyPageParamsFail(err?.response?.data?.message || err.message));
  }
}

export default function* saga() {
  yield takeLatest(actionsTypes.GET_COMPANY_PAGE_PARAMS_REQUEST, getCompanyPageParams);
  yield takeLatest(actionsTypes.UPDATE_COMPANY_PAGE_PARAMS_REQUEST, updateCompanyPageParams);
}
