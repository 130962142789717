import * as actionsTypes from 'features/warm-leads-utils/actions/warm-leads-utils-action-types';
import {
  FilterWarmLeadsFileRequestModel,
  MixWarmLeadsFilesRequestModel,
} from 'features/warm-leads-utils/warm-leads-utils-request-model';

// FILTER WARM LEADS FILE
export const handleFilterWarmLeadsFileRequestRequest = (
  payload: FilterWarmLeadsFileRequestModel
): actionsTypes.FilterWarmLeadsFileRequestAction => ({
  type: actionsTypes.FILTER_WARM_LEADS_FILE_REQUEST,
  payload,
});

export const handleFilterWarmLeadsFileRequestSuccess = (): actionsTypes.FilterWarmLeadsFileRequestSuccessAction => ({
  type: actionsTypes.FILTER_WARM_LEADS_FILE_REQUEST_SUCCESS,
});

export const handleFilterWarmLeadsFileRequestFail = (
  payload: string
): actionsTypes.FilterWarmLeadsFileRequestFailAction => ({
  type: actionsTypes.FILTER_WARM_LEADS_FILE_REQUEST_FAIL,
  payload,
});

// MIX WARM LEADS FILES
export const handleMixWarmLeadsFilesRequestRequest = (
  payload: MixWarmLeadsFilesRequestModel
): actionsTypes.MixWarmLeadsFilesRequestAction => ({
  type: actionsTypes.MIX_WARM_LEADS_FILES_REQUEST,
  payload,
});

export const handleMixWarmLeadsFilesRequestSuccess = (): actionsTypes.MixWarmLeadsFilesRequestSuccessAction => ({
  type: actionsTypes.MIX_WARM_LEADS_FILES_REQUEST_SUCCESS,
});

export const handleMixWarmLeadsFilesRequestFail = (
  payload: string
): actionsTypes.MixWarmLeadsFilesRequestFailAction => ({
  type: actionsTypes.MIX_WARM_LEADS_FILES_REQUEST_FAIL,
  payload,
});
