import React, { useEffect, useState } from 'react';
import { Button, Input, Row } from '@a_team/ui-components';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  headerItem: {
    width: '223px',
  },
});

export const MultipleInputFieldSet: React.FC<{
  values: any[];
  onDelete: (id: string) => void;
  onSave: (value: any) => void;
  renderChild?: (
    index: number,
    value: any,
    onChange: (index: number, key: string, value: string | number) => void
  ) => JSX.Element;
  getEmptyItem?: () => any;
  headers?: string[];
}> = ({ values, onSave, onDelete, renderChild, getEmptyItem, headers }) => {
  const styles = useStyles();
  const [items, setItems] = useState<any[]>([{ value: '' }]);

  const onValueChange = (index: number, key: string, value: string | number) => {
    const item = items[index];
    const numericValue = Number(value);
    item[key] = isNaN(numericValue) ? value : numericValue;

    setItems((current) => [...current.splice(0, index), item, ...current.splice(index + 1, current.length)]);
  };

  const onNewClick = () => {
    setItems((current) => [...current, getEmptyItem ? getEmptyItem() : { value: '' }]);
  };

  const onRemoveClick = (value: string) => {
    const item = items.find((i) => i.value === value);
    setItems((current) => current.filter((i) => i.value !== value));
    if (item?.id) {
      onDelete(item.id);
    }
  };

  const onSaveClick = (index: number) => {
    const item = items[index];
    if (item) {
      onSave(item);
    }
  };

  const canAddNew = () => !!items.find((i) => !i.id);

  useEffect(() => {
    if (values?.length) {
      setItems(values);
    }
  }, [values]);

  return (
    <>
      <Row direction="column" align="stretch" justify="between" noGutters>
        {headers && (
          <Row noGutters align="stretch" style={{ gap: 10 }}>
            {headers.map((header) => (
              <span className={styles.headerItem}>{header}</span>
            ))}
          </Row>
        )}
        {items.map((item, index) => (
          <Row align="stretch" noGutters style={{ gap: 10 }}>
            {renderChild ? (
              renderChild(index, item, onValueChange)
            ) : (
              <Input
                key={`input_${index}`}
                name={`item_${index}`}
                onChange={(e) => onValueChange(index, 'value', e.target.value)}
                value={item.value}
              />
            )}
            <Button
              key={`btn_save_${index}`}
              size="sm"
              variant="ghost"
              iconProps={{ name: 'send' }}
              onClick={() => onSaveClick(index)}
              disabled={!item}
              role="button"
            >
              Save
            </Button>
            <Button
              key={`btn_remove_${index}`}
              size="sm"
              variant="ghost"
              iconProps={{ name: 'remove' }}
              onClick={() => onRemoveClick(item.value)}
              disabled={items.length <= 1}
              role="button"
            >
              Remove
            </Button>
          </Row>
        ))}
      </Row>
      <Button size="sm" variant="ghost" iconProps={{ name: 'arrowOpen' }} onClick={onNewClick} disabled={canAddNew()}>
        New
      </Button>
    </>
  );
};
