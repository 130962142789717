import { Button, Input, Typography } from '@a_team/ui-components';
import { RootState } from 'core/store';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleFilterWarmLeadsFileRequestRequest } from '../actions/warm-leads-utils-actions';

interface FormikValues {
  csv: File | undefined;
}

export const WarmLeadsUtilsFilterFile: FC = () => {
  const { loading } = useSelector((state: RootState) => state.warmLeadsUtils);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      csv: undefined,
    },
    onSubmit: (values: FormikValues) => {
      dispatch(handleFilterWarmLeadsFileRequestRequest(values));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h3">Filter out companies not hiring for A.Team roles</Typography>
      <Typography variant="textMedium" component="p" margin="xs">
        Upload your CSV here after scraping the LinkedIn job boards. We will remove the rows for companies which are not
        hiring for LinkedIn roles.
      </Typography>
      <Input
        type="file"
        accept=".csv"
        name="csv"
        onChange={(e: any) => formik.setFieldValue('csv', e.target.files[0])}
      />
      <br />
      <Button loading={loading}>Submit</Button>
    </form>
  );
};
