import _ from 'lodash';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import SettingsFormInput from './profile-settings-form-input';

const separator = ', '; // TODO: Extract to common

export const reduceModel = (model: any, path: string[]): any => {
  let temp = model;
  path.forEach((segment) => {
    if (temp === undefined) {
      return;
    }
    temp = temp[segment];
  });

  return temp;
};

export interface LineInputModel {
  modelPath: string[];
  type: 'number' | 'text';
  isCommaSeparated?: boolean;
}

export interface Props {
  items: (LineInputModel | String)[];
  onChange: Function;
  settings: any;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  span: {
    marginLeft: '10px',
    marginRight: '10px',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
});

const ProfileSettingsFormLine: FC<Props> = ({ items, onChange, settings }) => {
  const { container, span } = useStyles();

  const handleChange = (value: string, item: LineInputModel) => {
    let convertedValue: string | number | string[] = item.type === 'number' ? parseFloat(value) : value;
    if (item.isCommaSeparated && item.type === 'text') {
      convertedValue = (convertedValue as string).split(',').map((x) => x.trim());
    }
    onChange(convertedValue, item.modelPath);
  };

  const renderItem = (item: String | LineInputModel, index: number) => {
    if (_.isString(item)) {
      return (
        <span className={span} key={index}>
          {item}
        </span>
      );
    } else {
      let value = '';
      if (settings) {
        value = reduceModel(settings, (item as LineInputModel).modelPath);
        if (_.isArray(value)) {
          value = value.join(separator);
        }
      }

      return (
        <SettingsFormInput
          key={index}
          value={value}
          type={(item as LineInputModel).type}
          handleChange={(val) => handleChange(val, item as LineInputModel)}
        />
      );
    }
  };

  return <div className={container}>{items.map(renderItem)}</div>;
};
export default ProfileSettingsFormLine;
