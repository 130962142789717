import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScoringProfileResponseModel } from 'features/scs/models/scoring-profile';

export type ScoringProfileState = {
  loading: boolean;
  data: ScoringProfileResponseModel;
};

const initialState: ScoringProfileState = {
  loading: false,
  data: {},
};

export const scsScoringProfile = createSlice({
  name: 'scsScoringProfile',
  initialState,
  reducers: {
    getScoringProfileRequest: (state) => {
      state.loading = true;
    },
    getScoringProfileSuccess: (state, action: PayloadAction<ScoringProfileResponseModel>) => {
      state.loading = false;
      state.data = action.payload;
    },
    getScoringProfileFail: (state) => {
      state.loading = false;
    },
    saveScoringProfileRequest: (state) => {
      state.loading = true;
    },
    saveScoringProfileSuccess: (state, action: PayloadAction<ScoringProfileResponseModel>) => {
      state.loading = false;
      state.data = action.payload;
    },
    saveScoringProfileFail: (state) => {
      state.loading = false;
    },
  },
});

export const {
  getScoringProfileRequest,
  getScoringProfileSuccess,
  getScoringProfileFail,
  saveScoringProfileRequest,
  saveScoringProfileSuccess,
  saveScoringProfileFail,
} = scsScoringProfile.actions;

export default scsScoringProfile.reducer;
