import PageHeader from 'core/components/page-header';
import { FC } from 'react';
import RequestTargetedLeads from './request-targeted-leads';

export const TargetedLeads: FC = () => {
  return (
    <>
      <PageHeader
        title="Targeted leads"
        description="This tool allows you to input the LinkedIn URL of someone you want to reach out to. It will output the paths possible to this person by showing A (core network) can introduce to B who can introduce to C (target)."
      />
      <RequestTargetedLeads />
    </>
  );
};
