import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './layout/layout';

type Props = {
  routes: any[];
};

export const Dashboard: FC<Props> = ({ routes }) => {
  const renderRoutes = () => {
    return routes.map((route, index) => {
      return <Route key={index} {...route} />;
    });
  };

  return (
    <Layout>
      <Switch>{renderRoutes()}</Switch>
    </Layout>
  );
};
