import { Button, Input, Select, SelectOption, Textarea, Typography } from '@a_team/ui-components';
import { BaseScoringProfile, generalSettings, independenceScoreSettings } from 'core/models/Profiles';
import { RootState } from 'core/store';
import {
  handleFetchProfileTypesRequest,
  handleSaveProfileSettingsRequest,
  handleSetSelectedProfile,
} from 'features/lqs/actions/lqs-actions';
import { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import SettingsFormLine from './profile-settings-form-line';
import {
  addNewProfile,
  setCurrentProfileName,
  setProfileSettingsItem,
  setSelectedProfile,
} from '../reducers/lqs-reducers';

const textAreaOptions: SelectOption[] = [
  {
    label: 'Inc. Positions for Aggregation',
    value: 'aggregatedExpIncludedPositions',
  },
  {
    label: 'Excl. Positions for Aggregation',
    value: 'aggregatedExpExcludedPositions',
  },
  { label: 'Top Companies 1', value: 'primaryTopCompanies' },
  { label: 'Inc. Positions 1', value: 'primaryIncludedPositions' },
  { label: 'Excl. Positions 1', value: 'primaryExcludedPositions' },
  { label: 'Top Companies 2', value: 'secondaryTopCompanies' },
  { label: 'Inc. Positions 2', value: 'secondaryIncludedPositions' },
  { label: 'Excl. Positions 2', value: 'secondaryExcludedPositions' },
  { label: 'Top 15 Universities', value: 'top15Universities' },
  { label: 'Top 30 Universities', value: 'top30Universities' },
  { label: 'Top Companies for Independence Score', value: 'independenceScoreTopCompanies' },
];

const useStyles = createUseStyles({
  container: {
    maxWidth: '600px',
  },
  textArea: {
    height: '200px',
  },
  buttonContainer: {
    display: 'flex',
  },
  button: {
    marginLeft: '1rem',
  },
});

const LqsProfileSettings: FC = () => {
  const { container, textArea, buttonContainer, button } = useStyles();

  const profileOptions = useSelector<RootState, SelectOption[]>((state) => state.lqs.profileSettings.profileOptions);
  const selectedProfile = useSelector<RootState, SelectOption | null>(
    (state) => state.lqs.profileSettings.selectedProfile
  );
  const currentProfile = useSelector<RootState, BaseScoringProfile | null>(
    (state) => state.lqs.profileSettings.currentProfile
  );

  const dispatch = useDispatch();

  const onSettingsItemChange = (value: string | number, modelPath: string[]) => {
    dispatch(setProfileSettingsItem({ value, modelPath }));
  };

  const onCurrentProfileNameChange = (value: string) => {
    dispatch(setCurrentProfileName(value));
  };

  useEffect(() => {
    dispatch(handleFetchProfileTypesRequest());
  }, []);

  useEffect(() => {
    if (profileOptions && profileOptions.length > 0) {
      dispatch(handleSetSelectedProfile(profileOptions[0]));
    }
  }, [profileOptions]);

  const [textAreaContent, setTextAreaContent] = useState('');
  const [variableName, setVariableName] = useState<any>('');

  useEffect(() => {
    if (!currentProfile) {
      return;
    }
    setTextAreaContent((currentProfile[variableName.value as keyof BaseScoringProfile] as string) || '');
  }, [currentProfile, variableName]);

  useEffect(() => {
    if (variableName) {
      dispatch(setProfileSettingsItem({ value: textAreaContent, modelPath: [variableName.value] }));
    }
  }, [textAreaContent]);

  return (
    <>
      <div className={container}>
        <Select
          label="Profile"
          name="profileOption"
          value={selectedProfile}
          onChange={(value) => {
            dispatch(setSelectedProfile(value as SelectOption));
            dispatch(handleSetSelectedProfile(value as SelectOption));
          }}
          options={profileOptions}
        />
        <Input
          label="Name"
          type="text"
          name="profileName"
          value={currentProfile?.name || ''}
          onChange={(e) => onCurrentProfileNameChange(e.target.value)}
        />
        <div>
          <Typography variant="h3">Variables for profile {currentProfile?.name}</Typography>
          <Select label="Variable" value={variableName} onChange={setVariableName} options={textAreaOptions} />
          <Textarea
            value={textAreaContent}
            onChange={(e) => setTextAreaContent(e.target.value)}
            size="stretch"
            label="Value"
            className={textArea}
          />
        </div>
      </div>
      <div>
        <Typography variant="h3">General settings for profile {currentProfile?.name}</Typography>
        {generalSettings.map((row, i) => (
          <SettingsFormLine onChange={onSettingsItemChange} items={row} settings={currentProfile} key={i} />
        ))}
      </div>
      <div>
        <Typography variant="h3">Independence Score settings for profile {currentProfile?.name}</Typography>
        {independenceScoreSettings.map((row, i) => (
          <SettingsFormLine onChange={onSettingsItemChange} items={row} settings={currentProfile} key={i} />
        ))}
      </div>
      <div className={buttonContainer}>
        <Button size="sm" onClick={() => dispatch(handleSaveProfileSettingsRequest())} margin="sm">
          Save
        </Button>
        <Button size="sm" onClick={() => dispatch(addNewProfile())} margin="sm" className={button}>
          Add new profile
        </Button>
      </div>
    </>
  );
};

export default LqsProfileSettings;
