import { Button, Icon, Input, Table, theme } from '@a_team/ui-components';
import { FC, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  title: {
    cursor: 'pointer',
  },
  tableDiv: {
    margin: '16px 0',
    '& table': {
      marginBottom: '12px',
      width: '100%',
      '& td': {
        padding: '4px',
        '& input:first-child, & input:last-child': {
          borderLeft: `1px solid ${theme.colors.Grey[800]}`,
          borderRight: `1px solid ${theme.colors.Grey[800]}`,
        },
        '& input': {
          width: '100%',
        },
      },
      '& button': {
        width: '100%',
      },
    },
  },
  addButton: {
    marginTop: '16px',
    marginLeft: 'auto',
  },
  deleteButton: {
    background: 'none',
    border: 'none',
    '&, & svg': {
      cursor: 'pointer',
    },
  },
});

enum TECH_STACK_TABLE_ACCESSORS {
  keyword = 'keyword',
  roleName = 'roleName',
  techStack = 'techStack',
  isEngineeringRole = 'isEngineeringRole',
  controls = 'controls',
}

const TECH_STACK_TABLE_COLUMNS = [
  { accessor: TECH_STACK_TABLE_ACCESSORS.keyword, Header: 'Keyword' },
  { accessor: TECH_STACK_TABLE_ACCESSORS.roleName, Header: 'Role Name' },
  { accessor: TECH_STACK_TABLE_ACCESSORS.techStack, Header: 'Tech Stack' },
  { accessor: TECH_STACK_TABLE_ACCESSORS.isEngineeringRole, Header: 'Is Enginnering Role?' },
  { accessor: TECH_STACK_TABLE_ACCESSORS.controls, Header: '' },
];

export type TableData = [string, string, string, boolean][];

export const TechStackTable: FC<{ onChange: (data: TableData) => void; initialData: TableData }> = ({
  onChange,
  initialData = [],
}) => {
  const { tableDiv, deleteButton } = useStyles();
  const [data, setData] = useState<TableData>(initialData);

  const onAdd = () => {
    setData([...data, ['', '', '', false]]);
  };

  const onDelete = (index: number) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const changeFieldValue = (index: number, value: string | boolean, valueIndex: number) => {
    const newData = [...data];
    newData[index][valueIndex] = value;
    setData(newData);
  };

  const techStackTableData = useMemo(() => {
    return data.map(([keyword, roleName, techStack, isEngineeringRole], index) => ({
      [TECH_STACK_TABLE_ACCESSORS.keyword]: (
        <Input label="" value={keyword} onChange={(e) => changeFieldValue(index, e.target.value, 0)} />
      ),
      [TECH_STACK_TABLE_ACCESSORS.roleName]: (
        <Input label="" value={roleName} onChange={(e) => changeFieldValue(index, e.target.value, 1)} />
      ),
      [TECH_STACK_TABLE_ACCESSORS.techStack]: (
        <Input label="" value={techStack} onChange={(e) => changeFieldValue(index, e.target.value, 2)} size="stretch" />
      ),
      [TECH_STACK_TABLE_ACCESSORS.isEngineeringRole]: (
        <Input
          label=""
          checked={isEngineeringRole}
          onChange={(e) => changeFieldValue(index, (e.target as HTMLInputElement).checked, 3)}
          size="stretch"
          type="checkbox"
        />
      ),
      [TECH_STACK_TABLE_ACCESSORS.controls]: (
        <button className={deleteButton} onClick={() => onDelete(index)} type="button">
          <Icon name="delete" />
        </button>
      ),
    }));
  }, [data]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <div className={tableDiv}>
      <Table columns={TECH_STACK_TABLE_COLUMNS} data={techStackTableData} />
      <Button onClick={onAdd}>Add Row</Button>
    </div>
  );
};
