import * as actionTypes from 'features/auth/actions/auth-action-types';
import { TokenData } from 'features/auth/models/auth-data';
import { apply, put, takeLatest } from 'redux-saga/effects';
import { auth } from '../reducers/auth-reducers';

function* signInSuccess({ token, expiresOn }: actionTypes.SigninSuccessAction) {
  const tokenData: TokenData = { token, expiresOn };
  yield apply(localStorage, localStorage.setItem, ['token', JSON.stringify(tokenData)]);
  yield put(auth.actions.signinSuccess({ token }));
}

function* signInFail({ errorCode, details }: actionTypes.SigninFailAction) {
  const errorMessage = `An error occurred during sign in, error code: ${errorCode}, details: ${details}`;
  yield put(auth.actions.signinReset());
  yield put(auth.actions.signinSetError(errorMessage));
}

function* signInReset() {
  yield apply(localStorage, localStorage.removeItem, ['token']);
}

export default function* saga() {
  yield takeLatest(actionTypes.SIGNIN_SUCCESS, signInSuccess);
  yield takeLatest(actionTypes.SIGNIN_FAIL, signInFail);
  yield takeLatest(actionTypes.SIGNIN_RESET, signInReset);
}
