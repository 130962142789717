import { Typography } from '@a_team/ui-components';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { TeamRequestPagesForm } from './team-request-pages-form';

const useStyles = createUseStyles({
  heading: {
    display: 'block',
    marginTop: 0,
  },
});

export const TeamRequestPages: FC = () => {
  const styles = useStyles();

  return (
    <>
      <Typography variant="h1" className={styles.heading}>
        Team Request Pages
      </Typography>
      <TeamRequestPagesForm />
    </>
  );
};
