import { DqsInput, DqsResult } from 'api/dqs-api';

export const RUN_TESTBED_REQUEST = 'demand/dqs/RUN_TESTBED_REQUEST';
export interface RunTestbedRequestAction {
  type: string;
  payload: DqsInput;
}

export const RUN_TESTBED_SUCCESS = 'demand/dqs/RUN_TESTBED_SUCCESS';
export interface RunTestbedSuccessAction {
  type: string;
  payload: DqsResult;
}

export const RUN_TESTBED_FAIL = 'demand/dqs/RUN_TESTBED_FAIL';
export interface RunTestbedFailAction {
  type: string;
  payload: string;
}

export type DqsTestbedActionsType = RunTestbedRequestAction | RunTestbedSuccessAction | RunTestbedFailAction;
