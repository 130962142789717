import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LinkedinJobPostingsState = {
  loading: boolean;
  submitted: boolean;
  error?: string;
  requestId?: number;
  csvRequestId?: number;
};

const initialState: LinkedinJobPostingsState = {
  loading: false,
  submitted: false,
  error: undefined,
  requestId: undefined,
};

export const linkedInJobPostings = createSlice({
  name: 'linkedInJobPostings',
  initialState,
  reducers: {
    createLinkedInJobsRequest: (state) => {
      state.loading = true;
      state.requestId = undefined;
    },
    createLinkedInJobsRequestSuccess: (state, action: PayloadAction<number>) => {
      state.loading = false;
      state.requestId = action.payload;
    },
    createLinkedInJobsRequestFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    createLinkedInJobsCsvRequest: (state) => {
      state.loading = true;
      state.csvRequestId = undefined;
    },
    createLinkedInJobsCsvRequestSuccess: (state, action: PayloadAction<number>) => {
      state.loading = false;
      state.csvRequestId = action.payload;
    },
    createLinkedInJobsCsvRequestFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  createLinkedInJobsRequest,
  createLinkedInJobsRequestSuccess,
  createLinkedInJobsRequestFail,
  createLinkedInJobsCsvRequest,
  createLinkedInJobsCsvRequestSuccess,
  createLinkedInJobsCsvRequestFail,
} = linkedInJobPostings.actions;

export default linkedInJobPostings.reducer;
