import demandLeadsApi, { ConnectionsParamsType } from 'api/demand-leads-api';
import * as actionsTypes from 'features/warm-leads/actions/warm-leads-action-types';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { warmLeads } from '../reducers/warm-leads-reducer';
import { CreateWarmLeadSagaSuccessResponse, GetParamsSagaSuccessResponse } from '../warm-leads-request-model';

function* createWarmLeadsRequest({ payload }: actionsTypes.CreateWarmLeadsRequestAction) {
  try {
    const res: CreateWarmLeadSagaSuccessResponse = yield call(demandLeadsApi.createWarmLeads, payload);
    yield put(warmLeads.actions.createWarmLeadsSuccess(res.data.requestId));
    toast.success('Request succesfully submitted');
  } catch (err: any) {
    yield put(warmLeads.actions.createWarmLeadsFail());
    toast.error(`Error submitting request: ${err.message}`);
  }
}

function* getParamsRequest() {
  try {
    const res: GetParamsSagaSuccessResponse = yield call(demandLeadsApi.getParams, {
      type: ConnectionsParamsType.WarmLeadsParams,
    });
    yield put(warmLeads.actions.getWarmLeadsParamsSuccess(res.data.params));
  } catch (err: any) {
    toast.error(`Error fetching warm leads params: ${err.message}`);
  }
}

export default function* saga() {
  yield all([
    takeLatest(actionsTypes.CREATE_WARM_LEADS_REQUEST, createWarmLeadsRequest),
    takeLatest(actionsTypes.GET_WARM_LEADS_PARAMS_REQUEST, getParamsRequest),
  ]);
}
