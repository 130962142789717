import { Input, Typography } from '@a_team/ui-components';
import { OptionalSection } from 'core/components/optional-section';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  inlineInputNumber: {
    margin: '5px',
    display: 'flex',
    width: '100px',
  },
  inlineInputText: {
    margin: '5px',
    display: 'flex',
    width: '500px',
  },
  inlineRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
});

export const WarmLeadsRolesForm: FC<{
  formik: any;
}> = ({ formik }) => {
  const { inlineInputNumber, inlineInputText, inlineRow } = useStyles();

  return (
    <>
      <OptionalSection
        title="Filters"
        description="From the list you input, you can decide who is going to be filtered out based on their roles and/ or company size/ fundraising amount."
      >
        <div className={inlineRow}>
          <Typography variant="h4">Companies to exclude:</Typography>
          <Input
            type="text"
            name="excludedCompanies"
            className={inlineInputText}
            onChange={formik.handleChange}
            value={formik.values.excludedCompanies}
          />
        </div>
        <div className={inlineRow}>
          <Typography variant="h4">Entities to exclude:</Typography>
          <Input
            type="text"
            name="excludedEntities"
            className={inlineInputText}
            onChange={formik.handleChange}
            value={formik.values.excludedEntities}
          />
        </div>

        <Typography variant="h4" weight="light">
          (Note: leave "Position Includes" below empty to include every position; likewise, leave "Position Excludes"
          below empty to not exclude any position)
        </Typography>
        <div className={inlineRow}>
          <Typography variant="h4">If a company's total raised amount is between</Typography>
          <Input
            type="number"
            name="totalRaisedLowerThreshold1Millions"
            className={inlineInputNumber}
            onChange={formik.handleChange}
            value={formik.values.totalRaisedLowerThreshold1Millions}
          />
          <Typography variant="h4">Million and</Typography>
          <Input
            type="number"
            name="totalRaisedUpperThreshold1Millions"
            className={inlineInputNumber}
            onChange={formik.handleChange}
            value={formik.values.totalRaisedUpperThreshold1Millions}
          />
          <Typography variant="h4">Million, OR this company's employee count is between</Typography>
          <Input
            type="number"
            name="employeeCountLowerThreshold1"
            className={inlineInputNumber}
            onChange={formik.handleChange}
            value={formik.values.employeeCountLowerThreshold1}
          />
          <Typography variant="h4">and</Typography>
          <Input
            type="number"
            name="employeeCountUpperThreshold1"
            className={inlineInputNumber}
            onChange={formik.handleChange}
            value={formik.values.employeeCountUpperThreshold1}
          />
          <Typography variant="h4">, then include</Typography>
          <Input
            type="text"
            name="positionIncludesRange1"
            className={inlineInputText}
            onChange={formik.handleChange}
            value={formik.values.positionIncludesRange1}
          />
          <Typography variant="h4">, and exclude</Typography>
          <Input
            type="text"
            name="positionExcludesRange1"
            className={inlineInputText}
            onChange={formik.handleChange}
            value={formik.values.positionExcludesRange1}
          />
          <Typography variant="h4">.</Typography>
        </div>
        <div className={inlineRow}>
          <Typography variant="h4">Else, if a company's total raised amount is between</Typography>
          <Input
            type="number"
            name="totalRaisedLowerThreshold2Millions"
            className={inlineInputNumber}
            onChange={formik.handleChange}
            value={formik.values.totalRaisedLowerThreshold2Millions}
          />
          <Typography variant="h4">Million and</Typography>
          <Input
            type="number"
            name="totalRaisedUpperThreshold2Millions"
            className={inlineInputNumber}
            onChange={formik.handleChange}
            value={formik.values.totalRaisedUpperThreshold2Millions}
          />
          <Typography variant="h4">Million, OR this company's employee count is between</Typography>
          <Input
            type="number"
            name="employeeCountLowerThreshold2"
            className={inlineInputNumber}
            onChange={formik.handleChange}
            value={formik.values.employeeCountLowerThreshold2}
          />
          <Typography variant="h4">and</Typography>
          <Input
            type="number"
            name="employeeCountUpperThreshold2"
            className={inlineInputNumber}
            onChange={formik.handleChange}
            value={formik.values.employeeCountUpperThreshold2}
          />
          <Typography variant="h4">, then include</Typography>
          <Input
            type="text"
            name="positionIncludesRange2"
            className={inlineInputText}
            onChange={formik.handleChange}
            value={formik.values.positionIncludesRange2}
          />
          <Typography variant="h4">, and exclude</Typography>
          <Input
            type="text"
            name="positionExcludesRange2"
            className={inlineInputText}
            onChange={formik.handleChange}
            value={formik.values.positionExcludesRange2}
          />
          <Typography variant="h4">.</Typography>
        </div>
        <Typography variant="h4">
          Otherwise, this company's total raised and employee count are out of the two ranges above, and will not be
          included in the final warm leads csv.
        </Typography>
      </OptionalSection>
    </>
  );
};
