import { Button, Input, Typography } from '@a_team/ui-components';
import { RootState } from 'core/store';
import { useFormik } from 'formik';
import { ChangeEvent, FC } from 'react';
import { createUseStyles } from 'react-jss';

import { OptionalSection } from 'core/components/optional-section';
import PageHeader from 'core/components/page-header';
import { useDispatch, useSelector } from 'react-redux';
import { handleOutboundUploadSearchTermsRequest } from '../actions/sge-outbound-actions';

const useStyles = createUseStyles({
  container: {
    maxWidth: '320px',
  },
  checkbox: {
    display: 'flex',
    gap: '6px',
    margin: '16px 0 24px 0',
  },
});

const DEFAULT_SEARCH_TERM_1 = 'ceo, cto, head, vp, director, manager';

const SgeOutbound: FC = () => {
  const styles = useStyles();

  const dispatch = useDispatch();

  const uploadSearchTermsInProgress = useSelector<RootState, boolean>(
    (state) => state.sgeOutbound.uploadSearchTermsInProgress
  );
  const requestId = useSelector<RootState, string>((state) => state.sgeOutbound.requestId);

  const formik = useFormik({
    initialValues: {
      searchTerm1: DEFAULT_SEARCH_TERM_1,
      searchTerm2: '',
      linkedInCount: 3,
      searchTermsFile: undefined,
      utmCampaign: '',
      shouldGenerateCP: true,
      shouldAddConnections: true,
    },
    onSubmit: (values) => {
      dispatch(handleOutboundUploadSearchTermsRequest(values));
    },
  });

  const handleFileChange = (e: ChangeEvent) => {
    const files = (e.target as HTMLInputElement)?.files;
    if (files !== null && files.length > 0) {
      formik.setFieldValue('searchTermsFile', files[0]);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <PageHeader
        title="Find Linkedin profiles of potential leads (per company/role)"
        description="This tool allows you to input the name of a company and roles you're looking for and it will output LinkedIn urls + data about the profiles of relevant people. We use SERP (Google Search to find these leads) by creating multiple queries for each company and roles mentioned below."
      />
      <div className={styles.container}>
        <Typography variant="h3" style={{ fontSize: 15 }}>
          Upload a CSV file (with company_name as the first column)
        </Typography>
        <Input type="file" accept=".csv" onChange={handleFileChange} />
        <Typography variant="h3" style={{ fontSize: 15 }}>
          Roles you want to find for these companies
        </Typography>
        <Input
          type="text"
          name="searchTerm1"
          placeholder="Enter comma-separated list of roles (i.e. 'CEO, CTO, VP')"
          onChange={formik.handleChange}
          value={formik.values.searchTerm1}
          size="stretch"
        />
        <OptionalSection title="More options">
          <Input
            type="text"
            label="Search term 2"
            name="searchTerm2"
            placeholder="Enter search term 2"
            onChange={formik.handleChange}
            value={formik.values.searchTerm2}
            size="stretch"
          />
          <Input type="text" label="Utm Campaign" onChange={formik.handleChange} value={formik.values.utmCampaign} />
          <Input
            type="number"
            label="Number of linkedin urls to capture per search"
            name="linkedInCount"
            onChange={formik.handleChange}
            value={formik.values.linkedInCount}
            size="stretch"
          />
          <label className={styles.checkbox}>
            <Input
              type="checkbox"
              name="shouldGenerateCP"
              onChange={(e: any) => formik.setFieldValue('shouldGenerateCP', e.target.checked)}
              checked={formik.values.shouldGenerateCP}
            />
            <span>Include company page link in the result</span>
          </label>
          <label className={styles.checkbox}>
            <Input
              type="checkbox"
              name="shouldAddConnections"
              onChange={(e: any) => formik.setFieldValue('shouldAddConnections', e.target.checked)}
              checked={formik.values.shouldAddConnections}
            />
            <span>Include closest connections (SCS) data in the result</span>
          </label>
        </OptionalSection>
        <Button margin="sm" loading={uploadSearchTermsInProgress}>
          SUBMIT
        </Button>
      </div>
      {requestId && (
        <>
          <p>
            Your request{' '}
            <small>
              <code>{requestId}</code>
            </small>{' '}
            is created now. You&apos;ll get a new email soon.
            <br />
            If not, please contact the support team with your request id.
          </p>
        </>
      )}
    </form>
  );
};

export default SgeOutbound;
