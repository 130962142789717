import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ColdIdeasState = {
  loading: boolean;
  submitted: boolean;
  error?: string;
};

const initialState: ColdIdeasState = {
  loading: false,
  submitted: false,
  error: undefined,
};

export const coldIdeas = createSlice({
  name: 'coldIdeas',
  initialState,
  reducers: {
    createColdIdeasRequest: (state) => {
      state.loading = true;
    },
    createColdIdeasRequestSuccess: (state) => {
      state.loading = false;
    },
    createColdIdeasRequestFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { createColdIdeasRequest, createColdIdeasRequestSuccess, createColdIdeasRequestFail } = coldIdeas.actions;

export default coldIdeas.reducer;
