import axios from 'config/axios-config';
import { emptyStringPropsToNull } from 'core/utils/api';
import { ScrubbingProfileForm } from 'features/scrubbing/forms/scrubbing-profiles-form-config';

export const getSettings = async () => {
  return axios.scrubbingInstance({
    method: 'GET',
    url: 'v1/config',
  });
};

export const putSettings = async (requestId: string, id: string, formData: any) => {
  return axios.scrubbingInstance({
    method: 'PUT',
    url: 'v1/config',
    data: {
      requestId,
      id,
      config: formData,
    },
  });
};

export const getProfiles = async () => {
  return axios.scrubbingInstance({
    method: 'GET',
    url: 'v1/profiles',
  });
};

export const postProfile = async (requestId: string, payload: ScrubbingProfileForm) => {
  return axios.scrubbingInstance({
    method: 'POST',
    url: 'v1/profiles',
    data: {
      requestId,
      profile: {
        ...payload,
        id: undefined,
      },
    },
  });
};

export const putProfile = async (requestId: string, id: string, payload: ScrubbingProfileForm) => {
  return axios.scrubbingInstance({
    method: 'PUT',
    url: 'v1/profiles',
    data: {
      requestId,
      id,
      profile: emptyStringPropsToNull(payload),
    },
  });
};

export const deleteProfile = async (requestId: string, profileId: string) => {
  return axios.scrubbingInstance({
    method: 'DELETE',
    url: 'v1/profiles',
    data: {
      requestId,
      profileId,
    },
  });
};
