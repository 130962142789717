import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WARM_LEAD_DEFAULT_PARAMS } from 'features/warm-leads/constants';
import { WarmLeadsParamsModel } from '../warm-leads-request-model';

export enum WarmLeadsJobState {
  Idle,
  SubmittingJob,
  ProcessingRmq,
  ProcessingNodes,
  Cancelling,
}

export type WarmLeadsState = {
  requestId: string | undefined;
  requestFailed: boolean;
  warmLeadsParamsLoaded: boolean;
  params: WarmLeadsParamsModel;
  warmLeadsJobState: WarmLeadsJobState;
};

const initialState: WarmLeadsState = {
  requestId: undefined,
  requestFailed: false,
  warmLeadsParamsLoaded: false,
  params: WARM_LEAD_DEFAULT_PARAMS,
  warmLeadsJobState: WarmLeadsJobState.Idle,
};

export const warmLeads = createSlice({
  name: 'warmLeads',
  initialState,
  reducers: {
    createWarmLeadsRequest: (state, action: PayloadAction<WarmLeadsParamsModel>) => {
      state.params = action.payload;
      state.requestId = undefined;
      state.warmLeadsJobState = WarmLeadsJobState.SubmittingJob;
      state.requestFailed = false;
    },
    createWarmLeadsSuccess: (state, action: PayloadAction<string>) => {
      state.requestId = action.payload;
      state.warmLeadsJobState = WarmLeadsJobState.Idle;
    },
    createWarmLeadsFail: (state) => {
      Object.assign(state, initialState);
      state.requestFailed = true;
      state.warmLeadsJobState = WarmLeadsJobState.Idle;
    },
    getWarmLeadsParamsSuccess: (state, action: PayloadAction<WarmLeadsParamsModel>) => {
      state.params = action.payload;
      state.warmLeadsParamsLoaded = true;
    },
  },
});

export const { createWarmLeadsRequest, createWarmLeadsSuccess, createWarmLeadsFail, getWarmLeadsParamsSuccess } =
  warmLeads.actions;

export default warmLeads.reducer;
