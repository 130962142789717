import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DqsSettings } from 'api/dqs-api';

export type DqsSettingsState = {
  loading: boolean;
  errorMsg: string;
  result?: DqsSettings;
};

export const initialState = (): DqsSettingsState => {
  return {
    loading: false,
    errorMsg: '',
  };
};

export const dqsSettings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    loadSettingsRequest: (state) => {
      state.loading = true;
      state.errorMsg = '';
      state.result = undefined;
    },
    loadSettingsSuccess: (state, action: PayloadAction<DqsSettings>) => {
      state.loading = false;
      state.errorMsg = '';
      state.result = action.payload;
    },
    loadSettingsFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMsg = action.payload;
    },
    saveSettingsRequest: (state) => {
      state.loading = true;
      state.errorMsg = '';
    },
    saveSettingsSuccess: (state, action: PayloadAction<DqsSettings>) => {
      state.loading = false;
      state.errorMsg = '';
      state.result = action.payload;
    },
    saveSettingsFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMsg = action.payload;
    },
  },
});

export const { actions, reducer } = dqsSettings;
export default dqsSettings.reducer;
