const baseApiUrl = process.env.REACT_APP_ATEAM_BASE_API_URL;

export const API_SUPPLY_BASE_PATH = baseApiUrl ? `${baseApiUrl}/supply/main/` : 'http://localhost:8000/';
export const GOOGLE_AUTH_CLIENT_ID: string = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || '';
export const API_DEMAND_BASE_PATH = baseApiUrl ? `${baseApiUrl}/demand/main/` : 'http://localhost:4001/';
export const API_SCRUBBING_BASE_PATH = baseApiUrl ? `${baseApiUrl}/supply/scrubbing/` : 'http://localhost:4001/';
export const API_CONNECTIONS_BASE_PATH = baseApiUrl ? `${baseApiUrl}/demand/leads/` : 'http://localhost:4000/';
export const API_DEMAND_CONNECTIONS_BASE_PATH = baseApiUrl
  ? `${baseApiUrl}/demand/connections/`
  : 'http://localhost:4010/';
export const API_BUILDER_ONBOARDING_BASE_PATH = baseApiUrl
  ? `${baseApiUrl}/onboarding/builder/`
  : 'http://localhost:8001/';
export const API_SUPPLY_SEARCH_BASE_PATH = baseApiUrl ? `${baseApiUrl}/supply/search/` : 'http://localhost:4006/';

export const SEPARATOR = ',';

export const COMPANY_PAGES_BASE_URL = process.env.REACT_APP_COMPANY_PAGES_BASE_URL || 'http://localhost:3000/for';

export const TEAM_REQUEST_PAGES_BASE_URL =
  process.env.REACT_APP_TEAM_REQUEST_PAGES_BASE_URL || 'http://localhost:3000/for';

export const ADMIN_USERS_LIST: string[] = JSON.parse(process.env.REACT_APP_ADMIN_USERS_LIST || '[]');

export const INTRO_SEARCH_NETWORK_LIST =
  process.env.REACT_APP_INTRO_SEARCH_NETWORK_LIST ||
  'https://airtable.com/app6Xw40lkgiQPpc4/tblEjyJruCrFIvDpH/viw64PiY2nWiE4WTK?blocks=hide';
