import { TargetedLeadsRequest } from 'features/targeted-leads/models/targeted-leads-reqeust';
import * as actions from './targeted-leads-action-types';

interface UploadTaggedProfilesRequest {
  taggedProfilesFile: File | undefined;
}

export const handleUploadTaggedProfilesRequest = (
  request: UploadTaggedProfilesRequest
): actions.UploadTaggedProfilesRequestAction => ({
  type: actions.UPLOAD_TAGGED_PROFILES_REQUEST,
  ...request,
});

export const handleUploadTaggedProfilesSuccess = (): actions.UploadTaggedProfilesSuccessAction => ({
  type: actions.UPLOAD_TAGGED_PROFILES_SUCCESS,
});

export const handleUploadTaggedProfilesFail = (payload: string): actions.UploadTaggedProfilesFailAction => ({
  type: actions.UPLOAD_TAGGED_PROFILES_FAIL,
  payload,
});

export const handleRequestTargetedLeadsRequest = (
  payload: TargetedLeadsRequest
): actions.RequestTargetedLeadsRequestAction => ({
  type: actions.REQUEST_TARGETED_LEADS_REQUEST,
  payload,
});

export const handleRequestTargetedLeadsSuccess = (): actions.RequestTargetedLeadsSuccessAction => ({
  type: actions.REQUEST_TARGETED_LEADS_SUCCESS,
});

export const handleRequestTargetedLeadsFail = (payload: string): actions.RequestTargetedLeadsFailAction => ({
  type: actions.REQUEST_TARGETED_LEADS_FAIL,
  payload,
});
