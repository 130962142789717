import { SelectOption } from '@a_team/ui-components';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseScoringProfile, defaultProfile } from 'core/models/Profiles';
import { Settings } from '../models/settings';

export type LqsState = {
  profileSettings: {
    profileOptions: SelectOption[];
    selectedProfile: SelectOption | null;
    currentProfile: BaseScoringProfile | null;
  };
  settings: Settings;
  requestId: string | undefined;
  queryResults: any;
  runQueryInProgress: boolean;
  uploadNameFileInProgress: boolean;
  uploadLinkedIdUrlsFileInProgress: boolean;
  getSettingsInProgress: boolean;
};

const updateModel = (model: any, path: string[], value: any): any => {
  const currentProfile = model?.profileSettings?.currentProfile;

  if (!currentProfile) {
    return;
  }

  path.forEach((path) => {
    let settingsKey = path as keyof typeof currentProfile;

    currentProfile[settingsKey] = value;
  });
};

const initialState: LqsState = {
  profileSettings: {
    profileOptions: [],
    selectedProfile: null,
    currentProfile: null,
  },
  settings: {
    signalHireEmailCallLqsThreshold: 0,
    excludedCompanyNames: '',
    excludedSchoolNames: '',
  },
  requestId: undefined,
  queryResults: undefined,
  runQueryInProgress: false,
  uploadNameFileInProgress: false,
  uploadLinkedIdUrlsFileInProgress: false,
  getSettingsInProgress: false,
};

export const lqs = createSlice({
  name: 'lqs',
  initialState,
  reducers: {
    setSelectedProfile: (state, action: PayloadAction<SelectOption>) => {
      state.profileSettings.selectedProfile = action.payload;
    },
    setProfileSettingsItem: (state, action: PayloadAction<{ modelPath: string[]; value: any }>) => {
      const { modelPath, value } = action.payload;
      updateModel(state, modelPath, value);
    },
    setCurrentProfileName: (state, action: PayloadAction<string>) => {
      const { currentProfile } = state.profileSettings;
      if (currentProfile) {
        currentProfile.name = action.payload;
      }
    },
    fetchProfileTypesRequest: (state) => state,
    fetchProfileTypesSuccess: (state, action: PayloadAction<SelectOption[]>) => {
      state.profileSettings.profileOptions = action.payload;
    },
    fetchProfileTypesFail: (state) => state,
    fetchProfileSuccess: (state, action: PayloadAction<BaseScoringProfile>) => {
      state.profileSettings.currentProfile = action.payload;
    },
    addNewProfile: (state) => {
      state.profileSettings.currentProfile = { ...defaultProfile };
      state.profileSettings.selectedProfile = null;
    },
    runQueryRequest: (state) => {
      state.runQueryInProgress = true;
    },
    runQuerySuccess: (state, action: PayloadAction<string>) => {
      state.requestId = action.payload;
      state.runQueryInProgress = false;
    },
    runQueryFail: (state) => {
      state.runQueryInProgress = false;
    },
    getQueryResultsSuccess: (state, action: PayloadAction<any>) => {
      state.queryResults = action.payload;
    },
    getQueryResultsFail: (state) => {
      state.queryResults = null;
    },
    uploadLinkedinUrlsFileRequest: (state) => {
      state.uploadLinkedIdUrlsFileInProgress = true;
    },
    uploadLinkedinUrlsFileSuccess: (state, action: PayloadAction<string>) => {
      state.uploadLinkedIdUrlsFileInProgress = false;
      state.requestId = action.payload;
    },
    uploadLinkedinUrlsFileFail: (state) => {
      state.uploadLinkedIdUrlsFileInProgress = false;
    },
    uploadNameCsvRequest: (state) => {
      state.uploadNameFileInProgress = true;
    },
    uploadNameCsvSuccess: (state, action: PayloadAction<string>) => {
      state.uploadNameFileInProgress = false;
      state.requestId = action.payload;
    },
    uploadNameCsvFail: (state) => {
      state.uploadNameFileInProgress = false;
    },
    signalHireEmailCallLqsThresholdChange: (state, action: PayloadAction<number>) => {
      state.settings.signalHireEmailCallLqsThreshold = action.payload;
    },
    excludedCompaniesChange: (state, action: PayloadAction<string>) => {
      state.settings.excludedCompanyNames = action.payload;
    },
    excludedSchoolsChange: (state, action: PayloadAction<string>) => {
      state.settings.excludedSchoolNames = action.payload;
    },
    getSettingsRequest: (state) => {
      state.getSettingsInProgress = true;
    },
    getSettingsSuccess: (state, action: PayloadAction<Settings>) => {
      state.settings = action.payload;
      state.getSettingsInProgress = false;
    },
    getSettingsFail: (state) => {
      state.getSettingsInProgress = false;
    },
  },
});

export const {
  setSelectedProfile,
  setProfileSettingsItem,
  setCurrentProfileName,
  fetchProfileTypesRequest,
  fetchProfileTypesSuccess,
  fetchProfileTypesFail,
  fetchProfileSuccess,
  addNewProfile,
  runQueryRequest,
  runQuerySuccess,
  runQueryFail,
  getQueryResultsSuccess,
  uploadLinkedinUrlsFileRequest,
  uploadLinkedinUrlsFileSuccess,
  uploadLinkedinUrlsFileFail,
  uploadNameCsvRequest,
  uploadNameCsvSuccess,
  uploadNameCsvFail,
  signalHireEmailCallLqsThresholdChange,
  excludedCompaniesChange,
  excludedSchoolsChange,
  getSettingsRequest,
  getSettingsSuccess,
  getSettingsFail,
} = lqs.actions;

export default lqs.reducer;
