import { Button, SelectOption, Typography } from '@a_team/ui-components';
import { DqsDecision, DqsProfileBase } from 'api/dqs-api';
import FieldGenerator from 'core/components/field-generator';
import { FormItemConfig } from 'core/components/field-type';
import { displayValueToMillions, millionsToDisplayValue } from 'core/utils/convert';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import * as Yup from 'yup';
import { requiresDelay, requiresHubspotCalendar } from '../utils';

type Props = {
  saving: boolean;
  saveErrorMsg: string;
  title: string;
  data?: DqsProfileBase;
  onCancel?: () => void;
  onSave?: (values: DqsProfileBase) => void;
};

const gutter = '8px';
const doubleGutter = '16px';

const useStyles = createUseStyles({
  titleStyle: {
    margin: `${gutter} 0`,
  },
  form: {
    padding: gutter,
    margin: doubleGutter,
    border: '1px solid black',
    borderRadius: gutter,
    backgroundColor: 'light-green',
  },
  fieldContainerPart1: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    columnGap: gutter,
    rowGap: gutter,
  },
  fieldContainerPart2: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    columnGap: gutter,
    rowGap: gutter,
  },
  fieldContainerDecision: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    columnGap: gutter,
    rowGap: gutter,
    marginTop: doubleGutter,
  },
  fieldContainerDelay: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    columnGap: gutter,
    rowGap: gutter,
    marginTop: doubleGutter,
  },
  tagContainer: {
    margin: gutter,
  },
  buttonGroup: {
    marginTop: doubleGutter,
    display: 'flex',
  },
  button: {
    margin: `0 ${gutter}`,
  },
});

const decisionOptions: SelectOption[] = [
  {
    value: DqsDecision.PASS,
    label: 'Pass',
  },
  {
    value: DqsDecision.PASS_WITH_MEETING,
    label: 'Pass with meeting',
  },
  {
    value: DqsDecision.PASS_WITH_DELAY,
    label: 'Pass with delay',
  },
  {
    value: DqsDecision.WAITLIST,
    label: 'Waitlist',
  },
];

const formPart1: FormItemConfig[] = [
  {
    field: 'standalone',
    name: 'name',
    type: 'text',
    label: 'Profile name',
  },
  {
    field: 'standalone',
    name: 'withCountriesCSV',
    type: 'text',
    label: 'Country Restriction, Optional, 2-CHAR ISO, csv',
    size: 'stretch',
  },
];

const formPart2: FormItemConfig[] = [
  {
    field: 'standalone',
    name: 'minRaise',
    type: 'number',
    label: 'Min raise (million)',
  },
  {
    field: 'standalone',
    name: 'maxRaise',
    type: 'number',
    label: 'Max raise (million)',
  },
  {
    field: 'standalone',
    name: 'minEmployees',
    type: 'number',
    label: 'Min employees',
  },
  {
    field: 'standalone',
    name: 'maxEmployees',
    type: 'number',
    label: 'Max employees',
  },
];

const nameIndustryMatchConfig: FormItemConfig[] = [
  {
    field: 'standalone',
    name: 'nameKeywordsCSV',
    type: 'text',
    label: 'Or matching these name keywords (optional, csv)',
    size: 'stretch',
  },
  {
    field: 'standalone',
    name: 'industryKeywordsCSV',
    type: 'text',
    label: 'Or matching these industry keywords (optional, csv)',
    size: 'stretch',
  },
];

const decisionFieldConfig: FormItemConfig = {
  field: 'standalone',
  name: 'decision',
  type: 'select',
  label: 'Decision',
  size: 'fit',
  options: decisionOptions,
};

const passDelayHoursFieldConfig: FormItemConfig = {
  field: 'standalone',
  name: 'passDelayHours',
  type: 'number',
  label: 'Pass delay (hours)',
  size: 'fit',
};

const hubspotCalendarUrlFieldConfig: FormItemConfig = {
  field: 'standalone',
  name: 'hubspotCalendarUrl',
  type: 'text',
  label: 'Hubspot Calendar Url',
  size: 'stretch',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  minRaise: Yup.number().typeError('Enter a number').required('Required'),
  maxRaise: Yup.number().typeError('Enter a number').required('Required'),
  minEmployees: Yup.number().typeError('Enter a number').required('Required'),
  maxEmployees: Yup.number().typeError('Enter a number').required('Required'),
  withCountriesCSV: Yup.string(),
  hubspotCalendarUrl: Yup.string().when('decision', (decision: DqsDecision, schema: any) => {
    return requiresHubspotCalendar(decision)
      ? schema.url('Must be a valid url with https://').required('Required')
      : schema;
  }),
  decision: Yup.string().required('Required'),
  passDelayHours: Yup.number().required('Required'),
});

export const DqsProfileForm = ({ saving, saveErrorMsg, title, data, onCancel, onSave }: Props) => {
  const {
    titleStyle,
    form,
    fieldContainerPart1,
    fieldContainerPart2,
    fieldContainerDecision,
    fieldContainerDelay,
    button,
    buttonGroup,
    tagContainer,
  } = useStyles();

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      sortOrder: data?.sortOrder || 0,
      minRaise: data?.minRaise ? millionsToDisplayValue(data.minRaise) : 0,
      maxRaise: data?.maxRaise ? millionsToDisplayValue(data.maxRaise) : 0,
      minEmployees: data?.minEmployees || 0,
      maxEmployees: data?.maxEmployees || 0,
      withCountriesCSV: data?.withCountriesCSV || '',
      nameKeywordsCSV: data?.nameKeywordsCSV || '',
      industryKeywordsCSV: data?.industryKeywordsCSV || '',
      hubspotCalendarUrl: data?.hubspotCalendarUrl || '',
      decision: data?.decision || DqsDecision.WAITLIST,
      passDelayHours: Number(data?.passDelayHours) || 0,
    },
    validationSchema,
    onSubmit: (values) => {
      if (onSave) {
        onSave({
          ...values,
          minRaise: displayValueToMillions(values.minRaise),
          maxRaise: displayValueToMillions(values.maxRaise),
          passDelayHours: requiresDelay(values.decision) ? values.passDelayHours : 0,
          hubspotCalendarUrl: requiresHubspotCalendar(values.decision) ? values.hubspotCalendarUrl : '',
        });
      }
    },
  });

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const showDelayField = useMemo(() => requiresDelay(formik.values.decision), [formik]);

  const showUrlField = useMemo(() => requiresHubspotCalendar(formik.values.decision), [formik]);

  return (
    <form onSubmit={formik.handleSubmit} className={form}>
      <Typography variant="h3" className={titleStyle}>
        {title}
      </Typography>
      <div className={fieldContainerPart1}>
        <FieldGenerator formik={formik} fieldConfig={formPart1} tagContainer={tagContainer} />
      </div>
      <div className={fieldContainerPart2}>
        <FieldGenerator formik={formik} fieldConfig={formPart2} tagContainer={tagContainer} />
      </div>
      <div>
        <FieldGenerator formik={formik} fieldConfig={nameIndustryMatchConfig} tagContainer={tagContainer} />
      </div>
      <div className={fieldContainerDecision}>
        <FieldGenerator formik={formik} fieldConfig={[decisionFieldConfig]} tagContainer={tagContainer} />
        {showUrlField && (
          <div>
            <FieldGenerator formik={formik} fieldConfig={[hubspotCalendarUrlFieldConfig]} tagContainer={tagContainer} />
          </div>
        )}
      </div>
      {showDelayField && (
        <div className={fieldContainerDelay}>
          <FieldGenerator formik={formik} fieldConfig={[passDelayHoursFieldConfig]} tagContainer={tagContainer} />
        </div>
      )}
      <div className={buttonGroup}>
        <Button size="sm" loading={saving}>
          Save
        </Button>
        <Button variant="secondary" size="sm" className={button} onClick={handleCancel}>
          Cancel
        </Button>
      </div>
      {saveErrorMsg && (
        <Typography variant={'textMedium'} color={'Red@700'}>
          Error: {saveErrorMsg}
        </Typography>
      )}
    </form>
  );
};
