import * as actionsTypes from 'features/company-pages/actions/company-pages-action-types';
import { CompanyPageParams } from 'features/company-pages/company-pages-request-model';

// CREATE/UPDATE PARAMS
export const handleGetCompanyPageParamsRequest = (): actionsTypes.GetCompanyPageParamsRequestAction => ({
  type: actionsTypes.GET_COMPANY_PAGE_PARAMS_REQUEST,
});

export const handleGetCompanyPageParamsSuccess = (
  payload: CompanyPageParams
): actionsTypes.GetCompanyPageParamsSuccessAction => ({
  type: actionsTypes.GET_COMPANY_PAGE_PARAMS_SUCCESS,
  payload,
});

export const handleGetCompanyPageParamsFail = (payload: string): actionsTypes.GetCompanyPageParamsFailAction => ({
  type: actionsTypes.GET_COMPANY_PAGE_PARAMS_FAIL,
  payload,
});

// CREATE/UPDATE PARAMS
export const handleUpdateCompanyPageParamsRequest = (
  payload: CompanyPageParams
): actionsTypes.UpdateCompanyPageParamsRequestAction => ({
  type: actionsTypes.UPDATE_COMPANY_PAGE_PARAMS_REQUEST,
  payload,
});

export const handleUpdateCompanyPageParamsSuccess = (
  payload: string
): actionsTypes.UpdateCompanyPageParamsSuccessAction => ({
  type: actionsTypes.UPDATE_COMPANY_PAGE_PARAMS_SUCCESS,
  payload,
});

export const handleUpdateCompanyPageParamsFail = (payload: string): actionsTypes.UpdateCompanyPageParamsFailAction => ({
  type: actionsTypes.UPDATE_COMPANY_PAGE_PARAMS_FAIL,
  payload,
});
