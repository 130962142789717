import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WaitlistedRole {
  platformRoleId: string;
  name: string;
  markedAsWaitlisted: boolean;
}

export interface WaitlistedRolesState {
  loading: boolean;
  error: string;
  roles: WaitlistedRole[];
}

const initialState: WaitlistedRolesState = {
  loading: false,
  error: '',
  roles: [],
};

export const waitlistedRoles = createSlice({
  name: 'waitlistedRoles',
  initialState,
  reducers: {
    saveWaitlistedRoles: (state, action: PayloadAction<WaitlistedRole[]>) => {
      state.roles = action.payload;
    },
    updateWaitlistedRolesRequest: (state, action: PayloadAction<WaitlistedRole[]>) => {
      state.loading = true;
      state.roles = action.payload;
    },
    updateWaitlistedRolesSuccess: (state) => {
      state.loading = false;
    },
    updateWaitlistedRolesFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.roles = initialState.roles;
    },
    getWaitlistedRolesRequest: (state) => {
      state.loading = true;
    },
    getWaitlistedRolesSuccess: (state, action: PayloadAction<WaitlistedRole[]>) => {
      state.loading = false;
      state.roles = action.payload;
    },
    getWaitlistedRolesFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  saveWaitlistedRoles,
  updateWaitlistedRolesRequest,
  updateWaitlistedRolesSuccess,
  updateWaitlistedRolesFail,
  getWaitlistedRolesRequest,
  getWaitlistedRolesSuccess,
  getWaitlistedRolesFail,
} = waitlistedRoles.actions;

export default waitlistedRoles.reducer;
