import {
  FilterWarmLeadsFileRequestModel,
  MixWarmLeadsFilesRequestModel,
} from 'features/warm-leads-utils/warm-leads-utils-request-model';

// Filter file
export const FILTER_WARM_LEADS_FILE_REQUEST = 'warm-leads-utils/FILTER_WARM_LEADS_FILE_REQUEST';
export interface FilterWarmLeadsFileRequestAction {
  type: typeof FILTER_WARM_LEADS_FILE_REQUEST;
  payload: FilterWarmLeadsFileRequestModel;
}

export const FILTER_WARM_LEADS_FILE_REQUEST_SUCCESS = 'warm-leads-utils/FILTER_WARM_LEADS_FILE_REQUEST_SUCCESS';
export interface FilterWarmLeadsFileRequestSuccessAction {
  type: typeof FILTER_WARM_LEADS_FILE_REQUEST_SUCCESS;
}

export const FILTER_WARM_LEADS_FILE_REQUEST_FAIL = 'warm-leads-utils/FILTER_WARM_LEADS_FILE_REQUEST_FAIL';
export interface FilterWarmLeadsFileRequestFailAction {
  type: typeof FILTER_WARM_LEADS_FILE_REQUEST_FAIL;
  payload: string;
}

// Mix files
export const MIX_WARM_LEADS_FILES_REQUEST = 'warm-leads-utils/MIX_WARM_LEADS_FILES_REQUEST';
export interface MixWarmLeadsFilesRequestAction {
  type: typeof MIX_WARM_LEADS_FILES_REQUEST;
  payload: MixWarmLeadsFilesRequestModel;
}

export const MIX_WARM_LEADS_FILES_REQUEST_SUCCESS = 'warm-leads-utils/MIX_WARM_LEADS_FILES_REQUEST_SUCCESS';
export interface MixWarmLeadsFilesRequestSuccessAction {
  type: typeof MIX_WARM_LEADS_FILES_REQUEST_SUCCESS;
}

export const MIX_WARM_LEADS_FILES_REQUEST_FAIL = 'warm-leads-utils/MIX_WARM_LEADS_FILES_REQUEST_FAIL';
export interface MixWarmLeadsFilesRequestFailAction {
  type: typeof MIX_WARM_LEADS_FILES_REQUEST_FAIL;
  payload: string;
}

export type LinkedInJobsActionType =
  | FilterWarmLeadsFileRequestAction
  | FilterWarmLeadsFileRequestSuccessAction
  | FilterWarmLeadsFileRequestFailAction
  | MixWarmLeadsFilesRequestAction
  | MixWarmLeadsFilesRequestSuccessAction
  | MixWarmLeadsFilesRequestFailAction;
