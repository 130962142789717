import { IconProps, theme } from '@a_team/ui-components';
import {
  ColdIdeasLocation,
  CompanyPagesGenerationLocation,
  CompanyPagesSettingsLocation,
  DqsLocation,
  EnrichCompaniesLocation,
  FacebookFriendsLocation,
  IntroSearchLocation,
  IntroSearchMonitoringLocation,
  IntroSearchSettingsLocation,
  LinkedInJobPostingsLocation,
  LqsLocation,
  LqsSettingsLocation,
  OutboundLocation,
  ScrubbingLocation,
  ScsSettingsLocation,
  SgeLocation,
  SocialConnectionsScoreLocation,
  SupplyRequestsLocation,
  TargetedLeadsLocation,
  WaitlistedRolesLocation,
  WarmLeadsLocation,
} from './locations';

export type MenuItemProps = {
  label: string;
  action?: () => void;
  actionIcon?: string;
  path: string | null;
  icon?: IconProps['name'];
  iconSize?: IconProps['size'];
  children?: MenuItemProps[];
  active?: boolean;
  badge?: string;
};

export interface HomepageColumnConfig {
  label: string;
  path: string;
  color?: string;
}

export const homepageConfig: { title: string; items: HomepageColumnConfig[] }[] = [
  {
    title: 'LQS',
    items: [
      {
        label: 'Run LQS',
        path: LqsLocation,
        color: theme.colors.Baracus[300],
      },
    ],
  },
  {
    title: 'Enrich',
    items: [
      {
        label: 'Scrape LinkedIn jobs boards',
        path: LinkedInJobPostingsLocation,
        color: theme.colors.Green[300],
      },
      {
        label: 'Scrape Facebook connections of people',
        path: FacebookFriendsLocation,
        color: theme.colors.Green[400],
      },
      {
        label: 'Find connections (past co-workers) of people',
        path: SocialConnectionsScoreLocation,
        color: theme.colors.Green[500],
      },
      {
        label: 'Find company data',
        path: EnrichCompaniesLocation,
        color: theme.colors.Green[600],
      },
    ],
  },
  {
    title: 'Find',
    items: [
      {
        label: 'Find LinkedIn profiles of potential leads (per company/role)',
        color: theme.colors.Murdock[300],
        path: OutboundLocation,
      },
      {
        label: 'Find LinkedIn profiles of potential builders (per tech stack)',
        color: theme.colors.Murdock[400],
        path: SgeLocation,
      },
      {
        label: 'Find path to a Demand Lead',
        color: theme.colors.Murdock[500],
        path: TargetedLeadsLocation,
      },
      {
        label: 'Find path to a Demand Company',
        color: theme.colors.Murdock[600],
        path: IntroSearchLocation,
      },
    ],
  },
  {
    title: 'Create',
    items: [
      {
        label: 'Generate highly personalized emails leveraging various data points',
        color: theme.colors.Hannibal[200],
        path: WarmLeadsLocation,
      },
      {
        label: 'Generate ideas for companies using GPT',
        color: theme.colors.Hannibal[300],
        path: ColdIdeasLocation,
      },
      {
        label: 'Generate personalized landing pages for companies',
        color: theme.colors.Hannibal[400],
        path: CompanyPagesGenerationLocation,
      },
    ],
  },
];

export const settingsTabs = [
  {
    label: 'Scrubbing',
    path: ScrubbingLocation,
  },
  {
    label: 'Waitlisted Roles',
    path: WaitlistedRolesLocation,
  },
  {
    label: 'LQS',
    path: LqsSettingsLocation,
  },
  {
    label: 'Intro Search Settings',
    path: IntroSearchSettingsLocation,
  },
  {
    label: 'Client App Sign Up',
    path: DqsLocation,
  },
  {
    label: 'SCS',
    path: ScsSettingsLocation,
  },
  {
    label: 'LinkedIn Roles & Company Pages',
    path: CompanyPagesSettingsLocation,
  },
];

export const monitoringTabs = [
  {
    label: 'Supply Requests',
    path: SupplyRequestsLocation,
  },
  {
    label: 'Intro Search',
    path: IntroSearchMonitoringLocation,
  },
];
